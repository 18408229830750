export enum Applications {
  Fla = 'Partnerships',
  Reporting = 'Reporting',
  Home = 'Home',
  Invoicing = 'Invoicing'
}

export interface EnvironmentVariables {
  production: boolean;
  appName: Applications;
  baseUrl: string;
  publicUrl: string;
  environment: string;
  authUrl: string;
  logoutUrl: string;
  authenticationClientId: string;
  authRedirectUrl: string;
  authLogoutRedirectUrl: string;
  transifexToken: string;
  sentryDsn: string;
  reactAppBaseRoute: string;
  deployedOnRootPath?: boolean;
  enableFlaAmendment: boolean;
  supportedLanguages: string;
  enableReportingOverviewPage: boolean;
  enablePreferencesPage: boolean;
}
