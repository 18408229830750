import { useLandingPageStats } from '../../../../services';
import { countriesGeo } from './countriesGeoJson';

interface CountryProps {
  lat: number;
  lng: number;
  name?: string;
}

interface Properties {
  filename: string;
  iso_a2: string;
  label_y: number;
  label_x: number;
  name?: string;
  formal_en?: string;
}

interface Polygon {
  type: string;
  coordinates: Array<Array<Array<number>>>;
}

interface MultiPolygon {
  type: string;
  coordinates: Array<Array<Array<Array<number>>>>;
}

interface Feature {
  type: string;
  properties: Properties;
  geometry?: MultiPolygon | Polygon;
}

type CountryPropsArray = Array<CountryProps>;

export const useLandingPage = () => {
  const { data } = useLandingPageStats();

  const features: Array<Feature> = countriesGeo.features;

  const filteredFeatures = features.filter((feature) =>
    data?.countries?.includes(feature.properties.iso_a2)
  );

  const transformedFeatures: CountryPropsArray = filteredFeatures.map(
    (feature) => ({
      lat: feature.properties.label_y,
      lng: feature.properties.label_x,
      name: feature.properties.name
    })
  );

  return {
    stats: { ...data, countries: transformedFeatures }
  };
};
