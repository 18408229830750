import { T } from '@transifex/react';
import { Blockquote, Button, Hero, Loading, Text, Wrapper } from '@wfp/ui';
import './HomeView.scss';
import { Separator } from '@partnerconnect-monorepo/shared';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { t } from '@transifex/native';
import { LandingPage } from './LandingPage/LandingPage.connect';

interface HomeViewProps {
  isAuthenticated: boolean;
  canAccessReporting: boolean;
  canAccessPartnerships: boolean;
  canAccessInvoicing: boolean;
  username: string;
  signinRedirect: () => void;
  isLoading: boolean;
}

export const HomeView = ({
  isAuthenticated,
  canAccessReporting,
  canAccessPartnerships,
  canAccessInvoicing,
  username,
  signinRedirect,
  isLoading
}: HomeViewProps) =>
  isLoading ? (
    <Loading />
  ) : (
    <main id="homepage">
      {isAuthenticated ? (
        <>
          <Hero
            id="homepage-hero"
            image="../assets/hero.webp"
            kind="splash-image"
            title={
              <Text kind="h1">
                <T _str="Partner Connect" />
              </Text>
            }
            subTitle={
              <>
                <Text className="subtitle" spacingBottom="lg">
                  <T _str="The gateway between WFP and our cooperating partners" />
                </Text>

                <div className="buttons">
                  {canAccessReporting && (
                    <Button
                      onClick={() => window.location.assign('/reporting/')}
                    >
                      <T _str="Reports" />
                    </Button>
                  )}
                  {canAccessPartnerships && (
                    <Button
                      onClick={() => window.location.assign('/partnerships/')}
                    >
                      <T _str="Partnerships" />
                    </Button>
                  )}
                  {canAccessInvoicing && (
                    <Button
                      onClick={() => window.location.assign('/invoicing/')}
                    >
                      <T _str="Finances" />
                    </Button>
                  )}
                  {!canAccessReporting &&
                    !canAccessPartnerships &&
                    !canAccessInvoicing && (
                      <Blockquote
                        title={
                          <T
                            _str="Sorry {username}. You do not have access yet!"
                            username={username}
                          />
                        }
                        withIcon
                        className="info-for-user-with-no-access"
                      >
                        <Text kind="p">
                          <T _str="Please contact your local WFP focal to get access to what you need in Partner Connect." />
                        </Text>
                      </Blockquote>
                    )}
                </div>
              </>
            }
          />
          <Wrapper pageWidth="lg" mobilePageWidth="full" id="homepage-about">
            <Text kind="h1">
              <T _str="What is Partner Connect?" />
            </Text>
            <Separator className="separator" />
            <Grid className="grid">
              <Row middle="md">
                <Col sm={12} md={6} className="col">
                  <Text kind="p">
                    <T _str="Partner Connect is a system of engagement for cooperating partners working with WFP." />
                  </Text>
                  <Text kind="p">
                    <T _str="Currently, Partner Connect can handle distribution reporting from cooperating partners to WFP in selected countries." />
                  </Text>
                  <Text kind="p">
                    <T _str="More functions and more countries are being added continuously." />
                  </Text>
                </Col>
                <Col sm={12} md={6} className="col">
                  <img
                    src="../assets/about.png"
                    alt={t('Screenshot of Partner Connect user interface')}
                  />
                </Col>
              </Row>
            </Grid>
          </Wrapper>
          <Hero
            kind="splash-compact"
            title={
              <span>
                <Text kind="h2">
                  <T _str="Questions? Contact us:" />
                </Text>
                <a href="mailto:global.partnerconnect@wfp.org">
                  <Text kind="h5">
                    <T _str="global.partnerconnect@wfp.org" />
                  </Text>
                </a>
              </span>
            }
            id="homepage-contact"
          />
        </>
      ) : (
        <LandingPage />
      )}
    </main>
  );
