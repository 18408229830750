import {
  Permission,
  hasPermission,
  useSigninRedirect,
  useUserProfile
} from '@partnerconnect-monorepo/shared';
import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';

export const useHome = () => {
  const { isAuthenticated } = useAuth();

  const { signinRedirect } = useSigninRedirect();

  const { username, userProfile, isLoading } = useUserProfile();

  const canAccessReporting = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewMonthlyReports
      ),
    [userProfile?.permissions]
  );

  const canAccessPartnerships = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanViewPartnershipOpportunity
      ),
    [userProfile?.permissions]
  );

  const canAccessInvoicing = useMemo(
    () =>
      hasPermission(
        userProfile?.permissions ?? [],
        Permission.CanAccessInvoicing
      ),
    [userProfile?.permissions]
  );

  return {
    isAuthenticated,
    canAccessReporting,
    canAccessPartnerships,
    canAccessInvoicing,
    username: username,
    signinRedirect,
    isLoading
  };
};
