import { T } from '@transifex/react';
import { Toggle } from '@wfp/ui';
import { Row, Col } from 'react-flexbox-grid';
import { Control, Controller } from 'react-hook-form';
import { NOTIFICATION_FREQUENCY_OPTIONS } from '../../../constants';
import { NotificationsFormFields } from '@partnerconnect-monorepo/types';
import Select from 'react-select';

interface NotificationProps {
  control: Control<NotificationsFormFields, any>;
  notificationIdx: number;
  notificationLabel: string;
  module: 'reportingModuleNotifications' | 'partnershipsModuleNotifications';
}

export const Notification = ({
  control,
  notificationIdx,
  notificationLabel,
  module
}: NotificationProps) => (
  <Row middle="xs">
    <Col>
      <Controller
        control={control}
        name={`${module}.${notificationIdx}.enabled`}
        render={({ field }) => (
          <Toggle
            id={module + notificationIdx}
            toggled={field.value}
            onToggle={field.onChange}
            labelA=""
            labelB=""
          />
        )}
      />
    </Col>
    <Col xs>{notificationLabel}</Col>
    <Col xs={4}>
      <Controller
        control={control}
        name={`${module}.${notificationIdx}.frequency`}
        render={({ field }) => (
          <Select
            {...field}
            placeholder={<T _str="Click to select" />}
            className="wfp--react-select-container"
            classNamePrefix="wfp--react-select"
            options={NOTIFICATION_FREQUENCY_OPTIONS}
          />
        )}
      />
    </Col>
  </Row>
);
