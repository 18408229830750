import { OptionElement } from '../../types';
import { FormikAdHocSubmissionCommodity } from '../adhoc-distribution-report/FormikAdhocSubmission';
import { BeneficiaryGroupSplit } from '../Commodity';
import { ParticipantsAndBeneficiaries } from '../ParticipantsAndBeneficiaries';
import { APIMonthlyDistributionReportAssistanceMonth } from './APIMonthlyDistributionReport';
export interface FormikMonthlyDistributionReportAssistanceMonth
  extends APIMonthlyDistributionReportAssistanceMonth {
  monthName: string;
  maxDays: number;
  required?: boolean;
}

export interface FormikMonthlyDistributionReportCommodity
  extends FormikAdHocSubmissionCommodity {
  openStock: string;
  closingStock: string;
  isAddedFromOpenStock?: boolean;
  isUpdatedFromOpenStock?: boolean;
  beneficiaryGroupSplits: Array<BeneficiaryGroupSplit>;
  outboundDeliveryNumber: string | null;
}

export interface FormikMonthlyDistributionReportStockOnly {
  basicInformation: {
    orgUnit: OptionElement | null;
    distributionSite: OptionElement | null;
    assistanceMonths: Array<FormikMonthlyDistributionReportAssistanceMonth>;
    problemsEncountered: OptionElement | null;
    notes: string;
    isOverridingAssistanceFeedingDaysCoverage: boolean;
    attachments: Array<FormikMonthlyDistributionReportAttachment>;
  };
  commodities: Array<FormikMonthlyDistributionReportCommodity>;
  report: string;
  asDraft?: boolean;
  step: string;
}
export interface FormikMonthlyDistributionReport
  extends FormikMonthlyDistributionReportStockOnly {
  participantsAndBeneficiaries: ParticipantsAndBeneficiaries;
}

export enum MonthlyDistributionReportAttachmentType {
  Waybill = 'waybill',
  NutritionKpisReports = 'nutrition_kpis_reports',
  NarrativeReports = 'narrative_reports',
  LossReports = 'loss_reports',
  Other = 'other'
}

interface FormikMonthlyDistributionReportAttachmentTypeOptionElement
  extends OptionElement {
  __isNew__?: boolean;
}

export interface FormikMonthlyDistributionReportAttachment {
  report: string;
  type?: FormikMonthlyDistributionReportAttachmentTypeOptionElement;
  typeOther?: string;
  file: File | string;
  filename: string;
  id: string;
  uploadedBy: string;
}
