import { PropsWithChildren, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import CacheBuster from 'react-cache-buster';
import { Loading } from '@wfp/ui';
import {
  UserProfileProvider,
  ContextsProvider,
  OnlineStatusProvider,
  RoutesContextProvider,
  SnackbarContextProvider,
  useEnv
} from '../context';
import { RoutesList } from './RoutesList';
import { ComposeContexts } from './ComposeProviders';
import { VERSION } from '../utils/version';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../styles/styles.scss';
import 'react-dates/initialize';
import { AuthProvider } from 'react-oidc-context';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { AxiosInterceptor } from './AxiosInterceptor';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';
import { FlagsProvider } from 'flagged';
import { FeatureFlag } from '../constants';
import '../styles/mobileStyles.scss';
import pckg from '../../../../../package.json';

L.Marker.prototype.options.icon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [24, 41],
  iconAnchor: [12, 41]
});

const client = new QueryClient({
  defaultOptions: {
    queries: {
      // staleTime: 5000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      retryOnMount: false,
      retry: false
    }
  }
});

export function Core({ children }: PropsWithChildren) {
  const [performAuth, setPerformAuth] = useState(false);

  const env = useEnv();

  const oidcConfig: UserManagerSettings = {
    authority: env.authUrl,
    client_id: env.authenticationClientId,
    redirect_uri: env.authRedirectUrl,
    post_logout_redirect_uri: env.authLogoutRedirectUrl,
    metadata: {
      issuer: `${env.authUrl}/`,
      authorization_endpoint: `${env.authUrl}/oauth2/authorize`,
      token_endpoint: `${env.authUrl}/oauth2/token`,
      end_session_endpoint: env.logoutUrl
    },
    userStore: new WebStorageStateStore({ store: window.localStorage })
  };

  const onSigninCallback = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

  useEffect(() => {
    if (env?.sentryDsn) {
      Sentry.init({
        dsn: env.sentryDsn,
        release: VERSION,
        environment: env.environment,
        tracesSampleRate: 0.001
      });
    }
    if (env?.baseUrl) {
      setPerformAuth(true);
    }
  }, [env]);

  // Uncomment when An error writing JSON Object to meta.json will be resolved
  // const isProduction = process.env.NODE_ENV === 'production';
  // isEnabled={isProduction}
  // Add yarn run generate-meta-tag && to yml file

  return (
    // <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <CacheBuster
      currentVersion={pckg.version}
      isEnabled={false} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <FlagsProvider
        features={{
          [FeatureFlag.FlaAmendment]: env.enableFlaAmendment ?? false,
          [FeatureFlag.ReportingOverviewPage]:
            env.enableReportingOverviewPage ?? false,
          [FeatureFlag.PreferencesPage]: env.enablePreferencesPage ?? false
        }}
      >
        <QueryClientProvider client={client}>
          <OnlineStatusProvider>
            <SnackbarContextProvider hideProgressBar={true}>
              <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
                <AxiosInterceptor>
                  <UserProfileProvider performAuth={performAuth}>
                    <BrowserRouter basename={env.reactAppBaseRoute}>
                      <RoutesContextProvider>
                        <ContextsProvider>
                          <ComposeContexts>
                            {children}
                            <RoutesList />
                          </ComposeContexts>
                        </ContextsProvider>
                      </RoutesContextProvider>
                    </BrowserRouter>
                  </UserProfileProvider>
                </AxiosInterceptor>
              </AuthProvider>
            </SnackbarContextProvider>
          </OnlineStatusProvider>
        </QueryClientProvider>
      </FlagsProvider>
    </CacheBuster>
    // </Sentry.ErrorBoundary>
  );
}
