import { T } from '@transifex/react';
import { InfoBar } from '@wfp/ui';

export const OfflineBanner = () => {
  return (
    <InfoBar>
      <T _str="OFFLINE: You are currently offline and Partner Connect is limited. You can still report distributions." />
    </InfoBar>
  );
};
