import { useQuery } from 'react-query';
import { axios } from '@partnerconnect-monorepo/shared';

interface States {
  partners: number;
  modules: number;
  countries: Array<string>;
}

export const getLandingPageStats = (): Promise<States> =>
  axios.get(`/stats`).then((res) => res.data);

export function useLandingPageStats() {
  return useQuery('LANDING_PAGE_STATS', getLandingPageStats);
}
