import localforage from 'localforage';

export const COMMODITIES_LOCAL_FORAGE_STORE_NAME = 'commodities';

const dbName = 'WFP';
const commoditiesLocalForage = localforage.createInstance({
  name: dbName,
  storeName: COMMODITIES_LOCAL_FORAGE_STORE_NAME
});

export { commoditiesLocalForage };
