import { T } from '@transifex/react';
import {
  SecondaryNavigation,
  Wrapper,
  Blockquote,
  SecondaryNavigationTitle
} from '@wfp/ui';
import { useSupport } from './Support.hooks';
import './Support.scss';

export const Support = () => {
  const { supportMaterials } = useSupport();

  return (
    <>
      <SecondaryNavigation>
        <SecondaryNavigationTitle>
          <T _str="Partner Connect Support" />
        </SecondaryNavigationTitle>
      </SecondaryNavigation>
      <section className="support">
        <Wrapper pageWidth="lg">
          <h4>
            <T _str="Training and support materials" />
          </h4>
          <p>
            <T _str="If you are experiencing issues with Partner Connect, you might be able to find the help needed in our FAQ or in the training materials listed below:" />
          </p>
          {supportMaterials.length > 0 &&
            supportMaterials.map((material: any) => (
              <a
                href={material.url}
                className="wfp--link support-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {material.title}
              </a>
            ))}
          <div className="contact">
            <h4>
              <T _str="Contact us" />
            </h4>
            <p>
              <T _str="For all Partner Connect support, please send an email to our global support email:" />
            </p>
            <Blockquote>
              <T
                _str="Partner Connect global support: {link}"
                link={
                  <a
                    href="mailto:global.partnerconnect@wfp.org"
                    aria-label="Partner Connect support"
                    className="wfp--link"
                    data-testid="footer-partner-connect-support"
                  >
                    global.partnerconnect@wfp.org
                  </a>
                }
              />
            </Blockquote>
          </div>
        </Wrapper>
      </section>
    </>
  );
};
