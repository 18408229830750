import './styles.scss';
import { useEnv } from '../../../context/environment';

export const EnvironmentBanner = () => {
  const env = useEnv();

  if (env?.environment && env.environment !== 'production') {
    return (
      <div className={`environment-indicator ${env.environment}`}>
        {env.environment}
      </div>
    );
  }
  return null;
};
