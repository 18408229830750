import { IRouteProps } from '@partnerconnect-monorepo/types';
import { Home } from './pages/Home';

export const routes: Array<IRouteProps> = [
  {
    path: '',
    component: Home,
    isDefault: true
  }
];
