import {
  APINotificationInterface,
  NotificationInterface
} from '@partnerconnect-monorepo/types';
import { axios } from '../core';
import { mapToCamelCase } from '../helpers';
import { UseQueryOptions, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { NOTIFICATION_FREQUENCY_OPTIONS } from '../constants';

const getNotifications = (moduleUrl: string) =>
  axios
    .get(`${moduleUrl}/notifications`)
    .then<Array<APINotificationInterface>>((apiNotifications) =>
      mapToCamelCase(apiNotifications.data)
    )
    .then<Array<NotificationInterface>>((notifications) =>
      notifications.map((notification) => ({
        ...notification,
        frequency: NOTIFICATION_FREQUENCY_OPTIONS.find(
          ({ value }) => value === notification.frequency
        )
      }))
    );

export const useReportingModuleNotifications = (
  options: UseQueryOptions<
    Array<NotificationInterface>,
    AxiosError,
    Array<NotificationInterface>,
    [string]
  > = {}
) =>
  useQuery(
    ['REPORTING_MODULE_NOTIFICATIONS'],
    () => getNotifications(process.env.NX_REPORTING_APP_BE_BASE ?? ''),
    options
  );

export const usePartnershipsModuleNotifications = (
  options: UseQueryOptions<
    Array<NotificationInterface>,
    AxiosError,
    Array<NotificationInterface>,
    [string]
  > = {}
) =>
  useQuery(
    ['PARTNERSHIPS_MODULE_NOTIFICATIONS'],
    () => getNotifications(process.env.NX_FLA_APP_BE_BASE ?? ''),
    options
  );
