import localforage from 'localforage';

const dbName = 'WFP';
const distributionReportsLocalForage = localforage.createInstance({
  name: dbName,
  storeName: 'distributionReports'
});

const getAllItems = (localForage: LocalForage) => {
  return new Promise((resolve) => {
    const result: any = [];
    localForage
      .iterate((value: any) => {
        try {
          result.push(JSON.parse(value));
        } catch {
          result.push(value);
        }
      })
      .then(() => {
        resolve(result);
      });
  });
};

export { distributionReportsLocalForage, getAllItems };
