import {
  NotificationInterface,
  NotificationsFormFields
} from '@partnerconnect-monorepo/types';
import { T } from '@transifex/react';
import { Loading, Text } from '@wfp/ui';
import { Control } from 'react-hook-form';
import './Notifications.scss';
import { Notification } from './Notification';

interface NotificationsViewProps {
  reportingModuleNotifications: Array<NotificationInterface> | undefined;
  partnershipsModuleNotifications: Array<NotificationInterface> | undefined;
  control: Control<NotificationsFormFields, any>;
  isLoading: boolean;
}

export const NotificationsView = ({
  reportingModuleNotifications,
  partnershipsModuleNotifications,
  control,
  isLoading
}: NotificationsViewProps) => (
  <>
    <Text kind="h4">
      <T _str="Notifications" />
    </Text>
    {isLoading ? (
      <Loading withOverlay={false} small />
    ) : !reportingModuleNotifications?.length &&
      !partnershipsModuleNotifications?.length ? (
      <Text kind="p">
        <T _str="No notifications to configure" />
      </Text>
    ) : (
      <>
        {!!reportingModuleNotifications?.length && (
          <>
            <Text kind="h5" className="mb-1">
              <T _str="Reporting" />
            </Text>
            <div className="notifications">
              {reportingModuleNotifications?.map(
                ({ name }, notificationIdx) => (
                  <Notification
                    control={control}
                    notificationIdx={notificationIdx}
                    notificationLabel={name}
                    module="reportingModuleNotifications"
                  />
                )
              )}
            </div>
          </>
        )}
        {!!partnershipsModuleNotifications?.length && (
          <>
            <Text kind="h5" className="mb-1">
              <T _str="Partnerships & Finances" />
            </Text>
            <div className="notifications">
              {partnershipsModuleNotifications?.map(
                ({ name }, notificationIdx) => (
                  <Notification
                    control={control}
                    notificationIdx={notificationIdx}
                    notificationLabel={name}
                    module="partnershipsModuleNotifications"
                  />
                )
              )}
            </div>
          </>
        )}
      </>
    )}
  </>
);
