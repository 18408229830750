import { T } from '@transifex/react';
import {
  MapContainer,
  Marker as LocationMarker,
  GeoJSON,
  Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import { Text, Hero } from '@wfp/ui';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { countriesGeo } from '../countriesGeoJson';
import { useLandingPage } from '../LandingPage.hook';

const icon = L.icon({
  iconSize: [13, 13],
  iconUrl: '../../assets/customIcons/locatorMarker.svg'
});

const DEFAULT_STATS = {
  partners: 0,
  modules: 0,
  countries: []
};
const INITIAL_ZOOM = 2;
const INITIAL_MAP_CENTER = { lat: 43, lng: 1 };

export const LandingPageMap = () => {
  const { stats } = useLandingPage();

  const { partners, modules, countries } = stats || DEFAULT_STATS;
  return (
    <div className="landing-page-map">
      <div>
        <MapContainer
          center={INITIAL_MAP_CENTER}
          zoom={INITIAL_ZOOM}
          scrollWheelZoom={false}
          className="map-container"
        >
          {countries?.map((country) => (
            <LocationMarker position={country} icon={icon}>
              {country.name && <Tooltip>{country.name}</Tooltip>}
            </LocationMarker>
          ))}
          <GeoJSON
            style={{
              weight: 1,
              fillColor: '#99cbe5',
              fillOpacity: 1,
              color: '#f9fafb'
            }}
            data={countriesGeo}
          />
        </MapContainer>
      </div>

      <Hero
        kind="splash-compact"
        className="landing-page-about-map landing-page-splash-compact"
        title={
          <Grid>
            <Row middle="sm">
              <Col sm={12} md={4} className="col">
                <Col sm={12} md={12}>
                  <div className="landing-page-about-map__amount-block">
                    <Text kind="h3">
                      {modules}{' '}
                      <span>
                        <T _str="modules" />
                      </span>
                    </Text>
                  </div>
                  <Text kind="subtitle">
                    <T _str="Partnership, Distribution Reporting, Finances" />
                  </Text>
                </Col>
              </Col>
              <Col sm={12} md={4} className="col">
                <Col sm={12} md={12}>
                  <div className="landing-page-about-map__amount-block">
                    <Text kind="h3">
                      {partners}{' '}
                      <span>
                        <T _str="partners" />
                      </span>
                    </Text>
                  </div>
                  <Text kind="subtitle">
                    <T _str="Using the Distribution Reporting system" />
                  </Text>
                </Col>
              </Col>
              <Col sm={12} md={4} className="col">
                <Col sm={12} md={12}>
                  <div className="landing-page-about-map__amount-block">
                    <Text kind="h3">
                      {countries?.length}{' '}
                      <span>
                        <T _str="countries" />
                      </span>
                    </Text>
                  </div>
                  <Text kind="subtitle">
                    <T _str="Users from Ethiopia, Uganda and more" />
                  </Text>
                </Col>
              </Col>
            </Row>
          </Grid>
        }
      />
    </div>
  );
};
