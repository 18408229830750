import { MonthlyDistributionReportAttachmentType } from '@partnerconnect-monorepo/types';
import { t } from '@transifex/native';

export const AttachmentTypeOptions = [
  {
    value: MonthlyDistributionReportAttachmentType.Waybill,
    label: t('Waybill')
  },
  {
    value: MonthlyDistributionReportAttachmentType.NutritionKpisReports,
    label: t('Nurtition KPIs reports')
  },
  {
    value: MonthlyDistributionReportAttachmentType.NarrativeReports,
    label: t('Narrative reports')
  },
  {
    value: MonthlyDistributionReportAttachmentType.LossReports,
    label: t('Loss reports')
  }
];
