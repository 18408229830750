import { useNotifications } from './Notifications.hooks';
import { NotificationsView } from './NotificationsView';

export const Notifications = () => {
  const {
    reportingModuleNotifications,
    partnershipsModuleNotifications,
    control,
    isLoading
  } = useNotifications();

  return (
    <NotificationsView
      reportingModuleNotifications={reportingModuleNotifications}
      partnershipsModuleNotifications={partnershipsModuleNotifications}
      control={control}
      isLoading={isLoading}
    />
  );
};
