import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react';
import {
  OptionElement,
  PartnershipInterface
} from '@partnerconnect-monorepo/types';
import { getUser } from '../api/user';
import {
  getUserNameFromToken,
  isWfpUser,
  mapInitialUserData
} from '../helpers';
import { useAuth } from 'react-oidc-context';
import { isEqual } from 'lodash';

export type UserProfile = {
  reportingCountry: OptionElement | null;
  partnershipsCountry: {
    iso2Code: string;
    name: string;
  } | null;
  allowedModules?: {
    canAccessPartnerships: boolean;
    canAccessReporting: boolean;
  };
  isNew?: boolean;
  userType: string;
  userTypeCode: string;
  firstName: string;
  lastName: string;
  partnerName: string;
  email: string;
  groups?: Array<string>;
  partnerships: Array<PartnershipInterface>;
  roles: Array<OptionElement>;
  reportingRoles?: Array<OptionElement>;
  flaRoles?: Array<OptionElement>;
  canAssignCountriesList: Array<OptionElement>;
  canAssignPartnerRolesList: Array<OptionElement>;
  canAssignWfpRolesList: Array<OptionElement>;
  permissions: Array<string>;
} | null;

type Action =
  | {
      type: 'setUserProfile';
      userProfile: UserProfile;
    }
  | {
      type: 'stopLoading';
    };

type Dispatch = (action: Action) => void;

interface State {
  userProfile: UserProfile;
  isWfpUser: boolean;
  isLoading: boolean;
  username: string;
}

interface UserProfileContextInterface extends State {
  userProfileDispatch: Dispatch;
}

const initialValues: State = {
  userProfile: null,
  isWfpUser: false,
  isLoading: true,
  username: ''
};

function userProfileReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setUserProfile': {
      return {
        ...state,
        userProfile: action.userProfile,
        isWfpUser: isWfpUser(action.userProfile?.roles),
        isLoading: false,
        username: action.userProfile?.firstName
          ? action.userProfile?.firstName
          : getUserNameFromToken() ?? ''
      };
    }
    case 'stopLoading': {
      return {
        ...state,
        isLoading: false
      };
    }
    default:
      return state;
  }
}

const UserProfileContext = createContext<
  UserProfileContextInterface | undefined
>(undefined);

function UserProfileProvider({
  performAuth,
  children
}: PropsWithChildren<{ performAuth?: boolean }>) {
  const { user } = useAuth();

  const [state, dispatch] = useReducer(
    (state: State, action: Action) => userProfileReducer(state, action),
    initialValues
  );

  const { userProfile, isLoading, isWfpUser, username } = state;

  const value: UserProfileContextInterface = {
    userProfile,
    isWfpUser,
    isLoading,
    userProfileDispatch: dispatch,
    username
  };

  const authenticate = useCallback(() => {
    if (user?.id_token && !user.expired) {
      return getUser()
        .then((res) => {
          const mappedUser = mapInitialUserData(res.data);
          !isEqual(userProfile, mappedUser) &&
            dispatch({ type: 'setUserProfile', userProfile: mappedUser });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return dispatch({ type: 'stopLoading' });
  }, [user?.expired, user?.id_token, userProfile]);

  useEffect(() => {
    if (performAuth) {
      authenticate();
    }
  }, [performAuth, authenticate]);

  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
}

function useUserProfile() {
  const context = useContext(UserProfileContext);

  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileProvider');
  }

  return context;
}

export { UserProfileProvider, useUserProfile };
