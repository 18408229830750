import {
  isPlainObject,
  mapKeys,
  mapValues,
  snakeCase,
  camelCase
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment, { duration } from 'moment';
import { PERMISSIONS } from '../utils/permissions';
import {
  ActivityLocation,
  PartnershipInterface,
  ParticipantGroupRow,
  BeneficiaryGroupRow,
  APIMonthlyDistributionReportParticipantsAndBeneficiariesGroup,
  FormikMonthlyDistributionReportStockOnly,
  FormikMonthlyDistributionReport,
  APIMonthlyDistributionReportAssistanceMonth,
  FormikMonthlyDistributionReportAssistanceMonth,
  BeneficiaryTableConfig,
  APIBeneficiaryGroup,
  APIAdHocSubmissionBeneficiaryGroup,
  FormikAdHocSubmissionCommodity,
  APICommodityMovement,
  FormikMonthlyDistributionReportCommodity,
  ResidenceStatusRow,
  PartnerType,
  OptionElement,
  ActivityTag,
  Demographies,
  APIMonthlyDistrbutionReportAttachment,
  FormikMonthlyDistributionReportAttachment,
  MonthlyDistributionReportAttachmentType
} from '@partnerconnect-monorepo/types';
import {
  AgeGroup,
  getAgeGroupIDByLabel,
  COMMODITIES_DIGITS_AFTER_DECIMAL_POINT
} from '../constants';
import { t } from '@transifex/native';
import { GROUPS } from '../utils/groups';
import { transformDateToShortRepresentation } from './datetime';
import { AttachmentTypeOptions } from '../constants/monthlyDistributionReportAttachments';
import { getReportingUserRoleOptions } from '../utils';

const PERCENTAGE_SQUARE_DIVISOR = 100 * 100;

export const mapKeysDeepLodash = (
  obj: any,
  cb: (v: any, k: any) => any,
  isRecursive?: boolean
): any => {
  if (!obj && !isRecursive) {
    return {};
  }

  if (!isRecursive) {
    if (
      typeof obj === 'string' ||
      typeof obj === 'number' ||
      typeof obj === 'boolean'
    ) {
      return {};
    }
  }

  if (Array.isArray(obj)) {
    return obj.map((item: any) => mapKeysDeepLodash(item, cb, true));
  }

  if (!isPlainObject(obj)) {
    return obj;
  }

  const result = mapKeys(obj, cb);

  return mapValues(result, (value: any) => mapKeysDeepLodash(value, cb, true));
};

export const mapToSnakeCase = (values: any) => {
  return mapKeysDeepLodash(values, (v: any, k: any) => snakeCase(k));
};

export const mapToCamelCase = (values: any) => {
  return mapKeysDeepLodash(values, (v: any, k: any) => camelCase(k));
};

export const mapListToOptions = (values: Array<string>) => {
  return values.map((each) => {
    return {
      value: each,
      label: each
    };
  });
};

export const mapLocationsToOptions = (locations: {
  [key: string]: Array<string>;
}) =>
  Object.keys(locations).reduce((acc: Array<OptionElement>, curr) => {
    if (locations[curr].length > 0) {
      const mappedObjectLocation = locations[curr].map((each) => {
        return {
          value: `${each},${curr}`,
          label: `${each} (${curr})`
        };
      });
      return acc.concat(...mappedObjectLocation);
    }
    return acc.concat({
      value: `,${curr}`,
      label: curr
    });
  }, []);

export const mapAdminBreakDownToCommaSeperatedOptions = (
  locationList: Array<{
    adminBreakdown1: string;
    adminBreakdown2: string;
    id: number;
  }>
) => {
  return locationList.reduce((acc: any, curr) => {
    if (curr.adminBreakdown2) {
      acc.push({
        value: `${curr.adminBreakdown2},${curr.adminBreakdown1}`,
        label: `${curr.adminBreakdown2} (${curr.adminBreakdown1})`
      });
    } else {
      acc.push({
        value: `,${curr.adminBreakdown1}`,
        label: `${curr.adminBreakdown1}`
      });
    }
    return acc;
  }, []);
};

export const getPartnershipOptions = (
  partnerships: PartnerType,
  locale: string
) => {
  const keys = Object.keys(partnerships);
  return keys.map((each) => {
    const startDate = transformDateToShortRepresentation(
      partnerships[each].start_date,
      locale,
      true
    );
    const endDate = transformDateToShortRepresentation(
      partnerships[each].end_date,
      locale,
      true
    );
    return {
      value: each,
      label: `${each} (${startDate} - ${endDate})`
    };
  });
};

export const getLocationOptions = (activityTags: {
  [tag: string]: ActivityTag;
}): Record<string, Array<OptionElement>> =>
  Object.keys(activityTags).reduce(
    (locationOptions, activityTagCode: string) => {
      const locations = activityTags[activityTagCode].locations;
      const locationKeys = Object.keys(locations);
      const formattedLocations = locationKeys.flatMap((adminBreakdown1) => {
        const location = locations[adminBreakdown1];

        return location.map((adminBreakdown2) => ({
          value: adminBreakdown2 ? adminBreakdown2 : adminBreakdown1,
          label: adminBreakdown2
            ? `${adminBreakdown2} (${adminBreakdown1})`
            : adminBreakdown1,
          id: adminBreakdown2
            ? `${adminBreakdown2},${adminBreakdown1}`
            : `,${adminBreakdown1}`
        }));
      });
      locationOptions[activityTagCode] = formattedLocations;

      return locationOptions;
    },
    {} as Record<string, Array<OptionElement>>
  );

export const getLocationBasedOnAdminBreakdown = (
  location: ActivityLocation
) => {
  if (!location.adminBreakdown2) {
    return location.adminBreakdown1;
  }
  return `${location.adminBreakdown2} (${location.adminBreakdown1})`;
};

export const mapInitialUserData = (values: any) => {
  const mappedUser = mapToCamelCase(values);
  if (mappedUser.partnerships && mappedUser.partnerships.length > 0) {
    mappedUser.partnerships = mappedUser.partnerships?.map(
      (partnershipObject: any) => {
        if (partnershipObject.activityTags.length > 0) {
          // eslint-disable-next-line
          partnershipObject.activityTags = partnershipObject.activityTags.map(
            (tag: any) => {
              return {
                ...tag,
                locations: tag.locations.map((location: any) =>
                  getLocationBasedOnAdminBreakdown(location)
                )
              };
            }
          );
        }
        return partnershipObject;
      }
    );
  }
  mappedUser.canAssignWfpRolesList = getReportingUserRoleOptions(
    mappedUser.canAssignWfpRolesList
  );
  mappedUser.canAssignPartnerRolesList = getReportingUserRoleOptions(
    mappedUser.canAssignPartnerRolesList
  );

  return mappedUser;
};

export const mapBeneficiaryGroups = (beneficiaryGroups: any) => {
  if (JSON.stringify(beneficiaryGroups) === '{}') {
    return null;
  } else {
    return beneficiaryGroups?.map((each: any) => {
      return {
        code: each.code,
        description: each.description,
        rows: each.groups.map((group: string) => {
          return {
            id: group,
            group,
            male: 0,
            female: 0,
            total: 0
          };
        })
      };
    });
  }
};
export const mapMonthlyReportBeneficiaryGroups = (
  beneficiaryGroups: Array<APIBeneficiaryGroup>
): Array<APIAdHocSubmissionBeneficiaryGroup> => {
  if (JSON.stringify(beneficiaryGroups) === '{}') {
    return [];
  } else {
    return beneficiaryGroups?.map((beneficiaryGroup, beneficiaryGroupIdx) => {
      return {
        id: beneficiaryGroupIdx,
        description: '',
        code: beneficiaryGroup.groupCode,
        rows: beneficiaryGroup.groups.map((beneficiaryGroupRow) => ({
          id: +getAgeGroupIDByLabel(beneficiaryGroupRow.group),
          group: '' + beneficiaryGroupRow.group,
          male: 0,
          newMale: 0,
          newFemale: 0,
          female: 0,
          total: 0
        }))
      };
    });
  }
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  return currentDate.getFullYear();
};

export const isWfpUser = (
  roles: Array<{ value: string; label: string }> | undefined
) => {
  if (!roles || roles.length === 0) {
    return false;
  }
  return roles.some((role) => role.value.includes('wfp'));
};

export const hasPermission = (
  userPermissions: Array<string>,
  permissionCheck: string
) => {
  if (!userPermissions || !permissionCheck) {
    return false;
  }
  const match = PERMISSIONS[permissionCheck];
  if (!match) {
    return false;
  }
  return userPermissions.includes(match);
};

export const hasGroup = (userGroup: Array<string>, groupCheck: string) => {
  if (!userGroup || !groupCheck) {
    return false;
  }
  const match = GROUPS[groupCheck];
  if (!match) {
    return false;
  }
  return userGroup.includes(match);
};

export const getStringFromOptions = (arr: any) => {
  const mappedArray = arr.map((each: any) => each.label);
  return mappedArray.join(', ');
};

export const parseErrors = (err: any) => {
  if (typeof err === 'string') {
    return [err];
  }
  // filter negative values
  const filtered = err.filter((each: any) => each);
  // reduce to a list of unique errors
  const reduced = filtered.reduce((acc: any, curr: any) => {
    const values = Object.values(curr).filter(
      (each: any) => !acc.includes(each)
    );
    return [...acc, ...values];
  }, []);
  return reduced;
};

export const parseDjangoErrors = (err: any) => {
  const filterDjangoErrors = Object.keys(err)?.reduce(
    (acc: Array<any>, curr: any) => {
      const errors = err[curr].reduce(
        (errorAccumulator: Array<any>, errorCurrent: any) => {
          let formatted;
          if (Object.keys(errorCurrent).length) {
            if (Array.isArray(errorCurrent)) {
              return [...errorAccumulator, ...errorCurrent];
            }
            if (typeof errorCurrent === 'string') {
              return [...errorAccumulator, errorCurrent];
            }
            // eslint-disable-next-line
            formatted = Object.keys(errorCurrent).map(
              (each) => `${each} - ${errorCurrent[each]}`
            )[0];
            if (!errorAccumulator.includes(formatted)) {
              return [...errorAccumulator, formatted];
            }
          }
          return errorAccumulator;
        },
        []
      );
      return [...acc, ...errors.flat(1)];
    },
    []
  );
  return filterDjangoErrors;
};

export const getDateRangeString = (
  startDate: string,
  endDate: string,
  locale: string
) => {
  return `${new Date(startDate).toLocaleString(locale, {
    day: 'numeric',
    month: 'short'
  })} - ${new Date(endDate).toLocaleString(locale, {
    day: 'numeric',
    month: 'short'
  })}`;
};

export const getMonthNamesWithMaxDays = (
  dateList: Array<string>,
  reportingMonth: string,
  locale: string
): Array<FormikMonthlyDistributionReportAssistanceMonth> => {
  if (!dateList.length) {
    return [];
  }
  return dateList.reduce(
    (
      acc: Array<FormikMonthlyDistributionReportAssistanceMonth>,
      curr: string
    ) => [
      ...acc,
      {
        monthName: moment(curr).locale(locale).format('MMMM YYYY'),
        maxDays: moment(curr).daysInMonth(),
        required: curr === reportingMonth,
        date: curr,
        assistanceDays: 0
      }
    ],
    []
  );
};

export const mapMonthsForEdit = (
  dateList: Array<APIMonthlyDistributionReportAssistanceMonth>,
  reportingMonth: string,
  locale: string
): Array<FormikMonthlyDistributionReportAssistanceMonth> => {
  if (!dateList.length) {
    return [];
  }
  return dateList.reduce(
    (acc: Array<FormikMonthlyDistributionReportAssistanceMonth>, curr) => [
      ...acc,
      {
        ...curr,
        monthName: moment(curr.date).locale(locale).format('MMMM YYYY'),
        maxDays: moment(curr.date).daysInMonth(),
        required: curr.date === reportingMonth
      }
    ],
    []
  );
};

export const getAssistanceMonthsBasedOnDate = (
  dateList: any,
  locale: string
) => {
  if (!dateList.length) {
    return [];
  }
  return dateList.reduce((acc: any, curr: any) => {
    const monthName = moment(curr.date).locale(locale).format('MMMM YYYY');
    return [
      ...acc,
      {
        monthName,
        assistanceDays: curr.assistanceDays
      }
    ];
  }, []);
};

export const assistanceDayValidation = (
  value: number,
  max: number,
  required: boolean
) => {
  if (typeof value !== 'number') {
    return t('Field cannot be empty');
  }
  if (value > max) {
    return t('Value cannot be bigger than {max}', { max });
  }
  if (value < 0) {
    return t('Value cannot be negative');
  }
  if (value === 0 && required) {
    return t('This field is required');
  }
  return null;
};

export const getTotalAssistanceDays = (assistanceMonths: Array<any>) => {
  if (!assistanceMonths || !assistanceMonths.length) {
    return 0;
  }
  return assistanceMonths.reduce((acc: any, curr: any) => {
    if (typeof curr.assistanceDays === 'number') {
      return acc + curr.assistanceDays;
    }
    return acc;
  }, 0);
};

export const getTotalAssistanceDaysStringByMonths = (
  assistanceMonths: Array<any>
) => {
  if (!assistanceMonths || !assistanceMonths.length) {
    return '';
  }
  return assistanceMonths
    .reduce((acc: any, curr: any) => {
      if (curr.monthName && curr.assistanceDays) {
        return [...acc, `${curr.monthName}: ${curr.assistanceDays}`];
      }
      return acc;
    }, [])
    .join('; ');
};

export const mapMonthlyDistributionReportForSubmission = (
  values: FormikMonthlyDistributionReport
) => {
  return {
    ...values.basicInformation,
    problemsEncountered:
      values.basicInformation.problemsEncountered?.value ?? null,
    distributionSite: values.basicInformation.distributionSite?.value,
    orgUnit: values.basicInformation.orgUnit?.value,
    participantsAndBeneficiaries: values.participantsAndBeneficiaries?.groups
      .length
      ? {
          beneficiaryRatio:
            values.participantsAndBeneficiaries.beneficiaryRatio,
          multiplier: values.participantsAndBeneficiaries.multiplier,
          groups: values?.participantsAndBeneficiaries?.groups?.map(
            ({
              isApplicable,
              isResidenceStatusNumericValue,
              beneficiaryGroup,
              residenceStatus,
              participantGroup,
              isBeneficiariesFromParticipants
            }) => ({
              isApplicable,
              group: beneficiaryGroup.code,
              residenceStatus: residenceStatus.rows.filter(
                ({ isTotalRow }) => !isTotalRow
              ),
              participantsGroup: participantGroup.rows
                .filter(({ isTotalRow }) => !isTotalRow)
                .map((row) => ({ ...row, group: +row.group })),
              beneficiaryGroup: beneficiaryGroup.rows
                .filter(({ isTotalRow }) => !isTotalRow)
                .map((row) => ({ ...row, group: +row.group })),
              isResidenceStatusNumericValue,
              isBeneficiariesFromParticipants
            })
          )
        }
      : {},
    commodities: values.commodities
      .filter((commodity: any) => !commodity.isTotalRow)
      .map((each: any) => {
        const lossReasons = each.lossReasons.map(
          (lossReason: any) => lossReason.id
        );

        return {
          ...each,
          commodity: each.commodityId,
          batchNumber: each.batch,
          internalPartnerMovement: each.internalMovement,
          received: each.receivedFromWfp,
          lost: each.losses,
          returned: each.returnedToWfp,
          lossReasons,
          beneficiaryGroupSplits:
            each.distributed > 0 ? each?.beneficiaryGroupSplits : []
        };
      }),
    report: values.report,
    asDraft: values.asDraft,
    step: values.step
  };
};

export const mapMonthlyDistributionReportStockOnlyForSubmission = (
  values: FormikMonthlyDistributionReportStockOnly
) => ({
  ...values.basicInformation,
  problemsEncountered:
    values.basicInformation.problemsEncountered?.value ?? null,
  distributionSite: values.basicInformation.distributionSite?.value,
  orgUnit: values.basicInformation.orgUnit?.value,
  commodities: values.commodities
    .filter((commodity) => !commodity.isTotalRow)
    .map((each) => {
      const lossReasons = each.lossReasons.map((lossReason) => lossReason.id);
      return {
        ...each,
        commodity: each.commodityId,
        batchNumber: each.batch,
        internalPartnerMovement: each.internalMovement,
        received: each.receivedFromWfp,
        lost: each.losses,
        returned: each.returnedToWfp,
        lossReasons
      };
    }),
  report: values.report,
  asDraft: values.asDraft,
  step: values.step
});

export const mapMonthlyDistributionReportAttachments = (
  params: FormikMonthlyDistributionReportAttachment
) => {
  const res: APIMonthlyDistrbutionReportAttachment = {
    report: params.report,
    type: '',
    typeOther: undefined,
    file: params.file
  };
  if (params.type?.__isNew__) {
    res.typeOther = params.type.value;
    res.type = MonthlyDistributionReportAttachmentType.Other;
  } else {
    res.type = params?.type?.value;
    res.typeOther = undefined;
  }
  const resSnakeCase = mapToSnakeCase(res);
  const formData = new FormData();
  formData.append('type', resSnakeCase.type);
  formData.append('type_other', resSnakeCase.type_other);
  formData.append('report', resSnakeCase.report);
  formData.append('file', resSnakeCase.file);
  return formData;
};

export const mapMonthlyDistributionReportAttachmentsToFormik = (
  params: Array<{
    file: string;
    pk: string;
    report: string;
    type: string;
    typeOther: string;
    uploadedBy: string;
    filename: string;
  }>
): Array<FormikMonthlyDistributionReportAttachment> => {
  return params.map((attachment) => {
    let selectedTypeSelectOption = { value: '', label: '' };

    if (attachment.type === MonthlyDistributionReportAttachmentType.Other) {
      selectedTypeSelectOption = {
        value: attachment.typeOther,
        label: attachment.typeOther
      };
    } else {
      selectedTypeSelectOption = AttachmentTypeOptions.find(
        ({ value }) => value === attachment.type
      ) ?? { value: 'other', label: 'unknown Type found' };
    }
    return {
      ...attachment,
      type: selectedTypeSelectOption,
      id: attachment.pk
    };
  });
};

export const getPluralDays = (range: any) => {
  const { startDate, endDate } = range;
  const diff = duration(moment(endDate).diff(startDate)).add(1, 'd').asDays();
  return `${diff} ${diff > 1 ? t('days') : t('day')}`;
};

export const getRedirectPath = () => '/';

export const checkPermission = (permissions: any, check: string) => {
  if (!permissions || !permissions.length) {
    return false;
  }
  return !!permissions.find((permission: any) => permission.value === check);
};

export const checkIfCommoditiesAreUnique = (
  commodities: Array<FormikAdHocSubmissionCommodity>
) => {
  const keys = commodities
    .filter(({ isTotalRow }) => !isTotalRow)
    .map(({ batch, commodityId }) => `${batch}-${commodityId}`);

  return new Set(keys).size === keys.length;
};

export const mapParticipantsAndBeneficiariesGroups = (
  list: Array<APIMonthlyDistributionReportParticipantsAndBeneficiariesGroup>
) => {
  if (!list.length) {
    return [];
  }
  return list.map((each) => {
    const parsedResidenceStatusRows = each.residenceStatus.map(
      (residenceStatus) => ({
        ...residenceStatus,
        male: parseFloat(residenceStatus.male),
        female: parseFloat(residenceStatus.female),
        total: parseFloat(residenceStatus.total)
      })
    );

    return {
      ...each,
      beneficiaryGroup: {
        rows: attachBeneficiaryTotalRow(each.beneficiaryGroup)
      },
      participantGroup: {
        rows: attachParticipantsTotalRow(each.participantsGroup)
      },
      residenceStatus: {
        rows: each.isResidenceStatusNumericValue
          ? attachNumericResidenceStatusTotalRow(parsedResidenceStatusRows)
          : attachPercentageResidenceStatusTotalRow(
              each.beneficiaryGroup.reduce(
                (sum: number, row: BeneficiaryGroupRow) =>
                  sum + row.beneficiaryTotal,
                0
              ) ?? 0,
              parsedResidenceStatusRows
            )
      }
    };
  });
};

export const getPartnershipStartDateFromPartnershipList = (
  partnerships: Array<PartnershipInterface>,
  partnershipLookupCode: string
) => {
  const matchingPartnership = partnerships?.find(
    (partnership) => partnership.partnershipCode === partnershipLookupCode
  );
  if (!matchingPartnership) {
    return null;
  }
  return matchingPartnership.startDate;
};

export const mapMetaDataForProfileEdit = (metaData: PartnerType) =>
  Object.keys(metaData).reduce(
    (acc, partnership) => {
      const activityOptions = Object.keys(
        metaData[partnership].activity_tags
      ).map((activity) => {
        return {
          value: activity,
          label: metaData[partnership].activity_tags[activity].description
        };
      });
      const activityTags = Object.keys(
        metaData[partnership].activity_tags
      ).reduce((tagsAcc, curr) => {
        const accCopy = { ...tagsAcc };
        const locations = mapLocationsToOptions(
          metaData[partnership].activity_tags[curr].locations
        );

        accCopy[curr] = {
          ...metaData[partnership].activity_tags[curr],
          locationOptions: locations
        };
        return accCopy;
      }, {} as Record<string, ActivityTag & { locationOptions: Array<OptionElement> }>);

      acc[partnership] = {
        ...metaData[partnership],
        activityTags,
        activityOptions
      };

      return acc;
    },
    {} as Record<
      string,
      {
        activityTags: Record<
          string,
          ActivityTag & { locationOptions: Array<OptionElement> }
        >;
        activityOptions: Array<OptionElement>;
      }
    >
  );

const applyDemographySplit = (value: number, demography: number) => {
  if (value === 0) {
    return 0;
  }

  if (demography === 0) {
    return value;
  }
  return Number(value * (demography / 100));
};

export const updateBeneficiaryRowsBasedOnMultiplier = (
  participantsRows: Array<ParticipantGroupRow>,
  beneficiaryRows: Array<BeneficiaryGroupRow>,
  multiplier: number,
  shouldResetUnmatchedAgeGroups: boolean
) =>
  Object.keys(AgeGroup).map((id) => {
    const matchingBeneficiaryRow = beneficiaryRows.find(
      ({ group }) => '' + group === id
    );
    const matchingParticipantRow = participantsRows.find(
      ({ group }) => '' + group === id
    );

    if (!matchingParticipantRow || !matchingBeneficiaryRow) {
      return {
        id,
        group: id,
        newBeneficiaryFemale: shouldResetUnmatchedAgeGroups
          ? 0
          : matchingBeneficiaryRow?.newBeneficiaryFemale ?? 0,
        newBeneficiaryMale: shouldResetUnmatchedAgeGroups
          ? 0
          : matchingBeneficiaryRow?.newBeneficiaryMale ?? 0,
        beneficiaryFemale: shouldResetUnmatchedAgeGroups
          ? 0
          : matchingBeneficiaryRow?.beneficiaryFemale ?? 0,
        beneficiaryMale: shouldResetUnmatchedAgeGroups
          ? 0
          : matchingBeneficiaryRow?.beneficiaryMale ?? 0,
        beneficiaryTotal: shouldResetUnmatchedAgeGroups
          ? 0
          : matchingBeneficiaryRow?.beneficiaryTotal ?? 0,
        isTotalRow: false
      };
    }

    const newBeneficiaryFemale =
      matchingBeneficiaryRow.newBeneficiaryFemale === 0
        ? matchingParticipantRow.newParticipantsFemale
        : matchingBeneficiaryRow.newBeneficiaryFemale;
    const newBeneficiaryMale =
      matchingBeneficiaryRow.newBeneficiaryMale === 0
        ? matchingParticipantRow.newParticipantsMale
        : matchingBeneficiaryRow.newBeneficiaryMale;
    const beneficiaryFemale =
      matchingParticipantRow?.participantsFemale === 0
        ? 0
        : roundDownToNearestInteger(
            matchingParticipantRow?.participantsFemale * multiplier
          );
    const beneficiaryMale =
      matchingParticipantRow?.participantsMale === 0
        ? 0
        : roundDownToNearestInteger(
            matchingParticipantRow?.participantsMale * multiplier
          );
    const beneficiaryTotal = beneficiaryFemale + beneficiaryMale;

    return {
      id,
      group: matchingBeneficiaryRow.group,
      newBeneficiaryFemale,
      newBeneficiaryMale,
      beneficiaryFemale,
      beneficiaryMale,
      beneficiaryTotal,
      isTotalRow: false
    };
  });

const roundDownToNearestInteger = (number: number) => {
  // Extract the fractional part of the number
  const decimal = number - Math.floor(number);

  // Check if the decimal part is greater than 0.5
  if (decimal > 0.5) {
    return Math.ceil(number); // If more than 0.5, round up
  } else {
    return Math.floor(number); // If 0.5 or less, round down
  }
};

export const updateBeneficiaryRowsBasedOnDemographies = (
  participantRows: Array<ParticipantGroupRow>,
  beneficiaryRows: Array<BeneficiaryGroupRow>,
  multiplier: number,
  demographies: Demographies
) => {
  const totalParticipantsRow = participantRows.find(
    ({ isTotalRow }) => isTotalRow
  );

  const totalParticipants = totalParticipantsRow?.participantsTotal
    ? totalParticipantsRow?.participantsTotal * multiplier
    : 0;

  const ageGroupMap: Record<
    number,
    {
      ageGroup: number;
      malePercentage: number;
      femalePercentage: number;
    }
  > = {};

  demographies.forEach((item) => {
    if (!ageGroupMap[item.ageGroup]) {
      ageGroupMap[item.ageGroup] = {
        ageGroup: item.ageGroup,
        malePercentage: 0,
        femalePercentage: 0
      };
    }
    if (item.gender.toLowerCase() === 'male') {
      ageGroupMap[item.ageGroup].malePercentage = Number(item.percentage);
    } else if (item.gender.toLowerCase() === 'female') {
      ageGroupMap[item.ageGroup].femalePercentage = Number(item.percentage);
    }
  });

  const newRowsWithDemographies: Array<BeneficiaryGroupRow> = Object.keys(
    AgeGroup
  ).map((id) => {
    const { ageGroup, malePercentage, femalePercentage } = ageGroupMap[+id] || {
      ageGroup: id,
      malePercentage: 0,
      femalePercentage: 0
    };
    const matchingBeneficiaryRows = beneficiaryRows.find(
      ({ group }) => group === id
    );
    const matchingParticipantRow = participantRows.find(
      ({ group }) => group === id
    );
    const beneficiaryMale =
      malePercentage > 0
        ? roundDownToNearestInteger(
            applyDemographySplit(totalParticipants, malePercentage)
          )
        : 0;

    const beneficiaryFemale =
      femalePercentage > 0
        ? roundDownToNearestInteger(
            applyDemographySplit(totalParticipants, femalePercentage)
          )
        : 0;

    const newBeneficiaryMale =
      matchingBeneficiaryRows?.newBeneficiaryMale ||
      matchingParticipantRow?.newParticipantsMale ||
      0;
    const newBeneficiaryFemale =
      matchingBeneficiaryRows?.newBeneficiaryFemale ||
      matchingParticipantRow?.newParticipantsFemale ||
      0;
    return {
      id: '' + ageGroup,
      group: '' + ageGroup,
      newBeneficiaryFemale,
      newBeneficiaryMale,
      beneficiaryFemale,
      beneficiaryMale,
      beneficiaryTotal: Number(beneficiaryFemale) + Number(beneficiaryMale),
      isTotalRow: false
    };
  });

  return newRowsWithDemographies;
};

export const updateParticipantsRowsTotalValue = (
  params: any,
  rows: Array<any>
) => {
  return rows.map((row: any) => {
    if (params.data.id === row.id) {
      return {
        ...params.data,
        participantsTotal:
          Number(params.data.participantsMale) +
          Number(params.data.participantsFemale)
      };
    }
    return row;
  });
};

export const updateBeneficiaryRowsTotalValue = (
  params: any,
  rows: Array<any>
) => {
  return rows.map((row: any) => {
    if (params.data.id === row.id) {
      return {
        ...params.data,
        beneficiaryTotal:
          Number(params.data.beneficiaryMale) +
          Number(params.data.beneficiaryFemale)
      };
    }
    return row;
  });
};

export const countTotal = (list: Array<{ total: number }>) =>
  list.reduce(
    (acc: number, curr: { total: number }) => acc + Number(curr.total),
    0
  );

export const resetBeneficiaryRows = (rows: Array<BeneficiaryGroupRow>) => {
  return rows.map((row) => ({
    ...row,
    newBeneficiaryFemale: 0,
    newBeneficiaryMale: 0,
    beneficiaryFemale: 0,
    beneficiaryMale: 0,
    beneficiaryTotal: 0
  }));
};

export const mapParticipantsToBeneficiaryRows = (
  participantsRows: Array<ParticipantGroupRow>,
  beneficiaryRows: Array<BeneficiaryGroupRow>
) =>
  beneficiaryRows.map((row: BeneficiaryGroupRow) => {
    const matchingParticipantRow = participantsRows.find(
      (participantRow) => participantRow.group === row.group
    );

    const newBeneficiaryMale = matchingParticipantRow?.newParticipantsMale || 0;
    const newBeneficiaryFemale =
      matchingParticipantRow?.newParticipantsFemale || 0;
    const beneficiaryMale = matchingParticipantRow?.participantsMale || 0;
    const beneficiaryFemale = matchingParticipantRow?.participantsFemale || 0;
    const beneficiaryTotal = beneficiaryMale + beneficiaryFemale;

    return {
      id: row.id,
      group: row.group,
      newBeneficiaryMale,
      newBeneficiaryFemale,
      beneficiaryMale,
      beneficiaryFemale,
      beneficiaryTotal,
      ...(row.isTotalRow && { isTotalRow: row.isTotalRow })
    };
  });

export const attachParticipantsTotalRow = (
  rows: Array<ParticipantGroupRow>
): Array<ParticipantGroupRow> => {
  const copiedRows = rows.filter((row) => !row.isTotalRow);
  const totalRow = copiedRows.reduce(
    (acc: ParticipantGroupRow, curr: ParticipantGroupRow) => {
      if (!curr.isTotalRow) {
        return {
          ...acc,
          newParticipantsMale:
            acc.newParticipantsMale + Number(curr.newParticipantsMale),
          newParticipantsFemale:
            acc.newParticipantsFemale + Number(curr.newParticipantsFemale),
          participantsMale:
            acc.participantsMale + Number(curr.participantsMale),
          participantsFemale:
            acc.participantsFemale + Number(curr.participantsFemale),
          participantsTotal:
            acc.participantsTotal + Number(curr.participantsTotal)
        };
      }
      return acc;
    },
    {
      id: '43',
      group: '0',
      newParticipantsFemale: 0,
      newParticipantsMale: 0,
      participantsFemale: 0,
      participantsMale: 0,
      participantsTotal: 0,
      isTotalRow: true
    }
  );
  return [...copiedRows, totalRow];
};

export const attachBeneficiaryTotalRow = (rows: Array<BeneficiaryGroupRow>) => {
  const copiedRows = rows.filter((row) => !row.isTotalRow);
  const totalRow = copiedRows.reduce(
    (acc: BeneficiaryGroupRow, curr: BeneficiaryGroupRow) => {
      if (!curr.isTotalRow) {
        return {
          ...acc,
          newBeneficiaryMale:
            acc.newBeneficiaryMale + Number(curr.newBeneficiaryMale),
          newBeneficiaryFemale:
            acc.newBeneficiaryFemale + Number(curr.newBeneficiaryFemale),
          beneficiaryMale: acc.beneficiaryMale + Number(curr.beneficiaryMale),
          beneficiaryFemale:
            acc.beneficiaryFemale + Number(curr.beneficiaryFemale),
          beneficiaryTotal: acc.beneficiaryTotal + Number(curr.beneficiaryTotal)
        };
      }
      return acc;
    },
    {
      id: '43',
      group: '0',
      newBeneficiaryFemale: 0,
      newBeneficiaryMale: 0,
      beneficiaryFemale: 0,
      beneficiaryMale: 0,
      beneficiaryTotal: 0,
      isTotalRow: true
    }
  );
  return [...copiedRows, totalRow];
};

export const attachCommodityTotalRow = (rows: Array<any>) => {
  const commodityRows = rows.filter((row) => !row.isTotalRow);

  if (!commodityRows.length) {
    return [];
  }

  const totalRow = commodityRows.reduce(
    (acc, curr) => ({
      ...acc,
      openStock: acc.openStock + Number(curr.openStock),
      receivedFromWfp: acc.receivedFromWfp + Number(curr.receivedFromWfp),
      distributed: acc.distributed + Number(curr.distributed),
      returnedToWfp: acc.returnedToWfp + Number(curr.returnedToWfp),
      losses: acc.losses + Number(curr.losses),
      closingStock: acc.closingStock + Number(curr.closingStock)
    }),
    {
      commodityId: -1,
      type: t('Total'),
      batchNumber: null,
      openStock: 0,
      receivedFromWfp: 0,
      distributed: 0,
      returnedToWfp: 0,
      losses: 0,
      lossReasons: [],
      closingStock: 0,
      isTotalRow: true,
      key: '6560999-c192-4172-9275-f9689b250zz3'
    }
  );

  const fixedTotalRow = {
    ...totalRow,
    openStock:
      totalRow.openStock !== 0
        ? totalRow.openStock.toFixed(COMMODITIES_DIGITS_AFTER_DECIMAL_POINT)
        : totalRow.openStock,
    receivedFromWfp:
      totalRow.receivedFromWfp !== 0
        ? totalRow.receivedFromWfp.toFixed(
            COMMODITIES_DIGITS_AFTER_DECIMAL_POINT
          )
        : totalRow.receivedFromWfp,
    distributed:
      totalRow.distributed !== 0
        ? totalRow.distributed.toFixed(COMMODITIES_DIGITS_AFTER_DECIMAL_POINT)
        : totalRow.distributed,
    returnedToWfp:
      totalRow.returnedToWfp !== 0
        ? totalRow.returnedToWfp.toFixed(COMMODITIES_DIGITS_AFTER_DECIMAL_POINT)
        : totalRow.returnedToWfp,
    losses:
      totalRow.losses !== 0
        ? totalRow.losses.toFixed(COMMODITIES_DIGITS_AFTER_DECIMAL_POINT)
        : totalRow.losses,
    closingStock:
      totalRow.closingStock !== 0
        ? totalRow.closingStock.toFixed(COMMODITIES_DIGITS_AFTER_DECIMAL_POINT)
        : totalRow.closingStock
  };

  return [...commodityRows, fixedTotalRow];
};

export const mapMonthlyReportCommoditiesFromApi = (
  commodities: Array<APICommodityMovement>
): Array<FormikMonthlyDistributionReportCommodity> => {
  if (!commodities || !commodities.length) {
    return [];
  }
  return commodities.map((each) => ({
    ...each,
    key: uuidv4(),
    type: each.commodity.label,
    commodityId: each.commodity.id,
    batch: each.batchNumber,
    internalMovement: each.internalPartnerMovement,
    receivedFromWfp: each.received,
    losses: each.lost,
    returnedToWfp: each.returned,
    category: each.commodity.category,
    isTotalRow: false,
    beneficiaryGroupSplits: each.beneficiaryGroupSplits,
    outboundDeliveryNumber: null
  }));
};

export const filterGroupsBasedOnConfig = (beneficiaries: any, conf: any) => {
  const reducedGroups = beneficiaries?.groups?.reduce((acc: any, curr: any) => {
    const { code: beneficiaryCode } = curr.beneficiaryGroup;
    // find config value
    const { male, female } = conf.find(
      (el: any) => el.beneficiaryGroup === beneficiaryCode
    );
    return [
      ...acc,
      {
        ...curr,
        beneficiaryGroup: {
          ...curr.beneficiaryGroup,
          maleEnabled: male,
          femaleEnabled: female,
          rows: attachBeneficiaryTotalRow(curr.beneficiaryGroup.rows)
        },
        participantGroup: {
          ...curr.participantGroup,
          maleEnabled: male,
          femaleEnabled: female,
          rows: attachParticipantsTotalRow(curr.participantGroup.rows)
        }
      }
    ];
  }, []);

  return {
    ...beneficiaries,
    groups: reducedGroups
  };
};

export const filterBeneficiariesBasedOnConfig = (
  beneficiaryGroups: Array<APIAdHocSubmissionBeneficiaryGroup>,
  beneficiaryTableConfigs: Array<BeneficiaryTableConfig> | undefined
) => {
  return beneficiaryTableConfigs
    ? beneficiaryGroups?.map((group) => {
        const { code, rows } = group;
        // find config value
        const configValue = beneficiaryTableConfigs?.find(
          ({ beneficiaryGroup }) => beneficiaryGroup === code
        );
        // filter values with the config
        const filteredBeneficiaries = rows.filter((row) =>
          configValue?.ageGroup?.includes(row.group)
        );
        return {
          ...group,
          description: configValue?.description ?? '',
          maleEnabled: configValue?.male ?? true,
          femaleEnabled: configValue?.female ?? true,
          rows: filteredBeneficiaries
        };
      })
    : [];
};

export const getTotalResidentsBasedOnPercentages = (
  totalBeneficiaries: number,
  residenceStatusRows: Array<ResidenceStatusRow>
) => {
  const totalResidents = residenceStatusRows?.reduce(
    (totalResidents, residenceStatusRow) => {
      totalResidents.male +=
        (residenceStatusRow.male *
          residenceStatusRow.total *
          totalBeneficiaries) /
        PERCENTAGE_SQUARE_DIVISOR;
      totalResidents.female +=
        (residenceStatusRow.female *
          residenceStatusRow.total *
          totalBeneficiaries) /
        PERCENTAGE_SQUARE_DIVISOR;
      return totalResidents;
    },
    { male: 0, female: 0 }
  );

  return {
    male: Math.ceil(totalResidents.male),
    female: Math.ceil(totalResidents.female)
  };
};

export const attachPercentageResidenceStatusTotalRow = (
  totalBeneficiaries: number,
  rows: Array<ResidenceStatusRow>
) => {
  const residenceStatusRows = rows.filter(({ isTotalRow }) => !isTotalRow);

  const totalResidents = getTotalResidentsBasedOnPercentages(
    totalBeneficiaries,
    residenceStatusRows
  );

  return [
    ...residenceStatusRows,
    {
      id: '',
      status: t('Total'),
      male: totalResidents.male,
      female: totalResidents.female,
      total: countTotal(residenceStatusRows),
      isTotalRow: true
    }
  ];
};

export const attachNumericResidenceStatusTotalRow = (
  rows: Array<ResidenceStatusRow>
) => {
  const residenceStatusRows = rows.filter(({ isTotalRow }) => !isTotalRow);

  const totalResidents = residenceStatusRows?.reduce(
    (totalResidents, residenceStatusRow) => {
      totalResidents.male += residenceStatusRow.male;
      totalResidents.female += residenceStatusRow.female;
      return totalResidents;
    },
    { male: 0, female: 0 }
  );

  return [
    ...residenceStatusRows,
    {
      id: '',
      status: t('Total'),
      male: totalResidents.male,
      female: totalResidents.female,
      total: countTotal(residenceStatusRows),
      isTotalRow: true
    }
  ];
};
