import { t } from '@transifex/native';

export enum MonthlyReportStatus {
  ReadyForSubmission = 'ready_for_submission',
  Draft = 'draft',
  AwaitingWfpReview = 'awaiting_wfp_review',
  AwaitingWfpVerification = 'awaiting_wfp_verification',
  ReadyForReview = 'ready_for_review',
  AwaitingPartnerApproval = 'awaiting_partner_approval',
  AwaitingWfpApproval = 'awaiting_wfp_approval',
  ApprovedAwaitingSync = 'approved_awaiting_sync',
  Approved = 'approved',
  Error = 'error'
}

export const MONTHLY_REPORT_STATUS: { [key: string]: string } = {
  ready_for_submission: t('Ready for submission'),
  draft: t('Draft'),
  awaiting_wfp_review: t('Awaiting WFP review'),
  ready_for_review: t('Ready for review'),
  awaiting_partner_approval: t('Awaiting partner approval'),
  awaiting_wfp_approval: t('Awaiting WFP approval'),
  approved_awaiting_sync: t('Validation pending'),
  approved: t('Approved'),
  error: t('Approved (sync error)'),
  awaiting_wfp_verification: t('Awaiting WFP verification')
};

export enum MonthlyReportCometStatus {
  Template = 'template',
  Draft = 'draft',
  Submitted = 'submitted',
  Validated = 'validated'
}
