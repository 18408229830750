import { T } from '@transifex/react';
import { t } from '@transifex/native';
import { Text, Wrapper, Hero } from '@wfp/ui';
import { Col, Grid, Row } from 'react-flexbox-grid';
import { LandingPageMap } from './LandingPageMap';

export const LandingContent = () => {
  return (
    <>
      <Wrapper pageWidth="lg" mobilePageWidth="full">
        <Grid className="landing-page-about">
          <Row middle="md">
            <Col sm={12} md={6}>
              <img
                className="image-with-shadow"
                src="../../assets/report_distraction.svg"
                alt={t('Screenshot of report distraction')}
              />
            </Col>
            <Col sm={12} md={6} className="landing-page-about__description">
              <Text kind="h1">
                <T _str="End-to-End Digitization of the Partner Value Chain" />
              </Text>
              <Text kind="p">
                <T _str="Digitize your paper-based process, eliminate the number of manual steps, improve data quality and gaps in the information flow, use a reliable, timely reporting process." />
              </Text>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      <Hero
        kind="splash-compact"
        id="landing-page-hero-modules"
        className="landing-page-splash-compact"
        title={
          <Grid className="landing-page-about">
            <Row middle="sm" className="row">
              <Col sm={12} md={4}>
                <Col>
                  <img
                    src="../../assets/customIcons/partnership_sm.svg"
                    alt={t('Icon of partnership management')}
                    className="icon"
                  />
                  <Text kind="h5">
                    <T _str="Partnership management" />
                  </Text>
                  <Text kind="p">
                    <T _str="Seamless collaboration from contracting to budgeting." />
                  </Text>
                </Col>
              </Col>
              <Col sm={12} md={4}>
                <Col>
                  <img
                    src="../../assets/customIcons/distribution_reporting_sm.svg"
                    alt={t('Icon of distribution reporting')}
                    className="icon"
                  />
                  <Text kind="h5">
                    <T _str="Distribution reporting" />
                  </Text>
                  <Text kind="p">
                    <T _str="Streamlined reporting experience for daily operations." />
                  </Text>
                </Col>
              </Col>
              <Col sm={12} md={4}>
                <Col>
                  <img
                    src="../../assets/customIcons/financial_tracking_sm.svg"
                    alt={t('Icon of financial tracking')}
                    className="icon"
                  />
                  <Text kind="h5">
                    <T _str="Financial tracking" />
                  </Text>
                  <Text kind="p">
                    <T _str="Transparent Financial Management." />
                  </Text>
                </Col>
              </Col>
            </Row>
          </Grid>
        }
      />
      <Wrapper pageWidth="lg" mobilePageWidth="full">
        <Grid className="landing-page-about-modules">
          <Row middle="md" className="row">
            <Col sm={12} md={6}>
              <div className="background">
                <img
                  src="../../assets/customIcons/partnership_md.svg"
                  alt={t('Screenshot of partnership management')}
                />
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              className="landing-page-about-modules__description"
            >
              <Text kind="subtitle">
                <T _str="Partnership management" />
              </Text>
              <Text kind="h3">
                <T _str="Next level of collaboration" />
              </Text>
              <Text kind="p">
                <T _str="Assistance for our partners from planning an operation to budgeting operations in the field level agreement." />
              </Text>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      <Wrapper pageWidth="lg" mobilePageWidth="full">
        <Grid className="landing-page-about-modules">
          <Row middle="md" className="row">
            <Col
              sm={12}
              md={6}
              className="landing-page-about-modules__description"
            >
              <Text kind="subtitle">
                <T _str="Distribution reporting" />
              </Text>
              <Text kind="h3">
                <T _str="Streamlined reporting" />
              </Text>
              <Text kind="p">
                <T _str="Partner Connect eliminates manual work for partners in the reporting process. It also streamlines report validation and distribution as all documents are uploaded in a single place. " />
              </Text>
            </Col>
            <Col sm={12} md={6} className="right-mode">
              <div className="background">
                <img
                  src="../../assets/customIcons/distribution_reporting_md.svg"
                  alt={t('Screenshot of distribution reporting')}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
      <Wrapper pageWidth="lg" mobilePageWidth="full">
        <Grid className="landing-page-about-modules">
          <Row middle="md" className="row">
            <Col sm={12} md={6}>
              <div className="background">
                <img
                  src="../../assets/customIcons/financial_tracking_md.svg"
                  alt={t('Screenshot of financial tracking')}
                />
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              className="landing-page-about-modules__description"
            >
              <Text kind="subtitle">
                <T _str="Financial tracking" />
              </Text>
              <Text kind="h3">
                <T _str="Financial transparency" />
              </Text>
              <Text kind="p">
                <T _str="Partner Connect helps partners with an improved financing and reconciliation process. It also enables digital financial transparency on both sides, from invoice submission to payment." />
              </Text>
            </Col>
          </Row>
        </Grid>
      </Wrapper>

      <LandingPageMap />

      <Hero
        kind="splash-compact"
        className="landing-page-contact-us landing-page-splash-compact"
        title={
          <span>
            <Text kind="h2">
              <T _str="Questions?" />
            </Text>
            <Text kind="subtitle">
              <T _str="Contact us" />
            </Text>
            <a href="mailto:global.partnerconnect@wfp.org">
              <Text kind="p">
                <T _str="global.partnerconnect@wfp.org" />
              </Text>
            </a>
          </span>
        }
      />
    </>
  );
};
