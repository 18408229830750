import { useHome } from './Home.hooks';
import { HomeView } from './HomeView';

export const Home = () => {
  const {
    isAuthenticated,
    canAccessReporting,
    canAccessPartnerships,
    canAccessInvoicing,
    username,
    signinRedirect,
    isLoading
  } = useHome();

  return (
    <HomeView
      isAuthenticated={isAuthenticated}
      canAccessReporting={canAccessReporting}
      canAccessPartnerships={canAccessPartnerships}
      canAccessInvoicing={canAccessInvoicing}
      username={username}
      signinRedirect={signinRedirect}
      isLoading={isLoading}
    />
  );
};
