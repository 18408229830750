import { useForm } from 'react-hook-form';
import { useReportingModuleNotifications } from '../../../api';
import { NotificationsFormFields } from '@partnerconnect-monorepo/types';
import { useEffect } from 'react';

export const useNotifications = () => {
  const { control, reset } = useForm<NotificationsFormFields>();

  const {
    data: reportingModuleNotifications,
    isLoading: isLoadingReportingModuleNotifications
  } = useReportingModuleNotifications();

  const {
    data: partnershipsModuleNotifications,
    isLoading: isLoadingPartnershipsModuleNotifications
  } = useReportingModuleNotifications();

  useEffect(() => {
    if (reportingModuleNotifications && partnershipsModuleNotifications) {
      reset({ reportingModuleNotifications, partnershipsModuleNotifications });
    }
  });

  return {
    reportingModuleNotifications,
    partnershipsModuleNotifications,
    control,
    isLoading:
      isLoadingReportingModuleNotifications ||
      isLoadingPartnershipsModuleNotifications
  };
};
