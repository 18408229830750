import { IRouteProps } from '@partnerconnect-monorepo/types';
import { NotFound } from './pages/NotFound';
import { Support } from './pages/Support/Support';
import { Preferences } from './pages/Preferences';
import LoginCallback from './pages/LoginCallback';

// this routes will be applied when application are deployed on base route
export const independentAppSharedRoutes: Array<IRouteProps> = [
  {
    path: 'auth/callback',
    component: LoginCallback
  },
  {
    path: '*',
    component: NotFound,
    fullLayout: true
  },
  {
    path: 'support',
    component: Support,
    isPrivate: false
  },
  {
    path: 'preferences',
    component: Preferences
  }
];

export const sharedRoutes = [
  {
    path: '*',
    component: NotFound,
    fullLayout: true
  }
];
