import { useState, useEffect } from 'react';
import { fetchSupportMaterials } from '../../api/support';

export const useSupport = () => {
  const [supportMaterials, setSupportMaterials] = useState([]);
  useEffect(() => {
    fetchSupportMaterials().then((res) => {
      setSupportMaterials(res);
    });
  }, []);
  return {
    supportMaterials
  };
};
