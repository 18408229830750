import { axios } from '../core';
import { mapToSnakeCase } from '../helpers';
import { distributionReportsLocalForage } from '../utils/localForage';
import { getDistributionDate } from '../utils/getDistributionDate';
import { t } from '@transifex/native';
import { User } from 'oidc-client-ts';

export const getToken = () => {
  const loggedInUser = sessionStorage.getItem(
    `oidc.user:${process.env.NX_REACT_APP_AUTHENTICATION_URL}:${process.env.NX_REACT_APP_AUTHENTICATION_CLIENT_ID}`
  );

  if (loggedInUser) {
    return (JSON.parse(loggedInUser) as User)?.id_token;
  }

  return '';
};

export const postOffline = (
  url: string,
  data: any,
  offlineStorageName: string
) => {
  return axios.post(url, mapToSnakeCase(data)).then(
    (res) => {
      return res;
    },
    (err) => {
      if (err.message === 'Network Error') {
        return distributionReportsLocalForage
          .setItem(
            offlineStorageName,
            JSON.stringify({ ...data, offlineSave: true })
          )
          .then((res) => {
            const { dateRange } = JSON.parse(res);
            const dateString = getDistributionDate(dateRange);
            return {
              res,
              toast: {
                kind: 'warning',
                message: t(
                  `Distribution report for ${dateString} successfully saved. Distribution data will be submitted once you are back online. Please leave this browser window open.`
                )
              }
            };
          })
          .catch((forageError) => {
            const error = {
              ...err,
              forage: forageError
            };
            throw error;
          });
      }
      return err;
    }
  );
};
