import { LandingTopHero } from './components/LandingTopHero';
import { LandingContent } from './components/LandingContent';
import './LandingPage.scss';

interface LandingPageViewTypes {
  signInRedirect: () => void;
}

export const LandingPageView = ({ signInRedirect }: LandingPageViewTypes) => (
  <div className="landing-page-wrapper">
    <LandingTopHero signInRedirect={signInRedirect} />
    <LandingContent />
  </div>
);
