import type { GeoJsonTypes } from 'geojson';

const countriesForTest = {
  type: 'FeatureCollection' as GeoJsonTypes,
  features: [
    {
      type: 'Feature',
      properties: {
        filename: 'countriesGeoJson.ts',
        iso_a2: 'PT',
        label_y: 0,
        label_x: 0
      }
    }
  ]
};

const countriesGeoJson = {
  type: 'FeatureCollection' as GeoJsonTypes,
  features: [
    {
      type: 'Feature',
      properties: {
        filename: 'countriesGeoJson.ts',
        iso_a2: '',
        label_y: 0,
        label_x: 0
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.54619625520348, 9.566134751824677],
            [-82.93289099804358, 9.476812038608173],
            [-82.92715491405916, 9.074330145702916],
            [-82.71918311230053, 8.925708726431495],
            [-82.86865719270477, 8.807266343618522],
            [-82.82977067740516, 8.62629547773237],
            [-82.91317643912421, 8.42351715741907],
            [-82.96578304719736, 8.225027980985985],
            [-83.50843726269431, 8.446926581247283],
            [-83.71147396516908, 8.656836249216866],
            [-83.59631303580665, 8.830443223501419],
            [-83.63264156770784, 9.051385809765321],
            [-83.90988562695374, 9.29080272057358],
            [-84.30340165885636, 9.487354030795714],
            [-84.64764421256866, 9.61553742109571],
            [-84.71335079622777, 9.908051866083852],
            [-84.97566036654133, 10.086723130733006],
            [-84.91137488477024, 9.795991522658923],
            [-85.11092342806532, 9.55703969974131],
            [-85.33948828809227, 9.83454214114866],
            [-85.66078650586698, 9.933347479690724],
            [-85.79744483106285, 10.134885565629034],
            [-85.79170874707843, 10.439337266476613],
            [-85.65931372754667, 10.75433095951172],
            [-85.94172543002176, 10.895278428587801],
            [-85.7125404528073, 11.088444932494824],
            [-85.5618519762442, 11.217119248901597],
            [-84.90300330273895, 10.952303371621896],
            [-84.67306901725627, 11.082657172078143],
            [-84.35593075228104, 10.999225572142905],
            [-84.19017859570485, 10.793450018756674],
            [-83.89505449088595, 10.726839097532446],
            [-83.65561174186158, 10.938764146361422],
            [-83.40231970898296, 10.395438137244653],
            [-83.01567664257517, 9.992982082555557],
            [-82.54619625520348, 9.566134751824677]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NIC.geojson',
        iso_a2: 'NI',
        label_y: 12.670697,
        label_x: -85.069347,
        name: 'Nicaragua',
        formal_en: 'Republic of Nicaragua'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.65561174186158, 10.938764146361422],
            [-83.89505449088595, 10.726839097532446],
            [-84.19017859570485, 10.793450018756674],
            [-84.35593075228104, 10.999225572142905],
            [-84.67306901725627, 11.082657172078143],
            [-84.90300330273895, 10.952303371621896],
            [-85.5618519762442, 11.217119248901597],
            [-85.7125404528073, 11.088444932494824],
            [-86.05848832878526, 11.403438625529944],
            [-86.52584998243296, 11.806876532432597],
            [-86.74599158399633, 12.143961900272487],
            [-87.16751624220116, 12.458257961471658],
            [-87.66849341505471, 12.909909979702633],
            [-87.55746660027562, 13.064551703336065],
            [-87.39238623731923, 12.914018256069838],
            [-87.31665442579549, 12.984685777228975],
            [-87.00576900912758, 13.025794379117158],
            [-86.88055701368438, 13.254204209847217],
            [-86.7338217841916, 13.263092556201443],
            [-86.75508663607971, 13.754845485890913],
            [-86.52070817741992, 13.778487453664468],
            [-86.31214209668993, 13.77135610600817],
            [-86.09626380079061, 14.038187364147234],
            [-85.80129472526859, 13.83605499923759],
            [-85.69866533073696, 13.960078436738002],
            [-85.51441301140028, 14.079011745657908],
            [-85.16536454948482, 14.354369615125051],
            [-85.14875057650296, 14.560196844943617],
            [-85.05278744173694, 14.551541042534723],
            [-84.9245006985724, 14.79049286545235],
            [-84.82003679069436, 14.81958669683267],
            [-84.64958207877964, 14.666805324761867],
            [-84.4493359036486, 14.621614284722511],
            [-84.22834164095241, 14.74876414637663],
            [-83.97572140169359, 14.749435939996488],
            [-83.62858496777292, 14.880073960830302],
            [-83.48998877636612, 15.016267198135537],
            [-83.14721900097413, 14.99582916916411],
            [-83.23323442252394, 14.899866034398102],
            [-83.2841615465476, 14.6766238468972],
            [-83.18212643098728, 14.31070302983845],
            [-83.41249996614445, 13.970077826386557],
            [-83.51983191601468, 13.567699286345883],
            [-83.55220720084554, 13.127054348193086],
            [-83.49851538769427, 12.869292303921227],
            [-83.47332312695198, 12.419087225794428],
            [-83.62610449902292, 12.320850328007566],
            [-83.71961300325506, 11.893124497927728],
            [-83.65085751009072, 11.62903209070012],
            [-83.8554703437504, 11.373311265503787],
            [-83.80893571647155, 11.103043524617275],
            [-83.65561174186158, 10.938764146361422]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'HTI.geojson',
        iso_a2: 'HT',
        label_y: 19.263784,
        label_x: -72.224051,
        name: 'Haiti',
        formal_en: 'Republic of Haiti'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.71236141629296, 19.714455878167357],
            [-71.62487321642283, 19.169837958243306],
            [-71.7013026597825, 18.785416978424053],
            [-71.94511206733556, 18.61690013272026],
            [-71.68773759630588, 18.31666006110447],
            [-71.70830481635805, 18.044997056546094],
            [-72.37247616238935, 18.21496084235406],
            [-72.84441118029488, 18.145611070218365],
            [-73.45455481636503, 18.2179063989947],
            [-73.92243323433566, 18.030992743395004],
            [-74.45803361682478, 18.342549953682706],
            [-74.36992529976713, 18.66490753831941],
            [-73.44954220243272, 18.526052964751145],
            [-72.69493709989064, 18.445799465401862],
            [-72.334881557897, 18.668421535715254],
            [-72.79164954292489, 19.10162506761803],
            [-72.78410478381028, 19.48359141690341],
            [-73.41502234566175, 19.639550889560283],
            [-73.18979061551762, 19.915683905511912],
            [-72.57967281766362, 19.871500555902358],
            [-71.71236141629296, 19.714455878167357]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'DOM.geojson',
        iso_a2: 'DO',
        label_y: 19.104137,
        label_x: -70.653998,
        name: 'Dominican Rep.',
        formal_en: 'Dominican Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.70830481635805, 18.044997056546094],
            [-71.68773759630588, 18.31666006110447],
            [-71.94511206733556, 18.61690013272026],
            [-71.7013026597825, 18.785416978424053],
            [-71.62487321642283, 19.169837958243306],
            [-71.71236141629296, 19.714455878167357],
            [-71.58730445014663, 19.8849105900821],
            [-70.80670610216174, 19.880285549391985],
            [-70.21436499701613, 19.62288524014616],
            [-69.95081519232758, 19.64799998624001],
            [-69.76925004747008, 19.29326711677244],
            [-69.22212582057988, 19.313214219637103],
            [-69.25434607611385, 19.015196234609874],
            [-68.80941199408083, 18.979074408437853],
            [-68.31794328476897, 18.612197577381693],
            [-68.68931596543452, 18.205142320218613],
            [-69.16494584824892, 18.42264842373511],
            [-69.62398759629764, 18.38071299893025],
            [-69.95293392605154, 18.42830699307106],
            [-70.1332329983179, 18.245915025296895],
            [-70.51713721381422, 18.184290879788833],
            [-70.66929846869763, 18.426885891183034],
            [-70.99995012071719, 18.283328762276213],
            [-71.4002099270339, 17.5985643579766],
            [-71.65766191271202, 17.7575727401387],
            [-71.70830481635805, 18.044997056546094]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SLV.geojson',
        iso_a2: 'SV',
        label_y: 13.685371,
        label_x: -88.890124,
        name: 'El Salvador',
        formal_en: 'Republic of El Salvador'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.35332597528281, 14.424132798719086],
            [-89.05851192905766, 14.340029405164087],
            [-88.84307288283284, 14.14050670008517],
            [-88.541230841816, 13.980154730683479],
            [-88.50399797234971, 13.845485948130857],
            [-88.06534257684014, 13.964625962779778],
            [-87.85951534702161, 13.893312486217098],
            [-87.7235029772294, 13.785050360565506],
            [-87.79311113152657, 13.384480495655055],
            [-87.90411210808952, 13.149016831917137],
            [-88.48330156121682, 13.163951320849492],
            [-88.84322791212972, 13.259733588102478],
            [-89.2567427233293, 13.458532823129303],
            [-89.81239356154767, 13.520622056527998],
            [-90.09555457229098, 13.735337632700734],
            [-90.0646779039966, 13.881969509328925],
            [-89.72193396682073, 14.134228013561696],
            [-89.53421932652051, 14.244815578666305],
            [-89.58734269891656, 14.36258616785949],
            [-89.35332597528281, 14.424132798719086]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GTM.geojson',
        iso_a2: 'GT',
        label_y: 14.982133,
        label_x: -90.497134,
        name: 'Guatemala',
        formal_en: 'Republic of Guatemala'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-92.22775000686983, 14.538828640190928],
            [-92.20322953974733, 14.830102850804069],
            [-92.08721594925207, 15.064584662328441],
            [-92.2292486234063, 15.251446641495875],
            [-91.74796017125595, 16.066564846251765],
            [-90.46447262242266, 16.069562079324655],
            [-90.43886695022204, 16.410109768128095],
            [-90.60084672724093, 16.47077789963879],
            [-90.71182186558772, 16.687483018454728],
            [-91.08167009150065, 16.918476670799407],
            [-91.45392127151516, 17.252177232324172],
            [-91.00226925328421, 17.25465770107418],
            [-91.00151994501596, 17.81759491624571],
            [-90.06793351923098, 17.819326076727478],
            [-89.14308041050333, 17.808318996649405],
            [-89.15080603713095, 17.015576687075836],
            [-89.22912167026928, 15.886937567605171],
            [-88.93061275913527, 15.887273464415076],
            [-88.60458614780585, 15.70638011317736],
            [-88.51836402052686, 15.855389105690975],
            [-88.22502275262202, 15.727722479713904],
            [-88.68067969435563, 15.346247056535304],
            [-89.15481096063357, 15.06641917567481],
            [-89.22522009963127, 14.874286200413621],
            [-89.14553504103719, 14.678019110569153],
            [-89.35332597528281, 14.424132798719086],
            [-89.58734269891656, 14.36258616785949],
            [-89.53421932652051, 14.244815578666305],
            [-89.72193396682073, 14.134228013561696],
            [-90.0646779039966, 13.881969509328925],
            [-90.09555457229098, 13.735337632700734],
            [-90.60862403030085, 13.909771429901951],
            [-91.23241024449605, 13.927832342987957],
            [-91.68974667027913, 14.126218166556455],
            [-92.22775000686983, 14.538828640190928]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CUB.geojson',
        iso_a2: 'CU',
        label_y: 21.334024,
        label_x: -77.975855,
        name: 'Cuba',
        formal_en: 'Republic of Cuba'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-82.26815121125706, 23.188610744717707],
            [-81.40445716014683, 23.117271429938782],
            [-80.6187686835812, 23.105980129483],
            [-79.67952368846025, 22.76530324959883],
            [-79.28148596873208, 22.399201565027056],
            [-78.34743445505649, 22.512166246017088],
            [-77.99329586456028, 22.277193508385935],
            [-77.14642249216105, 21.657851467367834],
            [-76.52382483590856, 21.206819566324373],
            [-76.19462012399319, 21.220565497314013],
            [-75.59822241891267, 21.016624457274133],
            [-75.67106035022806, 20.735091254148003],
            [-74.9338960435845, 20.693905137611385],
            [-74.17802486845126, 20.28462779385974],
            [-74.29664811877726, 20.05037852628068],
            [-74.96159461129294, 19.92343537035569],
            [-75.63468014189459, 19.873774318923196],
            [-76.323656175426, 19.95289093676206],
            [-77.75548092315307, 19.855480861891877],
            [-77.08510840524674, 20.413353786698792],
            [-77.49265458851661, 20.673105373613893],
            [-78.13729224314159, 20.739948838783434],
            [-78.48282670766119, 21.02861338956585],
            [-78.71986650258401, 21.598113511638434],
            [-79.28499996612794, 21.5591753199065],
            [-80.21747534861865, 21.827324327069036],
            [-80.51753455272141, 22.03707896574176],
            [-81.82094336620318, 22.19205658618507],
            [-82.16999182811864, 22.387109279870753],
            [-81.79500179719267, 22.636964830001958],
            [-82.77589799674085, 22.688150336187064],
            [-83.49445878775936, 22.16851797127613],
            [-83.90880042187563, 22.154565334557333],
            [-84.05215084505326, 21.910575059491254],
            [-84.54703019889638, 21.801227728761646],
            [-84.97491105827311, 21.89602814380109],
            [-84.44706214062776, 22.204949856041907],
            [-84.23035702181178, 22.565754706303764],
            [-83.7782399156902, 22.788118394455694],
            [-83.26754757356575, 22.983041897060644],
            [-82.51043616405751, 23.078746649665188],
            [-82.26815121125706, 23.188610744717707]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'HND.geojson',
        iso_a2: 'HN',
        label_y: 14.794801,
        label_x: -86.887604,
        name: 'Honduras',
        formal_en: 'Republic of Honduras'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-83.14721900097413, 14.99582916916411],
            [-83.48998877636612, 15.016267198135537],
            [-83.62858496777292, 14.880073960830302],
            [-83.97572140169359, 14.749435939996488],
            [-84.22834164095241, 14.74876414637663],
            [-84.4493359036486, 14.621614284722511],
            [-84.64958207877964, 14.666805324761867],
            [-84.82003679069436, 14.81958669683267],
            [-84.9245006985724, 14.79049286545235],
            [-85.05278744173694, 14.551541042534723],
            [-85.14875057650296, 14.560196844943617],
            [-85.16536454948482, 14.354369615125051],
            [-85.51441301140028, 14.079011745657908],
            [-85.69866533073696, 13.960078436738002],
            [-85.80129472526859, 13.83605499923759],
            [-86.09626380079061, 14.038187364147234],
            [-86.31214209668993, 13.77135610600817],
            [-86.52070817741992, 13.778487453664468],
            [-86.75508663607971, 13.754845485890913],
            [-86.7338217841916, 13.263092556201443],
            [-86.88055701368438, 13.254204209847217],
            [-87.00576900912758, 13.025794379117158],
            [-87.31665442579549, 12.984685777228975],
            [-87.48940873894713, 13.297534898323931],
            [-87.79311113152657, 13.384480495655055],
            [-87.7235029772294, 13.785050360565506],
            [-87.85951534702161, 13.893312486217098],
            [-88.06534257684014, 13.964625962779778],
            [-88.50399797234971, 13.845485948130857],
            [-88.541230841816, 13.980154730683479],
            [-88.84307288283284, 14.14050670008517],
            [-89.05851192905766, 14.340029405164087],
            [-89.35332597528281, 14.424132798719086],
            [-89.14553504103719, 14.678019110569153],
            [-89.22522009963127, 14.874286200413621],
            [-89.15481096063357, 15.06641917567481],
            [-88.68067969435563, 15.346247056535304],
            [-88.22502275262202, 15.727722479713904],
            [-88.12115312371537, 15.688655096901357],
            [-87.90181250685242, 15.864458319558196],
            [-87.61568010125234, 15.878798529519202],
            [-87.52292090528846, 15.797278957578783],
            [-87.36776241733213, 15.846940009011291],
            [-86.90319129102818, 15.75671295822957],
            [-86.44094560417739, 15.782835394753192],
            [-86.11923397494434, 15.893448798073962],
            [-86.00195431185784, 16.00540578863439],
            [-85.68331743034628, 15.953651841693954],
            [-85.44400387240256, 15.885749009662447],
            [-85.18244361035721, 15.909158433490632],
            [-84.98372188997882, 15.995923163308703],
            [-84.52697974316715, 15.857223619037427],
            [-84.36825558138258, 15.835157782448732],
            [-84.06305457226682, 15.648244126849136],
            [-83.77397661002612, 15.424071763566872],
            [-83.41038123242038, 15.270902818253774],
            [-83.14721900097413, 14.99582916916411]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'USA.geojson',
        iso_a2: 'US',
        label_y: 39.538479,
        label_x: -97.482602,
        name: 'United States of America',
        formal_en: 'United States of America'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.84, 49.000000000000114],
              [-120, 49.000000000000114],
              [-117.03121, 49],
              [-116.04818, 49],
              [-113, 49],
              [-110.05, 49],
              [-107.05, 49],
              [-104.04826, 48.99986],
              [-100.65, 49.000000000000114],
              [-97.2287200000048, 49.0007],
              [-95.15906950917206, 49],
              [-95.15609, 49.38425],
              [-94.81758, 49.38905],
              [-94.64, 48.84],
              [-94.32914, 48.67074],
              [-93.63087, 48.60926],
              [-92.61, 48.45],
              [-91.64, 48.14],
              [-90.83, 48.27],
              [-89.6, 48.010000000000105],
              [-89.27291744663665, 48.01980825458281],
              [-88.37811418328671, 48.302917588893706],
              [-87.43979262330028, 47.94],
              [-86.46199083122826, 47.553338019392],
              [-85.65236324740341, 47.22021881773051],
              [-84.8760798815149, 46.90008331968238],
              [-84.77923824739992, 46.63710195574902],
              [-84.54374874544584, 46.538684190449146],
              [-84.6049, 46.4396],
              [-84.3367, 46.40877],
              [-84.1421195136734, 46.51222585711571],
              [-84.0918512641615, 46.27541860613826],
              [-83.89076534700574, 46.116926988299014],
              [-83.61613094759059, 46.116926988299014],
              [-83.46955074739469, 45.994686387712534],
              [-83.59285071484311, 45.81689362241252],
              [-82.55092464875821, 45.34751658790543],
              [-82.33776312543114, 44.44],
              [-82.13764238150395, 43.57108755143997],
              [-82.43, 42.98],
              [-82.9, 42.43],
              [-83.12, 42.08],
              [-83.14199968131264, 41.975681057292874],
              [-83.029810146807, 41.83279572200598],
              [-82.69008928092023, 41.675105088867326],
              [-82.4392777167916, 41.675105088867326],
              [-81.27774654816716, 42.209025987306816],
              [-80.24744767934794, 42.36619985612255],
              [-78.93936214874375, 42.86361135514798],
              [-78.92, 42.965],
              [-79.01, 43.27],
              [-79.17167355011186, 43.46633942318426],
              [-78.72027991404235, 43.62508942318493],
              [-77.7378850979577, 43.62905558936328],
              [-76.82003414580558, 43.628784288093755],
              [-76.5, 44.01845889375865],
              [-76.375, 44.09631],
              [-75.31821, 44.81645],
              [-74.867, 45.00048],
              [-73.34783, 45.00738],
              [-71.50506, 45.0082],
              [-71.405, 45.255],
              [-71.08482, 45.30524000000014],
              [-70.66, 45.46],
              [-70.305, 45.915],
              [-69.99997, 46.69307],
              [-69.237216, 47.447781],
              [-68.905, 47.185],
              [-68.23444, 47.35486],
              [-67.79046, 47.06636],
              [-67.79134, 45.70281],
              [-67.13741, 45.13753],
              [-66.96466, 44.809700000000134],
              [-68.03252, 44.3252],
              [-69.06, 43.98],
              [-70.11617, 43.68405000000013],
              [-70.64547563341102, 43.09023834896402],
              [-70.81489, 42.8653],
              [-70.825, 42.335],
              [-70.495, 41.805],
              [-70.08, 41.78],
              [-70.185, 42.145],
              [-69.88497, 41.92283],
              [-69.96503, 41.63717000000014],
              [-70.64, 41.475],
              [-71.12039, 41.49445000000014],
              [-71.86, 41.32],
              [-72.295, 41.27],
              [-72.87643, 41.22065],
              [-73.71, 40.93110235165449],
              [-72.24126, 41.119480000000124],
              [-71.94499999999988, 40.93],
              [-73.345, 40.63],
              [-73.982, 40.628],
              [-73.952325, 40.75075],
              [-74.25671, 40.47351],
              [-73.96244, 40.42763],
              [-74.17838, 39.70926],
              [-74.90604, 38.93954],
              [-74.98041, 39.1964],
              [-75.20002, 39.248450000000105],
              [-75.52805, 39.4985],
              [-75.32, 38.96],
              [-75.07183476478986, 38.782032230179254],
              [-75.05673, 38.40412],
              [-75.37747, 38.01551],
              [-75.94023, 37.21689],
              [-76.03127, 37.2566],
              [-75.72204999999985, 37.93705000000011],
              [-76.23287, 38.319215],
              [-76.35, 39.15],
              [-76.542725, 38.717615],
              [-76.32933, 38.08326],
              [-76.98999793161352, 38.23999176691336],
              [-76.30162, 37.917945],
              [-76.25874, 36.9664],
              [-75.9718, 36.89726],
              [-75.8680399999999, 36.55125],
              [-75.72749, 35.55074000000013],
              [-76.36318, 34.80854000000011],
              [-77.397635, 34.51201],
              [-78.05496, 33.92547],
              [-78.55434999999989, 33.86133000000012],
              [-79.06067, 33.49395],
              [-79.20357, 33.15839],
              [-80.301325, 32.509355],
              [-80.86498, 32.0333],
              [-81.33629, 31.44049],
              [-81.49042, 30.7299900000001],
              [-81.31371, 30.03552],
              [-80.98, 29.18000000000012],
              [-80.535585, 28.47213],
              [-80.52999999999986, 28.04],
              [-80.05653928497759, 26.88000000000011],
              [-80.088015, 26.205765],
              [-80.13156, 25.816775],
              [-80.38103, 25.20616],
              [-80.68, 25.08],
              [-81.17213, 25.201260000000104],
              [-81.33, 25.64],
              [-81.70999999999987, 25.87],
              [-82.24, 26.730000000000132],
              [-82.70515, 27.49504],
              [-82.85526, 27.88624],
              [-82.65, 28.550000000000125],
              [-82.93, 29.1],
              [-83.70959, 29.93656],
              [-84.1, 30.09],
              [-85.10882, 29.63615],
              [-85.28784, 29.68612000000013],
              [-85.7731, 30.15261],
              [-86.4, 30.4],
              [-87.53036, 30.27433],
              [-88.41782, 30.3849],
              [-89.18049, 30.31598],
              [-89.5938311784198, 30.159994004836847],
              [-89.413735, 29.89419],
              [-89.43, 29.48864],
              [-89.21767, 29.29108],
              [-89.40823, 29.15961],
              [-89.77928, 29.307140000000118],
              [-90.15463, 29.11743],
              [-90.880225, 29.148535],
              [-91.626785, 29.677000000000135],
              [-92.49906, 29.5523],
              [-93.22637, 29.78375],
              [-93.84842, 29.71363],
              [-94.69, 29.480000000000132],
              [-95.60026, 28.73863],
              [-96.59404, 28.30748],
              [-97.13999999999987, 27.83],
              [-97.37, 27.38],
              [-97.38, 26.69],
              [-97.33, 26.21],
              [-97.13999999999987, 25.87],
              [-97.53, 25.84],
              [-98.24, 26.06],
              [-99.02, 26.37],
              [-99.3, 26.84],
              [-99.52, 27.54],
              [-100.11, 28.110000000000127],
              [-100.45584, 28.69612000000012],
              [-100.9576, 29.380710000000136],
              [-101.6624, 29.7793],
              [-102.48, 29.76],
              [-103.11, 28.97],
              [-103.94, 29.27],
              [-104.4569699999999, 29.57196],
              [-104.70575, 30.12173],
              [-105.03737, 30.64402],
              [-105.63159, 31.08383],
              [-106.1429, 31.39995],
              [-106.50758999999988, 31.75452],
              [-108.24, 31.754853718166373],
              [-108.24194, 31.34222],
              [-109.035, 31.341940000000136],
              [-111.02361, 31.33472],
              [-113.30498, 32.03914],
              [-114.815, 32.52528],
              [-114.72139, 32.72083],
              [-115.99135, 32.61239000000012],
              [-117.12775999999985, 32.53534],
              [-117.29593769127393, 33.04622461520387],
              [-117.944, 33.621236431201396],
              [-118.41060227589753, 33.74090922312445],
              [-118.51989482279976, 34.02778157757575],
              [-119.081, 34.078],
              [-119.43884064201671, 34.34847717828427],
              [-120.36778, 34.44711],
              [-120.62286, 34.60855],
              [-120.74433, 35.15686000000011],
              [-121.71457, 36.16153],
              [-122.54747, 37.551760000000115],
              [-122.51201, 37.78339000000011],
              [-122.95319, 38.11371],
              [-123.7272, 38.95166000000012],
              [-123.86517, 39.76699],
              [-124.39807, 40.3132],
              [-124.17886, 41.142020000000116],
              [-124.2137, 41.99964000000011],
              [-124.53284, 42.7659900000001],
              [-124.14214, 43.70838],
              [-124.020535, 44.615895],
              [-123.89893, 45.52341],
              [-124.079635, 46.86475],
              [-124.39567, 47.72017000000011],
              [-124.68721008300781, 48.18443298339855],
              [-124.56610107421875, 48.37971496582037],
              [-123.12, 48.04],
              [-122.58736, 47.096],
              [-122.34, 47.36],
              [-122.5, 48.18],
              [-122.84, 49.000000000000114]
            ]
          ],
          [
            [
              [-155.40214, 20.07975],
              [-155.22452, 19.99302],
              [-155.06226, 19.8591],
              [-154.80741, 19.50871],
              [-154.83147, 19.45328],
              [-155.22217, 19.23972],
              [-155.54211, 19.08348],
              [-155.68817, 18.91619],
              [-155.93665, 19.05939],
              [-155.90806, 19.33888],
              [-156.07347, 19.70294],
              [-156.02368, 19.81422],
              [-155.85008, 19.97729],
              [-155.91907, 20.17395],
              [-155.86108, 20.26721],
              [-155.78505, 20.2487],
              [-155.40214, 20.07975]
            ]
          ],
          [
            [
              [-155.99566, 20.76404],
              [-156.07926, 20.64397],
              [-156.41445, 20.57241],
              [-156.58673, 20.783],
              [-156.70167, 20.8643],
              [-156.71055, 20.92676],
              [-156.61258, 21.01249],
              [-156.25711, 20.91745],
              [-155.99566, 20.76404]
            ]
          ],
          [
            [
              [-156.75824, 21.17684],
              [-156.78933, 21.06873],
              [-157.32521, 21.09777],
              [-157.25027, 21.21958],
              [-156.75824, 21.17684]
            ]
          ],
          [
            [
              [-158.0252, 21.71696],
              [-157.94161, 21.65272],
              [-157.65283, 21.32217],
              [-157.70703, 21.26442],
              [-157.7786, 21.27729],
              [-158.12667, 21.31244],
              [-158.2538, 21.53919],
              [-158.29265, 21.57912],
              [-158.0252, 21.71696]
            ]
          ],
          [
            [
              [-159.36569, 22.21494],
              [-159.34512, 21.982],
              [-159.46372, 21.88299],
              [-159.80051, 22.06533],
              [-159.74877, 22.1382],
              [-159.5962, 22.23618],
              [-159.36569, 22.21494]
            ]
          ],
          [
            [
              [-166.46779212142462, 60.384169826897754],
              [-165.67442969466364, 60.29360687930625],
              [-165.57916419173358, 59.90998688418753],
              [-166.19277014876727, 59.75444082298899],
              [-166.84833736882197, 59.941406155020985],
              [-167.45527706609008, 60.21306915957936],
              [-166.46779212142462, 60.384169826897754]
            ]
          ],
          [
            [
              [-153.22872941792113, 57.96896841087248],
              [-152.56479061583514, 57.901427313866996],
              [-152.1411472239064, 57.591058661522],
              [-153.00631405333692, 57.11584219016593],
              [-154.0050902984581, 56.734676825581076],
              [-154.51640275777004, 56.99274892844669],
              [-154.67099280497118, 57.46119578717253],
              [-153.7627795074415, 57.81657461204373],
              [-153.22872941792113, 57.96896841087248]
            ]
          ],
          [
            [
              [-140.98598761037601, 69.71199839952635],
              [-140.986, 69.712],
              [-140.9925, 66.00003],
              [-140.99778, 60.30639],
              [-140.013, 60.27682],
              [-139.039, 60],
              [-138.34089, 59.56211],
              [-137.4525, 58.905],
              [-136.47972, 59.46389],
              [-135.47583, 59.78778],
              [-134.945, 59.2705600000001],
              [-134.27111, 58.86111],
              [-133.35556, 58.41028],
              [-132.73042, 57.69289],
              [-131.70781, 56.55212],
              [-130.00778, 55.91583],
              [-129.98, 55.285],
              [-130.53611, 54.80278],
              [-130.53610895273684, 54.80275447679924],
              [-130.5361101894673, 54.8027534043494],
              [-131.08581823797215, 55.17890615500204],
              [-131.9672114671423, 55.497775580459006],
              [-132.2500107428595, 56.3699962428974],
              [-133.53918108435641, 57.17888743756214],
              [-134.07806292029608, 58.12306753196691],
              [-135.0382110322791, 58.18771474876394],
              [-136.62806230995471, 58.21220937767043],
              [-137.800006279686, 58.49999542910376],
              [-139.867787041413, 59.53776154238915],
              [-140.825273817133, 59.727517401765056],
              [-142.57444353556446, 60.08444651960497],
              [-143.9588809948799, 59.999180406323376],
              [-145.92555681682788, 60.45860972761426],
              [-147.11437394914665, 60.884656073644635],
              [-148.22430620012761, 60.67298940697714],
              [-148.01806555885082, 59.97832896589364],
              [-148.57082251686086, 59.914172675203304],
              [-149.72785783587585, 59.70565827090553],
              [-150.60824337461642, 59.368211168039466],
              [-151.7163927886833, 59.15582103131993],
              [-151.85943315326722, 59.744984035879554],
              [-151.40971900124717, 60.72580272077937],
              [-150.3469414947325, 61.03358755150987],
              [-150.62111080625704, 61.2844249538544],
              [-151.89583919981683, 60.727197984451266],
              [-152.57832984109558, 60.061657212964235],
              [-154.01917212625764, 59.35027944603428],
              [-153.28751135965317, 58.86472768821977],
              [-154.23249243875847, 58.14637360293051],
              [-155.3074914215102, 57.727794501366304],
              [-156.30833472392305, 57.422774359763594],
              [-156.55609737854638, 56.97998484967064],
              [-158.11721655986779, 56.46360809999419],
              [-158.43332129619714, 55.99415355083852],
              [-159.60332739971741, 55.56668610292013],
              [-160.28971961163427, 55.643580634170576],
              [-161.22304765525777, 55.364734605523495],
              [-162.23776607974105, 55.02418691672011],
              [-163.06944658104638, 54.68973704692712],
              [-164.78556922102717, 54.40417308208214],
              [-164.94222632552007, 54.57222483989534],
              [-163.84833960676565, 55.03943146424609],
              [-162.87000139061595, 55.34804311789321],
              [-161.80417497459607, 55.89498647727038],
              [-160.5636047027812, 56.00805451112501],
              [-160.07055986228448, 56.41805532492873],
              [-158.6844429189195, 57.01667511659787],
              [-158.46109737855403, 57.21692129172885],
              [-157.72277035218391, 57.57000051536306],
              [-157.55027442119362, 58.328326321030204],
              [-157.04167497457698, 58.91888458926172],
              [-158.19473120830554, 58.61580231386978],
              [-158.51721798402303, 58.78778148053732],
              [-159.0586061269288, 58.42418610293163],
              [-159.71166704001737, 58.93139028587632],
              [-159.98128882550017, 58.572549140041644],
              [-160.3552711659965, 59.07112335879361],
              [-161.3550034251151, 58.670837714260756],
              [-161.96889360252632, 58.67166453717738],
              [-162.05498653872465, 59.26692536074745],
              [-161.8741707021354, 59.63362132429057],
              [-162.51805904849212, 59.98972361921386],
              [-163.8183414378202, 59.79805573184336],
              [-164.66221757714652, 60.26748444278263],
              [-165.3463877024748, 60.50749563256238],
              [-165.3508318756519, 61.073895168697504],
              [-166.12137915755602, 61.50001902937623],
              [-165.73445187077058, 62.074996853271784],
              [-164.9191786367179, 62.63307648380794],
              [-164.56250790103934, 63.14637848576302],
              [-163.75333248599708, 63.21944896102377],
              [-163.06722449445786, 63.05945872664802],
              [-162.26055538638175, 63.54193573674115],
              [-161.53444983624863, 63.455816962326764],
              [-160.7725066803211, 63.766108100023246],
              [-160.9583351308426, 64.22279857040274],
              [-161.51806840721218, 64.40278758407527],
              [-160.77777767641481, 64.78860382756642],
              [-161.39192623598765, 64.77723501246231],
              [-162.4530500966689, 64.55944468856819],
              [-162.75778601789415, 64.33860545516876],
              [-163.54639421288428, 64.5591604681905],
              [-164.96082984114514, 64.44694509546883],
              [-166.42528825586447, 64.68667206487066],
              [-166.8450042389391, 65.08889557561452],
              [-168.11056006576715, 65.66999705673675],
              [-166.70527116602193, 66.08831777613938],
              [-164.47470964257548, 66.5766600612975],
              [-163.65251176659564, 66.5766600612975],
              [-163.78860165103623, 66.07720734319668],
              [-161.67777442121013, 66.11611969671242],
              [-162.48971452538004, 66.73556509059512],
              [-163.71971696679117, 67.11639455837008],
              [-164.4309913808565, 67.61633820257777],
              [-165.39028683170673, 68.04277212185025],
              [-166.76444068099605, 68.35887685817966],
              [-166.20470740462667, 68.88303091091615],
              [-164.43081051334346, 68.91553538682774],
              [-163.1686136546145, 69.37111481391287],
              [-162.930566169262, 69.85806183539927],
              [-161.90889726463556, 70.33332998318764],
              [-160.93479651593367, 70.44768992784958],
              [-159.03917578838713, 70.89164215766891],
              [-158.11972286683394, 70.82472117785102],
              [-156.58082455139808, 71.35776357694175],
              [-155.06779029032427, 71.14777639432367],
              [-154.3441652089412, 70.69640859647018],
              [-153.9000062733926, 70.88998851183567],
              [-152.21000606993528, 70.82999217394485],
              [-152.27000240782613, 70.60000621202983],
              [-150.73999243874448, 70.43001658800569],
              [-149.7200030181675, 70.53001048449045],
              [-147.61336157935705, 70.2140349392418],
              [-145.68998980022533, 70.12000967068673],
              [-144.9200109590764, 69.98999176704046],
              [-143.58944618042523, 70.15251414659832],
              [-142.07251034871348, 69.85193817817265],
              [-140.98598752156073, 69.71199839952635],
              [-140.98598761037601, 69.71199839952635]
            ]
          ],
          [
            [
              [-171.73165686753944, 63.782515367275934],
              [-171.1144335602453, 63.59219106714495],
              [-170.4911124339407, 63.694975490973505],
              [-169.6825054596536, 63.43111562769119],
              [-168.6894394603007, 63.297506212000556],
              [-168.77194088445466, 63.18859813094544],
              [-169.5294398672051, 62.97693146427792],
              [-170.29055620021595, 63.194437567794424],
              [-170.67138566799093, 63.3758218451389],
              [-171.55306311753873, 63.317789211675105],
              [-171.79111060289122, 63.40584585230046],
              [-171.73165686753944, 63.782515367275934]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CAN.geojson',
        iso_a2: 'CA',
        label_y: 60.324287,
        label_x: -101.9107,
        name: 'Canada',
        formal_en: 'Canada'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-122.84, 49.000000000000114],
              [-122.97421, 49.00253777777778],
              [-124.91024, 49.98456],
              [-125.62461, 50.41656],
              [-127.43561, 50.83061],
              [-127.99276, 51.71583],
              [-127.85032, 52.32961],
              [-129.12979, 52.75538],
              [-129.30523, 53.56159],
              [-130.51497, 54.28757],
              [-130.53610895273684, 54.80275447679924],
              [-130.53611, 54.80278],
              [-129.98, 55.285],
              [-130.00778, 55.91583],
              [-131.70781, 56.55212],
              [-132.73042, 57.69289],
              [-133.35556, 58.41028],
              [-134.27111, 58.86111],
              [-134.945, 59.2705600000001],
              [-135.47583, 59.78778],
              [-136.47972, 59.46389],
              [-137.4525, 58.905],
              [-138.34089, 59.56211],
              [-139.039, 60],
              [-140.013, 60.27682],
              [-140.99778, 60.30639],
              [-140.9925, 66.00003],
              [-140.986, 69.712],
              [-140.98598761037601, 69.71199839952635],
              [-139.12052, 69.47102],
              [-137.54636, 68.99002],
              [-136.50358, 68.89804],
              [-135.62576, 69.31512],
              [-134.41464, 69.62743],
              [-132.92925, 69.50534],
              [-131.43136, 69.94451],
              [-129.79471, 70.19369],
              [-129.10773, 69.77927],
              [-128.36156, 70.01286],
              [-128.13817, 70.48384],
              [-127.44712, 70.37721],
              [-125.75632, 69.48058],
              [-124.42483, 70.1584],
              [-124.28968, 69.39969],
              [-123.06108, 69.56372],
              [-122.6835, 69.85553],
              [-121.47226, 69.79778],
              [-119.94288, 69.37786],
              [-117.60268, 69.01128],
              [-116.22643, 68.84151],
              [-115.2469, 68.90591],
              [-113.89794, 68.3989],
              [-115.30489, 67.90261],
              [-113.49727, 67.68815],
              [-110.798, 67.80612],
              [-109.94619, 67.98104],
              [-108.8802, 67.38144],
              [-107.79239, 67.88736],
              [-108.81299, 68.31164],
              [-108.16721, 68.65392],
              [-106.95, 68.7],
              [-106.15, 68.8],
              [-105.34282, 68.56122],
              [-104.33791, 68.018],
              [-103.22115, 68.09775],
              [-101.45433, 67.64689],
              [-99.90195, 67.80566],
              [-98.4432, 67.78165],
              [-98.5586, 68.40394],
              [-97.66948, 68.57864],
              [-96.11991, 68.23939],
              [-96.12588, 67.29338],
              [-95.48943, 68.0907],
              [-94.685, 68.06383],
              [-94.23282, 69.06903],
              [-95.30408, 69.68571],
              [-96.47131, 70.08976],
              [-96.39115, 71.19482],
              [-95.2088, 71.92053],
              [-93.88997, 71.76015],
              [-92.87818, 71.31869],
              [-91.51964, 70.19129],
              [-92.40692, 69.69997],
              [-90.5471, 69.49766],
              [-90.55151, 68.47499],
              [-89.21515, 69.25873],
              [-88.01966, 68.61508],
              [-88.31749, 67.87338],
              [-87.35017, 67.19872],
              [-86.30607, 67.92146],
              [-85.57664, 68.78456],
              [-85.52197, 69.88211],
              [-84.10081, 69.80539],
              [-82.62258, 69.65826],
              [-81.28043, 69.16202],
              [-81.2202, 68.66567],
              [-81.96436, 68.13253],
              [-81.25928, 67.59716],
              [-81.38653, 67.11078],
              [-83.34456, 66.41154],
              [-84.73542, 66.2573],
              [-85.76943, 66.55833],
              [-86.0676, 66.05625],
              [-87.03143, 65.21297],
              [-87.32324, 64.77563],
              [-88.48296, 64.09897],
              [-89.91444, 64.03273],
              [-90.70398, 63.61017],
              [-90.77004, 62.96021],
              [-91.93342, 62.83508],
              [-93.15698, 62.02469],
              [-94.24153, 60.89865],
              [-94.62931, 60.11021],
              [-94.6846, 58.94882],
              [-93.21502, 58.78212],
              [-92.76462, 57.84571],
              [-92.29703, 57.08709],
              [-90.89769, 57.28468],
              [-89.03953, 56.85172],
              [-88.03978, 56.47162],
              [-87.32421, 55.99914],
              [-86.07121, 55.72383],
              [-85.01181, 55.3026],
              [-83.36055, 55.24489],
              [-82.27285, 55.14832],
              [-82.4362, 54.28227],
              [-82.12502, 53.27703],
              [-81.40075, 52.15788],
              [-79.91289, 51.20842],
              [-79.14301, 51.53393],
              [-78.60191, 52.56208],
              [-79.12421, 54.14145],
              [-79.82958, 54.66772],
              [-78.22874, 55.13645],
              [-77.0956, 55.83741],
              [-76.54137, 56.53423],
              [-76.62319, 57.20263],
              [-77.30226, 58.05209],
              [-78.51688, 58.80458],
              [-77.33676, 59.85261],
              [-77.77272, 60.75788],
              [-78.10687, 62.31964],
              [-77.41067, 62.55053],
              [-75.69621, 62.2784],
              [-74.6682, 62.18111],
              [-73.83988, 62.4438],
              [-72.90853, 62.10507],
              [-71.67708, 61.52535],
              [-71.37369, 61.13717],
              [-69.59042, 61.06141],
              [-69.62033, 60.22125],
              [-69.2879, 58.95736],
              [-68.37455, 58.80106],
              [-67.64976, 58.21206],
              [-66.20178, 58.76731],
              [-65.24517, 59.87071],
              [-64.58352, 60.33558],
              [-63.80475, 59.4426],
              [-62.50236, 58.16708],
              [-61.39655, 56.96745],
              [-61.79866, 56.33945],
              [-60.46853, 55.77548],
              [-59.56962, 55.20407],
              [-57.97508, 54.94549],
              [-57.3332, 54.6265],
              [-56.93689, 53.78032],
              [-56.15811, 53.64749],
              [-55.75632, 53.27036],
              [-55.68338, 52.14664],
              [-56.40916, 51.7707],
              [-57.12691, 51.41972],
              [-58.77482, 51.0643],
              [-60.03309, 50.24277],
              [-61.72366, 50.08046],
              [-63.86251, 50.29099],
              [-65.36331, 50.2982],
              [-66.39905, 50.22897],
              [-67.23631, 49.51156],
              [-68.51114, 49.06836],
              [-69.95362, 47.74488],
              [-71.10458, 46.82171],
              [-70.25522, 46.98606],
              [-68.65, 48.3],
              [-66.55243, 49.1331],
              [-65.05626, 49.23278],
              [-64.17099, 48.74248],
              [-65.11545, 48.07085],
              [-64.79854, 46.99297],
              [-64.47219, 46.23849],
              [-63.17329, 45.73902],
              [-61.52072, 45.88377],
              [-60.51815, 47.00793],
              [-60.4486, 46.28264],
              [-59.80287, 45.9204],
              [-61.03988, 45.26525],
              [-63.25471, 44.67014],
              [-64.24656, 44.26553],
              [-65.36406, 43.54523],
              [-66.1234, 43.61867],
              [-66.16173, 44.46512],
              [-64.42549, 45.29204],
              [-66.02605, 45.25931],
              [-67.13741, 45.13753],
              [-67.79134, 45.70281],
              [-67.79046, 47.06636],
              [-68.23444, 47.35486],
              [-68.905, 47.185],
              [-69.237216, 47.447781],
              [-69.99997, 46.69307],
              [-70.305, 45.915],
              [-70.66, 45.46],
              [-71.08482, 45.30524000000014],
              [-71.405, 45.255],
              [-71.50506, 45.0082],
              [-73.34783, 45.00738],
              [-74.867, 45.00048],
              [-75.31821, 44.81645],
              [-76.375, 44.09631],
              [-76.5, 44.01845889375865],
              [-76.82003414580558, 43.628784288093755],
              [-77.7378850979577, 43.62905558936328],
              [-78.72027991404235, 43.62508942318493],
              [-79.17167355011186, 43.46633942318426],
              [-79.01, 43.27],
              [-78.92, 42.965],
              [-78.93936214874375, 42.86361135514798],
              [-80.24744767934794, 42.36619985612255],
              [-81.27774654816716, 42.209025987306816],
              [-82.4392777167916, 41.675105088867326],
              [-82.69008928092023, 41.675105088867326],
              [-83.029810146807, 41.83279572200598],
              [-83.14199968131264, 41.975681057292874],
              [-83.12, 42.08],
              [-82.9, 42.43],
              [-82.43, 42.98],
              [-82.13764238150395, 43.57108755143997],
              [-82.33776312543114, 44.44],
              [-82.55092464875821, 45.34751658790543],
              [-83.59285071484311, 45.81689362241252],
              [-83.46955074739469, 45.994686387712534],
              [-83.61613094759059, 46.116926988299014],
              [-83.89076534700574, 46.116926988299014],
              [-84.0918512641615, 46.27541860613826],
              [-84.1421195136734, 46.51222585711571],
              [-84.3367, 46.40877],
              [-84.6049, 46.4396],
              [-84.54374874544584, 46.538684190449146],
              [-84.77923824739992, 46.63710195574902],
              [-84.8760798815149, 46.90008331968238],
              [-85.65236324740341, 47.22021881773051],
              [-86.46199083122826, 47.553338019392],
              [-87.43979262330028, 47.94],
              [-88.37811418328671, 48.302917588893706],
              [-89.27291744663665, 48.01980825458281],
              [-89.6, 48.010000000000105],
              [-90.83, 48.27],
              [-91.64, 48.14],
              [-92.61, 48.45],
              [-93.63087, 48.60926],
              [-94.32914, 48.67074],
              [-94.64, 48.84],
              [-94.81758, 49.38905],
              [-95.15609, 49.38425],
              [-95.15906950917206, 49],
              [-97.2287200000048, 49.0007],
              [-100.65, 49.000000000000114],
              [-104.04826, 48.99986],
              [-107.05, 49],
              [-110.05, 49],
              [-113, 49],
              [-116.04818, 49],
              [-117.03121, 49],
              [-120, 49.000000000000114],
              [-122.84, 49.000000000000114]
            ]
          ],
          [
            [
              [-83.99367, 62.4528],
              [-83.25048, 62.91409],
              [-81.87699, 62.90458],
              [-81.89825, 62.7108],
              [-83.06857, 62.15922],
              [-83.77462, 62.18231],
              [-83.99367, 62.4528]
            ]
          ],
          [
            [
              [-79.77583312988281, 72.8029022216797],
              [-80.87609863281251, 73.33318328857422],
              [-80.83388519287111, 73.69318389892578],
              [-80.35305786132811, 73.75971984863281],
              [-78.06443786621094, 73.65193176269531],
              [-76.34, 73.10268498995305],
              [-76.25140380859375, 72.82638549804688],
              [-77.31443786621094, 72.85554504394531],
              [-78.39167022705078, 72.87665557861328],
              [-79.4862518310547, 72.74220275878906],
              [-79.77583312988281, 72.8029022216797]
            ]
          ],
          [
            [
              [-80.315395, 62.085565],
              [-79.92939, 62.3856],
              [-79.52002, 62.36371],
              [-79.26582, 62.158675],
              [-79.65752, 61.63308],
              [-80.09956, 61.7181],
              [-80.36215, 62.01649],
              [-80.315395, 62.085565]
            ]
          ],
          [
            [
              [-93.61275590694046, 74.97999726022438],
              [-94.15690873897391, 74.59234650338688],
              [-95.60868058956564, 74.66686391875176],
              [-96.82093217648455, 74.92762319609658],
              [-96.28858740922982, 75.37782827422338],
              [-94.85081987178917, 75.64721751576089],
              [-93.97774654821797, 75.29648956979595],
              [-93.61275590694046, 74.97999726022438]
            ]
          ],
          [
            [
              [-93.84000301794399, 77.51999726023455],
              [-94.29560828324529, 77.49134267852868],
              [-96.16965410031007, 77.55511139597685],
              [-96.43630449093614, 77.83462921824362],
              [-94.42257727738641, 77.820004787905],
              [-93.7206562975659, 77.63433136668031],
              [-93.84000301794399, 77.51999726023455]
            ]
          ],
          [
            [
              [-96.75439876990876, 78.76581268992702],
              [-95.5592779202946, 78.41831452098033],
              [-95.83029496944934, 78.05694122996324],
              [-97.30984290239799, 77.85059723582181],
              [-98.12428931353404, 78.08285696075761],
              [-98.55286780474668, 78.45810537384507],
              [-98.63198442258553, 78.87193024363837],
              [-97.33723141151266, 78.83198436147676],
              [-96.75439876990876, 78.76581268992702]
            ]
          ],
          [
            [
              [-88.15035030796028, 74.39230703398503],
              [-89.7647220527584, 74.51555532500116],
              [-92.42244096552946, 74.83775788034099],
              [-92.76828548864282, 75.38681997344214],
              [-92.88990597204175, 75.88265534128267],
              [-93.89382402217599, 76.31924367950056],
              [-95.9624574450358, 76.4413809272224],
              [-97.1213789538295, 76.7510777859476],
              [-96.74512285031237, 77.16138865834507],
              [-94.68408586299944, 77.09787832305837],
              [-93.57392106807313, 76.77629588490605],
              [-91.6050231595366, 76.7785179714946],
              [-90.7418458727493, 76.44959747995681],
              [-90.96966142450802, 76.07401317005947],
              [-89.82223792189926, 75.84777374948565],
              [-89.18708289259985, 75.61016551380762],
              [-87.83827633334965, 75.56618886992725],
              [-86.37919226758864, 75.4824213731821],
              [-84.78962521029058, 75.69920400664653],
              [-82.75344458691006, 75.78431509063124],
              [-81.12853084992436, 75.71398346628199],
              [-80.05751095245915, 75.33684886341591],
              [-79.83393286814837, 74.92312734648716],
              [-80.45777075877587, 74.65730377877777],
              [-81.94884253612557, 74.44245901152432],
              [-83.22889360221143, 74.56402781849094],
              [-86.09745235873332, 74.41003205026117],
              [-88.15035030796028, 74.39230703398503]
            ]
          ],
          [
            [
              [-111.26444332563088, 78.15295604116154],
              [-109.85445187054711, 77.99632477488488],
              [-110.18693803591302, 77.69701487905034],
              [-112.0511911690585, 77.4092288276169],
              [-113.53427893761912, 77.73220652944111],
              [-112.7245867582539, 78.05105011668196],
              [-111.26444332563088, 78.15295604116154]
            ]
          ],
          [
            [
              [-110.96366065147602, 78.8044408230652],
              [-109.6631457182026, 78.60197256134565],
              [-110.88131425661892, 78.40691986765997],
              [-112.54209143761516, 78.4079017198735],
              [-112.52589087609164, 78.55055451121522],
              [-111.5000103422334, 78.8499935981305],
              [-110.96366065147602, 78.8044408230652]
            ]
          ],
          [
            [
              [-55.600218268442056, 51.31707469339794],
              [-56.13403581401709, 50.68700979267928],
              [-56.795881720595276, 49.81230866149089],
              [-56.14310502788433, 50.15011749938286],
              [-55.471492275603, 49.93581533466846],
              [-55.82240108908096, 49.58712860777905],
              [-54.935142584845636, 49.3130109726868],
              [-54.473775397343786, 49.556691189159125],
              [-53.47654944519137, 49.24913890237404],
              [-53.786013759971254, 48.516780503933624],
              [-53.08613399922626, 48.68780365660358],
              [-52.958648240762216, 48.15716421161447],
              [-52.64809872090421, 47.53554840757552],
              [-53.069158291218386, 46.65549876564492],
              [-53.521456264853, 46.61829173439477],
              [-54.17893551290251, 46.80706574155698],
              [-53.9618686590605, 47.62520701760193],
              [-54.24048214376214, 47.752279364607645],
              [-55.40077307801157, 46.884993801453135],
              [-55.99748084168583, 46.919720363953275],
              [-55.29121904155279, 47.38956248635099],
              [-56.250798712780586, 47.632545070987376],
              [-57.32522925477708, 47.57280711525797],
              [-59.26601518414682, 47.60334788674247],
              [-59.419494188053676, 47.899453843774886],
              [-58.79658647320744, 48.25152537697942],
              [-59.23162451845657, 48.52318838153781],
              [-58.3918049790652, 49.12558055276418],
              [-57.35868974468606, 50.71827403421587],
              [-56.738650071832026, 51.28743825947855],
              [-55.87097693543532, 51.63209422464921],
              [-55.40697424988659, 51.5882726100657],
              [-55.600218268442056, 51.31707469339794]
            ]
          ],
          [
            [
              [-83.88262630891977, 65.10961782496354],
              [-82.78757687043883, 64.76669302027467],
              [-81.6420137193926, 64.45513580998697],
              [-81.55344031444432, 63.97960928003714],
              [-80.81736121287886, 64.057485663501],
              [-80.10345130076664, 63.72598135034862],
              [-80.99101986359572, 63.41124603947496],
              [-82.54717810741704, 63.65172231714521],
              [-83.10879757356511, 64.10187571883971],
              [-84.10041663281388, 63.569711819098],
              [-85.52340471061905, 63.052379055424055],
              [-85.8667687649824, 63.63725291610349],
              [-87.22198320183678, 63.54123810490519],
              [-86.35275977247133, 64.0358332383707],
              [-86.2248864407651, 64.82291697860823],
              [-85.88384782585486, 65.7387783881171],
              [-85.1613079495499, 65.6572846543928],
              [-84.97576371940592, 65.21751821558898],
              [-84.4640120104195, 65.37177236598022],
              [-83.88262630891977, 65.10961782496354]
            ]
          ],
          [
            [
              [-78.77063859731078, 72.35217316353418],
              [-77.8246239895596, 72.74961660429098],
              [-75.60584469267573, 72.2436784939374],
              [-74.228616095665, 71.76714427355789],
              [-74.09914079455771, 71.33084015571758],
              [-72.24222571479768, 71.55692454699452],
              [-71.20001542833518, 70.92001251899718],
              [-68.7860542466849, 70.52502370877427],
              [-67.91497046575694, 70.12194753689765],
              [-66.9690333726542, 69.18608734809182],
              [-68.8051228502006, 68.72019847276444],
              [-66.4498660956339, 68.06716339789203],
              [-64.86231441919524, 67.84753856065159],
              [-63.424934454996794, 66.92847321234059],
              [-61.851981370680605, 66.86212067327783],
              [-62.16317684594226, 66.16025136988962],
              [-63.918444383384184, 64.9986685248329],
              [-65.14886023625368, 65.42603261988667],
              [-66.72121904159852, 66.38804108343219],
              [-68.015016038674, 66.26272573512439],
              [-68.1412874009792, 65.68978913030439],
              [-67.08964616562342, 65.10845510523696],
              [-65.73208045109976, 64.64840566675856],
              [-65.32016760930125, 64.38273712834605],
              [-64.66940629744968, 63.392926744227495],
              [-65.01380388045888, 62.67418508569598],
              [-66.27504472519048, 62.94509878198612],
              [-68.7831862046927, 63.74567007105183],
              [-67.36968075221309, 62.88396556258484],
              [-66.32829728866726, 62.28007477482201],
              [-66.16556820338015, 61.93089712182582],
              [-68.87736650254465, 62.330149237712824],
              [-71.02343705919385, 62.91070811629588],
              [-72.23537858751902, 63.39783600529522],
              [-71.88627844917127, 63.67998932560887],
              [-73.37830624051838, 64.19396312118384],
              [-74.83441891142263, 64.6790756293238],
              [-74.81850257027673, 64.38909332951793],
              [-77.70997982452008, 64.22954234481678],
              [-78.5559488593542, 64.57290639918013],
              [-77.89728105336198, 65.30919220647475],
              [-76.01827429879717, 65.32696889918314],
              [-73.95979529488268, 65.45476471624094],
              [-74.29388342964964, 65.81177134872938],
              [-73.94491248238262, 66.31057811142666],
              [-72.65116716173942, 67.28457550726391],
              [-72.92605994331605, 67.72692576768235],
              [-73.31161780464572, 68.06943716091287],
              [-74.84330725777684, 68.55462718370127],
              [-76.86910091826672, 68.89473562283025],
              [-76.22864905465738, 69.14776927354741],
              [-77.28736996123715, 69.76954010688321],
              [-78.1686339993266, 69.82648753526887],
              [-78.95724219431673, 70.16688019477543],
              [-79.49245500356366, 69.87180776638884],
              [-81.30547095409176, 69.74318512641436],
              [-84.94470618359851, 69.96663401964442],
              [-87.06000342481789, 70.26000112576538],
              [-88.68171322300148, 70.4107412787608],
              [-89.51341956252303, 70.76203766548095],
              [-88.46772111688082, 71.21818553332132],
              [-89.88815121128755, 71.22255219184997],
              [-90.20516028518205, 72.23507436796079],
              [-89.436576707705, 73.12946421985238],
              [-88.40824154331287, 73.53788890247121],
              [-85.82615108920098, 73.80381582304518],
              [-86.56217851433412, 73.15744700793844],
              [-85.77437130404454, 72.53412588163387],
              [-84.85011247428822, 73.34027822538708],
              [-82.31559017610101, 73.7509508328106],
              [-80.60008765330768, 72.71654368762417],
              [-80.74894161652443, 72.06190664335072],
              [-78.77063859731078, 72.35217316353418]
            ]
          ],
          [
            [
              [-94.50365759965237, 74.13490672473922],
              [-92.42001217321173, 74.1000251329422],
              [-90.50979285354263, 73.85673248971206],
              [-92.00396521682987, 72.96624420845852],
              [-93.19629553910026, 72.77199249947334],
              [-94.26904659704726, 72.02459625923599],
              [-95.40985551632266, 72.06188080513458],
              [-96.03374508338244, 72.94027680123183],
              [-96.01826799191102, 73.43742991809582],
              [-95.49579342322404, 73.86241689726417],
              [-94.50365759965237, 74.13490672473922]
            ]
          ],
          [
            [
              [-122.85492448615902, 76.11654287383568],
              [-122.85492529360326, 76.11654287383568],
              [-121.15753536032824, 76.86450755482828],
              [-119.1039389718211, 77.51221995717462],
              [-117.570130784966, 77.4983189968881],
              [-116.19858659550738, 77.6452867703262],
              [-116.33581336145845, 76.87696157501061],
              [-117.10605058476882, 76.53003184681911],
              [-118.04041215703819, 76.48117178008714],
              [-119.89931758688572, 76.053213406062],
              [-121.49999507712648, 75.90001862253276],
              [-122.85492448615902, 76.11654287383568]
            ]
          ],
          [
            [
              [-132.71000788443126, 54.04000931542356],
              [-131.74998958400334, 54.12000438090922],
              [-132.049480347351, 52.98462148702447],
              [-131.1790425218266, 52.180432847698285],
              [-131.57782954982298, 52.18237071390928],
              [-132.18042842677852, 52.639707139692405],
              [-132.54999243231384, 53.100014960332146],
              [-133.05461117875552, 53.411468817755406],
              [-133.2396644827927, 53.851080227262344],
              [-133.1800040417117, 54.169975490935315],
              [-132.71000788443126, 54.04000931542356]
            ]
          ],
          [
            [
              [-105.4922891914932, 79.30159393992916],
              [-103.52928239623795, 79.16534902619163],
              [-100.8251580472688, 78.80046173777872],
              [-100.0601918200522, 78.32475434031589],
              [-99.67093909381364, 77.90754466420744],
              [-101.30394019245301, 78.01898489044486],
              [-102.94980872273302, 78.34322866486023],
              [-105.17613277873151, 78.3803323432458],
              [-104.21042945027713, 78.67742015249176],
              [-105.41958045125853, 78.91833567983649],
              [-105.4922891914932, 79.30159393992916]
            ]
          ],
          [
            [
              [-123.51000158755119, 48.51001089130341],
              [-124.01289078839955, 48.37084625914139],
              [-125.65501277733838, 48.8250045843385],
              [-125.95499446679275, 49.17999583596759],
              [-126.85000443587185, 49.53000031188043],
              [-127.02999344954443, 49.81499583597008],
              [-128.0593363043662, 49.9949590114266],
              [-128.44458410710214, 50.539137681676095],
              [-128.35841365625546, 50.77064809834371],
              [-127.30858109602994, 50.552573554071955],
              [-126.69500097721235, 50.400903225295394],
              [-125.7550066738232, 50.29501821552935],
              [-125.4150015875588, 49.95000051533259],
              [-124.92076818911934, 49.475274970083376],
              [-123.92250870832106, 49.06248362893581],
              [-123.51000158755119, 48.51001089130341]
            ]
          ],
          [
            [
              [-121.53788, 74.44893],
              [-120.10978, 74.24135],
              [-117.55564, 74.18577],
              [-116.58442, 73.89607],
              [-115.51081, 73.47519],
              [-116.76794, 73.22292],
              [-119.22, 72.52],
              [-120.46, 71.82],
              [-120.46, 71.38360179308756],
              [-123.09219, 70.90164],
              [-123.62, 71.34],
              [-125.92894873747338, 71.86868846301138],
              [-125.5, 72.29226081179502],
              [-124.80729, 73.02256],
              [-123.94, 73.68000000000012],
              [-124.91775, 74.29275000000013],
              [-121.53788, 74.44893]
            ]
          ],
          [
            [
              [-107.81943, 75.84552],
              [-106.92893, 76.01282],
              [-105.881, 75.9694],
              [-105.70498, 75.47951],
              [-106.31347, 75.00527],
              [-109.7, 74.85],
              [-112.22307, 74.41696],
              [-113.74381, 74.39427],
              [-113.87135, 74.72029],
              [-111.79421, 75.1625],
              [-116.31221, 75.04343],
              [-117.7104, 75.2222],
              [-116.34602, 76.19903],
              [-115.40487, 76.47887],
              [-112.59056, 76.14134],
              [-110.81422, 75.54919],
              [-109.0671, 75.47321],
              [-110.49726, 76.42982],
              [-109.5811, 76.79417],
              [-108.54859, 76.67832],
              [-108.21141, 76.20168],
              [-107.81943, 75.84552]
            ]
          ],
          [
            [
              [-106.52259, 73.07601],
              [-105.40246, 72.67259],
              [-104.77484, 71.6984000000001],
              [-104.4647599999999, 70.99297],
              [-102.78537, 70.49776],
              [-100.98078, 70.02432],
              [-101.08929, 69.58447000000012],
              [-102.73116, 69.50402],
              [-102.09329, 69.11962000000011],
              [-102.43024, 68.75282],
              [-104.24, 68.91],
              [-105.96, 69.18000000000012],
              [-107.12254, 69.11922],
              [-109, 68.78],
              [-111.53414887520017, 68.63005915681794],
              [-113.3132, 68.53554],
              [-113.85495999999989, 69.00744],
              [-115.22, 69.28],
              [-116.10794, 69.16821],
              [-117.34, 69.96],
              [-116.67473, 70.06655],
              [-115.13112, 70.2373],
              [-113.72141, 70.19237],
              [-112.4161, 70.36638],
              [-114.35, 70.6],
              [-116.48684, 70.52045],
              [-117.9048, 70.54056000000014],
              [-118.43238, 70.9092],
              [-116.11311, 71.30918],
              [-117.65568, 71.2952],
              [-119.40199, 71.55859],
              [-118.56267, 72.30785],
              [-117.86642, 72.70594],
              [-115.18909, 73.31459000000012],
              [-114.16717, 73.12145],
              [-114.66634, 72.65277],
              [-112.44102, 72.95540000000011],
              [-111.05039, 72.4504],
              [-109.92035, 72.96113],
              [-109.00654, 72.63335],
              [-108.18835, 71.65089],
              [-107.68599, 72.06548],
              [-108.39639, 73.08953],
              [-107.51645, 73.23598],
              [-106.52259, 73.07601]
            ]
          ],
          [
            [
              [-100.43836, 72.70588],
              [-101.54, 73.36],
              [-100.35642, 73.84389],
              [-99.16387, 73.63339],
              [-97.38, 73.76],
              [-97.12, 73.47],
              [-98.05359, 72.99052],
              [-96.54, 72.56],
              [-96.72, 71.66],
              [-98.35966, 71.27285],
              [-99.32286, 71.35639],
              [-100.01482, 71.73827],
              [-102.5, 72.51],
              [-102.48, 72.83],
              [-100.43836, 72.70588]
            ]
          ],
          [
            [
              [-106.6, 73.6],
              [-105.26, 73.64],
              [-104.5, 73.42],
              [-105.38, 72.76],
              [-106.94, 73.46],
              [-106.6, 73.6]
            ]
          ],
          [
            [
              [-98.5, 76.72],
              [-97.735585, 76.25656],
              [-97.704415, 75.74344],
              [-98.16, 75],
              [-99.80874, 74.89744],
              [-100.88366, 75.05736],
              [-100.86292, 75.64075],
              [-102.50209, 75.5638],
              [-102.56552, 76.3366],
              [-101.48973, 76.30537],
              [-99.98349, 76.64634],
              [-98.57699, 76.58859],
              [-98.5, 76.72]
            ]
          ],
          [
            [
              [-96.01644, 80.60233],
              [-95.32345, 80.90729],
              [-94.29843, 80.97727],
              [-94.73542, 81.20646],
              [-92.40984, 81.25739],
              [-91.13289, 80.72345],
              [-89.45, 80.50932203389831],
              [-87.81, 80.32],
              [-87.02, 79.66],
              [-85.81435, 79.3369],
              [-87.18756, 79.0393],
              [-89.03535, 78.28723],
              [-90.80436, 78.21533],
              [-92.87669, 78.34333],
              [-93.95116, 78.75099],
              [-93.93574, 79.11373],
              [-93.14524, 79.3801],
              [-94.974, 79.37248],
              [-96.07614, 79.70502],
              [-96.70972, 80.15777],
              [-96.01644, 80.60233]
            ]
          ],
          [
            [
              [-91.58702, 81.89429],
              [-90.1, 82.085],
              [-88.93227, 82.11751],
              [-86.97024, 82.27961],
              [-85.5, 82.65227345805702],
              [-84.260005, 82.6],
              [-83.18, 82.32],
              [-82.42, 82.86],
              [-81.1, 83.02],
              [-79.30664, 83.13056],
              [-76.25, 83.17205882352941],
              [-75.71878, 83.06404],
              [-72.83153, 83.23324],
              [-70.665765, 83.16978075838284],
              [-68.5, 83.10632151676572],
              [-65.82735, 83.02801],
              [-63.68, 82.9],
              [-61.85, 82.6286],
              [-61.89388, 82.36165],
              [-64.334, 81.92775],
              [-66.75342, 81.72527],
              [-67.65755, 81.50141],
              [-65.48031, 81.50657],
              [-67.84, 80.9],
              [-69.4697, 80.61683],
              [-71.18, 79.8],
              [-73.2428, 79.63415],
              [-73.88, 79.43016220480206],
              [-76.90773, 79.32309],
              [-75.52924, 79.19766],
              [-76.22046, 79.01907],
              [-75.39345, 78.52581],
              [-76.34354, 78.18296],
              [-77.88851, 77.89991],
              [-78.36269, 77.50859],
              [-79.75951, 77.20968],
              [-79.61965, 76.98336],
              [-77.91089, 77.022045],
              [-77.88911, 76.777955],
              [-80.56125, 76.17812],
              [-83.17439, 76.45403],
              [-86.11184, 76.29901],
              [-87.6, 76.42],
              [-89.49068, 76.47239],
              [-89.6161, 76.95213],
              [-87.76739, 77.17833],
              [-88.26, 77.9],
              [-87.65, 77.97022222222223],
              [-84.97634, 77.53873],
              [-86.34, 78.18],
              [-87.96192, 78.37181],
              [-87.15198, 78.75867],
              [-85.37868, 78.9969],
              [-85.09495, 79.34543],
              [-86.50734, 79.73624],
              [-86.93179, 80.25145],
              [-84.19844, 80.20836],
              [-83.40869565217389, 80.1],
              [-81.84823, 80.46442],
              [-84.1, 80.58],
              [-87.59895, 80.51627],
              [-89.36663, 80.85569],
              [-90.2, 81.26],
              [-91.36786, 81.5531],
              [-91.58702, 81.89429]
            ]
          ],
          [
            [
              [-75.21597, 67.44425],
              [-75.86588, 67.14886],
              [-76.98687, 67.09873],
              [-77.2364, 67.58809],
              [-76.81166, 68.14856],
              [-75.89521, 68.28721],
              [-75.1145, 68.01036],
              [-75.10333, 67.58202],
              [-75.21597, 67.44425]
            ]
          ],
          [
            [
              [-96.25740120380055, 69.49003035832177],
              [-95.64768120380054, 69.10769035832178],
              [-96.26952120380055, 68.75704035832177],
              [-97.61740120380055, 69.06003035832177],
              [-98.43180120380055, 68.95070035832177],
              [-99.79740120380055, 69.40003035832177],
              [-98.91740120380055, 69.71003035832177],
              [-98.21826120380055, 70.14354035832177],
              [-97.15740120380055, 69.86003035832177],
              [-96.55740120380055, 69.68003035832177],
              [-96.25740120380055, 69.49003035832177]
            ]
          ],
          [
            [
              [-64.51912, 49.87304],
              [-64.17322, 49.95718],
              [-62.85829, 49.70641],
              [-61.835585, 49.28855],
              [-61.806305, 49.10506],
              [-62.29318, 49.08717],
              [-63.58926, 49.40069],
              [-64.51912, 49.87304]
            ]
          ],
          [
            [
              [-64.01486, 47.03601],
              [-63.6645, 46.55001],
              [-62.9393, 46.41587],
              [-62.01208, 46.44314],
              [-62.50391, 46.03339],
              [-62.87433, 45.96818],
              [-64.1428, 46.39265],
              [-64.39261, 46.72747],
              [-64.01486, 47.03601]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MEX.geojson',
        iso_a2: 'MX',
        label_y: 23.919988,
        label_x: -102.289448,
        name: 'Mexico',
        formal_en: 'United Mexican States'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-117.12775999999985, 32.53534],
            [-115.99135, 32.61239000000012],
            [-114.72139, 32.72083],
            [-114.815, 32.52528],
            [-113.30498, 32.03914],
            [-111.02361, 31.33472],
            [-109.035, 31.341940000000136],
            [-108.24194, 31.34222],
            [-108.24, 31.754853718166373],
            [-106.50758999999988, 31.75452],
            [-106.1429, 31.39995],
            [-105.63159, 31.08383],
            [-105.03737, 30.64402],
            [-104.70575, 30.12173],
            [-104.4569699999999, 29.57196],
            [-103.94, 29.27],
            [-103.11, 28.97],
            [-102.48, 29.76],
            [-101.6624, 29.7793],
            [-100.9576, 29.380710000000136],
            [-100.45584, 28.69612000000012],
            [-100.11, 28.110000000000127],
            [-99.52, 27.54],
            [-99.3, 26.84],
            [-99.02, 26.37],
            [-98.24, 26.06],
            [-97.53, 25.84],
            [-97.14000830767071, 25.8699974634784],
            [-97.52807247596655, 24.9921440699203],
            [-97.70294552284224, 24.272343044526735],
            [-97.77604183631905, 22.93257986092766],
            [-97.87236670611111, 22.44421173755336],
            [-97.69904395220419, 21.898689480064263],
            [-97.38895952023677, 21.411018988525825],
            [-97.18933346229329, 20.635433254473128],
            [-96.52557552772032, 19.890930894444068],
            [-96.29212724484177, 19.320371405509547],
            [-95.90088497595997, 18.82802419684873],
            [-94.83906348344271, 18.562717393462208],
            [-94.42572953975622, 18.144370835843347],
            [-93.5486512926824, 18.423836981677937],
            [-92.7861138577835, 18.52483856859226],
            [-92.0373481920904, 18.704569200103435],
            [-91.40790340855926, 18.87608327888023],
            [-90.77186987991087, 19.28412038825678],
            [-90.53358985061305, 19.8674181177513],
            [-90.45147599970124, 20.707521877520435],
            [-90.2786183336849, 20.999855454995554],
            [-89.60132117385149, 21.26172577563449],
            [-88.54386633986286, 21.49367544197662],
            [-87.65841651075772, 21.45884552661198],
            [-87.05189022494807, 21.5435431991383],
            [-86.81198238803296, 21.331514797444754],
            [-86.84590796583262, 20.849864610268355],
            [-87.38329118523586, 20.25540477139873],
            [-87.62105445021075, 19.64655304613592],
            [-87.43675045444178, 19.47240346931227],
            [-87.58656043165593, 19.04013011319074],
            [-87.83719112827151, 18.25981598558343],
            [-88.09066402866318, 18.51664785407405],
            [-88.3000310940937, 18.4999822046599],
            [-88.49012285027935, 18.486830552641607],
            [-88.84834387892661, 17.883198147040233],
            [-89.02985734735182, 18.001511338772488],
            [-89.15090938999553, 17.95546763760042],
            [-89.14308041050333, 17.808318996649405],
            [-90.06793351923098, 17.819326076727478],
            [-91.00151994501596, 17.81759491624571],
            [-91.00226925328421, 17.25465770107418],
            [-91.45392127151516, 17.252177232324172],
            [-91.08167009150065, 16.918476670799407],
            [-90.71182186558772, 16.687483018454728],
            [-90.60084672724093, 16.47077789963879],
            [-90.43886695022204, 16.410109768128095],
            [-90.46447262242266, 16.069562079324655],
            [-91.74796017125595, 16.066564846251765],
            [-92.2292486234063, 15.251446641495875],
            [-92.08721594925207, 15.064584662328441],
            [-92.20322953974733, 14.830102850804069],
            [-92.22775000686983, 14.538828640190928],
            [-93.35946387406176, 15.615429592343673],
            [-93.87516883011853, 15.940164292865916],
            [-94.69165646033014, 16.200975246642884],
            [-95.25022701697304, 16.128318182840644],
            [-96.05338212765332, 15.752087917539598],
            [-96.55743404822829, 15.653515122942792],
            [-97.26359249549665, 15.917064927631316],
            [-98.01302995480961, 16.107311713113916],
            [-98.94767574745651, 16.566043402568766],
            [-99.69739742714705, 16.70616404872817],
            [-100.82949886758132, 17.17107107184205],
            [-101.66608862995446, 17.649026394109626],
            [-101.91852800170022, 17.916090196193977],
            [-102.47813208698892, 17.975750637275098],
            [-103.50098954955808, 18.29229462327885],
            [-103.91752743204682, 18.74857168220001],
            [-104.9920096504755, 19.316133938061682],
            [-105.49303849976144, 19.946767279535436],
            [-105.73139604370766, 20.434101874264115],
            [-105.39777299683135, 20.531718654863425],
            [-105.50066077352443, 20.81689504646613],
            [-105.27075232625793, 21.07628489835514],
            [-105.26581722697404, 21.42210358325235],
            [-105.6031609769754, 21.87114594165257],
            [-105.69341386597313, 22.269080308516152],
            [-106.02871639689897, 22.773752346278627],
            [-106.90998043498837, 23.767774359628902],
            [-107.91544877809139, 24.54891531015295],
            [-108.40190487347098, 25.172313951105934],
            [-109.26019873740665, 25.58060944264406],
            [-109.44408932171734, 25.82488393808768],
            [-109.29164384645628, 26.442934068298428],
            [-109.80145768923182, 26.676175645447927],
            [-110.3917317370857, 27.16211497650454],
            [-110.64101884646163, 27.859876003525528],
            [-111.17891883018785, 27.94124054616907],
            [-111.75960689985163, 28.46795258230395],
            [-112.2282346260904, 28.95440867768349],
            [-112.27182369672869, 29.266844387320077],
            [-112.80959448937398, 30.02111359305235],
            [-113.16381059451868, 30.786880804969428],
            [-113.14866939985717, 31.170965887978923],
            [-113.87188106978186, 31.567608344035193],
            [-114.20573666060352, 31.524045111613134],
            [-114.77645117883503, 31.79953217216115],
            [-114.93669979537214, 31.393484605427602],
            [-114.7712318591735, 30.913617255165263],
            [-114.67389929895177, 30.162681179315996],
            [-114.33097449426293, 29.750432440707414],
            [-113.58887508833544, 29.061611436473015],
            [-113.42405310754054, 28.82617361095123],
            [-113.27196936730553, 28.7547826197399],
            [-113.14003943566439, 28.41128937429596],
            [-112.9622983467965, 28.42519033458251],
            [-112.76158708377488, 27.780216783147523],
            [-112.45791052941166, 27.52581370697476],
            [-112.2449519519368, 27.17172679291076],
            [-111.61648902061921, 26.662817287700477],
            [-111.28467464887302, 25.732589830014433],
            [-110.9878193835724, 25.294606228124564],
            [-110.71000688357134, 24.82600434010186],
            [-110.65504899782889, 24.298594672131117],
            [-110.17285620811344, 24.265547593680424],
            [-109.77184709352855, 23.811182562754198],
            [-109.40910437705571, 23.36467234953625],
            [-109.43339230023292, 23.1855876734287],
            [-109.85421932660171, 22.818271592698068],
            [-110.03139197471444, 22.823077500901206],
            [-110.29507097048366, 23.43097321216669],
            [-110.94950130902805, 24.000964260345995],
            [-111.6705684070127, 24.484423122652515],
            [-112.18203589562148, 24.738412787367167],
            [-112.14898881717085, 25.47012523040405],
            [-112.3007108223797, 26.012004299416617],
            [-112.77729671919155, 26.32195954030317],
            [-113.46467078332194, 26.768185533143424],
            [-113.59672990604383, 26.639459540304472],
            [-113.84893673384425, 26.90006378835244],
            [-114.46574662968004, 27.142090358991368],
            [-115.05514217818501, 27.72272675222291],
            [-114.98225257043742, 27.798200181585116],
            [-114.57036556685495, 27.74148529714489],
            [-114.19932878299926, 28.115002549750557],
            [-114.16201839888464, 28.566111965442303],
            [-114.93184221073665, 29.27947927501549],
            [-115.518653937627, 29.5563615992354],
            [-115.88736528202958, 30.180793768834178],
            [-116.25835038945291, 30.836464341753583],
            [-116.72152625208497, 31.635743720012044],
            [-117.12775999999985, 32.53534]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BLZ.geojson',
        iso_a2: 'BZ',
        label_y: 17.202068,
        label_x: -88.712962,
        name: 'Belize',
        formal_en: 'Belize'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-89.14308041050333, 17.808318996649405],
            [-89.15090938999553, 17.95546763760042],
            [-89.02985734735182, 18.001511338772488],
            [-88.84834387892661, 17.883198147040233],
            [-88.49012285027935, 18.486830552641607],
            [-88.3000310940937, 18.4999822046599],
            [-88.29633622918482, 18.35327281338327],
            [-88.10681291375438, 18.348673610909287],
            [-88.1234785631685, 18.07667470954101],
            [-88.2853549873228, 17.644142971258034],
            [-88.19786678745265, 17.489475409408456],
            [-88.30264075392444, 17.131693630435663],
            [-88.23951799187991, 17.036066392479555],
            [-88.35542822951057, 16.530774237529627],
            [-88.55182451043585, 16.265467434143147],
            [-88.73243364129594, 16.233634751851355],
            [-88.93061275913527, 15.887273464415076],
            [-89.22912167026928, 15.886937567605171],
            [-89.15080603713095, 17.015576687075836],
            [-89.14308041050333, 17.808318996649405]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PAN.geojson',
        iso_a2: 'PA',
        label_y: 8.72198,
        label_x: -80.352106,
        name: 'Panama',
        formal_en: 'Republic of Panama'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.35336076527386, 8.67050466555807],
            [-77.47472286651133, 8.524286200388218],
            [-77.24256649444008, 7.935278225125444],
            [-77.43110795765699, 7.638061224798734],
            [-77.7534138658614, 7.709839789252143],
            [-77.88157141794525, 7.223771267114785],
            [-78.21493608266012, 7.512254950384161],
            [-78.42916073272607, 8.052041123888927],
            [-78.18209570993864, 8.319182440621773],
            [-78.4354652574657, 8.38770538984079],
            [-78.62212053090394, 8.718124497915028],
            [-79.12030717641375, 8.996092027213024],
            [-79.55787736684519, 8.932374986197146],
            [-79.76057817251005, 8.5845150822244],
            [-80.16448116730334, 8.333315944853595],
            [-80.38265906443962, 8.298408514840432],
            [-80.4806892564973, 8.09030752200107],
            [-80.00368994822716, 7.547524115423372],
            [-80.276670701809, 7.419754136581716],
            [-80.42115800649708, 7.271571966984765],
            [-80.8864009264208, 7.220541490096537],
            [-81.05954281281473, 7.817921047390597],
            [-81.18971574575795, 7.64790558515034],
            [-81.51951473664468, 7.706610012233909],
            [-81.72131120474447, 8.108962714058435],
            [-82.13144120962892, 8.175392767769637],
            [-82.39093441438257, 8.29236237226229],
            [-82.82008134635042, 8.290863755725823],
            [-82.85095801464482, 8.073822740099956],
            [-82.96578304719736, 8.225027980985985],
            [-82.91317643912421, 8.42351715741907],
            [-82.82977067740516, 8.62629547773237],
            [-82.86865719270477, 8.807266343618522],
            [-82.71918311230053, 8.925708726431495],
            [-82.92715491405916, 9.074330145702916],
            [-82.93289099804358, 9.476812038608173],
            [-82.54619625520348, 9.566134751824677],
            [-82.18712256542341, 9.20744863528678],
            [-82.20758643261097, 8.9955752628901],
            [-81.80856686066929, 8.950616766796173],
            [-81.71415401887204, 9.031955471223583],
            [-81.43928707551154, 8.786234035675719],
            [-80.94730160187676, 8.858503526235907],
            [-80.52190121125008, 9.111072089062432],
            [-79.91459977895599, 9.31276520429762],
            [-79.57330278188431, 9.611610012241528],
            [-79.02119177927793, 9.552931423374105],
            [-79.05845048696037, 9.454565334506526],
            [-78.50088762074719, 9.420458889193881],
            [-78.05592770049802, 9.2477304142583],
            [-77.72951351592641, 8.946844387238869],
            [-77.35336076527386, 8.67050466555807]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GRL.geojson',
        iso_a2: 'GL',
        label_y: 74.319387,
        label_x: -39.335251,
        name: 'Greenland',
        formal_en: 'Greenland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-46.76379, 82.62796],
            [-43.40644, 83.22516],
            [-39.89753, 83.18018],
            [-38.62214, 83.54905],
            [-35.08787, 83.64513],
            [-27.10046, 83.51966],
            [-20.84539, 82.72669],
            [-22.69182, 82.34165],
            [-26.51753, 82.29765],
            [-31.9, 82.2],
            [-31.39646, 82.02154],
            [-27.85666, 82.13178],
            [-24.84448, 81.78697],
            [-22.90328, 82.09317],
            [-22.07175, 81.73449],
            [-23.16961, 81.15271],
            [-20.62363, 81.52462],
            [-15.76818, 81.91245],
            [-12.77018, 81.71885],
            [-12.20855, 81.29154],
            [-16.28533, 80.58004],
            [-16.85, 80.35],
            [-20.04624, 80.17708],
            [-17.73035, 80.12912],
            [-18.9, 79.4],
            [-19.70499, 78.75128],
            [-19.67353, 77.63859],
            [-18.47285, 76.98565],
            [-20.03503, 76.94434],
            [-21.67944, 76.62795],
            [-19.83407, 76.09808],
            [-19.59896, 75.24838],
            [-20.66818, 75.15585],
            [-19.37281, 74.29561],
            [-21.59422, 74.22382],
            [-20.43454, 73.81713],
            [-20.76234, 73.46436],
            [-22.17221, 73.30955],
            [-23.56593, 73.30663],
            [-22.31311, 72.62928],
            [-22.29954, 72.18409],
            [-24.27834, 72.59788],
            [-24.79296, 72.3302],
            [-23.44296, 72.08016],
            [-22.13281, 71.46898],
            [-21.75356, 70.66369],
            [-23.53603, 70.471],
            [-24.30702, 70.85649],
            [-25.54341, 71.43094],
            [-25.20135, 70.75226],
            [-26.36276, 70.22646],
            [-23.72742, 70.18401],
            [-22.34902, 70.12946],
            [-25.02927, 69.2588],
            [-27.74737, 68.47046],
            [-30.67371, 68.12503],
            [-31.77665, 68.12078],
            [-32.81105, 67.73547],
            [-34.20196, 66.67974],
            [-36.35284, 65.9789],
            [-37.04378, 65.93768],
            [-38.37505, 65.69213],
            [-39.81222, 65.45848],
            [-40.66899, 64.83997],
            [-40.68281, 64.13902],
            [-41.1887, 63.48246],
            [-42.81938, 62.68233],
            [-42.41666, 61.90093],
            [-42.86619, 61.07404],
            [-43.3784, 60.09772],
            [-44.7875, 60.03676],
            [-46.26364, 60.85328],
            [-48.26294, 60.85843],
            [-49.23308, 61.40681],
            [-49.90039, 62.38336],
            [-51.63325, 63.62691],
            [-52.14014, 64.27842],
            [-52.27659, 65.1767],
            [-53.66166, 66.09957],
            [-53.30161, 66.8365],
            [-53.96911, 67.18899],
            [-52.9804, 68.35759],
            [-51.47536, 68.72958],
            [-51.08041, 69.14781],
            [-50.87122, 69.9291],
            [-52.013585, 69.574925],
            [-52.55792, 69.42616],
            [-53.45629, 69.283625],
            [-54.68336, 69.61003],
            [-54.75001, 70.28932],
            [-54.35884, 70.821315],
            [-53.431315, 70.835755],
            [-51.39014, 70.56978],
            [-53.10937, 71.20485],
            [-54.00422, 71.54719],
            [-55, 71.40653696727257],
            [-55.83468, 71.65444],
            [-54.71819, 72.58625],
            [-55.32634, 72.95861],
            [-56.12003, 73.64977],
            [-57.32363, 74.71026],
            [-58.59679, 75.09861],
            [-58.58516, 75.51727],
            [-61.26861, 76.10238],
            [-63.39165, 76.1752],
            [-66.06427, 76.13486],
            [-68.50438, 76.06141],
            [-69.66485, 76.37975],
            [-71.40257, 77.00857],
            [-68.77671, 77.32312],
            [-66.76397, 77.37595],
            [-71.04293, 77.63595],
            [-73.297, 78.04419],
            [-73.15938, 78.43271],
            [-69.37345, 78.91388],
            [-65.7107, 79.39436],
            [-65.3239, 79.75814],
            [-68.02298, 80.11721],
            [-67.15129, 80.51582],
            [-63.68925, 81.21396],
            [-62.23444, 81.3211],
            [-62.65116, 81.77042],
            [-60.28249, 82.03363],
            [-57.20744, 82.19074],
            [-54.13442, 82.19962],
            [-53.04328, 81.88833],
            [-50.39061, 82.43883],
            [-48.00386, 82.06481],
            [-46.59984, 81.985945],
            [-44.523, 81.6607],
            [-46.9007, 82.19979],
            [-46.76379, 82.62796]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BHS.geojson',
        iso_a2: 'BS',
        label_y: 26.401789,
        label_x: -77.146688,
        name: 'Bahamas',
        formal_en: 'Commonwealth of the Bahamas'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-78.98, 26.79],
              [-78.51, 26.87],
              [-77.85, 26.84],
              [-77.82, 26.58],
              [-78.91, 26.42],
              [-78.98, 26.79]
            ]
          ],
          [
            [
              [-77.79, 27.04],
              [-77, 26.59],
              [-77.17255, 25.87918],
              [-77.35641, 26.00735],
              [-77.34, 26.53],
              [-77.78802, 26.92516],
              [-77.79, 27.04]
            ]
          ],
          [
            [
              [-78.19087, 25.2103],
              [-77.89, 25.17],
              [-77.54, 24.34],
              [-77.53466, 23.75975],
              [-77.78, 23.71],
              [-78.03405, 24.28615],
              [-78.40848, 24.57564],
              [-78.19087, 25.2103]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TTO.geojson',
        iso_a2: 'TT',
        label_y: 10.9989,
        label_x: -60.9184,
        name: 'Trinidad and Tobago',
        formal_en: 'Republic of Trinidad and Tobago'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.68, 10.76],
            [-61.105, 10.89],
            [-60.895, 10.855],
            [-60.935, 10.11],
            [-61.77, 10],
            [-61.95, 10.09],
            [-61.66, 10.365],
            [-61.68, 10.76]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PRI.geojson',
        iso_a2: 'PR',
        label_y: 18.234668,
        label_x: -66.481065,
        name: 'Puerto Rico',
        formal_en: 'Commonwealth of Puerto Rico'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-66.28243445500821, 18.514761664295364],
            [-65.7713028632093, 18.426679185453878],
            [-65.59100379094295, 18.228034979723915],
            [-65.84716386581377, 17.97590566657186],
            [-66.59993445500949, 17.981822618069273],
            [-67.18416236028527, 17.946553453030077],
            [-67.24242753769435, 18.374460150622937],
            [-67.10067908391774, 18.52060110114435],
            [-66.28243445500821, 18.514761664295364]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'JAM.geojson',
        iso_a2: 'JM',
        label_y: 18.137124,
        label_x: -77.318767,
        name: 'Jamaica',
        formal_en: 'Jamaica'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.56960079619921, 18.490525417550487],
            [-76.89661861846213, 18.400866807524082],
            [-76.36535905628554, 18.160700588447597],
            [-76.19965857614164, 17.886867173732966],
            [-76.9025614081757, 17.868237819891746],
            [-77.20634131540348, 17.70111623785982],
            [-77.76602291534061, 17.86159739834224],
            [-78.33771928578561, 18.225967922432233],
            [-78.21772661000388, 18.454532782459196],
            [-77.79736467152563, 18.524218451404778],
            [-77.56960079619921, 18.490525417550487]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'IDN.geojson',
        iso_a2: 'ID',
        label_y: -0.954404,
        label_x: 101.892949,
        name: 'Indonesia',
        formal_en: 'Republic of Indonesia'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [141.00021040259185, -2.60015105551566],
              [141.01705691951895, -5.859021905138071],
              [141.03385176001382, -9.117892754760483],
              [140.14341515519254, -8.29716765710095],
              [139.1277665549281, -8.096042982620979],
              [138.881476678625, -8.380935153846075],
              [137.61447391169287, -8.41168263105974],
              [138.03909915583517, -7.597882175327321],
              [138.66862145401478, -7.320224704623087],
              [138.4079138531023, -6.232849216337485],
              [137.92783979711078, -5.393365573756],
              [135.98925011611345, -4.54654387778907],
              [135.16459760959975, -4.462931410340822],
              [133.66288048719787, -3.538853448097541],
              [133.36770470594672, -4.024818617370315],
              [132.98395551974727, -4.112978610860253],
              [132.75694095268904, -3.746282647317123],
              [132.75378869031925, -3.31178720460705],
              [131.98980431531618, -2.820551039240499],
              [133.0668445171434, -2.460417982598436],
              [133.78003095920354, -2.479848321140182],
              [133.69621178602614, -2.214541517753702],
              [132.23237348849426, -2.212526136894319],
              [131.83622195854474, -1.617161960459647],
              [130.94283979708285, -1.432522067880783],
              [130.5195581401801, -0.937720228686089],
              [131.8675378765136, -0.695461114101789],
              [132.3801164084167, -0.369537855636949],
              [133.98554813042836, -0.780210463060456],
              [134.14336795464772, -1.151867364103623],
              [134.42262739475302, -2.769184665542376],
              [135.45760298069467, -3.367752780779149],
              [136.29331424371884, -2.307042331556154],
              [137.44073774632756, -1.703513278819365],
              [138.3297274110447, -1.702686455902693],
              [139.18492068904288, -2.051295668143673],
              [139.92668419816044, -2.409051608900313],
              [141.00021040259185, -2.60015105551566]
            ]
          ],
          [
            [
              [124.96868248911623, -8.892790215697083],
              [125.07001997284061, -9.089987481322872],
              [125.08852013560109, -9.393173109579294],
              [124.43595014861933, -10.140000909061449],
              [123.57998172413669, -10.359987481327956],
              [123.459989048355, -10.239994805546223],
              [123.55000939340744, -9.900015557497987],
              [123.9800089865081, -9.290026950724716],
              [124.96868248911623, -8.892790215697083]
            ]
          ],
          [
            [
              [134.21013390516885, -6.89523772545472],
              [134.11277550673094, -6.142467136259],
              [134.29033572808584, -5.783057549669017],
              [134.49962527886788, -5.445042006047871],
              [134.72700158095216, -5.737582289252167],
              [134.7246244650667, -6.214400730009288],
              [134.21013390516885, -6.89523772545472]
            ]
          ],
          [
            [
              [117.88203494677018, 4.137551377779516],
              [117.3132324565335, 3.234428208830593],
              [118.0483297058854, 2.287690131027333],
              [117.87562706916597, 1.827640692548925],
              [118.99674726773816, 0.902219143066063],
              [117.8118583517178, 0.784241848143708],
              [117.47833865770603, 0.102474676917026],
              [117.52164350796664, -0.803723239753268],
              [116.56004845587947, -1.48766082113621],
              [116.5337968282752, -2.483517347832901],
              [116.14808393764866, -4.012726332214022],
              [116.00085778204911, -3.657037448749058],
              [114.86480309454456, -4.106984144714396],
              [114.46865156459506, -3.495703627133828],
              [113.75567182826407, -3.43916961020652],
              [113.25699425664752, -3.118775729996905],
              [112.06812625534067, -3.478392022316051],
              [111.70329064336005, -2.994442233902654],
              [111.04824018762824, -3.049425957861211],
              [110.223846063276, -2.934032484553455],
              [110.07093550012434, -1.592874037282463],
              [109.571947869914, -1.314906507984475],
              [109.0918738139225, -0.459506524257094],
              [108.9526575053282, 0.415375474444318],
              [109.06913618371408, 1.341933905437614],
              [109.66326012577375, 2.006466986494956],
              [109.83022667850881, 1.338135687664163],
              [110.51406090702716, 0.773131415200965],
              [111.15913781132662, 0.976478176269481],
              [111.79754845586041, 0.904441229654608],
              [112.38025190638359, 1.410120957846743],
              [112.85980919805218, 1.497790025229904],
              [113.8058496440195, 1.217548732911069],
              [114.62135542201753, 1.430688177898901],
              [115.13403730678522, 2.821481838386234],
              [115.51907840379198, 3.169238389494396],
              [115.8655172058767, 4.3065591495901],
              [117.01521447150628, 4.306094061699469],
              [117.88203494677018, 4.137551377779516]
            ]
          ],
          [
            [
              [129.37099775606094, -2.802154229344595],
              [130.47134402885177, -3.093764336767634],
              [130.83483605359282, -3.858472181822776],
              [129.99054650280817, -3.446300957862796],
              [129.15524865124235, -3.362636813982248],
              [128.59068362845363, -3.428679294451264],
              [127.89889122936235, -3.393435967628207],
              [128.13587934785284, -2.843650404474971],
              [129.37099775606094, -2.802154229344595]
            ]
          ],
          [
            [
              [126.87492272349886, -3.790982761249587],
              [126.18380211802736, -3.607376397316564],
              [125.98903364471926, -3.177273451351305],
              [127.00065148326497, -3.129317722184446],
              [127.24921512258891, -3.45906503663889],
              [126.87492272349886, -3.790982761249587]
            ]
          ],
          [
            [
              [127.93237755748748, 2.174596258956569],
              [128.00415612194087, 1.628531398928345],
              [128.5945593608755, 1.540810655112878],
              [128.6882487326207, 1.132385972494063],
              [128.63595218314134, 0.258485826006194],
              [128.1201697124361, 0.356412665199286],
              [127.96803429576886, -0.252077325037519],
              [128.3799988139997, -0.7800037573313],
              [128.1000159038423, -0.899996433113031],
              [127.69647464407507, -0.266598402511534],
              [127.39949018769369, 1.011721503092545],
              [127.60051150930906, 1.810690822757195],
              [127.93237755748748, 2.174596258956569]
            ]
          ],
          [
            [
              [122.9275667664518, 0.875192368977409],
              [124.07752241424288, 0.917101955566125],
              [125.0659892111218, 1.64325918213153],
              [125.2405005229715, 1.419836127117605],
              [124.4370353536974, 0.427881171058957],
              [123.6855049988767, 0.235593166500891],
              [122.72308312387287, 0.431136786293337],
              [121.05672488818911, 0.381217352699394],
              [120.18308312386272, 0.237246812334234],
              [120.04086958219548, -0.519657891444837],
              [120.93590538949073, -1.408905938323393],
              [121.4758207540762, -0.95596200928513],
              [123.34056481332846, -0.615672702643138],
              [123.25839928598441, -1.076213067228309],
              [122.82271528533161, -0.930950616055853],
              [122.38852990121529, -1.516858005381117],
              [121.50827355355551, -1.904482924002458],
              [122.4545723816843, -3.186058444840924],
              [122.2718961935325, -3.529500013852712],
              [123.17096276254655, -4.683693129091701],
              [123.1623327983538, -5.340603936385996],
              [122.62851525277875, -5.634591159694466],
              [122.23639448454801, -5.282933037948268],
              [122.71956912647701, -4.464171644715826],
              [121.73823367725436, -4.851331475446543],
              [121.48946333220127, -4.574552504091265],
              [121.61917117725386, -4.188477878438682],
              [120.89818159391766, -3.602105401222794],
              [120.97238895068878, -2.627642917494939],
              [120.30545291552986, -2.931603692235733],
              [120.39004723519167, -4.097579034037274],
              [120.43071658740537, -5.528241062037793],
              [119.79654341031949, -5.673400160345665],
              [119.36690555224489, -5.37987802492782],
              [119.65360639860017, -4.459417412944973],
              [119.49883548388601, -3.494411716326532],
              [119.07834435432704, -3.487021986508793],
              [118.76776899625287, -2.801999200047718],
              [119.18097374885869, -2.147103773612805],
              [119.3233939962551, -1.353147067880464],
              [119.82599897672587, 0.154254462073482],
              [120.0357019389663, 0.566477362465761],
              [120.88577925016762, 1.30922272379685],
              [121.66681684782696, 1.013943589681091],
              [122.9275667664518, 0.875192368977409]
            ]
          ],
          [
            [
              [120.29501427620689, -10.258649997603591],
              [118.96780846565471, -9.557969252158074],
              [119.90030968636157, -9.361340427287502],
              [120.42575564990534, -9.665921319215798],
              [120.77550174365675, -9.96967538822743],
              [120.71560875863045, -10.239581394087885],
              [120.29501427620689, -10.258649997603591]
            ]
          ],
          [
            [
              [121.34166873584651, -8.536739597206072],
              [122.00736453663043, -8.460620212440148],
              [122.90353722543607, -8.094234307490765],
              [122.75698286345632, -8.649807631060696],
              [121.2544905945701, -8.933666273639957],
              [119.92439090380958, -8.81041798262384],
              [119.92092858284605, -8.444858900591122],
              [120.71509199430757, -8.236964613480914],
              [121.34166873584651, -8.536739597206072]
            ]
          ],
          [
            [
              [118.26061648974044, -8.362383314653293],
              [118.87845991422208, -8.280682875199844],
              [119.12650678922307, -8.705824883665088],
              [117.97040164598928, -8.906639499551304],
              [117.27773074754901, -9.040894870645594],
              [116.74014082241665, -9.032936700072646],
              [117.0837374207253, -8.45715789147659],
              [117.6320243673421, -8.449303073768228],
              [117.90001834520776, -8.09568124759494],
              [118.26061648974044, -8.362383314653293]
            ]
          ],
          [
            [
              [108.48684614464926, -6.42198495852574],
              [108.62347863162896, -6.777673841990705],
              [110.53922732955328, -6.877357679881726],
              [110.75957563684585, -6.465186455921747],
              [112.6148112325564, -6.946035658397626],
              [112.97876834518806, -7.594213148634594],
              [114.47893517462114, -7.776527601760328],
              [115.70552697150106, -8.370806573116873],
              [114.56451134649649, -8.751816908404855],
              [113.46473351446085, -8.348947442257405],
              [112.55967247930097, -8.376180922075221],
              [111.52206139531245, -8.302128594600973],
              [110.58614953007432, -8.122604668819001],
              [109.42766727095511, -7.740664157749762],
              [108.69365522668133, -7.641600437046243],
              [108.27776329959633, -7.766657403192576],
              [106.45410200401612, -7.354899590690934],
              [106.28062422081231, -6.924899997590252],
              [105.36548628135552, -6.851416110871206],
              [106.05164594932702, -5.895918877794472],
              [107.2650085795402, -5.954985039904081],
              [108.07209109907467, -6.345762220895224],
              [108.48684614464926, -6.42198495852574]
            ]
          ],
          [
            [
              [104.36999148968489, -1.084843031421059],
              [104.53949018760221, -1.782371514496766],
              [104.88789269411402, -2.340425306816705],
              [105.62211144411697, -2.428843682468099],
              [106.10859337771265, -3.061776625178965],
              [105.85744591677414, -4.305524997579774],
              [105.8176550639094, -5.85235564537242],
              [104.71038414919144, -5.873284600450632],
              [103.86821333213078, -5.037314955264996],
              [102.5842606954069, -4.220258884298183],
              [102.156173130301, -3.614146009946801],
              [101.39911339722507, -2.799777113459164],
              [100.90250288290015, -2.050262139497832],
              [100.14198082886065, -0.650347588710986],
              [99.26373986206028, 0.183141587724634],
              [98.97001102091326, 1.042882391764536],
              [98.60135135294306, 1.823506577965574],
              [97.69959760944985, 2.45318390544206],
              [97.17694217324984, 3.308790594898596],
              [96.42401655475726, 3.868859768077925],
              [95.3808760925135, 4.970782172053688],
              [95.29302615761729, 5.479820868344788],
              [95.93686282754174, 5.439513251157123],
              [97.4848820332771, 5.246320909033955],
              [98.36916914265566, 4.268370266126396],
              [99.1425586283358, 3.590349636240873],
              [99.69399783732241, 3.174328518075143],
              [100.64143354696162, 2.099381211755741],
              [101.65801232300734, 2.083697414555161],
              [102.49827111207323, 1.398700466310231],
              [103.07684044801303, 0.561361395668868],
              [103.83839603069836, 0.104541734208695],
              [103.4376452982749, -0.711945896002902],
              [104.01078860882404, -1.059211521004286],
              [104.36999148968489, -1.084843031421059]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MYS.geojson',
        iso_a2: 'MY',
        label_y: 2.528667,
        label_x: 113.83708,
        name: 'Malaysia',
        formal_en: 'Malaysia'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [100.0857568705271, 6.464489447450291],
              [100.25959638875696, 6.642824815289543],
              [101.07551557821336, 6.204867051615921],
              [101.15421878459387, 5.691384182147715],
              [101.81428185425798, 5.810808417174242],
              [102.14118696493638, 6.221636053894628],
              [102.37114708863521, 6.128205064310919],
              [102.96170535686673, 5.524495144061106],
              [103.38121463421217, 4.855001125503748],
              [103.43857547405612, 4.181605536308339],
              [103.33212202353486, 3.726697902842986],
              [103.42942874554049, 3.382868760589005],
              [103.50244754436889, 2.791018581550176],
              [103.8546741068703, 2.515454006353764],
              [104.24793175661145, 1.631141058759084],
              [104.22881147666348, 1.293048000489492],
              [103.51970747275439, 1.226333726400682],
              [102.57361535035477, 1.967115383304687],
              [101.39063846232919, 2.760813706875581],
              [101.27353966675582, 3.270291652841152],
              [100.69543541870664, 3.93913971599487],
              [100.55740766805502, 4.767280381688295],
              [100.19670617065775, 5.312492580583708],
              [100.30626020711652, 6.040561835143905],
              [100.0857568705271, 6.464489447450291]
            ]
          ],
          [
            [
              [117.88203494677018, 4.137551377779516],
              [117.01521447150628, 4.306094061699469],
              [115.8655172058767, 4.3065591495901],
              [115.51907840379198, 3.169238389494396],
              [115.13403730678522, 2.821481838386234],
              [114.62135542201753, 1.430688177898901],
              [113.8058496440195, 1.217548732911069],
              [112.85980919805218, 1.497790025229904],
              [112.38025190638359, 1.410120957846743],
              [111.79754845586041, 0.904441229654608],
              [111.15913781132662, 0.976478176269481],
              [110.51406090702716, 0.773131415200965],
              [109.83022667850881, 1.338135687664163],
              [109.66326012577375, 2.006466986494956],
              [110.3961352885371, 1.663774725751395],
              [111.16885298059748, 1.850636704918813],
              [111.37008100794205, 2.697303371588859],
              [111.7969283386729, 2.885896511238059],
              [112.99561486211522, 3.102394924324855],
              [113.71293541875868, 3.893509426281156],
              [114.20401655482837, 4.525873928236805],
              [114.65959598191353, 4.007636826997754],
              [114.8695573263154, 4.348313706881925],
              [115.34746097215066, 4.316636053887009],
              [115.40570031134361, 4.955227565933839],
              [115.45071048386981, 5.447729803891534],
              [116.22074100145102, 6.143191229675566],
              [116.72510298061971, 6.924771429873999],
              [117.12962609260047, 6.92805288332454],
              [117.64339318244627, 6.422166449403249],
              [117.68907514859231, 5.987490139180154],
              [118.34769127815224, 5.708695786965492],
              [119.18190392463997, 5.407835598162207],
              [119.11069380094176, 5.016128241389808],
              [118.43972700406408, 4.966518866389606],
              [118.6183207540648, 4.478202419447555],
              [117.88203494677018, 4.137551377779516]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CYP.geojson',
        iso_a2: 'CY',
        label_y: 34.913329,
        label_x: 33.084182,
        name: 'Cyprus',
        formal_en: 'Republic of Cyprus'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.73178022637745, 35.14002594658844],
            [32.919572381326134, 35.08783274997364],
            [33.19097700372305, 35.17312470147138],
            [33.3838334490363, 35.16271190036457],
            [33.45592207208347, 35.10142365166641],
            [33.47581749851585, 35.000344550103506],
            [33.5256852556775, 35.03868846286407],
            [33.675391880027064, 35.01786286065045],
            [33.86643965021011, 35.09359467217419],
            [33.97361657078346, 35.058506374648005],
            [34.00488081232004, 34.97809784600186],
            [32.97982710137845, 34.57186941175544],
            [32.49029625827754, 34.701654771456475],
            [32.25666710788596, 35.10323232679663],
            [32.73178022637745, 35.14002594658844]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'IND.geojson',
        iso_a2: 'IN',
        label_y: 22.686852,
        label_x: 79.358105,
        name: 'India',
        formal_en: 'Republic of India'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [97.32711388549004, 28.26158274994634],
            [97.40256147663614, 27.882536119085444],
            [97.0519885599681, 27.69905894623315],
            [97.1339990580153, 27.083773505149964],
            [96.41936567585097, 27.264589341739224],
            [95.12476769407496, 26.5735720891323],
            [95.1551534362626, 26.001307277932085],
            [94.60324913938538, 25.162495428970402],
            [94.55265791217164, 24.675238348890336],
            [94.10674197792507, 23.85074087167348],
            [93.3251876159428, 24.078556423432204],
            [93.28632693885928, 23.043658352139005],
            [93.06029422401463, 22.70311066333557],
            [93.16612755734837, 22.278459580977103],
            [92.67272098182556, 22.041238918541254],
            [92.14603478390681, 23.627498684172593],
            [91.86992760617132, 23.624346421802784],
            [91.70647505083211, 22.985263983649187],
            [91.15896325069973, 23.50352692310439],
            [91.46772993364368, 24.072639471934792],
            [91.91509280799443, 24.130413723237112],
            [92.37620161333481, 24.976692816664965],
            [91.79959598182208, 25.147431748957317],
            [90.87221072791212, 25.132600612889547],
            [89.92069258012185, 25.26974986419218],
            [89.83248091019962, 25.96508209889548],
            [89.35509402868729, 26.014407253518073],
            [88.56304935094977, 26.446525580342723],
            [88.2097892598025, 25.768065700782714],
            [88.93155398962308, 25.238692328384776],
            [88.30637251175602, 24.866079413344206],
            [88.08442223506242, 24.501657212821925],
            [88.69994022009092, 24.23371491138856],
            [88.52976972855379, 23.631141872649167],
            [88.87631188350309, 22.87914642993783],
            [89.03196129756623, 22.055708319582976],
            [88.88876590368542, 21.690588487224748],
            [88.20849734899522, 21.703171698487807],
            [86.97570438024027, 21.49556163175521],
            [87.03316857294887, 20.743307806882413],
            [86.49935102737379, 20.151638495356607],
            [85.0602657409097, 19.4785788029711],
            [83.94100589390001, 18.302009792549725],
            [83.18921715691785, 17.67122142177898],
            [82.19279218946592, 17.016636053937816],
            [82.19124189649719, 16.556664130107848],
            [81.69271935417748, 16.310219224507904],
            [80.79199913933014, 15.951972357644493],
            [80.32489586784388, 15.89918488205835],
            [80.02506920768644, 15.136414903214147],
            [80.2332735533904, 13.835770778859981],
            [80.28629357292186, 13.006260687710835],
            [79.8625468281285, 12.056215318240888],
            [79.85799930208682, 10.35727509199711],
            [79.340511509116, 10.30885427493962],
            [78.88534549348918, 9.546135972527722],
            [79.1897196796883, 9.216543687370148],
            [78.2779407083305, 8.933046779816934],
            [77.94116539908435, 8.252959092639742],
            [77.53989790233794, 7.965534776232332],
            [76.59297895702167, 8.89927623131419],
            [76.13006147655108, 10.299630031775521],
            [75.7464673196485, 11.308250637248307],
            [75.39610110870959, 11.781245022015824],
            [74.86481570831683, 12.741935736537897],
            [74.61671715688354, 13.99258291264968],
            [74.44385949086723, 14.617221787977698],
            [73.5341992532334, 15.99065216721496],
            [73.11990929554943, 17.928570054592498],
            [72.82090945830865, 19.208233547436166],
            [72.8244751321368, 20.419503282141534],
            [72.6305334817454, 21.356009426351008],
            [71.17527347197395, 20.757441311114235],
            [70.4704586119451, 20.877330634031384],
            [69.16413008003883, 22.0892980005727],
            [69.6449276060824, 22.450774644454338],
            [69.34959679553435, 22.84317963306269],
            [68.1766451353734, 23.69196503345671],
            [68.84259931831878, 24.35913361256094],
            [71.04324018746823, 24.3565239527302],
            [70.84469933460284, 25.21510203704352],
            [70.2828731627256, 25.72222870533983],
            [70.16892662952202, 26.491871649678842],
            [69.51439293811313, 26.940965684511372],
            [70.61649620960193, 27.989196275335868],
            [71.77766564320032, 27.913180243434525],
            [72.8237516620847, 28.961591701772054],
            [73.45063846221743, 29.97641347911987],
            [74.42138024282028, 30.979814764931177],
            [74.40592898956501, 31.69263947196528],
            [75.25864179881322, 32.2711054550405],
            [74.45155927927871, 32.7648996038055],
            [74.10429365427734, 33.44147329358685],
            [73.74994835805197, 34.31769887952785],
            [74.24020267120497, 34.748887030571254],
            [75.75706098826834, 34.50492259372132],
            [76.87172163280403, 34.65354401299274],
            [77.83745079947457, 35.494009507787766],
            [78.91226891471322, 34.32193634697579],
            [78.81108646028574, 33.50619802503242],
            [79.20889163606859, 32.994394639613716],
            [79.17612877799553, 32.48377981213771],
            [78.45844648632601, 32.61816437431273],
            [78.73889448437401, 31.515906073527063],
            [79.7213668151071, 30.88271474865473],
            [81.11125613802932, 30.183480943313402],
            [80.4767212259174, 29.72986522065534],
            [80.08842451367627, 28.79447011974014],
            [81.05720258985203, 28.416095282499043],
            [81.99998742058497, 27.925479234319994],
            [83.30424889519955, 27.36450572357556],
            [84.6750179381738, 27.234901231387536],
            [85.25177859898338, 26.726198431906344],
            [86.02439293817918, 26.63098460540857],
            [87.2274719583663, 26.397898057556077],
            [88.06023766474982, 26.41461538340249],
            [88.17480431514092, 26.81040517832595],
            [88.04313276566123, 27.445818589786825],
            [88.12044070836987, 27.876541652939594],
            [88.73032596227856, 28.086864732367516],
            [88.81424848832056, 27.299315904239364],
            [88.83564253128938, 27.098966376243762],
            [89.74452762243885, 26.719402981059957],
            [90.37327477413407, 26.87572418874288],
            [91.21751264848643, 26.808648179628022],
            [92.03348351437509, 26.83831045176356],
            [92.10371178585974, 27.452614040633208],
            [91.69665652869668, 27.771741848251665],
            [92.50311893104364, 27.89687632904645],
            [93.41334760943269, 28.640629380807226],
            [94.56599043170294, 29.277438055939985],
            [95.40480228066464, 29.03171662039213],
            [96.11767866413103, 29.452802028922466],
            [96.58659061074749, 28.83097951915434],
            [96.24883344928779, 28.411030992134442],
            [97.32711388549004, 28.26158274994634]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CHN.geojson',
        iso_a2: 'CN',
        label_y: 32.498178,
        label_x: 106.337289,
        name: 'China',
        formal_en: "People's Republic of China"
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [109.47520958866365, 18.197700913968575],
              [108.65520796105616, 18.507681993071387],
              [108.62621748254044, 19.367887885001906],
              [109.11905561730804, 19.821038519769345],
              [110.21159874882281, 20.101253973872033],
              [110.78655073450221, 20.077534491450052],
              [111.01005130416458, 19.69592987719072],
              [110.57064660038677, 19.25587921800927],
              [110.33918786015147, 18.678395087147592],
              [109.47520958866365, 18.197700913968575]
            ]
          ],
          [
            [
              [80.2599902688853, 42.34999929459906],
              [80.1801501809943, 42.92006785742694],
              [80.86620649610126, 43.18036204688101],
              [79.96610639844141, 44.91751699480463],
              [81.9470707539181, 45.31702749285312],
              [82.45892581576906, 45.539649563166506],
              [83.18048383986047, 47.33003123635086],
              [85.16429039911324, 47.0009557155161],
              [85.72048383987067, 47.452969468773105],
              [85.7682328633083, 48.45575063739699],
              [86.59877648310336, 48.549181626980626],
              [87.35997033076265, 49.21498078062912],
              [87.75126427607671, 49.297197984405486],
              [88.01383222855173, 48.599462795600616],
              [88.85429772334676, 48.069081732772965],
              [90.28082563676392, 47.69354909930793],
              [90.97080936072501, 46.88814606382293],
              [90.58576826371828, 45.71971609148753],
              [90.9455395853343, 45.28607330991028],
              [92.13389082231822, 45.11507599545646],
              [93.4807336771413, 44.975472113619965],
              [94.68892866412533, 44.35233185482842],
              [95.30687544147153, 44.24133087826547],
              [95.76245486855669, 43.319449164394605],
              [96.34939578652781, 42.725635280928685],
              [97.45175744017801, 42.74888967546002],
              [99.51581749878004, 42.524691473961724],
              [100.84586551310827, 42.66380442969145],
              [101.83304039917994, 42.51487295182628],
              [103.31227827353482, 41.9074681666676],
              [104.52228193564899, 41.908346666016556],
              [104.96499393109347, 41.59740957291635],
              [106.12931562706169, 42.13432770442891],
              [107.74477257693795, 42.48151581478187],
              [109.24359581913146, 42.5194463160841],
              [110.41210330611528, 42.87123362891103],
              [111.12968224492023, 43.40683401140015],
              [111.82958784388137, 43.74311839453952],
              [111.66773725794323, 44.07317576758771],
              [111.34837690637946, 44.45744171811009],
              [111.8733061056003, 45.10207937273506],
              [112.43606245325881, 45.01164561622429],
              [113.46390669154417, 44.80889313412712],
              [114.46033165899607, 45.339816799493825],
              [115.98509647020009, 45.727235012386004],
              [116.71786828009886, 46.38820241961521],
              [117.42170128791419, 46.67273285581426],
              [118.87432579963873, 46.80541209572365],
              [119.66326989143876, 46.69267995867892],
              [119.7728239278975, 47.04805878355013],
              [118.86657433479495, 47.74706004494617],
              [118.06414269416672, 48.06673045510369],
              [117.29550744025741, 47.69770905210743],
              [116.30895267137323, 47.85341014260284],
              [115.74283735561579, 47.72654450132629],
              [115.48528201707306, 48.13538259540344],
              [116.19180219936757, 49.134598090199106],
              [116.67880089728612, 49.888531399121405],
              [117.8792444194264, 49.510983384796965],
              [119.2884607280258, 50.142882798862004],
              [119.27939, 50.58292],
              [120.18208000000016, 51.64355000000012],
              [120.7382, 51.96411],
              [120.72578901579197, 52.516226304730935],
              [120.17708865771681, 52.75388621684124],
              [121.00308475147017, 53.25140106873119],
              [122.24574791879283, 53.431725979213695],
              [123.57147, 53.4588],
              [125.06821129771038, 53.16104482686886],
              [125.94634891164617, 52.79279857035698],
              [126.56439904185697, 51.7842554795327],
              [126.93915652883766, 51.35389415140592],
              [127.28745568248485, 50.73979726826548],
              [127.65740000000028, 49.760270000000105],
              [129.39781782442043, 49.44060008401544],
              [130.58229332898242, 48.72968740497614],
              [130.9872600000001, 47.79013],
              [132.50669, 47.78896],
              [133.37359581922794, 48.18344167743487],
              [135.02631147678665, 48.478229885443874],
              [134.50081, 47.57845],
              [134.1123500000001, 47.21248],
              [133.76964399631288, 46.116926988299014],
              [133.09712000000013, 45.14409],
              [131.8834542176595, 45.32116160743652],
              [131.02519000000018, 44.96796],
              [131.28855512911548, 44.11151968034828],
              [131.1446879416149, 42.92998973242689],
              [130.63386640840974, 42.90301463477053],
              [130.63999970690955, 42.39502427522179],
              [129.99426720593323, 42.985386867843786],
              [129.59666873587952, 42.42498179785456],
              [128.0522152039723, 41.99428457291795],
              [128.20843305879066, 41.46677155208249],
              [127.34378299368302, 41.50315176041597],
              [126.86908328664987, 41.81656932226619],
              [126.18204511932943, 41.10733612727637],
              [125.07994184784064, 40.56982371679245],
              [124.26562462778531, 39.928493353834156],
              [122.86757042856095, 39.63778758397622],
              [122.1313879741309, 39.170451768544666],
              [121.05455447803286, 38.897471014962846],
              [121.58599490772248, 39.36085358332407],
              [121.37675703337268, 39.75026133885949],
              [122.16859500538106, 40.422442531896024],
              [121.64035851449353, 40.94638987890333],
              [120.76862877816194, 40.59338816991754],
              [119.63960208544907, 39.898055935214245],
              [119.02346398323306, 39.252333075511146],
              [118.04274865119791, 39.2042739934797],
              [117.53270226447711, 38.73763580988408],
              [118.05969852098964, 38.06147553156106],
              [118.87814985562832, 37.89732534438593],
              [118.91163618375344, 37.44846385349871],
              [119.70280236214205, 37.15638865818505],
              [120.8234574728236, 37.87042776137801],
              [121.711258579598, 37.48112335870718],
              [122.35793745329849, 37.45448415786072],
              [122.5199947449658, 36.93061432550185],
              [121.10416385303307, 36.651329047180425],
              [120.63700890511453, 36.111439520811075],
              [119.66456180224606, 35.60979055433772],
              [119.1512081238586, 34.90985911716044],
              [120.22752485563373, 34.36033193616865],
              [120.62036909391655, 33.37672272392514],
              [121.22901411345023, 32.46031871187719],
              [121.90814578663003, 31.692174384074647],
              [121.89191938689038, 30.949351508095106],
              [121.26425744027328, 30.6762674016487],
              [121.50351932178467, 30.14291494396429],
              [122.0921138855891, 29.83252045340315],
              [121.93842817595306, 29.01802236583478],
              [121.68443851123851, 28.225512600206617],
              [121.12566124886649, 28.135673122667185],
              [120.39547326058232, 27.05320689544932],
              [119.5854968608395, 25.740780544532612],
              [118.65687137255452, 24.54739085540024],
              [117.28160647997088, 23.624501451099658],
              [115.89073530483512, 22.78287323657807],
              [114.76382734584624, 22.668074042241667],
              [114.15254682826564, 22.22376007739615],
              [113.80677981980074, 22.5483397486214],
              [113.24107791550159, 22.051367499270455],
              [111.84359215703248, 21.550493679281473],
              [110.78546552942407, 21.397143866455338],
              [110.44403934127168, 20.34103261970633],
              [109.88986128137361, 20.282457383703488],
              [109.62765506392466, 21.008227037026703],
              [109.86448815311832, 21.395050970947523],
              [108.5228129415244, 21.715212307211814],
              [108.05018029178294, 21.55237986906012],
              [107.04342003787264, 21.811898912029914],
              [106.56727339073532, 22.21820486092477],
              [106.72540327354847, 22.79426788989842],
              [105.81124718630522, 22.976892401617903],
              [105.32920942588663, 23.352063300056912],
              [104.47685835166448, 22.819150092046968],
              [103.50451460166056, 22.70375661873921],
              [102.7069922221001, 22.708795070887675],
              [102.17043582561358, 22.464753119389304],
              [101.65201785686152, 22.318198757409547],
              [101.80311974488293, 21.17436676684507],
              [101.27002566935997, 21.201651923095184],
              [101.18000532430754, 21.436572984294028],
              [101.15003299357825, 21.849984442629022],
              [100.41653771362738, 21.558839423096614],
              [99.98348921102149, 21.7429367131364],
              [99.24089887898725, 22.11831431730458],
              [99.5319922220874, 22.94903880461258],
              [98.89874922078278, 23.14272207284253],
              [98.66026248575577, 24.063286037689966],
              [97.60471967976198, 23.897404690033042],
              [97.72460900267914, 25.083637193293],
              [98.67183800658916, 25.918702500913525],
              [98.71209394734451, 26.743535874940267],
              [98.68269005737046, 27.50881216075062],
              [98.2462309102333, 27.74722138112918],
              [97.91198774616944, 28.335945136014345],
              [97.32711388549004, 28.26158274994634],
              [96.24883344928779, 28.411030992134442],
              [96.58659061074749, 28.83097951915434],
              [96.11767866413103, 29.452802028922466],
              [95.40480228066464, 29.03171662039213],
              [94.56599043170294, 29.277438055939985],
              [93.41334760943269, 28.640629380807226],
              [92.50311893104364, 27.89687632904645],
              [91.69665652869668, 27.771741848251665],
              [91.25885379431992, 28.040614325466294],
              [90.7305139505678, 28.064953925075756],
              [90.01582889197118, 28.296438503527217],
              [89.47581017452111, 28.042758897406397],
              [88.81424848832056, 27.299315904239364],
              [88.73032596227856, 28.086864732367516],
              [88.12044070836987, 27.876541652939594],
              [86.9545170430006, 27.974261786403517],
              [85.82331994013151, 28.203575954698707],
              [85.01163821812304, 28.642773952747344],
              [84.23457970575015, 28.839893703724698],
              [83.89899295444673, 29.320226141877658],
              [83.33711510613719, 29.463731594352197],
              [82.32751264845088, 30.115268052688137],
              [81.52580447787474, 30.42271698660863],
              [81.11125613802932, 30.183480943313402],
              [79.7213668151071, 30.88271474865473],
              [78.73889448437401, 31.515906073527063],
              [78.45844648632601, 32.61816437431273],
              [79.17612877799553, 32.48377981213771],
              [79.20889163606859, 32.994394639613716],
              [78.81108646028574, 33.50619802503242],
              [78.91226891471322, 34.32193634697579],
              [77.83745079947457, 35.494009507787766],
              [76.19284834178569, 35.89840342868783],
              [75.89689741405013, 36.666806138651836],
              [75.15802778514092, 37.13303091078912],
              [74.98000247589542, 37.419990139305895],
              [74.8299857929521, 37.9900070257014],
              [74.86481570831683, 38.3788463404816],
              [74.25751427602273, 38.60650686294345],
              [73.92885216664644, 38.50581533462274],
              [73.6753792662548, 39.4312368841056],
              [73.96001305531843, 39.660008449861735],
              [73.8222436868283, 39.893973497063186],
              [74.77686242055606, 40.36642527929163],
              [75.4678279967307, 40.56207225194867],
              [76.52636803579745, 40.42794607193512],
              [76.90448449087708, 41.06648590754965],
              [78.18719689322597, 41.18531586360481],
              [78.54366092317531, 41.58224254003869],
              [80.11943037305139, 42.12394074153825],
              [80.2599902688853, 42.34999929459906]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ISR.geojson',
        iso_a2: 'IL',
        label_y: 30.911148,
        label_x: 34.847915,
        name: 'Israel',
        formal_en: 'State of Israel'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.71991824722275, 32.709192409794866],
            [35.54566531753454, 32.393992011030576],
            [35.183930291491436, 32.53251068778894],
            [34.97464074070933, 31.866582343059722],
            [35.22589155451243, 31.754341132121766],
            [34.970506626125996, 31.61677846936081],
            [34.92740848159457, 31.353435370401414],
            [35.397560662586045, 31.489086005167582],
            [35.420918409981965, 31.100065822874356],
            [34.92260257339143, 29.501326198844524],
            [34.823243288783814, 29.76108076171822],
            [34.26544, 31.21936],
            [34.26543474464621, 31.21935730952032],
            [34.26543338393569, 31.219360866820153],
            [34.55637169773891, 31.548823960896996],
            [34.48810713068136, 31.60553884533732],
            [34.75258711115117, 32.07292633720117],
            [34.95541710789678, 32.82737641044638],
            [35.098457472480675, 33.080539252244265],
            [35.126052687324545, 33.09090037691878],
            [35.460709262846706, 33.08904002535628],
            [35.55279666519081, 33.26427480725802],
            [35.82110070165024, 33.2774264592763],
            [35.836396925608625, 32.86812327730851],
            [35.70079796727475, 32.71601369885738],
            [35.71991824722275, 32.709192409794866]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PSE.geojson',
        iso_a2: 'PS',
        label_y: 32.047431,
        label_x: 35.291341,
        name: 'Palestine',
        formal_en: 'West Bank and Gaza'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.397560662586045, 31.489086005167582],
            [34.92740848159457, 31.353435370401414],
            [34.970506626125996, 31.61677846936081],
            [35.22589155451243, 31.754341132121766],
            [34.97464074070933, 31.866582343059722],
            [35.183930291491436, 32.53251068778894],
            [35.54566531753454, 32.393992011030576],
            [35.5452519060762, 31.78250478772084],
            [35.397560662586045, 31.489086005167582]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LBN.geojson',
        iso_a2: 'LB',
        label_y: 34.133368,
        label_x: 35.992892,
        name: 'Lebanon',
        formal_en: 'Lebanese Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.82110070165024, 33.2774264592763],
            [35.55279666519081, 33.26427480725802],
            [35.460709262846706, 33.08904002535628],
            [35.126052687324545, 33.09090037691878],
            [35.48220665868013, 33.90545014091944],
            [35.9795923194894, 34.61005829521913],
            [35.99840254084364, 34.644914048800004],
            [36.4481942075121, 34.59393524834407],
            [36.61175011571589, 34.20178864189718],
            [36.066460402172055, 33.82491242119255],
            [35.82110070165024, 33.2774264592763]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SYR.geojson',
        iso_a2: 'SY',
        label_y: 35.006636,
        label_x: 38.277783,
        name: 'Syria',
        formal_en: 'Syrian Arab Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.71991824722275, 32.709192409794866],
            [35.70079796727475, 32.71601369885738],
            [35.836396925608625, 32.86812327730851],
            [35.82110070165024, 33.2774264592763],
            [36.066460402172055, 33.82491242119255],
            [36.61175011571589, 34.20178864189718],
            [36.4481942075121, 34.59393524834407],
            [35.99840254084364, 34.644914048800004],
            [35.905023227692226, 35.410009467097325],
            [36.149762811026534, 35.82153473565367],
            [36.417550083163036, 36.04061697035506],
            [36.6853890317318, 36.259699205056464],
            [36.7394942563414, 36.81752045343109],
            [37.06676110204583, 36.62303620050062],
            [38.1677274920242, 36.90121043552777],
            [38.6998913917659, 36.71292735447234],
            [39.52258019385255, 36.71605377862599],
            [40.67325931169569, 37.09127635349729],
            [41.21208947120305, 37.074352321921694],
            [42.34959109881177, 37.2298725449041],
            [41.83706424334096, 36.605853786763575],
            [41.289707472505455, 36.35881460219227],
            [41.383965285005814, 35.628316555314356],
            [41.006158888519934, 34.41937226006212],
            [38.792340529136084, 33.378686428352225],
            [36.834062127435544, 32.312937526980775],
            [35.71991824722275, 32.709192409794866]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KOR.geojson',
        iso_a2: 'KR',
        label_y: 36.384924,
        label_x: 128.129504,
        name: 'South Korea',
        formal_en: 'Republic of Korea'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [126.17475874237624, 37.74968577732804],
            [126.23733890188176, 37.84037791600028],
            [126.68371992401893, 37.80477285415118],
            [127.07330854706737, 38.2561148137884],
            [127.78003543509101, 38.30453563084589],
            [128.20574588431145, 38.37039724380189],
            [128.34971642467661, 38.61224294692785],
            [129.21291954968007, 37.43239248305595],
            [129.46044966035817, 36.78418915460283],
            [129.4683044780665, 35.63214061130395],
            [129.0913765809296, 35.082484239231434],
            [128.1858504578791, 34.89037710218639],
            [127.3865194031884, 34.47567373304412],
            [126.48574751190876, 34.39004588473648],
            [126.37391971242914, 34.934560451795946],
            [126.55923139862779, 35.6845405136479],
            [126.11739790253229, 36.72548472751926],
            [126.86014326386339, 36.893924058574626],
            [126.17475874237624, 37.74968577732804]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PRK.geojson',
        iso_a2: 'KP',
        label_y: 39.885252,
        label_x: 126.444516,
        name: 'North Korea',
        formal_en: "Democratic People's Republic of Korea"
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [130.78000366004676, 42.220007813203225],
              [130.78000485358513, 42.22001036108258],
              [130.78000735893113, 42.22000722916885],
              [130.78000366004676, 42.220007813203225]
            ]
          ],
          [
            [
              [130.63999970690955, 42.39502427522179],
              [130.6400000000001, 42.395],
              [130.77999231657833, 42.22000960427719],
              [130.40003055228902, 42.28000356705971],
              [129.96594852103726, 41.94136790625106],
              [129.66736209525482, 41.60110443782523],
              [129.70518924369247, 40.88282786718433],
              [129.18811486218, 40.66180776627199],
              [129.01039961152821, 40.485436102859815],
              [128.63336836152672, 40.18984691015031],
              [127.96741417858135, 40.02541250259756],
              [127.53343550019417, 39.7568500839767],
              [127.5021195822253, 39.32393077245153],
              [127.38543419811027, 39.213472398427655],
              [127.78334272675772, 39.05089834243742],
              [128.34971642467661, 38.61224294692785],
              [128.20574588431145, 38.37039724380189],
              [127.78003543509101, 38.30453563084589],
              [127.07330854706737, 38.2561148137884],
              [126.68371992401893, 37.80477285415118],
              [126.23733890188176, 37.84037791600028],
              [126.17475874237624, 37.74968577732804],
              [125.68910363169721, 37.94001007745902],
              [125.5684391622957, 37.75208873142962],
              [125.2753304383362, 37.669070542952724],
              [125.24008711151316, 37.85722443292744],
              [124.98103315643397, 37.94882090916478],
              [124.71216067921938, 38.10834605564979],
              [124.98599409393398, 38.54847422947968],
              [125.2219486837787, 38.66585724543067],
              [125.13285851450752, 38.84855927179859],
              [125.3865897970606, 39.387957872061165],
              [125.32111575734682, 39.55138458918421],
              [124.7374821310424, 39.66034434667162],
              [124.26562462778531, 39.928493353834156],
              [125.07994184784064, 40.56982371679245],
              [126.18204511932943, 41.10733612727637],
              [126.86908328664987, 41.81656932226619],
              [127.34378299368302, 41.50315176041597],
              [128.20843305879066, 41.46677155208249],
              [128.0522152039723, 41.99428457291795],
              [129.59666873587952, 42.42498179785456],
              [129.99426720593323, 42.985386867843786],
              [130.63999970690955, 42.39502427522179]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BTN.geojson',
        iso_a2: 'BT',
        label_y: 27.536685,
        label_x: 90.040294,
        name: 'Bhutan',
        formal_en: 'Kingdom of Bhutan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [91.69665652869668, 27.771741848251665],
            [92.10371178585974, 27.452614040633208],
            [92.03348351437509, 26.83831045176356],
            [91.21751264848643, 26.808648179628022],
            [90.37327477413407, 26.87572418874288],
            [89.74452762243885, 26.719402981059957],
            [88.83564253128938, 27.098966376243762],
            [88.81424848832056, 27.299315904239364],
            [89.47581017452111, 28.042758897406397],
            [90.01582889197118, 28.296438503527217],
            [90.7305139505678, 28.064953925075756],
            [91.25885379431992, 28.040614325466294],
            [91.69665652869668, 27.771741848251665]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'OMN.geojson',
        iso_a2: 'OM',
        label_y: 22.120427,
        label_x: 57.336553,
        name: 'Oman',
        formal_en: 'Sultanate of Oman'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [55.208341098863194, 22.708329982997046],
              [55.234489373602884, 23.110992743415324],
              [55.525841098864475, 23.524869289640932],
              [55.52863162620824, 23.933604030853502],
              [55.98121382022046, 24.13054291431783],
              [55.804118686756226, 24.269604193615265],
              [55.88623253766801, 24.920830593357447],
              [56.396847365144005, 24.924732163995486],
              [56.845140415276006, 24.241673081961505],
              [57.4034525897574, 23.878594468678813],
              [58.13694786970825, 23.74793060962881],
              [58.72921146020542, 23.56566783293536],
              [59.18050174341033, 22.992395331305474],
              [59.45009769067703, 22.6602709009656],
              [59.80806033716286, 22.533611965418217],
              [59.806148309168066, 22.310524807214193],
              [59.442191196536385, 21.714540513592027],
              [59.28240766788991, 21.43388580981485],
              [58.86114139184656, 21.114034532144302],
              [58.48798587426694, 20.428985907467094],
              [58.034318475176605, 20.481437486243337],
              [57.826372511634105, 20.24300242764863],
              [57.665762160070955, 19.73600495043307],
              [57.788700392493325, 19.067570298737678],
              [57.694390903560645, 18.94470958096376],
              [57.234263950433814, 18.947991034414287],
              [56.60965091332193, 18.574267076079465],
              [56.51218916201947, 18.087113348863966],
              [56.28352094912793, 17.876066799383963],
              [55.66149173363064, 17.884128322821496],
              [55.26993940615512, 17.632309068263197],
              [55.27490034365513, 17.22835439703762],
              [54.79100223167404, 16.95069692633338],
              [54.23925296409371, 17.044980577049913],
              [53.570508253804576, 16.707662665264706],
              [53.10857262554751, 16.651051133688952],
              [52.78218427919205, 17.349742336491232],
              [52.00000980002224, 19.000003363516058],
              [54.99998172386236, 19.999994004796108],
              [55.666659376859826, 22.00000112557234],
              [55.208341098863194, 22.708329982997046]
            ]
          ],
          [
            [
              [56.261041701080956, 25.71460643157677],
              [56.07082075381456, 26.05546417897398],
              [56.36201744977927, 26.39593435312898],
              [56.48567915225374, 26.309117946878633],
              [56.39142133975335, 25.895990708921246],
              [56.261041701080956, 25.71460643157677]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'UZB.geojson',
        iso_a2: 'UZ',
        label_y: 41.693603,
        label_x: 64.005429,
        name: 'Uzbekistan',
        formal_en: 'Republic of Uzbekistan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [55.96819135928291, 41.30864166926936],
            [55.928917270741096, 44.99585846615911],
            [58.50312706892845, 45.586804307632974],
            [58.68998904809581, 45.50001373959873],
            [60.23997195825834, 44.78403677019473],
            [61.05831994003245, 44.40581696225051],
            [62.01330040878625, 43.50447663021565],
            [63.18578698105657, 43.650074978198006],
            [64.90082441595928, 43.72808055274258],
            [66.09801232286509, 42.997660020513095],
            [66.02339155463562, 41.99464630794404],
            [66.51064863471572, 41.987644151368556],
            [66.71404707221652, 41.1684435084615],
            [67.98585574735182, 41.13599070898222],
            [68.25989586779562, 40.6623245305949],
            [68.63248294462002, 40.66868073176681],
            [69.07002729683524, 41.38424428971234],
            [70.3889648782208, 42.08130768489745],
            [70.96231489449914, 42.266154283205495],
            [71.25924767444823, 42.16771067968946],
            [70.42002241402821, 41.51999827734314],
            [71.1578585142916, 41.14358714452912],
            [71.87011478057047, 41.392900092121266],
            [73.05541710804917, 40.866033026689465],
            [71.77487511585656, 40.14584442805378],
            [71.01419803252017, 40.24436554621823],
            [70.60140669137269, 40.21852733007229],
            [70.45815962105962, 40.49649485937029],
            [70.66662234892505, 40.960213324541414],
            [69.32949466337283, 40.72782440852485],
            [69.0116329283455, 40.08615814875667],
            [68.53641645698943, 39.53345286717894],
            [67.70142866401736, 39.58047842056453],
            [67.44221967964131, 39.140143541005486],
            [68.17602501818592, 38.901553453113905],
            [68.39203250516596, 38.15702525486874],
            [67.82999962755952, 37.144994004864685],
            [67.07578209825962, 37.35614390720929],
            [66.51860680528867, 37.36278432875879],
            [66.54615034370022, 37.97468496352687],
            [65.2159989765074, 38.4026950139843],
            [64.17022301621677, 38.892406724598246],
            [63.51801476426103, 39.36325653742564],
            [62.374260288345006, 40.05388621679039],
            [61.88271406438469, 41.084856879229406],
            [61.54717898951356, 41.266370347654615],
            [60.465952996670694, 41.22032664648255],
            [60.083340691981675, 41.425146185871405],
            [59.976422153569786, 42.22308197689021],
            [58.62901085799146, 42.75155101172305],
            [57.78652998233708, 42.17055288346552],
            [56.932215203687804, 41.826026109375604],
            [57.0963912290791, 41.32231008561057],
            [55.96819135928291, 41.30864166926936]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KAZ.geojson',
        iso_a2: 'KZ',
        label_y: 49.054149,
        label_x: 68.685548,
        name: 'Kazakhstan',
        formal_en: 'Republic of Kazakhstan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [87.35997033076265, 49.21498078062912],
            [86.59877648310336, 48.549181626980626],
            [85.7682328633083, 48.45575063739699],
            [85.72048383987067, 47.452969468773105],
            [85.16429039911324, 47.0009557155161],
            [83.18048383986047, 47.33003123635086],
            [82.45892581576906, 45.539649563166506],
            [81.9470707539181, 45.31702749285312],
            [79.96610639844141, 44.91751699480463],
            [80.86620649610126, 43.18036204688101],
            [80.1801501809943, 42.92006785742694],
            [80.2599902688853, 42.34999929459906],
            [79.64364546094012, 42.49668284765953],
            [79.14217736197978, 42.85609243424952],
            [77.65839196158322, 42.96068553320826],
            [76.00035363149846, 42.98802236589067],
            [75.63696495962202, 42.87789988867668],
            [74.21286583852256, 43.29833934180337],
            [73.64530358266092, 43.09127187760987],
            [73.48975752146237, 42.50089447689132],
            [71.8446382994506, 42.8453954127651],
            [71.18628055205212, 42.70429291439214],
            [70.96231489449914, 42.266154283205495],
            [70.3889648782208, 42.08130768489745],
            [69.07002729683524, 41.38424428971234],
            [68.63248294462002, 40.66868073176681],
            [68.25989586779562, 40.6623245305949],
            [67.98585574735182, 41.13599070898222],
            [66.71404707221652, 41.1684435084615],
            [66.51064863471572, 41.987644151368556],
            [66.02339155463562, 41.99464630794404],
            [66.09801232286509, 42.997660020513095],
            [64.90082441595928, 43.72808055274258],
            [63.18578698105657, 43.650074978198006],
            [62.01330040878625, 43.50447663021565],
            [61.05831994003245, 44.40581696225051],
            [60.23997195825834, 44.78403677019473],
            [58.68998904809581, 45.50001373959873],
            [58.50312706892845, 45.586804307632974],
            [55.928917270741096, 44.99585846615911],
            [55.96819135928291, 41.30864166926936],
            [55.45525109235377, 41.25985911718584],
            [54.75534549339264, 42.043971462566574],
            [54.07941775901495, 42.32410940202083],
            [52.944293247291654, 42.11603424739759],
            [52.50245975119615, 41.78331553808637],
            [52.44633914572722, 42.027150783855575],
            [52.692112257707265, 42.44389537207337],
            [52.50142622255032, 42.7922978785852],
            [51.342427199108215, 43.132974758469345],
            [50.89129194520024, 44.03103363705378],
            [50.339129266161365, 44.284015611338475],
            [50.30564293803627, 44.609835516938915],
            [51.278503452363225, 44.51485423438646],
            [51.31689904155604, 45.2459982366679],
            [52.16738976421573, 45.40839142514511],
            [53.0408764992452, 45.25904653582177],
            [53.220865512917726, 46.234645901059935],
            [53.042736850807785, 46.85300608986449],
            [52.04202273947561, 46.80463694923924],
            [51.191945428274266, 47.048704738953916],
            [50.03408328634248, 46.60898997658222],
            [49.10116000000011, 46.399330000000134],
            [48.59325000000018, 46.56104],
            [48.694733514201744, 47.07562816017793],
            [48.05725, 47.74377],
            [47.31524000000013, 47.7158500000001],
            [46.46644575377627, 48.39415233010493],
            [47.04367150247651, 49.152038886097614],
            [46.75159630716274, 49.35600576435377],
            [47.54948042174931, 50.454698391311126],
            [48.57784142435753, 49.87475962991567],
            [48.70238162618102, 50.60512848571284],
            [50.76664839051216, 51.6927623561599],
            [52.32872358583097, 51.718652248738124],
            [54.532878452376224, 51.02623973245932],
            [55.71694, 50.62171000000018],
            [56.77798000000013, 51.04355],
            [58.36332, 51.06364],
            [59.642282342370606, 50.545442206415714],
            [59.93280724471549, 50.842194118851864],
            [61.337424350840934, 50.79907013610426],
            [61.58800337102417, 51.272658799843214],
            [59.967533807215545, 51.9604204372157],
            [60.92726850774028, 52.44754832621504],
            [60.73999311711458, 52.71998647725775],
            [61.699986199800605, 52.97999644633427],
            [60.978066440683165, 53.66499339457914],
            [61.43660000000017, 54.00625],
            [65.17853356309593, 54.35422781027211],
            [65.66687, 54.60125000000011],
            [68.16910037625883, 54.97039175070432],
            [69.06816694527288, 55.38525014914353],
            [70.86526655465514, 55.169733588270105],
            [71.18013105660941, 54.13328522400826],
            [72.22415001820218, 54.376655381886735],
            [73.5085160663844, 54.0356167669766],
            [73.42567874542044, 53.489810289109755],
            [74.38482000000016, 53.54685000000012],
            [76.89110029491343, 54.49052440044193],
            [76.52517947785475, 54.17700348572714],
            [77.80091556184425, 53.404414984747575],
            [80.03555952344169, 50.86475088154725],
            [80.56844689323549, 51.38833649352847],
            [81.94598554883993, 50.81219594990637],
            [83.38300377801238, 51.069182847693924],
            [83.93511478061885, 50.88924551045358],
            [84.41637739455308, 50.311399644565824],
            [85.11555952346203, 50.11730296487764],
            [85.54126997268247, 49.69285858824816],
            [86.82935672398963, 49.82667470966817],
            [87.35997033076265, 49.21498078062912]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TJK.geojson',
        iso_a2: 'TJ',
        label_y: 38.199835,
        label_x: 72.587276,
        name: 'Tajikistan',
        formal_en: 'Republic of Tajikistan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [67.82999962755952, 37.144994004864685],
            [68.39203250516596, 38.15702525486874],
            [68.17602501818592, 38.901553453113905],
            [67.44221967964131, 39.140143541005486],
            [67.70142866401736, 39.58047842056453],
            [68.53641645698943, 39.53345286717894],
            [69.0116329283455, 40.08615814875667],
            [69.32949466337283, 40.72782440852485],
            [70.66662234892505, 40.960213324541414],
            [70.45815962105962, 40.49649485937029],
            [70.60140669137269, 40.21852733007229],
            [71.01419803252017, 40.24436554621823],
            [70.64801883329997, 39.93575389257117],
            [69.55960981636852, 40.10321137141298],
            [69.46488691597753, 39.5266832545487],
            [70.54916181832562, 39.6041979029865],
            [71.784693637992, 39.27946320246437],
            [73.6753792662548, 39.4312368841056],
            [73.92885216664644, 38.50581533462274],
            [74.25751427602273, 38.60650686294345],
            [74.86481570831683, 38.3788463404816],
            [74.8299857929521, 37.9900070257014],
            [74.98000247589542, 37.419990139305895],
            [73.9486959166465, 37.4215662704908],
            [73.26005577992501, 37.495256862939],
            [72.63688968291729, 37.047558091778356],
            [72.1930408059624, 36.948287665345674],
            [71.8446382994506, 36.73817129164692],
            [71.44869347523024, 37.06564484308052],
            [71.54191775908478, 37.905774441065645],
            [71.23940392444817, 37.953265082341886],
            [71.34813113799026, 38.25890534113216],
            [70.80682050973289, 38.486281643216415],
            [70.3763041523093, 38.13839590102752],
            [70.27057417184014, 37.735164699854025],
            [70.11657840361033, 37.58822276463209],
            [69.51878543485796, 37.60899669041342],
            [69.19627282092438, 37.15114350030743],
            [68.85944583524594, 37.344335842430596],
            [68.13556237170138, 37.02311513930431],
            [67.82999962755952, 37.144994004864685]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MNG.geojson',
        iso_a2: 'MN',
        label_y: 45.997488,
        label_x: 104.150405,
        name: 'Mongolia',
        formal_en: 'Mongolia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [87.75126427607671, 49.297197984405486],
            [88.80556684769552, 49.47052073831242],
            [90.71366743364068, 50.33181183532109],
            [92.23471154171968, 50.80217072204172],
            [93.10421, 50.49529],
            [94.14756635943559, 50.48053660745717],
            [94.81594933469873, 50.01343333597085],
            [95.8140200000002, 49.97746000000012],
            [97.25976000000014, 49.72605],
            [98.23176150919156, 50.422400621128745],
            [97.82573978067431, 51.01099518493318],
            [98.86149051310034, 52.04736603454669],
            [99.98173221232354, 51.63400625264399],
            [100.88948042196262, 51.51685578063832],
            [102.06521, 51.25991],
            [102.25589, 50.51056000000011],
            [103.67654544476022, 50.089966132195116],
            [104.62158, 50.27532000000019],
            [105.88659142458675, 50.406019192092224],
            [106.8888041524553, 50.27429596618032],
            [107.86817589725095, 49.793705145865815],
            [108.47516727095129, 49.28254771585074],
            [109.40244917199664, 49.292960516957635],
            [110.66201053267878, 49.13012807880585],
            [111.58123091028662, 49.37796824807769],
            [112.89773969935436, 49.54356537535699],
            [114.36245649623527, 50.24830272073741],
            [114.96210981655018, 50.140247300815126],
            [115.48569542853136, 49.80517731383475],
            [116.67880089728612, 49.888531399121405],
            [116.19180219936757, 49.134598090199106],
            [115.48528201707306, 48.13538259540344],
            [115.74283735561579, 47.72654450132629],
            [116.30895267137323, 47.85341014260284],
            [117.29550744025741, 47.69770905210743],
            [118.06414269416672, 48.06673045510369],
            [118.86657433479495, 47.74706004494617],
            [119.7728239278975, 47.04805878355013],
            [119.66326989143876, 46.69267995867892],
            [118.87432579963873, 46.80541209572365],
            [117.42170128791419, 46.67273285581426],
            [116.71786828009886, 46.38820241961521],
            [115.98509647020009, 45.727235012386004],
            [114.46033165899607, 45.339816799493825],
            [113.46390669154417, 44.80889313412712],
            [112.43606245325881, 45.01164561622429],
            [111.8733061056003, 45.10207937273506],
            [111.34837690637946, 44.45744171811009],
            [111.66773725794323, 44.07317576758771],
            [111.82958784388137, 43.74311839453952],
            [111.12968224492023, 43.40683401140015],
            [110.41210330611528, 42.87123362891103],
            [109.24359581913146, 42.5194463160841],
            [107.74477257693795, 42.48151581478187],
            [106.12931562706169, 42.13432770442891],
            [104.96499393109347, 41.59740957291635],
            [104.52228193564899, 41.908346666016556],
            [103.31227827353482, 41.9074681666676],
            [101.83304039917994, 42.51487295182628],
            [100.84586551310827, 42.66380442969145],
            [99.51581749878004, 42.524691473961724],
            [97.45175744017801, 42.74888967546002],
            [96.34939578652781, 42.725635280928685],
            [95.76245486855669, 43.319449164394605],
            [95.30687544147153, 44.24133087826547],
            [94.68892866412533, 44.35233185482842],
            [93.4807336771413, 44.975472113619965],
            [92.13389082231822, 45.11507599545646],
            [90.9455395853343, 45.28607330991028],
            [90.58576826371828, 45.71971609148753],
            [90.97080936072501, 46.88814606382293],
            [90.28082563676392, 47.69354909930793],
            [88.85429772334676, 48.069081732772965],
            [88.01383222855173, 48.599462795600616],
            [87.75126427607671, 49.297197984405486]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'VNM.geojson',
        iso_a2: 'VN',
        label_y: 21.715416,
        label_x: 105.387292,
        name: 'Vietnam',
        formal_en: 'Socialist Republic of Vietnam'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [104.33433475140347, 10.48654368737523],
            [105.19991499229235, 10.889309800658097],
            [106.24967003786946, 10.961811835163587],
            [105.81052371625313, 11.567614650921229],
            [107.49140302941089, 12.337205918827948],
            [107.61454796756243, 13.535530707244206],
            [107.38272749230109, 14.202440904186972],
            [107.5645251811039, 15.20217316330556],
            [107.3127059265456, 15.90853831630318],
            [106.55600792849569, 16.604283962464805],
            [105.92576216026403, 17.48531545660896],
            [105.09459842328152, 18.66697459561108],
            [103.89653201702671, 19.265180975821806],
            [104.18338789267894, 19.62466807706022],
            [104.8225736836971, 19.886641750563882],
            [104.43500044150805, 20.75873322192153],
            [103.20386111858645, 20.76656220141375],
            [102.75489627483466, 21.675137233969465],
            [102.17043582561358, 22.464753119389304],
            [102.7069922221001, 22.708795070887675],
            [103.50451460166056, 22.70375661873921],
            [104.47685835166448, 22.819150092046968],
            [105.32920942588663, 23.352063300056912],
            [105.81124718630522, 22.976892401617903],
            [106.72540327354847, 22.79426788989842],
            [106.56727339073532, 22.21820486092477],
            [107.04342003787264, 21.811898912029914],
            [108.05018029178294, 21.55237986906012],
            [106.7150679870901, 20.69685069425202],
            [105.88168216351903, 19.752050482659698],
            [105.66200564984631, 19.05816518806057],
            [106.42681684776602, 18.004120998603227],
            [107.36195356651974, 16.697456569887052],
            [108.26949507042963, 16.07974233648615],
            [108.87710656131748, 15.27669057867044],
            [109.33526981001722, 13.426028347217724],
            [109.20013593957398, 11.666859239137764],
            [108.36612999881545, 11.008320624226272],
            [107.22092858279524, 10.364483954301832],
            [106.40511274620343, 9.53083974856932],
            [105.15826378786511, 8.599759629750494],
            [104.79518517458239, 9.241038316276502],
            [105.07620161338562, 9.918490505406808],
            [104.33433475140347, 10.48654368737523]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KHM.geojson',
        iso_a2: 'KH',
        label_y: 12.647584,
        label_x: 104.50487,
        name: 'Cambodia',
        formal_en: 'Kingdom of Cambodia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [102.5849324890267, 12.186594956913282],
            [102.34809939983302, 13.394247341358223],
            [102.98842207236163, 14.225721136934467],
            [104.28141808473661, 14.416743068901367],
            [105.21877689007889, 14.273211778210694],
            [106.04394616091552, 13.881091009979956],
            [106.49637332563088, 14.570583807834282],
            [107.38272749230109, 14.202440904186972],
            [107.61454796756243, 13.535530707244206],
            [107.49140302941089, 12.337205918827948],
            [105.81052371625313, 11.567614650921229],
            [106.24967003786946, 10.961811835163587],
            [105.19991499229235, 10.889309800658097],
            [104.33433475140347, 10.48654368737523],
            [103.4972799011397, 10.632555446815928],
            [103.09068973186726, 11.153660590047165],
            [102.5849324890267, 12.186594956913282]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ARE.geojson',
        iso_a2: 'AE',
        label_y: 23.466285,
        label_x: 54.547256,
        name: 'United Arab Emirates',
        formal_en: 'United Arab Emirates'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [51.57951867046327, 24.245497137951105],
            [51.757440626844186, 24.29407298430547],
            [51.79438927593287, 24.019826158132506],
            [52.57708051942561, 24.177439276622707],
            [53.404006788960146, 24.15131684009917],
            [54.00800092958758, 24.121757920828216],
            [54.69302371604863, 24.79789236093509],
            [55.43902469261414, 25.43914520924494],
            [56.07082075381456, 26.05546417897398],
            [56.261041701080956, 25.71460643157677],
            [56.396847365144005, 24.924732163995486],
            [55.88623253766801, 24.920830593357447],
            [55.804118686756226, 24.269604193615265],
            [55.98121382022046, 24.13054291431783],
            [55.52863162620824, 23.933604030853502],
            [55.525841098864475, 23.524869289640932],
            [55.234489373602884, 23.110992743415324],
            [55.208341098863194, 22.708329982997046],
            [55.006803012924905, 22.496947536707136],
            [52.000733270074335, 23.00115448657894],
            [51.61770755392698, 24.01421926522883],
            [51.57951867046327, 24.245497137951105]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GEO.geojson',
        iso_a2: 'GE',
        label_y: 41.870087,
        label_x: 43.735724,
        name: 'Georgia',
        formal_en: 'Georgia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.955008579270924, 43.43499766699922],
            [40.07696495947977, 43.553104153002316],
            [40.922190000000114, 43.38215000000014],
            [42.39440000000013, 43.2203],
            [43.75599000000011, 42.74083],
            [43.931210000000135, 42.55496000000011],
            [44.537622918481986, 42.71199270280363],
            [45.47027916848572, 42.50278066666998],
            [45.7764, 42.09244000000018],
            [46.404950799348825, 41.860675157227305],
            [46.14543175637902, 41.72280243587258],
            [46.63790815612058, 41.181672675128226],
            [46.50163740416693, 41.06444468847411],
            [45.96260053893039, 41.123872585609774],
            [45.217426385281584, 41.41145193131405],
            [44.97248009621808, 41.248128567055595],
            [43.58274580259273, 41.09214325618257],
            [42.61954878110449, 41.58317271581994],
            [41.55408410011066, 41.53565623632757],
            [41.70317060727271, 41.96294281673292],
            [41.45347008643839, 42.64512339941794],
            [40.87546919125379, 43.013628038091284],
            [40.32139448422032, 43.128633938156845],
            [39.955008579270924, 43.43499766699922]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'AZE.geojson',
        iso_a2: 'AZ',
        label_y: 40.402387,
        label_x: 47.210994,
        name: 'Azerbaijan',
        formal_en: 'Republic of Azerbaijan'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [46.404950799348825, 41.860675157227305],
              [46.68607059101661, 41.82713715266988],
              [47.37331546406623, 41.2197323675112],
              [47.81566572448463, 41.15141612402135],
              [47.98728315612598, 41.40581920019423],
              [48.58435339611342, 41.80886879162067],
              [49.110263706260696, 41.28228668880051],
              [49.6189148293096, 40.57292430272996],
              [50.08482954285313, 40.52615713150581],
              [50.39282107931268, 40.25656118423912],
              [49.569202101444795, 40.176100979160665],
              [49.395259230350376, 39.39948171646225],
              [49.22322838725071, 39.04921885838793],
              [48.85653242370759, 38.81548635513178],
              [48.88324913920249, 38.32024526626262],
              [48.63437544128482, 38.27037750910097],
              [48.01074425638648, 38.79401479751452],
              [48.35552941263788, 39.28876496027691],
              [48.06009524922524, 39.58223541926246],
              [47.685079380083096, 39.50836395930122],
              [46.50571984231797, 38.770605373686294],
              [46.48349897643246, 39.464154771475535],
              [46.034534132680676, 39.628020738273065],
              [45.61001224140293, 39.89999380142518],
              [45.89190717955509, 40.21847565364],
              [45.35917483905817, 40.56150381119346],
              [45.56035118997045, 40.812289537105926],
              [45.17949588397934, 40.98535390885141],
              [44.97248009621808, 41.248128567055595],
              [45.217426385281584, 41.41145193131405],
              [45.96260053893039, 41.123872585609774],
              [46.50163740416693, 41.06444468847411],
              [46.63790815612058, 41.181672675128226],
              [46.14543175637902, 41.72280243587258],
              [46.404950799348825, 41.860675157227305]
            ]
          ],
          [
            [
              [46.14362308124882, 38.74120148371222],
              [45.45772179543877, 38.87413910578306],
              [44.95268802265031, 39.33576467544637],
              [44.79398969908195, 39.71300263117705],
              [45.00198733905675, 39.740003567049555],
              [45.29814497252146, 39.471751207022436],
              [45.73997846861698, 39.47399913182713],
              [45.73537926614301, 39.31971914321974],
              [46.14362308124882, 38.74120148371222]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TUR.geojson',
        iso_a2: 'TR',
        label_y: 39.345388,
        label_x: 34.508268,
        name: 'Turkey',
        formal_en: 'Republic of Turkey'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [44.77267710159504, 37.17043692561684],
              [44.29345177590287, 37.0015143906063],
              [43.9422587420473, 37.25622752537295],
              [42.77912560402183, 37.385263576805755],
              [42.34959109881177, 37.2298725449041],
              [41.21208947120305, 37.074352321921694],
              [40.67325931169569, 37.09127635349729],
              [39.52258019385255, 36.71605377862599],
              [38.6998913917659, 36.71292735447234],
              [38.1677274920242, 36.90121043552777],
              [37.06676110204583, 36.62303620050062],
              [36.7394942563414, 36.81752045343109],
              [36.6853890317318, 36.259699205056464],
              [36.417550083163036, 36.04061697035506],
              [36.149762811026534, 35.82153473565367],
              [35.782084995269855, 36.27499542901485],
              [36.160821567537, 36.65060557712833],
              [35.55093631362831, 36.565442816711325],
              [34.714553256984345, 36.7955321314909],
              [34.026894972476384, 36.219960028624016],
              [32.509158156064075, 36.107563788389186],
              [31.699595167779567, 36.64427521417258],
              [30.621624790171097, 36.6778648951623],
              [30.391096225717064, 36.26298065850699],
              [29.69997562024554, 36.144357408180994],
              [28.732902866335422, 36.67683136651647],
              [27.641186557737317, 36.65882212986274],
              [27.048767937943264, 37.653360907536],
              [26.318218214633045, 38.208133246405396],
              [26.804700148228733, 38.985760199533516],
              [26.17078535330433, 39.463612168936464],
              [27.28001997244939, 40.42001373957831],
              [28.81997765474722, 40.46001129817221],
              [29.240003696415584, 41.21999074967267],
              [31.14593387220444, 41.08762156835702],
              [32.34797936374571, 41.736264146484615],
              [33.51328291192746, 42.01896006933731],
              [35.167703891751785, 42.040224921225416],
              [36.9131270688421, 41.33535838476427],
              [38.34766482926452, 40.94858612727573],
              [39.512606642420195, 41.10276276301855],
              [40.373432651538224, 41.01367259374735],
              [41.55408410011066, 41.53565623632757],
              [42.61954878110449, 41.58317271581994],
              [43.58274580259273, 41.09214325618257],
              [43.75265791196841, 40.74020091405876],
              [43.65643639504094, 40.253563951166186],
              [44.4000085792887, 40.00500031184228],
              [44.79398969908195, 39.71300263117705],
              [44.10922529478234, 39.4281362981681],
              [44.421402622257546, 38.28128123631454],
              [44.22575564960053, 37.97158437758935],
              [44.77267, 37.17045],
              [44.77267710159504, 37.17043692561684]
            ]
          ],
          [
            [
              [26.1170418637208, 41.82690460872456],
              [27.13573937349048, 42.14148489030134],
              [27.99672041190539, 42.00735871028779],
              [28.115524529744416, 41.62288605403624],
              [28.988442824018733, 41.29993419042819],
              [28.806438429486743, 41.05496206314856],
              [27.61901736828409, 40.99982330989314],
              [27.19237674328238, 40.69056570084242],
              [26.35800906749779, 40.151993923496505],
              [26.04335127127257, 40.61775360774317],
              [26.05694217296534, 40.82412344010076],
              [26.294602085075667, 40.93626129817413],
              [26.60419559093623, 41.562114569661105],
              [26.1170418637208, 41.82690460872456]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LAO.geojson',
        iso_a2: 'LA',
        label_y: 19.431821,
        label_x: 102.533912,
        name: 'Laos',
        formal_en: "Lao People's Democratic Republic"
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [107.38272749230109, 14.202440904186972],
            [106.49637332563088, 14.570583807834282],
            [106.04394616091552, 13.881091009979956],
            [105.21877689007889, 14.273211778210694],
            [105.54433841351769, 14.723933620660418],
            [105.58903852745016, 15.570316066952858],
            [104.7793205098688, 16.44186493577145],
            [104.7169470560925, 17.42885895433008],
            [103.9564766784853, 18.24095408779688],
            [103.20019209189374, 18.309632066312773],
            [102.99870568238771, 17.9616946476916],
            [102.41300499879162, 17.932781683824288],
            [102.11359175009248, 18.109101670804165],
            [101.05954756063517, 17.51249725999449],
            [101.03593143107777, 18.408928330961615],
            [101.2820146016517, 19.462584947176765],
            [100.60629357300316, 19.508344427971224],
            [100.54888105672688, 20.109237982661128],
            [100.11598758341785, 20.417849636308187],
            [100.32910119018953, 20.786121731036232],
            [101.18000532430754, 21.436572984294028],
            [101.27002566935997, 21.201651923095184],
            [101.80311974488293, 21.17436676684507],
            [101.65201785686152, 22.318198757409547],
            [102.17043582561358, 22.464753119389304],
            [102.75489627483466, 21.675137233969465],
            [103.20386111858645, 20.76656220141375],
            [104.43500044150805, 20.75873322192153],
            [104.8225736836971, 19.886641750563882],
            [104.18338789267894, 19.62466807706022],
            [103.89653201702671, 19.265180975821806],
            [105.09459842328152, 18.66697459561108],
            [105.92576216026403, 17.48531545660896],
            [106.55600792849569, 16.604283962464805],
            [107.3127059265456, 15.90853831630318],
            [107.5645251811039, 15.20217316330556],
            [107.38272749230109, 14.202440904186972]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KGZ.geojson',
        iso_a2: 'KG',
        label_y: 41.66854,
        label_x: 74.532637,
        name: 'Kyrgyzstan',
        formal_en: 'Kyrgyz Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [70.96231489449914, 42.266154283205495],
            [71.18628055205212, 42.70429291439214],
            [71.8446382994506, 42.8453954127651],
            [73.48975752146237, 42.50089447689132],
            [73.64530358266092, 43.09127187760987],
            [74.21286583852256, 43.29833934180337],
            [75.63696495962202, 42.87789988867668],
            [76.00035363149846, 42.98802236589067],
            [77.65839196158322, 42.96068553320826],
            [79.14217736197978, 42.85609243424952],
            [79.64364546094012, 42.49668284765953],
            [80.2599902688853, 42.34999929459906],
            [80.11943037305139, 42.12394074153825],
            [78.54366092317531, 41.58224254003869],
            [78.18719689322597, 41.18531586360481],
            [76.90448449087708, 41.06648590754965],
            [76.52636803579745, 40.42794607193512],
            [75.4678279967307, 40.56207225194867],
            [74.77686242055606, 40.36642527929163],
            [73.8222436868283, 39.893973497063186],
            [73.96001305531843, 39.660008449861735],
            [73.6753792662548, 39.4312368841056],
            [71.784693637992, 39.27946320246437],
            [70.54916181832562, 39.6041979029865],
            [69.46488691597753, 39.5266832545487],
            [69.55960981636852, 40.10321137141298],
            [70.64801883329997, 39.93575389257117],
            [71.01419803252017, 40.24436554621823],
            [71.77487511585656, 40.14584442805378],
            [73.05541710804917, 40.866033026689465],
            [71.87011478057047, 41.392900092121266],
            [71.1578585142916, 41.14358714452912],
            [70.42002241402821, 41.51999827734314],
            [71.25924767444823, 42.16771067968946],
            [70.96231489449914, 42.266154283205495]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ARM.geojson',
        iso_a2: 'AM',
        label_y: 40.459077,
        label_x: 44.800564,
        name: 'Armenia',
        formal_en: 'Republic of Armenia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [46.50571984231797, 38.770605373686294],
            [46.14362308124882, 38.74120148371222],
            [45.73537926614301, 39.31971914321974],
            [45.73997846861698, 39.47399913182713],
            [45.29814497252146, 39.471751207022436],
            [45.00198733905675, 39.740003567049555],
            [44.79398969908195, 39.71300263117705],
            [44.4000085792887, 40.00500031184228],
            [43.65643639504094, 40.253563951166186],
            [43.75265791196841, 40.74020091405876],
            [43.58274580259273, 41.09214325618257],
            [44.97248009621808, 41.248128567055595],
            [45.17949588397934, 40.98535390885141],
            [45.56035118997045, 40.812289537105926],
            [45.35917483905817, 40.56150381119346],
            [45.89190717955509, 40.21847565364],
            [45.61001224140293, 39.89999380142518],
            [46.034534132680676, 39.628020738273065],
            [46.48349897643246, 39.464154771475535],
            [46.50571984231797, 38.770605373686294]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'IRQ.geojson',
        iso_a2: 'IQ',
        label_y: 33.09403,
        label_x: 43.26181,
        name: 'Iraq',
        formal_en: 'Republic of Iraq'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.19546837744497, 32.16100881604267],
            [38.792340529136084, 33.378686428352225],
            [41.006158888519934, 34.41937226006212],
            [41.383965285005814, 35.628316555314356],
            [41.289707472505455, 36.35881460219227],
            [41.83706424334096, 36.605853786763575],
            [42.34959109881177, 37.2298725449041],
            [42.77912560402183, 37.385263576805755],
            [43.9422587420473, 37.25622752537295],
            [44.29345177590287, 37.0015143906063],
            [44.77267710159504, 37.17043692561684],
            [45.42061811705321, 35.977545884742824],
            [46.0763403664048, 35.67738332777549],
            [46.15178795755094, 35.09325877536429],
            [45.64845950702809, 34.748137722303014],
            [45.41669070819904, 33.967797756479584],
            [46.10936160663932, 33.017287299119005],
            [47.33466149271191, 32.46915538179911],
            [47.8492037290421, 31.70917593029867],
            [47.68528608581227, 30.984853217079632],
            [48.004698113808324, 30.985137437457244],
            [48.0145683123761, 30.452456773392598],
            [48.567971225789755, 29.926778265903522],
            [47.974519077349896, 29.975819200148504],
            [47.30262210469096, 30.059069932570722],
            [46.568713413281756, 29.09902517345229],
            [44.70949873228474, 29.178891099559383],
            [41.889980910007836, 31.19000865327837],
            [40.399994337736246, 31.889991766887935],
            [39.19546837744497, 32.16100881604267]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'IRN.geojson',
        iso_a2: 'IR',
        label_y: 32.166225,
        label_x: 54.931495,
        name: 'Iran',
        formal_en: 'Islamic Republic of Iran'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.567971225789755, 29.926778265903522],
            [48.0145683123761, 30.452456773392598],
            [48.004698113808324, 30.985137437457244],
            [47.68528608581227, 30.984853217079632],
            [47.8492037290421, 31.70917593029867],
            [47.33466149271191, 32.46915538179911],
            [46.10936160663932, 33.017287299119005],
            [45.41669070819904, 33.967797756479584],
            [45.64845950702809, 34.748137722303014],
            [46.15178795755094, 35.09325877536429],
            [46.0763403664048, 35.67738332777549],
            [45.42061811705321, 35.977545884742824],
            [44.77267710159504, 37.17043692561684],
            [44.77267, 37.17045],
            [44.22575564960053, 37.97158437758935],
            [44.421402622257546, 38.28128123631454],
            [44.10922529478234, 39.4281362981681],
            [44.79398969908195, 39.71300263117705],
            [44.95268802265031, 39.33576467544637],
            [45.45772179543877, 38.87413910578306],
            [46.14362308124882, 38.74120148371222],
            [46.50571984231797, 38.770605373686294],
            [47.685079380083096, 39.50836395930122],
            [48.06009524922524, 39.58223541926246],
            [48.35552941263788, 39.28876496027691],
            [48.01074425638648, 38.79401479751452],
            [48.63437544128482, 38.27037750910097],
            [48.88324913920249, 38.32024526626262],
            [49.19961225769334, 37.582874253889884],
            [50.14777143738462, 37.37456655532134],
            [50.84235436381971, 36.8728142359834],
            [52.264024692601424, 36.7004216578577],
            [53.82578982932642, 36.965030829408235],
            [53.92159793479556, 37.19891836196126],
            [54.800303989486565, 37.392420762678185],
            [55.51157840355191, 37.96411713312317],
            [56.18037479027333, 37.93512665460743],
            [56.61936608259282, 38.121394354803485],
            [57.330433790928986, 38.02922943781094],
            [58.436154412678206, 37.5223094752438],
            [59.23476199731681, 37.41298798273034],
            [60.37763797388388, 36.52738312432837],
            [61.123070509694145, 36.491597194966246],
            [61.210817091725744, 35.650072333309225],
            [60.80319339380745, 34.40410187431986],
            [60.52842980331158, 33.676446031218006],
            [60.963700392506006, 33.52883230237626],
            [60.536077915290775, 32.98126882581157],
            [60.863654819588966, 32.18291962333443],
            [60.94194461451113, 31.548074652628753],
            [61.69931440618083, 31.379506130492672],
            [61.781221551363444, 30.735850328081238],
            [60.87424848820879, 29.829238999952608],
            [61.36930870956494, 29.303276272085924],
            [61.77186811711863, 28.6993338078908],
            [62.72783043808599, 28.25964488373539],
            [62.75542565292986, 27.378923448184988],
            [63.2338977395203, 27.21704702403071],
            [63.31663170761959, 26.756532497661667],
            [61.87418745305655, 26.239974880472104],
            [61.49736290878419, 25.0782370061185],
            [59.616134067630846, 25.380156561783778],
            [58.525761346272304, 25.60996165618573],
            [57.39725141788239, 25.73990204518364],
            [56.970765822177555, 26.966106268821363],
            [56.492138706290206, 27.143304755150197],
            [55.723710158110066, 26.964633490501043],
            [54.71508955263727, 26.480657863871514],
            [53.49309695823135, 26.81236888275305],
            [52.48359785340961, 27.580849107365495],
            [51.52076256694742, 27.865689602158298],
            [50.85294803243954, 28.814520575469388],
            [50.115008579311585, 30.147772528599717],
            [49.576850213423995, 29.985715236932407],
            [48.94133344909855, 30.317090359004037],
            [48.567971225789755, 29.926778265903522]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'QAT.geojson',
        iso_a2: 'QA',
        label_y: 25.237383,
        label_x: 51.143509,
        name: 'Qatar',
        formal_en: 'State of Qatar'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [50.81010827006958, 24.754742539971378],
            [50.74391076030369, 25.482424221289396],
            [51.013351678273494, 26.006991685484195],
            [51.28646162293606, 26.11458201751587],
            [51.58907881043726, 25.80111277923338],
            [51.60670047384881, 25.21567047779874],
            [51.38960778179063, 24.62738597258806],
            [51.11241539897702, 24.556330878186724],
            [50.81010827006958, 24.754742539971378]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SAU.geojson',
        iso_a2: 'SA',
        label_y: 23.806908,
        label_x: 44.6996,
        name: 'Saudi Arabia',
        formal_en: 'Kingdom of Saudi Arabia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.95603722508426, 29.356554673778845],
            [36.06894087092206, 29.197494615184453],
            [36.50121422704359, 29.505253607698705],
            [36.74052778498725, 29.86528331147619],
            [37.503581984209035, 30.003776150018407],
            [37.66811974462638, 30.3386652694859],
            [37.998848911294374, 30.508499864213135],
            [37.00216556168101, 31.508412990844747],
            [39.00488569515255, 32.01021698661498],
            [39.19546837744497, 32.16100881604267],
            [40.399994337736246, 31.889991766887935],
            [41.889980910007836, 31.19000865327837],
            [44.70949873228474, 29.178891099559383],
            [46.568713413281756, 29.09902517345229],
            [47.45982181172283, 29.002519436147224],
            [47.708850538937384, 28.526062730416143],
            [48.416094191283946, 28.55200429942667],
            [48.80759484232718, 27.689627997339883],
            [49.29955447774583, 27.46121816660981],
            [49.47091352722566, 27.109999294538085],
            [50.15242231629088, 26.689663194275997],
            [50.212935418504685, 26.277026882425375],
            [50.11330325704594, 25.94397227630425],
            [50.239858839728754, 25.608049628190926],
            [50.527386509000735, 25.327808335872103],
            [50.66055667501689, 24.999895534764022],
            [50.81010827006958, 24.754742539971378],
            [51.11241539897702, 24.556330878186724],
            [51.38960778179063, 24.62738597258806],
            [51.57951867046327, 24.245497137951105],
            [51.61770755392698, 24.01421926522883],
            [52.000733270074335, 23.00115448657894],
            [55.006803012924905, 22.496947536707136],
            [55.208341098863194, 22.708329982997046],
            [55.666659376859826, 22.00000112557234],
            [54.99998172386236, 19.999994004796108],
            [52.00000980002224, 19.000003363516058],
            [49.11667158386487, 18.616667588774945],
            [48.18334354024134, 18.166669216377315],
            [47.46669477721763, 17.116681626854884],
            [47.000004917189756, 16.949999294497445],
            [46.74999433776165, 17.283338120996177],
            [46.366658563020536, 17.233315334537636],
            [45.39999922056876, 17.333335069238558],
            [45.21665123879719, 17.433328965723334],
            [44.06261315285508, 17.410358791569593],
            [43.79151858905192, 17.31997671149111],
            [43.380794305196105, 17.57998668056767],
            [43.11579756040336, 17.088440456607373],
            [43.21837527850275, 16.66688996018641],
            [42.77933230975097, 16.347891343648683],
            [42.649572788266084, 16.774635321514964],
            [42.34798912941071, 17.075805568912003],
            [42.270887892431226, 17.474721787989125],
            [41.75438195167396, 17.833046169500975],
            [41.22139122901558, 18.67159963630121],
            [40.93934126156654, 19.486485297111756],
            [40.247652215339826, 20.17463450772649],
            [39.80168460466095, 20.338862209550058],
            [39.139399448408284, 21.291904812092934],
            [39.023695916506796, 21.986875311770195],
            [39.06632897314759, 22.57965566659027],
            [38.49277225114008, 23.688451036060854],
            [38.02386030452362, 24.078685614512935],
            [37.483634881344386, 24.285494696545015],
            [37.154817742671185, 24.85848297779731],
            [37.209491408036, 25.084541530858107],
            [36.93162723160259, 25.60295949961018],
            [36.639603712721225, 25.826227525327223],
            [36.249136590323815, 26.57013560638488],
            [35.64018151219639, 27.376520494083422],
            [35.13018680190788, 28.06335195567472],
            [34.63233605320798, 28.058546047471566],
            [34.787778761541944, 28.6074272730597],
            [34.832220493312946, 28.957483425404845],
            [34.95603722508426, 29.356554673778845]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PAK.geojson',
        iso_a2: 'PK',
        label_y: 29.328389,
        label_x: 68.545632,
        name: 'Pakistan',
        formal_en: 'Islamic Republic of Pakistan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [77.83745079947457, 35.494009507787766],
            [76.87172163280403, 34.65354401299274],
            [75.75706098826834, 34.50492259372132],
            [74.24020267120497, 34.748887030571254],
            [73.74994835805197, 34.31769887952785],
            [74.10429365427734, 33.44147329358685],
            [74.45155927927871, 32.7648996038055],
            [75.25864179881322, 32.2711054550405],
            [74.40592898956501, 31.69263947196528],
            [74.42138024282028, 30.979814764931177],
            [73.45063846221743, 29.97641347911987],
            [72.8237516620847, 28.961591701772054],
            [71.77766564320032, 27.913180243434525],
            [70.61649620960193, 27.989196275335868],
            [69.51439293811313, 26.940965684511372],
            [70.16892662952202, 26.491871649678842],
            [70.2828731627256, 25.72222870533983],
            [70.84469933460284, 25.21510203704352],
            [71.04324018746823, 24.3565239527302],
            [68.84259931831878, 24.35913361256094],
            [68.1766451353734, 23.69196503345671],
            [67.44366661974547, 23.94484365487699],
            [67.14544192898907, 24.663611151624647],
            [66.37282758979327, 25.42514089609385],
            [64.53040774929113, 25.23703868255143],
            [62.90570071803461, 25.21840932871021],
            [61.49736290878419, 25.0782370061185],
            [61.87418745305655, 26.239974880472104],
            [63.31663170761959, 26.756532497661667],
            [63.2338977395203, 27.21704702403071],
            [62.75542565292986, 27.378923448184988],
            [62.72783043808599, 28.25964488373539],
            [61.77186811711863, 28.6993338078908],
            [61.36930870956494, 29.303276272085924],
            [60.87424848820879, 29.829238999952608],
            [62.54985680527278, 29.31857249604431],
            [63.55026085801117, 29.468330796826166],
            [64.14800215033125, 29.340819200145972],
            [64.35041873561852, 29.560030625928093],
            [65.0468620136161, 29.472180691031905],
            [66.34647260932442, 29.887943427036177],
            [66.38145755398602, 30.738899237586452],
            [66.93889122911847, 31.304911200479353],
            [67.68339358914747, 31.30315420178142],
            [67.79268924344478, 31.58293040620963],
            [68.55693200060932, 31.713310044882018],
            [68.92667687365767, 31.620189113892067],
            [69.31776411324256, 31.901412258424443],
            [69.26252200712256, 32.5019440780883],
            [69.68714725126486, 33.105498969041236],
            [70.3235941913716, 33.35853261975839],
            [69.9305432473596, 34.02012014417511],
            [70.8818030129884, 33.98885590263852],
            [71.15677330921346, 34.34891144463215],
            [71.11501875192164, 34.733125718722235],
            [71.61307620635071, 35.153203436822864],
            [71.49876793812109, 35.650563259416],
            [71.26234826038575, 36.074387518857804],
            [71.84629194528392, 36.50994232842986],
            [72.92002485544447, 36.72000702569632],
            [74.06755171091783, 36.83617564548845],
            [74.57589277537298, 37.02084137628346],
            [75.15802778514092, 37.13303091078912],
            [75.89689741405013, 36.666806138651836],
            [76.19284834178569, 35.89840342868783],
            [77.83745079947457, 35.494009507787766]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'THA.geojson',
        iso_a2: 'TH',
        label_y: 15.45974,
        label_x: 101.073198,
        name: 'Thailand',
        formal_en: 'Kingdom of Thailand'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [105.21877689007889, 14.273211778210694],
            [104.28141808473661, 14.416743068901367],
            [102.98842207236163, 14.225721136934467],
            [102.34809939983302, 13.394247341358223],
            [102.5849324890267, 12.186594956913282],
            [101.68715783081996, 12.645740057826572],
            [100.83180952352487, 12.627084865769206],
            [100.9784672383692, 13.412721665902566],
            [100.09779747925111, 13.406856390837433],
            [100.01873253784456, 12.307001044153354],
            [99.47892052612363, 10.846366685423547],
            [99.15377241414316, 9.963061428258555],
            [99.22239871622676, 9.239255479362427],
            [99.87383182169813, 9.20786204674512],
            [100.27964684448622, 8.295152899606052],
            [100.45927412313276, 7.429572658717177],
            [101.01732791545273, 6.856868597842478],
            [101.62307905477806, 6.74062246340192],
            [102.14118696493638, 6.221636053894628],
            [101.81428185425798, 5.810808417174242],
            [101.15421878459387, 5.691384182147715],
            [101.07551557821336, 6.204867051615921],
            [100.25959638875696, 6.642824815289543],
            [100.0857568705271, 6.464489447450291],
            [99.69069054565576, 6.848212795433597],
            [99.51964155476963, 7.34345388430276],
            [98.9882528015123, 7.907993068875328],
            [98.503786248776, 8.382305202666288],
            [98.339661899817, 7.794511623562386],
            [98.15000939330582, 8.350007432483878],
            [98.25915001830626, 8.973922837759801],
            [98.55355065307305, 9.932959906448545],
            [99.03812055867398, 10.960545762572437],
            [99.58728600463972, 11.892762762901697],
            [99.19635379435167, 12.80474843998867],
            [99.21201175333609, 13.269293728076464],
            [99.09775516153876, 13.827502549693278],
            [98.43081912637987, 14.622027696180837],
            [98.1920740091914, 15.123702500870351],
            [98.53737592976572, 15.308497422746084],
            [98.90334842325676, 16.17782420497612],
            [98.49376102091135, 16.83783559820793],
            [97.85912275593486, 17.567946071843664],
            [97.37589643757354, 18.445437730375815],
            [97.79778283080441, 18.627080389881755],
            [98.25372399291561, 19.708203029860044],
            [98.95967573445488, 19.752980658440947],
            [99.54330936075931, 20.186597601802063],
            [100.11598758341785, 20.417849636308187],
            [100.54888105672688, 20.109237982661128],
            [100.60629357300316, 19.508344427971224],
            [101.2820146016517, 19.462584947176765],
            [101.03593143107777, 18.408928330961615],
            [101.05954756063517, 17.51249725999449],
            [102.11359175009248, 18.109101670804165],
            [102.41300499879162, 17.932781683824288],
            [102.99870568238771, 17.9616946476916],
            [103.20019209189374, 18.309632066312773],
            [103.9564766784853, 18.24095408779688],
            [104.7169470560925, 17.42885895433008],
            [104.7793205098688, 16.44186493577145],
            [105.58903852745016, 15.570316066952858],
            [105.54433841351769, 14.723933620660418],
            [105.21877689007889, 14.273211778210694]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KWT.geojson',
        iso_a2: 'KW',
        label_y: 29.413628,
        label_x: 47.313999,
        name: 'Kuwait',
        formal_en: 'State of Kuwait'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [47.974519077349896, 29.975819200148504],
            [48.18318851094449, 29.534476630159762],
            [48.09394331237642, 29.306299343375002],
            [48.416094191283946, 28.55200429942667],
            [47.708850538937384, 28.526062730416143],
            [47.45982181172283, 29.002519436147224],
            [46.568713413281756, 29.09902517345229],
            [47.30262210469096, 30.059069932570722],
            [47.974519077349896, 29.975819200148504]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TLS.geojson',
        iso_a2: 'TL',
        label_y: -8.803705,
        label_x: 125.854679,
        name: 'Timor-Leste',
        formal_en: 'Democratic Republic of Timor-Leste'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [124.96868248911623, -8.892790215697083],
            [125.08624637258026, -8.65688730228468],
            [125.94707238169826, -8.432094821815035],
            [126.64470421763855, -8.398246758663852],
            [126.95724328013983, -8.273344821814398],
            [127.33592817597463, -8.397316582882603],
            [126.96799197805655, -8.668256117388893],
            [125.9258850444586, -9.106007175333353],
            [125.08852013560109, -9.393173109579294],
            [125.07001997284061, -9.089987481322872],
            [124.96868248911623, -8.892790215697083]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BRN.geojson',
        iso_a2: 'BN',
        label_y: 4.448298,
        label_x: 114.551943,
        name: 'Brunei',
        formal_en: 'Negara Brunei Darussalam'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [115.45071048386981, 5.447729803891534],
            [115.40570031134361, 4.955227565933839],
            [115.34746097215066, 4.316636053887009],
            [114.8695573263154, 4.348313706881925],
            [114.65959598191353, 4.007636826997754],
            [114.20401655482837, 4.525873928236805],
            [114.59996137904872, 4.900011298029966],
            [115.45071048386981, 5.447729803891534]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MMR.geojson',
        iso_a2: 'MM',
        label_y: 21.573855,
        label_x: 95.804497,
        name: 'Myanmar',
        formal_en: 'Republic of the Union of Myanmar'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [100.11598758341785, 20.417849636308187],
            [99.54330936075931, 20.186597601802063],
            [98.95967573445488, 19.752980658440947],
            [98.25372399291561, 19.708203029860044],
            [97.79778283080441, 18.627080389881755],
            [97.37589643757354, 18.445437730375815],
            [97.85912275593486, 17.567946071843664],
            [98.49376102091135, 16.83783559820793],
            [98.90334842325676, 16.17782420497612],
            [98.53737592976572, 15.308497422746084],
            [98.1920740091914, 15.123702500870351],
            [98.43081912637987, 14.622027696180837],
            [99.09775516153876, 13.827502549693278],
            [99.21201175333609, 13.269293728076464],
            [99.19635379435167, 12.80474843998867],
            [99.58728600463972, 11.892762762901697],
            [99.03812055867398, 10.960545762572437],
            [98.55355065307305, 9.932959906448545],
            [98.45717410684873, 10.67526601810515],
            [98.76454552612078, 11.441291612183749],
            [98.42833865762985, 12.032986761925685],
            [98.50957400919268, 13.122377631070677],
            [98.1036039571077, 13.640459703012851],
            [97.77773237507517, 14.837285874892642],
            [97.59707156778276, 16.10056793869977],
            [97.1645398294998, 16.92873444260934],
            [96.505768670643, 16.42724050543285],
            [95.3693522481124, 15.7143899601826],
            [94.80840457558412, 15.80345429123764],
            [94.18880415240454, 16.037936102762018],
            [94.53348595579135, 17.277240301985728],
            [94.32481652219676, 18.2135139022499],
            [93.54098839719364, 19.366492621330025],
            [93.66325483599621, 19.726961574781996],
            [93.07827762245219, 19.855144965081976],
            [92.36855350135562, 20.670883287025347],
            [92.30323449093868, 21.47548533780982],
            [92.65225711463799, 21.324047552978485],
            [92.67272098182556, 22.041238918541254],
            [93.16612755734837, 22.278459580977103],
            [93.06029422401463, 22.70311066333557],
            [93.28632693885928, 23.043658352139005],
            [93.3251876159428, 24.078556423432204],
            [94.10674197792507, 23.85074087167348],
            [94.55265791217164, 24.675238348890336],
            [94.60324913938538, 25.162495428970402],
            [95.1551534362626, 26.001307277932085],
            [95.12476769407496, 26.5735720891323],
            [96.41936567585097, 27.264589341739224],
            [97.1339990580153, 27.083773505149964],
            [97.0519885599681, 27.69905894623315],
            [97.40256147663614, 27.882536119085444],
            [97.32711388549004, 28.26158274994634],
            [97.91198774616944, 28.335945136014345],
            [98.2462309102333, 27.74722138112918],
            [98.68269005737046, 27.50881216075062],
            [98.71209394734451, 26.743535874940267],
            [98.67183800658916, 25.918702500913525],
            [97.72460900267914, 25.083637193293],
            [97.60471967976198, 23.897404690033042],
            [98.66026248575577, 24.063286037689966],
            [98.89874922078278, 23.14272207284253],
            [99.5319922220874, 22.94903880461258],
            [99.24089887898725, 22.11831431730458],
            [99.98348921102149, 21.7429367131364],
            [100.41653771362738, 21.558839423096614],
            [101.15003299357825, 21.849984442629022],
            [101.18000532430754, 21.436572984294028],
            [100.32910119018953, 20.786121731036232],
            [100.11598758341785, 20.417849636308187]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BGD.geojson',
        iso_a2: 'BD',
        label_y: 24.214956,
        label_x: 89.684963,
        name: 'Bangladesh',
        formal_en: "People's Republic of Bangladesh"
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [92.67272098182556, 22.041238918541254],
            [92.65225711463799, 21.324047552978485],
            [92.30323449093868, 21.47548533780982],
            [92.36855350135562, 20.670883287025347],
            [92.08288618364614, 21.19219513598577],
            [92.02521528520839, 21.701569729086767],
            [91.83489098507744, 22.182935695885565],
            [91.41708702999766, 22.76501902922122],
            [90.49600630082728, 22.80501658781513],
            [90.58695682166098, 22.392793687422866],
            [90.27297081905556, 21.83636770272011],
            [89.84746707556428, 22.039146023033425],
            [89.70204959509493, 21.857115790285306],
            [89.41886274613549, 21.9661789006373],
            [89.03196129756623, 22.055708319582976],
            [88.87631188350309, 22.87914642993783],
            [88.52976972855379, 23.631141872649167],
            [88.69994022009092, 24.23371491138856],
            [88.08442223506242, 24.501657212821925],
            [88.30637251175602, 24.866079413344206],
            [88.93155398962308, 25.238692328384776],
            [88.2097892598025, 25.768065700782714],
            [88.56304935094977, 26.446525580342723],
            [89.35509402868729, 26.014407253518073],
            [89.83248091019962, 25.96508209889548],
            [89.92069258012185, 25.26974986419218],
            [90.87221072791212, 25.132600612889547],
            [91.79959598182208, 25.147431748957317],
            [92.37620161333481, 24.976692816664965],
            [91.91509280799443, 24.130413723237112],
            [91.46772993364368, 24.072639471934792],
            [91.15896325069973, 23.50352692310439],
            [91.70647505083211, 22.985263983649187],
            [91.86992760617132, 23.624346421802784],
            [92.14603478390681, 23.627498684172593],
            [92.67272098182556, 22.041238918541254]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'AFG.geojson',
        iso_a2: 'AF',
        label_y: 34.164262,
        label_x: 66.496586,
        name: 'Afghanistan',
        formal_en: 'Islamic State of Afghanistan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [66.51860680528867, 37.36278432875879],
            [67.07578209825962, 37.35614390720929],
            [67.82999962755952, 37.144994004864685],
            [68.13556237170138, 37.02311513930431],
            [68.85944583524594, 37.344335842430596],
            [69.19627282092438, 37.15114350030743],
            [69.51878543485796, 37.60899669041342],
            [70.11657840361033, 37.58822276463209],
            [70.27057417184014, 37.735164699854025],
            [70.3763041523093, 38.13839590102752],
            [70.80682050973289, 38.486281643216415],
            [71.34813113799026, 38.25890534113216],
            [71.23940392444817, 37.953265082341886],
            [71.54191775908478, 37.905774441065645],
            [71.44869347523024, 37.06564484308052],
            [71.8446382994506, 36.73817129164692],
            [72.1930408059624, 36.948287665345674],
            [72.63688968291729, 37.047558091778356],
            [73.26005577992501, 37.495256862939],
            [73.9486959166465, 37.4215662704908],
            [74.98000247589542, 37.419990139305895],
            [75.15802778514092, 37.13303091078912],
            [74.57589277537298, 37.02084137628346],
            [74.06755171091783, 36.83617564548845],
            [72.92002485544447, 36.72000702569632],
            [71.84629194528392, 36.50994232842986],
            [71.26234826038575, 36.074387518857804],
            [71.49876793812109, 35.650563259416],
            [71.61307620635071, 35.153203436822864],
            [71.11501875192164, 34.733125718722235],
            [71.15677330921346, 34.34891144463215],
            [70.8818030129884, 33.98885590263852],
            [69.9305432473596, 34.02012014417511],
            [70.3235941913716, 33.35853261975839],
            [69.68714725126486, 33.105498969041236],
            [69.26252200712256, 32.5019440780883],
            [69.31776411324256, 31.901412258424443],
            [68.92667687365767, 31.620189113892067],
            [68.55693200060932, 31.713310044882018],
            [67.79268924344478, 31.58293040620963],
            [67.68339358914747, 31.30315420178142],
            [66.93889122911847, 31.304911200479353],
            [66.38145755398602, 30.738899237586452],
            [66.34647260932442, 29.887943427036177],
            [65.0468620136161, 29.472180691031905],
            [64.35041873561852, 29.560030625928093],
            [64.14800215033125, 29.340819200145972],
            [63.55026085801117, 29.468330796826166],
            [62.54985680527278, 29.31857249604431],
            [60.87424848820879, 29.829238999952608],
            [61.781221551363444, 30.735850328081238],
            [61.69931440618083, 31.379506130492672],
            [60.94194461451113, 31.548074652628753],
            [60.863654819588966, 32.18291962333443],
            [60.536077915290775, 32.98126882581157],
            [60.963700392506006, 33.52883230237626],
            [60.52842980331158, 33.676446031218006],
            [60.80319339380745, 34.40410187431986],
            [61.210817091725744, 35.650072333309225],
            [62.230651483005886, 35.270663967422294],
            [62.98466230657661, 35.40404083916762],
            [63.19353844590035, 35.857165635718914],
            [63.98289594915871, 36.0079574651466],
            [64.5464791197339, 36.31207326918427],
            [64.7461051776774, 37.111817735333304],
            [65.58894778835784, 37.30521678318564],
            [65.74563073106683, 37.66116404881207],
            [66.21738488145934, 37.39379018813392],
            [66.51860680528867, 37.36278432875879]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TKM.geojson',
        iso_a2: 'TM',
        label_y: 39.855246,
        label_x: 58.676647,
        name: 'Turkmenistan',
        formal_en: 'Turkmenistan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [52.50245975119615, 41.78331553808637],
            [52.944293247291654, 42.11603424739759],
            [54.07941775901495, 42.32410940202083],
            [54.75534549339264, 42.043971462566574],
            [55.45525109235377, 41.25985911718584],
            [55.96819135928291, 41.30864166926936],
            [57.0963912290791, 41.32231008561057],
            [56.932215203687804, 41.826026109375604],
            [57.78652998233708, 42.17055288346552],
            [58.62901085799146, 42.75155101172305],
            [59.976422153569786, 42.22308197689021],
            [60.083340691981675, 41.425146185871405],
            [60.465952996670694, 41.22032664648255],
            [61.54717898951356, 41.266370347654615],
            [61.88271406438469, 41.084856879229406],
            [62.374260288345006, 40.05388621679039],
            [63.51801476426103, 39.36325653742564],
            [64.17022301621677, 38.892406724598246],
            [65.2159989765074, 38.4026950139843],
            [66.54615034370022, 37.97468496352687],
            [66.51860680528867, 37.36278432875879],
            [66.21738488145934, 37.39379018813392],
            [65.74563073106683, 37.66116404881207],
            [65.58894778835784, 37.30521678318564],
            [64.7461051776774, 37.111817735333304],
            [64.5464791197339, 36.31207326918427],
            [63.98289594915871, 36.0079574651466],
            [63.19353844590035, 35.857165635718914],
            [62.98466230657661, 35.40404083916762],
            [62.230651483005886, 35.270663967422294],
            [61.210817091725744, 35.650072333309225],
            [61.123070509694145, 36.491597194966246],
            [60.37763797388388, 36.52738312432837],
            [59.23476199731681, 37.41298798273034],
            [58.436154412678206, 37.5223094752438],
            [57.330433790928986, 38.02922943781094],
            [56.61936608259282, 38.121394354803485],
            [56.18037479027333, 37.93512665460743],
            [55.51157840355191, 37.96411713312317],
            [54.800303989486565, 37.392420762678185],
            [53.92159793479556, 37.19891836196126],
            [53.73551110211252, 37.90613617609169],
            [53.880928582581845, 38.95209300389536],
            [53.1010278664329, 39.29057363540713],
            [53.35780805849123, 39.97528636327445],
            [52.69397260926982, 40.03362905533197],
            [52.915251092343624, 40.87652334244473],
            [53.858139275941134, 40.63103445084218],
            [54.73684533063215, 40.95101491959346],
            [54.008310988181314, 41.55121084244742],
            [53.72171349469059, 42.12319143327003],
            [52.916749708880076, 41.86811656347733],
            [52.81468875510362, 41.13537059179471],
            [52.50245975119615, 41.78331553808637]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'JOR.geojson',
        iso_a2: 'JO',
        label_y: 30.805025,
        label_x: 36.375991,
        name: 'Jordan',
        formal_en: 'Hashemite Kingdom of Jordan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [35.54566531753454, 32.393992011030576],
            [35.71991824722275, 32.709192409794866],
            [36.834062127435544, 32.312937526980775],
            [38.792340529136084, 33.378686428352225],
            [39.19546837744497, 32.16100881604267],
            [39.00488569515255, 32.01021698661498],
            [37.00216556168101, 31.508412990844747],
            [37.998848911294374, 30.508499864213135],
            [37.66811974462638, 30.3386652694859],
            [37.503581984209035, 30.003776150018407],
            [36.74052778498725, 29.86528331147619],
            [36.50121422704359, 29.505253607698705],
            [36.06894087092206, 29.197494615184453],
            [34.95603722508426, 29.356554673778845],
            [34.92260257339143, 29.501326198844524],
            [35.420918409981965, 31.100065822874356],
            [35.397560662586045, 31.489086005167582],
            [35.5452519060762, 31.78250478772084],
            [35.54566531753454, 32.393992011030576]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NPL.geojson',
        iso_a2: 'NP',
        label_y: 28.297925,
        label_x: 83.639914,
        name: 'Nepal',
        formal_en: 'Nepal'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [88.12044070836987, 27.876541652939594],
            [88.04313276566123, 27.445818589786825],
            [88.17480431514092, 26.81040517832595],
            [88.06023766474982, 26.41461538340249],
            [87.2274719583663, 26.397898057556077],
            [86.02439293817918, 26.63098460540857],
            [85.25177859898338, 26.726198431906344],
            [84.6750179381738, 27.234901231387536],
            [83.30424889519955, 27.36450572357556],
            [81.99998742058497, 27.925479234319994],
            [81.05720258985203, 28.416095282499043],
            [80.08842451367627, 28.79447011974014],
            [80.4767212259174, 29.72986522065534],
            [81.11125613802932, 30.183480943313402],
            [81.52580447787474, 30.42271698660863],
            [82.32751264845088, 30.115268052688137],
            [83.33711510613719, 29.463731594352197],
            [83.89899295444673, 29.320226141877658],
            [84.23457970575015, 28.839893703724698],
            [85.01163821812304, 28.642773952747344],
            [85.82331994013151, 28.203575954698707],
            [86.9545170430006, 27.974261786403517],
            [88.12044070836987, 27.876541652939594]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'YEM.geojson',
        iso_a2: 'YE',
        label_y: 15.328226,
        label_x: 45.874383,
        name: 'Yemen',
        formal_en: 'Republic of Yemen'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [52.00000980002224, 19.000003363516058],
            [52.78218427919205, 17.349742336491232],
            [53.10857262554751, 16.651051133688952],
            [52.38520592632588, 16.382411200419654],
            [52.19172936382509, 15.93843313238402],
            [52.1681649107, 15.597420355689948],
            [51.172515089732485, 15.175249742081492],
            [49.57457645040315, 14.708766587782748],
            [48.67923058451416, 14.00320241948566],
            [48.23894738138742, 13.948089504446372],
            [47.938914015500785, 14.007233181204427],
            [47.354453566279716, 13.592219753468383],
            [46.717076450391744, 13.39969920496502],
            [45.87759280781027, 13.347764390511685],
            [45.62505008319988, 13.290946153206763],
            [45.406458774605255, 13.026905422411433],
            [45.14435591002086, 12.95393830001531],
            [44.989533318874415, 12.69958690027471],
            [44.49457645038285, 12.721652736863348],
            [44.17511274595449, 12.585950425664876],
            [43.48295861183713, 12.636800035040084],
            [43.22287112811213, 13.220950425667425],
            [43.25144819516953, 13.767583726450852],
            [43.08794396339806, 14.06263031662131],
            [42.892245314308724, 14.802249253798749],
            [42.60487267433362, 15.213335272680595],
            [42.80501549660005, 15.261962795467255],
            [42.70243777850066, 15.718885809791999],
            [42.823670688657415, 15.911742255105267],
            [42.77933230975097, 16.347891343648683],
            [43.21837527850275, 16.66688996018641],
            [43.11579756040336, 17.088440456607373],
            [43.380794305196105, 17.57998668056767],
            [43.79151858905192, 17.31997671149111],
            [44.06261315285508, 17.410358791569593],
            [45.21665123879719, 17.433328965723334],
            [45.39999922056876, 17.333335069238558],
            [46.366658563020536, 17.233315334537636],
            [46.74999433776165, 17.283338120996177],
            [47.000004917189756, 16.949999294497445],
            [47.46669477721763, 17.116681626854884],
            [48.18334354024134, 18.166669216377315],
            [49.11667158386487, 18.616667588774945],
            [52.00000980002224, 19.000003363516058]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CYN.geojson',
        iso_a2: '-99',
        label_y: 35.216071,
        label_x: 33.692434,
        name: 'N. Cyprus',
        formal_en: 'Turkish Republic of Northern Cyprus'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.73178022637745, 35.14002594658844],
            [32.80247358575275, 35.14550364841138],
            [32.946960890440806, 35.3867033961337],
            [33.667227003724946, 35.37321584730552],
            [34.576473829900465, 35.67159556735879],
            [33.900804477684204, 35.245755927057616],
            [33.97361657078346, 35.058506374648005],
            [33.86643965021011, 35.09359467217419],
            [33.675391880027064, 35.01786286065045],
            [33.5256852556775, 35.03868846286407],
            [33.47581749851585, 35.000344550103506],
            [33.45592207208347, 35.10142365166641],
            [33.3838334490363, 35.16271190036457],
            [33.19097700372305, 35.17312470147138],
            [32.919572381326134, 35.08783274997364],
            [32.73178022637745, 35.14002594658844]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PHL.geojson',
        iso_a2: 'PH',
        label_y: 11.198,
        label_x: 122.465,
        name: 'Philippines',
        formal_en: 'Republic of the Philippines'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [120.83389611214656, 12.704496161342433],
              [120.32343631396745, 13.466413479053825],
              [121.18012820850211, 13.429697373910443],
              [121.5273938335035, 13.06959015548452],
              [121.26219038298159, 12.205560207564403],
              [120.83389611214656, 12.704496161342433]
            ]
          ],
          [
            [
              [122.5860889018671, 9.981044826696134],
              [122.83708133350875, 10.261156927934225],
              [122.9474105164519, 10.88186839440806],
              [123.49884972543845, 10.94062449792392],
              [123.33777428598472, 10.267383938025404],
              [124.0779358257012, 11.232725531453738],
              [123.98243777882584, 10.278778591345755],
              [123.62318322153271, 9.950090643753299],
              [123.30992068897939, 9.318268744336706],
              [122.99588300994166, 9.022188625520414],
              [122.38005496631942, 9.713360907424217],
              [122.5860889018671, 9.981044826696134]
            ]
          ],
          [
            [
              [126.37681359263745, 8.414706325713297],
              [126.47851281138789, 7.750354112168978],
              [126.53742394420061, 7.189380601424545],
              [126.19677290253259, 6.274294338400054],
              [125.83142052622907, 7.293715318221842],
              [125.36385216685221, 6.786485297060949],
              [125.68316084198369, 6.049656887227272],
              [125.39651167206063, 5.581003322772276],
              [124.21978763234239, 6.161355495626154],
              [123.9387195171069, 6.885135606306136],
              [124.24366214406128, 7.360610459823661],
              [123.61021243702749, 7.83352732994274],
              [123.29607140512519, 7.418875637232759],
              [122.82550581267537, 7.457374579290204],
              [122.08549930225573, 6.899424139834836],
              [121.91992801319256, 7.192119452336015],
              [122.31235884001705, 8.034962063016465],
              [122.94239790251959, 8.316236883981134],
              [123.48768761606347, 8.69300975182118],
              [123.84115441293983, 8.240324204944372],
              [124.60146976125021, 8.514157619659002],
              [124.76461225799562, 8.960409450715488],
              [125.47139082245155, 8.986996975129657],
              [125.41211795461277, 9.760334784377534],
              [126.2227144715431, 9.286074327018866],
              [126.30663699758514, 8.78248749433456],
              [126.37681359263745, 8.414706325713297]
            ]
          ],
          [
            [
              [118.50458092659036, 9.316382554558047],
              [117.17427453010066, 8.367499904814679],
              [117.66447716682137, 9.066888739452892],
              [118.38691369026175, 9.68449961998921],
              [118.98734215706105, 10.376292019080523],
              [119.51149620979751, 11.3696680770272],
              [119.68967654833989, 10.554291490109875],
              [119.02945844937892, 10.003653265823829],
              [118.50458092659036, 9.316382554558047]
            ]
          ],
          [
            [
              [122.336956821788, 18.224882717354106],
              [122.17427941293317, 17.810282701076403],
              [122.5156539246533, 17.093504746971973],
              [122.25231082569393, 16.26244436285407],
              [121.66278608610821, 15.931017564350142],
              [121.50506961475342, 15.124813544164622],
              [121.7288285665772, 14.328376369682275],
              [122.25892540902726, 14.21820221603599],
              [122.70127566944569, 14.336541245984378],
              [123.9502950379403, 13.782130642141027],
              [123.85510704965867, 13.237771104378425],
              [124.18128869028493, 12.9975273706535],
              [124.0774190613783, 12.536676947474575],
              [123.29803510955225, 13.02752553959894],
              [122.92865197152994, 13.552919826710422],
              [122.6713550151487, 13.185836289925092],
              [122.03464969288052, 13.784481919810304],
              [121.12638471891857, 13.636687323455547],
              [120.62863732308324, 13.857655747935596],
              [120.67938357959386, 14.271015529838294],
              [120.99181928923053, 14.52539276779504],
              [120.69333621631267, 14.756670640517312],
              [120.56414513558299, 14.396279201713796],
              [120.07042850146644, 14.970869452367126],
              [119.92092858284605, 15.406346747290739],
              [119.88377322802819, 16.363704331929995],
              [120.28648766487875, 16.034628811095345],
              [120.39004723519167, 17.599081122299523],
              [120.71586714079191, 18.505227362537525],
              [121.32130822152351, 18.504064642810945],
              [121.9376013530364, 18.218552354398355],
              [122.24600630095429, 18.478949896717097],
              [122.336956821788, 18.224882717354106]
            ]
          ],
          [
            [
              [122.03837039600555, 11.415840969279998],
              [121.88354780485909, 11.891755072471994],
              [122.48382124236149, 11.582187404827538],
              [123.12021650603594, 11.583660183147856],
              [123.10083784392646, 11.16593374271649],
              [122.63771365772669, 10.74130849857417],
              [122.00261030485957, 10.441016750526089],
              [121.96736697803652, 10.905691229694625],
              [122.03837039600555, 11.415840969279998]
            ]
          ],
          [
            [
              [125.50255171112354, 12.162694606978292],
              [125.78346479706221, 11.046121934447783],
              [125.01188398651226, 11.311454576050409],
              [125.03276126515817, 10.975816148314692],
              [125.27744917206019, 10.358722032101284],
              [124.80181928924577, 10.134678859899864],
              [124.76016808481853, 10.837995103392258],
              [124.45910119028605, 10.889929917845592],
              [124.30252160044171, 11.495370998577187],
              [124.89101281138153, 11.415582587118536],
              [124.87799035044401, 11.794189968304947],
              [124.26676150929569, 12.557760931849671],
              [125.22711632700788, 12.53572093347718],
              [125.50255171112354, 12.162694606978292]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LKA.geojson',
        iso_a2: 'LK',
        label_y: 7.581097,
        label_x: 80.704823,
        name: 'Sri Lanka',
        formal_en: 'Democratic Socialist Republic of Sri Lanka'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [81.7879590188914, 7.523055324733164],
            [81.63732221876059, 6.481775214051922],
            [81.21801964714433, 6.197141424988288],
            [80.34835696810441, 5.968369859232155],
            [79.87246870312853, 6.76346344647493],
            [79.69516686393513, 8.200843410673386],
            [80.14780073437964, 9.824077663609557],
            [80.83881798698656, 9.268426825391188],
            [81.30431928907177, 8.56420624433369],
            [81.7879590188914, 7.523055324733164]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TWN.geojson',
        iso_a2: 'CN-TW',
        label_y: 23.652408,
        label_x: 120.868204,
        name: 'Taiwan',
        formal_en: null
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [121.77781782438993, 24.3942735865194],
            [121.17563235889274, 22.790857245367167],
            [120.74707970589623, 21.970571397382113],
            [120.22008344938368, 22.81486094816674],
            [120.1061885926124, 23.556262722258236],
            [120.69467980355225, 24.538450832613737],
            [121.49504438688878, 25.295458889257386],
            [121.95124393116146, 24.997595933527037],
            [121.77781782438993, 24.3942735865194]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'JPN.geojson',
        iso_a2: 'JP',
        label_y: 36.142538,
        label_x: 138.44217,
        name: 'Japan',
        formal_en: 'Japan'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [141.88460086483497, 39.180864569651476],
              [140.95948937394581, 38.17400096287662],
              [140.97638756730527, 37.14207428644019],
              [140.5997697287621, 36.3439834661245],
              [140.77407433488258, 35.842877102190215],
              [140.2532792502451, 35.138113918593646],
              [138.9755277853962, 34.66760000257614],
              [137.21759891169125, 34.60628591566183],
              [135.79298302626893, 33.46480520276663],
              [135.1209827007454, 33.849071153289],
              [135.0794348491827, 34.596544908174806],
              [133.34031619683202, 34.375938218720805],
              [132.15677086805124, 33.90493337659652],
              [130.98614464734345, 33.88576142021624],
              [132.00003624890996, 33.149992377244544],
              [131.33279015515734, 31.450354519164822],
              [130.686317987186, 31.029579169228246],
              [130.2024198752049, 31.418237616495432],
              [130.44767622286213, 32.319474595665696],
              [129.81469160371893, 32.61030955660436],
              [129.40846316947255, 33.29605581311752],
              [130.35393517468464, 33.60415070244167],
              [130.87845096244718, 34.23274282484002],
              [131.8842293641439, 34.74971385348792],
              [132.61767296766243, 35.433393052709405],
              [134.6083008159777, 35.731617743465804],
              [135.67753787652885, 35.52713410088687],
              [136.72383060114242, 37.304984239240326],
              [137.39061160700447, 36.82739065199884],
              [138.8576021669062, 37.82748464614346],
              [139.42640465714283, 38.21596222589761],
              [140.054790073812, 39.43880748143637],
              [139.88337934789985, 40.56331248632368],
              [140.30578250545364, 41.19500519465953],
              [141.3689734234266, 41.378559882160275],
              [141.91426313697048, 39.991616115878685],
              [141.88460086483497, 39.180864569651476]
            ]
          ],
          [
            [
              [144.61342654843963, 43.96088288021748],
              [145.32082523008307, 44.38473297787541],
              [145.5431372418027, 43.26208832455062],
              [144.05966189999987, 42.98835826270056],
              [143.18384972551723, 41.9952147486992],
              [141.6114909201724, 42.6787905950561],
              [141.06728641170668, 41.58459381770797],
              [139.955106235921, 41.56955597591103],
              [139.81754357315998, 42.563758856774385],
              [140.31208703019325, 43.33327261003269],
              [141.38054894426, 43.38882477474644],
              [141.67195234595386, 44.77212535255146],
              [141.96764489152798, 45.55148346616134],
              [143.14287031470974, 44.51035838477697],
              [143.91016198137947, 44.17409983985374],
              [144.61342654843963, 43.96088288021748]
            ]
          ],
          [
            [
              [132.37117638563024, 33.46364248304006],
              [132.92437259331473, 34.06029857028204],
              [133.4929683778222, 33.94462087659667],
              [133.90410607313635, 34.36493113864263],
              [134.63842817600386, 34.149233710256354],
              [134.76637902235854, 33.80633474378362],
              [134.2034159689709, 33.20117788342964],
              [133.79295006727654, 33.5219851750976],
              [133.28026818250885, 33.28957042086489],
              [133.0148580262578, 32.70456736910474],
              [132.36311486219267, 32.989382025681394],
              [132.37117638563024, 33.46364248304006]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CHL.geojson',
        iso_a2: 'CL',
        label_y: -38.151771,
        label_x: -72.318871,
        name: 'Chile',
        formal_en: 'Republic of Chile'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.63401022758323, -52.63637045887449],
              [-68.63335, -54.8695],
              [-67.56244, -54.87001],
              [-66.95992, -54.89681],
              [-67.29103, -55.30124],
              [-68.14863, -55.61183],
              [-68.63999081081187, -55.58001799908692],
              [-69.2321, -55.49906],
              [-69.95809, -55.19843],
              [-71.00568, -55.05383],
              [-72.2639, -54.49514],
              [-73.2852, -53.95752],
              [-74.66253, -52.83749],
              [-73.8381, -53.04743],
              [-72.43418, -53.7154],
              [-71.10773, -54.07433],
              [-70.59177999999986, -53.61583],
              [-70.26748, -52.93123],
              [-69.34565, -52.5183],
              [-68.63401022758323, -52.63637045887449]
            ]
          ],
          [
            [
              [-69.59042375352405, -17.580011895419332],
              [-69.10024695501949, -18.260125420812678],
              [-68.96681840684187, -18.981683444904107],
              [-68.44222510443092, -19.40506845467143],
              [-68.75716712103375, -20.372657972904463],
              [-68.21991309271128, -21.494346612231865],
              [-67.82817989772273, -22.872918796482175],
              [-67.1066735500636, -22.735924574476417],
              [-66.9852339341777, -22.98634856536284],
              [-67.32844295924417, -24.02530323659095],
              [-68.41765296087614, -24.51855478281688],
              [-68.38600114609736, -26.185016371365215],
              [-68.59479977077268, -26.506908868111296],
              [-68.29554155137043, -26.89933969493578],
              [-69.00123491074825, -27.52121388113618],
              [-69.65613033718317, -28.459141127233686],
              [-70.01355038112992, -29.367922865518572],
              [-69.91900834825194, -30.33633920666828],
              [-70.53506893581951, -31.36501026787031],
              [-70.0743993801536, -33.09120981214805],
              [-69.81477698431922, -33.273886000299825],
              [-69.81730912950152, -34.1935714657983],
              [-70.38804948594913, -35.16968759535949],
              [-70.36476925320164, -36.00508879978992],
              [-71.12188066270987, -36.65812387466232],
              [-71.11862504747549, -37.57682748794724],
              [-70.81466427273469, -38.55299529394074],
              [-71.41351660834906, -38.91602223079114],
              [-71.68076127794649, -39.808164157878046],
              [-71.91573401557763, -40.83233936947069],
              [-71.7468037584155, -42.05138640723598],
              [-72.14889807807856, -42.254888197601375],
              [-71.91542395698389, -43.40856454851745],
              [-71.46405615913051, -43.787611179378345],
              [-71.79362260607193, -44.207172133156064],
              [-71.32980078803622, -44.407521661151655],
              [-71.22277889675976, -44.784242852559416],
              [-71.65931555854536, -44.973688653341426],
              [-71.55200944689128, -45.5607329241771],
              [-71.91725847033024, -46.88483814879177],
              [-72.44735531278027, -47.73853281025352],
              [-72.33116085477201, -48.2442383766618],
              [-72.64824744331494, -48.87861825947683],
              [-73.4154357571201, -49.31843637471297],
              [-73.32805091011453, -50.378785088909915],
              [-72.97574683296469, -50.741450290734285],
              [-72.30997351753234, -50.67700977966632],
              [-72.32940385607407, -51.42595631287243],
              [-71.91480383979638, -52.0090223058659],
              [-69.49836218939609, -52.14276091263727],
              [-68.57154537624133, -52.299443855346226],
              [-69.46128434922667, -52.29195077266391],
              [-69.9427795071062, -52.53793059037322],
              [-70.8451016913546, -52.89920052852571],
              [-71.00633216010525, -53.83325204220132],
              [-71.429794684521, -53.85645476030037],
              [-72.55794287788488, -53.53141000118449],
              [-73.7027567206629, -52.835069268607235],
              [-73.7027567206629, -52.835070076051494],
              [-74.94676347522517, -52.262753588419],
              [-75.2600260077785, -51.62935475037325],
              [-74.97663245308988, -51.0433956846157],
              [-75.47975419788355, -50.37837167745158],
              [-75.60801510283198, -48.67377288187184],
              [-75.18276974150216, -47.7119194476232],
              [-74.1265809801047, -46.93925343199511],
              [-75.64439531116545, -46.64764332457207],
              [-74.69215369332312, -45.76397633238103],
              [-74.35170935738425, -44.10304412208794],
              [-73.24035600451522, -44.454960625995604],
              [-72.7178039211798, -42.38335580827898],
              [-73.38889990913822, -42.117532240569574],
              [-73.70133561877488, -43.365776462579774],
              [-74.33194312203261, -43.22495818458442],
              [-74.0179571194272, -41.79481292090683],
              [-73.67709937202999, -39.94221282324317],
              [-73.21759253609065, -39.25868865331856],
              [-73.50555945503712, -38.282882582351114],
              [-73.58806087919109, -37.15628468195598],
              [-73.1667170884993, -37.12378020604439],
              [-72.55313696968174, -35.50884002049106],
              [-71.86173214383263, -33.90909270603153],
              [-71.4384504869299, -32.41889942803078],
              [-71.66872066922247, -30.920644626592495],
              [-71.37008256700773, -30.09568206148503],
              [-71.48989437527645, -28.861442152625923],
              [-70.90512386746161, -27.640379734001247],
              [-70.72495398627599, -25.705924167587256],
              [-70.40396582709502, -23.628996677344574],
              [-70.09124589708074, -21.39331918710126],
              [-70.16441972520605, -19.756468194256165],
              [-70.37257239447771, -18.34797535570887],
              [-69.85844356960587, -18.092693780187012],
              [-69.59042375352405, -17.580011895419332]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BOL.geojson',
        iso_a2: 'BO',
        label_y: -16.666015,
        label_x: -64.593433,
        name: 'Bolivia',
        formal_en: 'Plurinational State of Bolivia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.52967810736496, -10.951734307502194],
            [-68.78615759954948, -11.03638030359628],
            [-68.27125362819326, -11.01452117273682],
            [-68.04819230820539, -10.712059014532485],
            [-67.17380123561074, -10.306812432499612],
            [-66.6469083319628, -9.931331475466862],
            [-65.33843522811642, -9.761987806846392],
            [-65.44483700220539, -10.511451104375432],
            [-65.32189876978302, -10.895872084194679],
            [-65.40228146021303, -11.566270440317155],
            [-64.3163529120316, -12.461978041232193],
            [-63.19649878605057, -12.627032565972435],
            [-62.803060268796386, -13.000653171442686],
            [-62.127080857986385, -13.198780612849724],
            [-61.71320431176078, -13.489202162330052],
            [-61.08412126325565, -13.479383640194598],
            [-60.503304002511136, -13.775954685117659],
            [-60.45919816755003, -14.354007256734555],
            [-60.26432634137737, -14.645979099183641],
            [-60.251148851142936, -15.07721892665932],
            [-60.54296566429515, -15.093910414289596],
            [-60.158389655179036, -16.258283786690086],
            [-58.24121985536668, -16.299573256091293],
            [-58.38805843772404, -16.877109063385276],
            [-58.28080400250225, -17.271710300366017],
            [-57.734558274961, -17.55246835700777],
            [-57.49837114117099, -18.174187513911292],
            [-57.67600887717431, -18.96183969490403],
            [-57.949997321185826, -19.40000416430682],
            [-57.85380164247451, -19.96999521248619],
            [-58.166392381408045, -20.176700941653678],
            [-58.183471442280506, -19.868399346600363],
            [-59.11504248720611, -19.3569060197754],
            [-60.04356462262649, -19.342746677327426],
            [-61.78632646345377, -19.633736667562964],
            [-62.2659612697708, -20.513734633061276],
            [-62.291179368729225, -21.051634616787393],
            [-62.685057135657885, -22.249029229422387],
            [-62.84646847192156, -22.03498544686945],
            [-63.986838141522476, -21.99364430103595],
            [-64.37702104354226, -22.79809132252354],
            [-64.96489213729461, -22.075861504812327],
            [-66.27333940292485, -21.83231047942072],
            [-67.1066735500636, -22.735924574476417],
            [-67.82817989772273, -22.872918796482175],
            [-68.21991309271128, -21.494346612231865],
            [-68.75716712103375, -20.372657972904463],
            [-68.44222510443092, -19.40506845467143],
            [-68.96681840684187, -18.981683444904107],
            [-69.10024695501949, -18.260125420812678],
            [-69.59042375352405, -17.580011895419332],
            [-68.9596353827533, -16.50069793057127],
            [-69.38976416693471, -15.660129082911652],
            [-69.16034664577495, -15.323973890853019],
            [-69.33953467474701, -14.953195489158832],
            [-68.9488866848366, -14.453639418193283],
            [-68.92922380234954, -13.602683607643009],
            [-68.88007951523997, -12.899729099176653],
            [-68.66507971868963, -12.561300144097173],
            [-69.52967810736496, -10.951734307502194]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PER.geojson',
        iso_a2: 'PE',
        label_y: -12.976679,
        label_x: -72.90016,
        name: 'Peru',
        formal_en: 'Republic of Peru'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.89363521999663, -4.298186944194327],
            [-70.7947688463023, -4.251264743673303],
            [-70.92884334988358, -4.401591485210368],
            [-71.74840572781655, -4.593982842633011],
            [-72.89192765978726, -5.274561455916981],
            [-72.9645072089412, -5.741251315944893],
            [-73.21971126981461, -6.089188734566078],
            [-73.1200274319236, -6.629930922068239],
            [-73.72448666044164, -6.91859547285064],
            [-73.7234014553635, -7.340998630404414],
            [-73.98723548042966, -7.523829847853065],
            [-73.57105933296707, -8.424446709835834],
            [-73.01538265653255, -9.032833347208062],
            [-73.22671342639016, -9.462212823121234],
            [-72.56303300646564, -9.520193780152717],
            [-72.18489071316985, -10.053597914269432],
            [-71.30241227892154, -10.079436130415374],
            [-70.48189388699117, -9.490118096558845],
            [-70.54868567572841, -11.009146823778465],
            [-70.0937522040469, -11.123971856331012],
            [-69.52967810736496, -10.951734307502194],
            [-68.66507971868963, -12.561300144097173],
            [-68.88007951523997, -12.899729099176653],
            [-68.92922380234954, -13.602683607643009],
            [-68.9488866848366, -14.453639418193283],
            [-69.33953467474701, -14.953195489158832],
            [-69.16034664577495, -15.323973890853019],
            [-69.38976416693471, -15.660129082911652],
            [-68.9596353827533, -16.50069793057127],
            [-69.59042375352405, -17.580011895419332],
            [-69.85844356960587, -18.092693780187012],
            [-70.37257239447771, -18.34797535570887],
            [-71.37525021023693, -17.773798516513857],
            [-71.46204077827113, -17.363487644116383],
            [-73.44452958850042, -16.359362888252996],
            [-75.23788265654144, -15.265682875227782],
            [-76.00920508492995, -14.649286390850321],
            [-76.42346920439775, -13.823186944232432],
            [-76.25924150257417, -13.535039157772943],
            [-77.10619238962184, -12.22271615972082],
            [-78.09215287953464, -10.377712497604065],
            [-79.03695309112695, -8.386567884965892],
            [-79.44592037628485, -7.93083342858386],
            [-79.76057817251005, -7.194340915560084],
            [-80.53748165558608, -6.541667575713717],
            [-81.24999630402642, -6.136834405139183],
            [-80.92634680858244, -5.690556735866565],
            [-81.41094255239946, -4.736764825055459],
            [-81.09966956248937, -4.036394138203697],
            [-80.30256059438722, -3.404856459164713],
            [-80.18401485870967, -3.821161797708044],
            [-80.46929460317695, -4.059286797708999],
            [-80.44224199087216, -4.425724379090674],
            [-80.02890804718561, -4.346090996928893],
            [-79.62497921417618, -4.454198093283495],
            [-79.20528906931773, -4.959128513207389],
            [-78.63989722361234, -4.547784112164074],
            [-78.45068396677564, -3.873096612161376],
            [-77.83790483265861, -3.003020521663103],
            [-76.63539425322672, -2.608677666843818],
            [-75.54499569365204, -1.56160979574588],
            [-75.23372270374195, -0.911416924649529],
            [-75.37322323271385, -0.15203175212045],
            [-75.10662451852008, -0.05720549886486],
            [-74.44160051135597, -0.530820000819887],
            [-74.12239518908906, -1.002832533373848],
            [-73.6595035468346, -1.260491224781134],
            [-73.07039221870724, -2.308954359550953],
            [-72.32578650581365, -2.434218031426454],
            [-71.7747607082854, -2.169789727388938],
            [-71.41364579942979, -2.342802422702128],
            [-70.81347571479196, -2.256864515800743],
            [-70.04770850287485, -2.725156345229699],
            [-70.69268205430971, -3.742872002785859],
            [-70.39404395209499, -3.766591485207825],
            [-69.89363521999663, -4.298186944194327]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ARG.geojson',
        iso_a2: 'AR',
        label_y: -33.501159,
        label_x: -64.173331,
        name: 'Argentina',
        formal_en: 'Argentine Republic'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-68.63401022758323, -52.63637045887449],
              [-68.25, -53.1],
              [-67.75, -53.85],
              [-66.45, -54.45],
              [-65.05, -54.7],
              [-65.5, -55.2],
              [-66.45, -55.25],
              [-66.95992, -54.89681],
              [-67.56244, -54.87001],
              [-68.63335, -54.8695],
              [-68.63401022758323, -52.63637045887449]
            ]
          ],
          [
            [
              [-57.62513342958296, -30.21629485445426],
              [-57.87493730328188, -31.016556084926208],
              [-58.14244035504076, -32.044503676076154],
              [-58.13264767112145, -33.040566908502015],
              [-58.349611172098875, -33.26318897881541],
              [-58.42707414410439, -33.909454441057576],
              [-58.49544206402655, -34.43148976007008],
              [-57.22582963726366, -35.28802662530788],
              [-57.36235877137878, -35.977390232081476],
              [-56.73748735210545, -36.41312590916655],
              [-56.78828528504836, -36.901571547189334],
              [-57.74915686708346, -38.18387053807989],
              [-59.23185706240189, -38.720220228837235],
              [-61.23744523786564, -38.9284245745412],
              [-62.33595699731013, -38.827707208004334],
              [-62.125763108962936, -39.42410491308485],
              [-62.330530971919494, -40.17258635840034],
              [-62.145994432205214, -40.67689666113672],
              [-62.745802781816984, -41.0287614886121],
              [-63.77049475773255, -41.16678923926369],
              [-64.73208980981973, -40.80267709733515],
              [-65.11803524439158, -41.06431487402891],
              [-64.97856055363582, -42.05800099056934],
              [-64.3034079657425, -42.35901620866951],
              [-63.75594784204239, -42.043686618824495],
              [-63.458059048095876, -42.563138116222405],
              [-64.37880388045633, -42.87355844499969],
              [-65.18180396183975, -43.495380954767796],
              [-65.32882341171013, -44.501366062193696],
              [-65.5652689276616, -45.036785577169795],
              [-66.50996578638934, -45.03962778094586],
              [-67.29379391139247, -45.55189625425519],
              [-67.58054643418008, -46.30177296324257],
              [-66.59706641301729, -47.033924655953825],
              [-65.64102657740149, -47.23613453551193],
              [-65.98508826360079, -48.133289076531135],
              [-67.16617896184769, -48.697337334996945],
              [-67.81608761256643, -49.86966887797038],
              [-68.72874508327321, -50.26421843851883],
              [-69.13853919134777, -50.732510267947795],
              [-68.81556148952356, -51.771104011594126],
              [-68.14999487982038, -52.34998340612768],
              [-68.57154537624133, -52.299443855346226],
              [-69.49836218939609, -52.14276091263727],
              [-71.91480383979638, -52.0090223058659],
              [-72.32940385607407, -51.42595631287243],
              [-72.30997351753234, -50.67700977966632],
              [-72.97574683296469, -50.741450290734285],
              [-73.32805091011453, -50.378785088909915],
              [-73.4154357571201, -49.31843637471297],
              [-72.64824744331494, -48.87861825947683],
              [-72.33116085477201, -48.2442383766618],
              [-72.44735531278027, -47.73853281025352],
              [-71.91725847033024, -46.88483814879177],
              [-71.55200944689128, -45.5607329241771],
              [-71.65931555854536, -44.973688653341426],
              [-71.22277889675976, -44.784242852559416],
              [-71.32980078803622, -44.407521661151655],
              [-71.79362260607193, -44.207172133156064],
              [-71.46405615913051, -43.787611179378345],
              [-71.91542395698389, -43.40856454851745],
              [-72.14889807807856, -42.254888197601375],
              [-71.7468037584155, -42.05138640723598],
              [-71.91573401557763, -40.83233936947069],
              [-71.68076127794649, -39.808164157878046],
              [-71.41351660834906, -38.91602223079114],
              [-70.81466427273469, -38.55299529394074],
              [-71.11862504747549, -37.57682748794724],
              [-71.12188066270987, -36.65812387466232],
              [-70.36476925320164, -36.00508879978992],
              [-70.38804948594913, -35.16968759535949],
              [-69.81730912950152, -34.1935714657983],
              [-69.81477698431922, -33.273886000299825],
              [-70.0743993801536, -33.09120981214805],
              [-70.53506893581951, -31.36501026787031],
              [-69.91900834825194, -30.33633920666828],
              [-70.01355038112992, -29.367922865518572],
              [-69.65613033718317, -28.459141127233686],
              [-69.00123491074825, -27.52121388113618],
              [-68.29554155137043, -26.89933969493578],
              [-68.59479977077268, -26.506908868111296],
              [-68.38600114609736, -26.185016371365215],
              [-68.41765296087614, -24.51855478281688],
              [-67.32844295924417, -24.02530323659095],
              [-66.9852339341777, -22.98634856536284],
              [-67.1066735500636, -22.735924574476417],
              [-66.27333940292485, -21.83231047942072],
              [-64.96489213729461, -22.075861504812327],
              [-64.37702104354226, -22.79809132252354],
              [-63.986838141522476, -21.99364430103595],
              [-62.84646847192156, -22.03498544686945],
              [-62.685057135657885, -22.249029229422387],
              [-60.846564704009914, -23.880712579038292],
              [-60.02896603050403, -24.032796319273274],
              [-58.80712846539498, -24.77145924245331],
              [-57.77721716981794, -25.16233977630904],
              [-57.63366004091113, -25.60365650808164],
              [-58.61817359071975, -27.123718763947096],
              [-57.60975969097614, -27.395898532828387],
              [-56.486701626192996, -27.548499037386293],
              [-55.69584550639816, -27.387837009390864],
              [-54.78879492859505, -26.621785577096134],
              [-54.625290696823576, -25.739255466415514],
              [-54.13004960795439, -25.547639255477254],
              [-53.628348965048744, -26.124865004177472],
              [-53.64873531758789, -26.92347258881609],
              [-54.490725267135524, -27.47475676850579],
              [-55.16228634298457, -27.881915378533463],
              [-56.29089962423908, -28.852760512000895],
              [-57.62513342958296, -30.21629485445426]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SUR.geojson',
        iso_a2: 'SR',
        label_y: 4.143987,
        label_x: -55.91094,
        name: 'Suriname',
        formal_en: 'Republic of Suriname'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-54.524754197799716, 2.311848863123785],
            [-55.09758744975514, 2.523748073736613],
            [-55.569755011606, 2.421506252447131],
            [-55.973322109589375, 2.510363877773017],
            [-56.0733418442903, 2.220794989425499],
            [-55.905600145070885, 2.02199575439866],
            [-55.995698004771754, 1.817667141116601],
            [-56.539385748914555, 1.899522609866921],
            [-57.15009782573991, 2.768926906745406],
            [-57.28143347840971, 3.333491929534119],
            [-57.60156897645787, 3.334654649260685],
            [-58.04469438336068, 4.060863552258382],
            [-57.8602095200787, 4.57680105226045],
            [-57.91428890647214, 4.812626451024414],
            [-57.307245856339506, 5.073566595882227],
            [-57.14743648947689, 5.973149929219161],
            [-55.9493184067898, 5.772877915872002],
            [-55.841779751190415, 5.95312531170606],
            [-55.033250291551774, 6.025291449401664],
            [-53.9580446030709, 5.756548163267765],
            [-54.47863298197923, 4.896755682795586],
            [-54.399542202356514, 4.212611395683467],
            [-54.00693050801901, 3.620037746592558],
            [-54.181726040246275, 3.189779771330421],
            [-54.2697051662232, 2.732391669115046],
            [-54.524754197799716, 2.311848863123785]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GUY.geojson',
        iso_a2: 'GY',
        label_y: 5.124317,
        label_x: -58.942643,
        name: 'Guyana',
        formal_en: 'Co-operative Republic of Guyana'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-56.539385748914555, 1.899522609866921],
            [-56.78270423036083, 1.863710842288654],
            [-57.335822923396904, 1.948537705895759],
            [-57.66097103537737, 1.682584947105639],
            [-58.11344987652502, 1.507195135907025],
            [-58.429477098205965, 1.463941962078721],
            [-58.540012986878295, 1.268088283692521],
            [-59.03086157900265, 1.317697658692722],
            [-59.64604366722126, 1.786893825686789],
            [-59.71854570172675, 2.24963043864436],
            [-59.97452490908456, 2.755232652188056],
            [-59.815413174057866, 3.606498521332085],
            [-59.53803992373123, 3.958802598481938],
            [-59.767405768458715, 4.423502915866607],
            [-60.11100236676738, 4.574966538914083],
            [-59.980958624904886, 5.014061184098139],
            [-60.21368343773133, 5.244486395687602],
            [-60.73357418480372, 5.200277207861901],
            [-61.410302903881956, 5.959068101419618],
            [-61.13941504580795, 6.234296779806144],
            [-61.15933631045648, 6.696077378766319],
            [-60.54399919294099, 6.856584377464883],
            [-60.2956680975624, 7.043911444522919],
            [-60.637972785063766, 7.414999904810855],
            [-60.5505879380582, 7.779602972846178],
            [-59.758284878159195, 8.367034816924047],
            [-59.10168412945866, 7.999201971870492],
            [-58.48296220562806, 7.347691351750697],
            [-58.45487606467742, 6.832787380394464],
            [-58.078103196837375, 6.809093736188643],
            [-57.542218593970645, 6.321268215353356],
            [-57.14743648947689, 5.973149929219161],
            [-57.307245856339506, 5.073566595882227],
            [-57.91428890647214, 4.812626451024414],
            [-57.8602095200787, 4.57680105226045],
            [-58.04469438336068, 4.060863552258382],
            [-57.60156897645787, 3.334654649260685],
            [-57.28143347840971, 3.333491929534119],
            [-57.15009782573991, 2.768926906745406],
            [-56.539385748914555, 1.899522609866921]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BRA.geojson',
        iso_a2: 'BR',
        label_y: -12.098687,
        label_x: -49.55945,
        name: 'Brazil',
        formal_en: 'Federative Republic of Brazil'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-53.373661668498244, -33.768377780900764],
            [-53.6505439927181, -33.20200408298183],
            [-53.209588995971544, -32.727666110974724],
            [-53.78795162618219, -32.047242526987624],
            [-54.57245154480512, -31.494511407193748],
            [-55.601510179249345, -30.853878676071393],
            [-55.97324459494094, -30.883075860316303],
            [-56.976025763564735, -30.109686374636127],
            [-57.62513342958296, -30.21629485445426],
            [-56.29089962423908, -28.852760512000895],
            [-55.16228634298457, -27.881915378533463],
            [-54.490725267135524, -27.47475676850579],
            [-53.64873531758789, -26.92347258881609],
            [-53.628348965048744, -26.124865004177472],
            [-54.13004960795439, -25.547639255477254],
            [-54.625290696823576, -25.739255466415514],
            [-54.42894609233059, -25.162184747012166],
            [-54.29347632507745, -24.570799655863965],
            [-54.29295956075452, -24.02101409271073],
            [-54.65283423523513, -23.83957813893396],
            [-55.02790178080955, -24.00127369557523],
            [-55.40074723979542, -23.956935316668805],
            [-55.517639329639636, -23.571997572526637],
            [-55.610682745981144, -22.655619398694846],
            [-55.79795813660691, -22.356929620047822],
            [-56.47331743022939, -22.086300144135283],
            [-56.8815095689029, -22.28215382252148],
            [-57.937155727761294, -22.090175876557172],
            [-57.8706739976178, -20.73268767668195],
            [-58.166392381408045, -20.176700941653678],
            [-57.85380164247451, -19.96999521248619],
            [-57.949997321185826, -19.40000416430682],
            [-57.67600887717431, -18.96183969490403],
            [-57.49837114117099, -18.174187513911292],
            [-57.734558274961, -17.55246835700777],
            [-58.28080400250225, -17.271710300366017],
            [-58.38805843772404, -16.877109063385276],
            [-58.24121985536668, -16.299573256091293],
            [-60.158389655179036, -16.258283786690086],
            [-60.54296566429515, -15.093910414289596],
            [-60.251148851142936, -15.07721892665932],
            [-60.26432634137737, -14.645979099183641],
            [-60.45919816755003, -14.354007256734555],
            [-60.503304002511136, -13.775954685117659],
            [-61.08412126325565, -13.479383640194598],
            [-61.71320431176078, -13.489202162330052],
            [-62.127080857986385, -13.198780612849724],
            [-62.803060268796386, -13.000653171442686],
            [-63.19649878605057, -12.627032565972435],
            [-64.3163529120316, -12.461978041232193],
            [-65.40228146021303, -11.566270440317155],
            [-65.32189876978302, -10.895872084194679],
            [-65.44483700220539, -10.511451104375432],
            [-65.33843522811642, -9.761987806846392],
            [-66.6469083319628, -9.931331475466862],
            [-67.17380123561074, -10.306812432499612],
            [-68.04819230820539, -10.712059014532485],
            [-68.27125362819326, -11.01452117273682],
            [-68.78615759954948, -11.03638030359628],
            [-69.52967810736496, -10.951734307502194],
            [-70.0937522040469, -11.123971856331012],
            [-70.54868567572841, -11.009146823778465],
            [-70.48189388699117, -9.490118096558845],
            [-71.30241227892154, -10.079436130415374],
            [-72.18489071316985, -10.053597914269432],
            [-72.56303300646564, -9.520193780152717],
            [-73.22671342639016, -9.462212823121234],
            [-73.01538265653255, -9.032833347208062],
            [-73.57105933296707, -8.424446709835834],
            [-73.98723548042966, -7.523829847853065],
            [-73.7234014553635, -7.340998630404414],
            [-73.72448666044164, -6.91859547285064],
            [-73.1200274319236, -6.629930922068239],
            [-73.21971126981461, -6.089188734566078],
            [-72.9645072089412, -5.741251315944893],
            [-72.89192765978726, -5.274561455916981],
            [-71.74840572781655, -4.593982842633011],
            [-70.92884334988358, -4.401591485210368],
            [-70.7947688463023, -4.251264743673303],
            [-69.89363521999663, -4.298186944194327],
            [-69.44410193548961, -1.556287123219818],
            [-69.42048580593223, -1.122618503426409],
            [-69.5770653957766, -0.549991957200163],
            [-70.02065589057005, -0.185156345219539],
            [-70.01556576198931, 0.541414292804205],
            [-69.45239600287246, 0.706158758950693],
            [-69.25243404811906, 0.602650865070075],
            [-69.21863766140018, 0.985676581217433],
            [-69.80459672715773, 1.089081122233466],
            [-69.81697323269162, 1.714805202639624],
            [-67.86856502955884, 1.692455145673392],
            [-67.5378100246747, 2.03716278727633],
            [-67.2599975246736, 1.719998684084956],
            [-67.0650481838525, 1.130112209473225],
            [-66.87632585312258, 1.253360500489336],
            [-66.32576514348496, 0.724452215982012],
            [-65.54826738143757, 0.78925446207603],
            [-65.35471330428837, 1.0952822941085],
            [-64.61101192895987, 1.328730576987042],
            [-64.19930579289051, 1.49285492594602],
            [-64.08308549666609, 1.91636912679408],
            [-63.368788011311665, 2.200899562993129],
            [-63.42286739770512, 2.411067613124175],
            [-64.2699991522658, 2.497005520025567],
            [-64.40882788761792, 3.126786200366624],
            [-64.3684944322141, 3.797210394705246],
            [-64.81606401229402, 4.056445217297423],
            [-64.62865943058755, 4.14848094320925],
            [-63.88834286157416, 4.020530096854571],
            [-63.093197597899106, 3.770571193858785],
            [-62.804533047116706, 4.006965033377952],
            [-62.08542965355913, 4.162123521334308],
            [-60.96689327660154, 4.536467596856639],
            [-60.601179165271944, 4.91809804933213],
            [-60.73357418480372, 5.200277207861901],
            [-60.21368343773133, 5.244486395687602],
            [-59.980958624904886, 5.014061184098139],
            [-60.11100236676738, 4.574966538914083],
            [-59.767405768458715, 4.423502915866607],
            [-59.53803992373123, 3.958802598481938],
            [-59.815413174057866, 3.606498521332085],
            [-59.97452490908456, 2.755232652188056],
            [-59.71854570172675, 2.24963043864436],
            [-59.64604366722126, 1.786893825686789],
            [-59.03086157900265, 1.317697658692722],
            [-58.540012986878295, 1.268088283692521],
            [-58.429477098205965, 1.463941962078721],
            [-58.11344987652502, 1.507195135907025],
            [-57.66097103537737, 1.682584947105639],
            [-57.335822923396904, 1.948537705895759],
            [-56.78270423036083, 1.863710842288654],
            [-56.539385748914555, 1.899522609866921],
            [-55.995698004771754, 1.817667141116601],
            [-55.905600145070885, 2.02199575439866],
            [-56.0733418442903, 2.220794989425499],
            [-55.973322109589375, 2.510363877773017],
            [-55.569755011606, 2.421506252447131],
            [-55.09758744975514, 2.523748073736613],
            [-54.524754197799716, 2.311848863123785],
            [-54.08806250671725, 2.105556545414629],
            [-53.77852067728892, 2.376702785650082],
            [-53.554839240113544, 2.334896551925951],
            [-53.41846513529531, 2.053389187015981],
            [-52.939657151894956, 2.124857692875636],
            [-52.55642473001842, 2.504705308437053],
            [-52.249337531123956, 3.241094468596245],
            [-51.65779741067889, 4.156232408053029],
            [-51.31714636901086, 4.203490505383954],
            [-51.069771287629656, 3.650397650564031],
            [-50.508875291533656, 1.901563828942457],
            [-49.97407589374506, 1.736483465986069],
            [-49.94710079608871, 1.046189683431223],
            [-50.699251268096916, 0.222984117021682],
            [-50.38821082213214, -0.078444512536819],
            [-48.62056677915632, -0.235489190271821],
            [-48.58449662941659, -1.237805271005001],
            [-47.824956427590635, -0.5816179337628],
            [-46.566583624851226, -0.941027520352776],
            [-44.905703090990414, -1.551739597178134],
            [-44.417619187993665, -2.137750339367976],
            [-44.58158850765578, -2.691308282078524],
            [-43.418791266440195, -2.383110039889793],
            [-41.47265682632825, -2.912018324397116],
            [-39.97866533055404, -2.873054294449041],
            [-38.50038347019657, -3.700652357603396],
            [-37.2232521225352, -4.820945733258917],
            [-36.45293738457639, -5.109403578312154],
            [-35.59779578301047, -5.149504489770649],
            [-35.23538896334756, -5.464937432480247],
            [-34.89602983248683, -6.738193047719711],
            [-34.729993455533034, -7.343220716992967],
            [-35.12821204277422, -8.996401462442286],
            [-35.636966518687714, -9.649281508017815],
            [-37.046518724097, -11.040721123908803],
            [-37.68361161960736, -12.171194756725823],
            [-38.42387651218844, -13.038118584854288],
            [-38.67388709161652, -13.057652276260619],
            [-38.953275722802545, -13.793369642800023],
            [-38.88229814304965, -15.667053724838768],
            [-39.16109249526431, -17.208406670808472],
            [-39.2673392400564, -17.867746270420483],
            [-39.58352149103423, -18.262295830968938],
            [-39.76082333022764, -19.59911345792741],
            [-40.77474077001034, -20.904511814052423],
            [-40.94475623225061, -21.93731698983781],
            [-41.754164191238225, -22.370675551037458],
            [-41.98828426773656, -22.970070489190896],
            [-43.07470374202475, -22.96769337330547],
            [-44.64781185563781, -23.351959323827842],
            [-45.35213578955992, -23.796841729428582],
            [-46.47209326840554, -24.088968601174543],
            [-47.64897233742066, -24.885199069927722],
            [-48.4954581365777, -25.877024834905654],
            [-48.64100480812774, -26.623697605090932],
            [-48.474735887228654, -27.17591196056189],
            [-48.661520351747626, -28.18613453543572],
            [-48.8884574041574, -28.674115085567884],
            [-49.587329474472675, -29.224469089476337],
            [-50.696874152211485, -30.98446502047296],
            [-51.576226162306156, -31.77769825615321],
            [-52.256081305538046, -32.24536996839467],
            [-52.712099982297694, -33.19657805759118],
            [-53.373661668498244, -33.768377780900764]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'URY.geojson',
        iso_a2: 'UY',
        label_y: -32.961127,
        label_x: -55.966942,
        name: 'Uruguay',
        formal_en: 'Oriental Republic of Uruguay'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-57.62513342958296, -30.21629485445426],
            [-56.976025763564735, -30.109686374636127],
            [-55.97324459494094, -30.883075860316303],
            [-55.601510179249345, -30.853878676071393],
            [-54.57245154480512, -31.494511407193748],
            [-53.78795162618219, -32.047242526987624],
            [-53.209588995971544, -32.727666110974724],
            [-53.6505439927181, -33.20200408298183],
            [-53.373661668498244, -33.768377780900764],
            [-53.806425950726535, -34.39681487400223],
            [-54.93586605489773, -34.952646579733624],
            [-55.67408972840329, -34.75265878676407],
            [-56.21529700379607, -34.85983570733742],
            [-57.1396850246331, -34.430456231424245],
            [-57.81786068381551, -34.4625472958775],
            [-58.42707414410439, -33.909454441057576],
            [-58.349611172098875, -33.26318897881541],
            [-58.13264767112145, -33.040566908502015],
            [-58.14244035504076, -32.044503676076154],
            [-57.87493730328188, -31.016556084926208],
            [-57.62513342958296, -30.21629485445426]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ECU.geojson',
        iso_a2: 'EC',
        label_y: -1.259076,
        label_x: -78.188375,
        name: 'Ecuador',
        formal_en: 'Republic of Ecuador'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.37322323271385, -0.15203175212045],
            [-75.23372270374195, -0.911416924649529],
            [-75.54499569365204, -1.56160979574588],
            [-76.63539425322672, -2.608677666843818],
            [-77.83790483265861, -3.003020521663103],
            [-78.45068396677564, -3.873096612161376],
            [-78.63989722361234, -4.547784112164074],
            [-79.20528906931773, -4.959128513207389],
            [-79.62497921417618, -4.454198093283495],
            [-80.02890804718561, -4.346090996928893],
            [-80.44224199087216, -4.425724379090674],
            [-80.46929460317695, -4.059286797708999],
            [-80.18401485870967, -3.821161797708044],
            [-80.30256059438722, -3.404856459164713],
            [-79.77029334178093, -2.65751189535964],
            [-79.98655921092242, -2.220794366061014],
            [-80.36878394236925, -2.685158786635788],
            [-80.96776546906436, -2.246942640800704],
            [-80.76480628123804, -1.965047702648533],
            [-80.93365902375172, -1.057454522306358],
            [-80.58337032746127, -0.906662692878683],
            [-80.39932471385376, -0.283703301600141],
            [-80.02089820018037, 0.360340074053468],
            [-80.09060970734211, 0.768428859862397],
            [-79.5427620103998, 0.982937730305963],
            [-78.85525875518871, 1.380923773601822],
            [-77.85506140817952, 0.809925034992773],
            [-77.66861284047044, 0.825893052570962],
            [-77.4249843004304, 0.395686753741117],
            [-76.5763797675494, 0.256935533037435],
            [-76.29231441924097, 0.416047268064119],
            [-75.8014658271166, 0.084801337073202],
            [-75.37322323271385, -0.15203175212045]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'COL.geojson',
        iso_a2: 'CO',
        label_y: 3.373111,
        label_x: -73.174347,
        name: 'Colombia',
        formal_en: 'Republic of Colombia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-66.87632585312258, 1.253360500489336],
            [-67.0650481838525, 1.130112209473225],
            [-67.2599975246736, 1.719998684084956],
            [-67.5378100246747, 2.03716278727633],
            [-67.86856502955884, 1.692455145673392],
            [-69.81697323269162, 1.714805202639624],
            [-69.80459672715773, 1.089081122233466],
            [-69.21863766140018, 0.985676581217433],
            [-69.25243404811906, 0.602650865070075],
            [-69.45239600287246, 0.706158758950693],
            [-70.01556576198931, 0.541414292804205],
            [-70.02065589057005, -0.185156345219539],
            [-69.5770653957766, -0.549991957200163],
            [-69.42048580593223, -1.122618503426409],
            [-69.44410193548961, -1.556287123219818],
            [-69.89363521999663, -4.298186944194327],
            [-70.39404395209499, -3.766591485207825],
            [-70.69268205430971, -3.742872002785859],
            [-70.04770850287485, -2.725156345229699],
            [-70.81347571479196, -2.256864515800743],
            [-71.41364579942979, -2.342802422702128],
            [-71.7747607082854, -2.169789727388938],
            [-72.32578650581365, -2.434218031426454],
            [-73.07039221870724, -2.308954359550953],
            [-73.6595035468346, -1.260491224781134],
            [-74.12239518908906, -1.002832533373848],
            [-74.44160051135597, -0.530820000819887],
            [-75.10662451852008, -0.05720549886486],
            [-75.37322323271385, -0.15203175212045],
            [-75.8014658271166, 0.084801337073202],
            [-76.29231441924097, 0.416047268064119],
            [-76.5763797675494, 0.256935533037435],
            [-77.4249843004304, 0.395686753741117],
            [-77.66861284047044, 0.825893052570962],
            [-77.85506140817952, 0.809925034992773],
            [-78.85525875518871, 1.380923773601822],
            [-78.99093522817104, 1.691369940595251],
            [-78.61783138702371, 1.766404120283056],
            [-78.66211808949785, 2.267355454920477],
            [-78.42761043975733, 2.629555568854215],
            [-77.93154252797149, 2.696605739752926],
            [-77.51043128122501, 3.325016994638247],
            [-77.12768978545526, 3.849636135265357],
            [-77.49627193877703, 4.087606105969428],
            [-77.3076012844794, 4.667984117039452],
            [-77.53322058786573, 5.582811997902497],
            [-77.31881507028675, 5.84535411216136],
            [-77.47666073272228, 6.691116441266303],
            [-77.88157141794525, 7.223771267114785],
            [-77.7534138658614, 7.709839789252143],
            [-77.43110795765699, 7.638061224798734],
            [-77.24256649444008, 7.935278225125444],
            [-77.47472286651133, 8.524286200388218],
            [-77.35336076527386, 8.67050466555807],
            [-76.83667395700357, 8.638749497914716],
            [-76.08638383655786, 9.336820583529487],
            [-75.67460018584006, 9.443248195834599],
            [-75.66470414905618, 9.774003200718738],
            [-75.48042599150335, 10.618990383339309],
            [-74.90689510771199, 11.083044745320322],
            [-74.27675269234489, 11.102035834187587],
            [-74.1972226630477, 11.310472723836867],
            [-73.41476396350029, 11.22701528568548],
            [-72.62783525255963, 11.731971543825523],
            [-72.23819495307892, 11.955549628136326],
            [-71.75409013536864, 12.437303168177309],
            [-71.3998223537917, 12.376040757695293],
            [-71.13746110704588, 12.112981879113505],
            [-71.3315836249503, 11.776284084515808],
            [-71.97392167833829, 11.60867157637712],
            [-72.22757544624294, 11.10870209395324],
            [-72.61465776232521, 10.821975409381778],
            [-72.9052860175347, 10.450344346554772],
            [-73.02760413276957, 9.736770331252444],
            [-73.30495154488005, 9.151999823437606],
            [-72.7887298245004, 9.085027167187334],
            [-72.6604947577681, 8.625287787302682],
            [-72.43986223009796, 8.405275376820029],
            [-72.36090064155597, 8.002638454617895],
            [-72.47967892117885, 7.632506008327354],
            [-72.44448727078807, 7.423784898300482],
            [-72.19835242378188, 7.340430813013683],
            [-71.96017574734864, 6.991614895043539],
            [-70.67423356798152, 7.087784735538719],
            [-70.09331295437242, 6.96037649172311],
            [-69.38947994655712, 6.099860541198836],
            [-68.98531856960236, 6.206804917826858],
            [-68.26505245631823, 6.153268133972475],
            [-67.69508724635502, 6.267318020040647],
            [-67.34143958196557, 6.095468044454023],
            [-67.52153194850275, 5.556870428891969],
            [-67.74469662135522, 5.221128648291668],
            [-67.82301225449355, 4.503937282728899],
            [-67.62183590358129, 3.839481716319995],
            [-67.33756384954368, 3.542342230641722],
            [-67.30317318385345, 3.31845408773718],
            [-67.8099381171237, 2.820655015469569],
            [-67.44709204778631, 2.600280869960869],
            [-67.18129431829307, 2.250638129074062],
            [-66.87632585312258, 1.253360500489336]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PRY.geojson',
        iso_a2: 'PY',
        label_y: -21.674509,
        label_x: -60.146394,
        name: 'Paraguay',
        formal_en: 'Republic of Paraguay'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-58.166392381408045, -20.176700941653678],
            [-57.8706739976178, -20.73268767668195],
            [-57.937155727761294, -22.090175876557172],
            [-56.8815095689029, -22.28215382252148],
            [-56.47331743022939, -22.086300144135283],
            [-55.79795813660691, -22.356929620047822],
            [-55.610682745981144, -22.655619398694846],
            [-55.517639329639636, -23.571997572526637],
            [-55.40074723979542, -23.956935316668805],
            [-55.02790178080955, -24.00127369557523],
            [-54.65283423523513, -23.83957813893396],
            [-54.29295956075452, -24.02101409271073],
            [-54.29347632507745, -24.570799655863965],
            [-54.42894609233059, -25.162184747012166],
            [-54.625290696823576, -25.739255466415514],
            [-54.78879492859505, -26.621785577096134],
            [-55.69584550639816, -27.387837009390864],
            [-56.486701626192996, -27.548499037386293],
            [-57.60975969097614, -27.395898532828387],
            [-58.61817359071975, -27.123718763947096],
            [-57.63366004091113, -25.60365650808164],
            [-57.77721716981794, -25.16233977630904],
            [-58.80712846539498, -24.77145924245331],
            [-60.02896603050403, -24.032796319273274],
            [-60.846564704009914, -23.880712579038292],
            [-62.685057135657885, -22.249029229422387],
            [-62.291179368729225, -21.051634616787393],
            [-62.2659612697708, -20.513734633061276],
            [-61.78632646345377, -19.633736667562964],
            [-60.04356462262649, -19.342746677327426],
            [-59.11504248720611, -19.3569060197754],
            [-58.183471442280506, -19.868399346600363],
            [-58.166392381408045, -20.176700941653678]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'VEN.geojson',
        iso_a2: 'VE',
        label_y: 7.182476,
        label_x: -64.599381,
        name: 'Venezuela',
        formal_en: 'Bolivarian Republic of Venezuela'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-60.73357418480372, 5.200277207861901],
            [-60.601179165271944, 4.91809804933213],
            [-60.96689327660154, 4.536467596856639],
            [-62.08542965355913, 4.162123521334308],
            [-62.804533047116706, 4.006965033377952],
            [-63.093197597899106, 3.770571193858785],
            [-63.88834286157416, 4.020530096854571],
            [-64.62865943058755, 4.14848094320925],
            [-64.81606401229402, 4.056445217297423],
            [-64.3684944322141, 3.797210394705246],
            [-64.40882788761792, 3.126786200366624],
            [-64.2699991522658, 2.497005520025567],
            [-63.42286739770512, 2.411067613124175],
            [-63.368788011311665, 2.200899562993129],
            [-64.08308549666609, 1.91636912679408],
            [-64.19930579289051, 1.49285492594602],
            [-64.61101192895987, 1.328730576987042],
            [-65.35471330428837, 1.0952822941085],
            [-65.54826738143757, 0.78925446207603],
            [-66.32576514348496, 0.724452215982012],
            [-66.87632585312258, 1.253360500489336],
            [-67.18129431829307, 2.250638129074062],
            [-67.44709204778631, 2.600280869960869],
            [-67.8099381171237, 2.820655015469569],
            [-67.30317318385345, 3.31845408773718],
            [-67.33756384954368, 3.542342230641722],
            [-67.62183590358129, 3.839481716319995],
            [-67.82301225449355, 4.503937282728899],
            [-67.74469662135522, 5.221128648291668],
            [-67.52153194850275, 5.556870428891969],
            [-67.34143958196557, 6.095468044454023],
            [-67.69508724635502, 6.267318020040647],
            [-68.26505245631823, 6.153268133972475],
            [-68.98531856960236, 6.206804917826858],
            [-69.38947994655712, 6.099860541198836],
            [-70.09331295437242, 6.96037649172311],
            [-70.67423356798152, 7.087784735538719],
            [-71.96017574734864, 6.991614895043539],
            [-72.19835242378188, 7.340430813013683],
            [-72.44448727078807, 7.423784898300482],
            [-72.47967892117885, 7.632506008327354],
            [-72.36090064155597, 8.002638454617895],
            [-72.43986223009796, 8.405275376820029],
            [-72.6604947577681, 8.625287787302682],
            [-72.7887298245004, 9.085027167187334],
            [-73.30495154488005, 9.151999823437606],
            [-73.02760413276957, 9.736770331252444],
            [-72.9052860175347, 10.450344346554772],
            [-72.61465776232521, 10.821975409381778],
            [-72.22757544624294, 11.10870209395324],
            [-71.97392167833829, 11.60867157637712],
            [-71.3315836249503, 11.776284084515808],
            [-71.36000566271082, 11.539993597861212],
            [-71.94704993354651, 11.423282375530022],
            [-71.62086829292019, 10.969459947142795],
            [-71.63306393094109, 10.446494452349029],
            [-72.07417395698451, 9.865651353388373],
            [-71.69564409044654, 9.072263088411248],
            [-71.26455929226773, 9.137194525585983],
            [-71.03999935574339, 9.859992784052409],
            [-71.35008378771079, 10.211935126176215],
            [-71.40062333849224, 10.968969021036015],
            [-70.15529883490652, 11.37548167566004],
            [-70.29384334988103, 11.846822414594214],
            [-69.94324459499683, 12.162307033736099],
            [-69.58430009629747, 11.459610907431212],
            [-68.88299923366445, 11.443384507691563],
            [-68.23327145045873, 10.885744126829946],
            [-68.19412655299763, 10.554653225135922],
            [-67.29624854192633, 10.54586823164631],
            [-66.227864142508, 10.648626817258688],
            [-65.65523759628175, 10.200798855017323],
            [-64.89045223657817, 10.0772146671913],
            [-64.32947872583374, 10.38959870039568],
            [-64.31800655786495, 10.64141795495398],
            [-63.07932247582873, 10.7017243514386],
            [-61.880946010980196, 10.715625311725104],
            [-62.73011898461641, 10.420268662960906],
            [-62.388511928950976, 9.94820445397464],
            [-61.58876746280194, 9.873066921422264],
            [-60.83059668643172, 9.381339829948942],
            [-60.67125240745973, 8.580174261911878],
            [-60.15009558779618, 8.602756862823426],
            [-59.758284878159195, 8.367034816924047],
            [-60.5505879380582, 7.779602972846178],
            [-60.637972785063766, 7.414999904810855],
            [-60.2956680975624, 7.043911444522919],
            [-60.54399919294099, 6.856584377464883],
            [-61.15933631045648, 6.696077378766319],
            [-61.13941504580795, 6.234296779806144],
            [-61.410302903881956, 5.959068101419618],
            [-60.73357418480372, 5.200277207861901]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'FLK.geojson',
        iso_a2: 'FK',
        label_y: -51.608913,
        label_x: -58.738602,
        name: 'Falkland Is.',
        formal_en: 'Falkland Islands'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-61.2, -51.85],
            [-60, -51.25],
            [-59.15, -51.5],
            [-58.55, -51.1],
            [-57.75, -51.55],
            [-58.05, -51.9],
            [-59.4, -52.2],
            [-59.85, -51.85],
            [-60.7, -52.3],
            [-61.2, -51.85]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ETH.geojson',
        iso_a2: 'ET',
        label_y: 8.032795,
        label_x: 39.0886,
        name: 'Ethiopia',
        formal_en: 'Federal Democratic Republic of Ethiopia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [47.78942, 8.003],
            [44.9636, 5.00162],
            [43.66087, 4.95755],
            [42.76967, 4.25259],
            [42.12861, 4.23413],
            [41.85508309264397, 3.918911920483727],
            [41.1718, 3.91909],
            [40.76848, 4.25702],
            [39.85494, 3.83879],
            [39.55938425876585, 3.42206],
            [38.89251, 3.50074],
            [38.67114, 3.61607],
            [38.43697, 3.58851],
            [38.120915, 3.598605],
            [36.85509323800812, 4.447864127672769],
            [36.159078632855646, 4.447864127672769],
            [35.817447662353516, 4.77696566346189],
            [35.817447662353516, 5.338232082790797],
            [35.29800711823298, 5.506],
            [34.70702, 6.594220000000121],
            [34.25032, 6.82607],
            [34.0751, 7.22595],
            [33.568290000000104, 7.71334],
            [32.95418, 7.784970000000101],
            [33.29480000000012, 8.35458],
            [33.82550000000015, 8.37916],
            [33.97498, 8.68456],
            [33.96162, 9.58358],
            [34.25745, 10.63009],
            [34.73115000000013, 10.910170000000107],
            [34.83163000000013, 11.318960000000118],
            [35.26049, 12.08286],
            [35.86363, 12.57828],
            [36.27022, 13.563330000000121],
            [36.42951, 14.42211],
            [37.59377, 14.2131],
            [37.90607000000011, 14.959430000000168],
            [38.51295, 14.50547],
            [39.0994, 14.74064],
            [39.34061, 14.53155],
            [40.02625000000012, 14.51959],
            [40.8966, 14.118640000000141],
            [41.1552, 13.77333],
            [41.59856, 13.452090000000112],
            [42.00975, 12.86582],
            [42.35156000000012, 12.542230000000131],
            [42.000000000000114, 12.100000000000136],
            [41.66176000000013, 11.6312],
            [41.73959000000019, 11.355110000000138],
            [41.755570000000205, 11.050910000000101],
            [42.31414000000012, 11.0342],
            [42.55493000000013, 11.105110000000195],
            [42.77685184100096, 10.92687856693442],
            [42.55876, 10.57258000000013],
            [42.92812, 10.021940000000143],
            [43.29699000000011, 9.540480000000173],
            [43.67875, 9.18358000000012],
            [46.94834, 7.99688],
            [47.78942, 8.003]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SSD.geojson',
        iso_a2: 'SS',
        label_y: 7.230477,
        label_x: 30.390151,
        name: 'S. Sudan',
        formal_en: 'Republic of South Sudan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.833852421715427, 3.509171604222463],
            [29.953500197069474, 4.173699042167684],
            [29.71599531425602, 4.600804755060153],
            [29.1590784034465, 4.389267279473231],
            [28.696677687298802, 4.455077215996937],
            [28.428993768026913, 4.287154649264494],
            [27.97997724784281, 4.408413397637375],
            [27.37422610851749, 5.233944403500061],
            [27.21340905122517, 5.550953477394557],
            [26.465909458123235, 5.94671743410187],
            [26.213418409945117, 6.546603298362072],
            [25.79664798351118, 6.979315904158071],
            [25.124130893664727, 7.500085150579437],
            [25.11493248871679, 7.825104071479174],
            [24.567369012152085, 8.229187933785468],
            [23.886979580860668, 8.619729712933065],
            [24.19406772118765, 8.728696472403897],
            [24.53741516360202, 8.91753756573172],
            [24.794925745412684, 9.810240916008695],
            [25.069603699343986, 10.273759963267992],
            [25.790633328413946, 10.411098940233728],
            [25.962307049621018, 10.136420986302426],
            [26.477328213242515, 9.552730334198088],
            [26.752006167173818, 9.466893473594496],
            [27.112520981708883, 9.638567194801624],
            [27.833550610778786, 9.60423245056029],
            [27.970889587744352, 9.398223985111656],
            [28.966597170745786, 9.398223985111656],
            [29.000931914987177, 9.60423245056029],
            [29.515953078608618, 9.793073543888056],
            [29.61895731133285, 10.084918869940225],
            [29.996639497988554, 10.290927335388687],
            [30.837840731903384, 9.70723668328452],
            [31.35286189552488, 9.810240916008695],
            [31.850715687025513, 10.531270545078826],
            [32.400071594888345, 11.080626452941488],
            [32.31423473428475, 11.68148447716652],
            [32.073891524594785, 11.973329803218519],
            [32.67474954881965, 12.02483191958072],
            [32.743419037302544, 12.248007757149992],
            [33.206938084561784, 12.179338268667095],
            [33.086766479716744, 11.441141267476496],
            [33.206938084561784, 10.720111638406593],
            [33.72195924818311, 10.325262079630193],
            [33.84213085302815, 9.981914637215993],
            [33.82496348090751, 9.484060845715362],
            [33.963392794971185, 9.464285229420625],
            [33.97498, 8.68456],
            [33.82550000000015, 8.37916],
            [33.29480000000012, 8.35458],
            [32.95418, 7.784970000000101],
            [33.568290000000104, 7.71334],
            [34.0751, 7.22595],
            [34.25032, 6.82607],
            [34.70702, 6.594220000000121],
            [35.29800711823298, 5.506],
            [34.62019626785388, 4.847122742081988],
            [34.005, 4.249884947362048],
            [33.3900000000001, 3.79],
            [32.68642, 3.79232],
            [31.88145, 3.55827],
            [31.24556, 3.7819],
            [30.833852421715427, 3.509171604222463]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SOM.geojson',
        iso_a2: 'SO',
        label_y: 3.568925,
        label_x: 45.19238,
        name: 'Somalia',
        formal_en: 'Federal Republic of Somalia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [41.58513, -1.68325],
            [40.993, -0.85829],
            [40.98105, 2.78452],
            [41.85508309264397, 3.918911920483727],
            [42.12861, 4.23413],
            [42.76967, 4.25259],
            [43.66087, 4.95755],
            [44.9636, 5.00162],
            [47.78942, 8.003],
            [48.48673587422695, 8.837626247589995],
            [48.93812951029645, 9.451748968946617],
            [48.93823286316103, 9.973500067581512],
            [48.938491245322496, 10.982327378783467],
            [48.94200524271835, 11.394266058798138],
            [48.94820475850974, 11.410617281697963],
            [48.94820475850985, 11.41061728169797],
            [49.26776, 11.43033],
            [49.72862, 11.5789],
            [50.25878, 11.67957],
            [50.73202, 12.0219],
            [51.1112, 12.02464],
            [51.13387, 11.74815],
            [51.04153, 11.16651],
            [51.04531, 10.6409],
            [50.83418, 10.27972],
            [50.55239, 9.19874],
            [50.07092, 8.08173],
            [49.4527, 6.80466],
            [48.59455, 5.33911],
            [47.74079, 4.2194],
            [46.56476, 2.85529],
            [45.56399, 2.04576],
            [44.06815, 1.05283],
            [43.13597, 0.2922],
            [42.04157, -0.91916],
            [41.81095, -1.44647],
            [41.58513, -1.68325]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'KEN.geojson',
        iso_a2: 'KE',
        label_y: 0.549043,
        label_x: 37.907632,
        name: 'Kenya',
        formal_en: 'Republic of Kenya'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [39.20222, -4.67677],
            [37.7669, -3.67712],
            [37.69869, -3.09699],
            [34.07262, -1.05982],
            [33.90371119710453, -0.95],
            [33.893568969666944, 0.109813537861896],
            [34.18, 0.515],
            [34.6721, 1.17694],
            [35.03599, 1.90584],
            [34.59607, 3.053740000000118],
            [34.47913, 3.5556],
            [34.005, 4.249884947362048],
            [34.62019626785388, 4.847122742081988],
            [35.29800711823298, 5.506],
            [35.817447662353516, 5.338232082790797],
            [35.817447662353516, 4.77696566346189],
            [36.159078632855646, 4.447864127672769],
            [36.85509323800812, 4.447864127672769],
            [38.120915, 3.598605],
            [38.43697, 3.58851],
            [38.67114, 3.61607],
            [38.89251, 3.50074],
            [39.55938425876585, 3.42206],
            [39.85494, 3.83879],
            [40.76848, 4.25702],
            [41.1718, 3.91909],
            [41.85508309264397, 3.918911920483727],
            [40.98105, 2.78452],
            [40.993, -0.85829],
            [41.58513, -1.68325],
            [40.88477, -2.08255],
            [40.63785, -2.49979],
            [40.26304, -2.57309],
            [40.12119, -3.27768],
            [39.80006, -3.68116],
            [39.60489, -4.34653],
            [39.20222, -4.67677]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MWI.geojson',
        iso_a2: 'MW',
        label_y: -13.386737,
        label_x: 33.608082,
        name: 'Malawi',
        formal_en: 'Republic of Malawi'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.75937544122132, -9.23059905358906],
            [33.73972, -9.41715],
            [33.940837724096525, -9.693673841980285],
            [34.28, -10.16],
            [34.55998904799935, -11.520020033415925],
            [34.28000613784198, -12.280025323132506],
            [34.55998904799935, -13.579997653866876],
            [34.907151320136165, -13.565424899960568],
            [35.26795617039801, -13.887834161029566],
            [35.68684533055594, -14.611045830954332],
            [35.77190473810836, -15.896858819240727],
            [35.339062941231646, -16.10744028083011],
            [35.033810255683534, -16.801299737213093],
            [34.38129194513405, -16.183559665596043],
            [34.307291294092096, -15.478641452702597],
            [34.51766604995231, -15.013708591372612],
            [34.45963341648854, -14.613009535381423],
            [34.064825473778626, -14.35995004644812],
            [33.789700148256685, -14.45183074306307],
            [33.214024692525214, -13.971860039936153],
            [32.68816531752313, -13.712857761289277],
            [32.991764357237884, -12.783870537978274],
            [33.306422153463075, -12.435778090060218],
            [33.114289178201915, -11.607198174692314],
            [33.315310499817286, -10.796549981329697],
            [33.48568769708359, -10.525558770391115],
            [33.2313879737753, -9.6767216935648],
            [32.75937544122132, -9.23059905358906]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TZA.geojson',
        iso_a2: 'TZ',
        label_y: -6.051866,
        label_x: 34.959183,
        name: 'Tanzania',
        formal_en: 'United Republic of Tanzania'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.90371119710453, -0.95],
            [34.07262, -1.05982],
            [37.69869, -3.09699],
            [37.7669, -3.67712],
            [39.20222, -4.67677],
            [38.74054, -5.90895],
            [38.79977, -6.47566],
            [39.44, -6.839999999999861],
            [39.47000000000014, -7.1],
            [39.19469, -7.7039],
            [39.25203, -8.00781],
            [39.18652, -8.48551],
            [39.53574, -9.112369999999885],
            [39.9496, -10.0984],
            [40.316586229110854, -10.317097752817492],
            [40.31659, -10.317099999999868],
            [39.521, -10.89688],
            [38.42755659358775, -11.285202325081656],
            [37.82764, -11.26879],
            [37.47129, -11.56876],
            [36.775150994622805, -11.594537448780805],
            [36.51408165868426, -11.720938002166735],
            [35.31239790216904, -11.439146416879147],
            [34.55998904799935, -11.520020033415925],
            [34.28, -10.16],
            [33.940837724096525, -9.693673841980285],
            [33.73972, -9.41715],
            [32.75937544122132, -9.23059905358906],
            [32.19186486179194, -8.930358981973257],
            [31.556348097466497, -8.762048841998642],
            [31.15775133695005, -8.594578747317366],
            [30.740009731422095, -8.34000593035372],
            [30.74001549655179, -8.340007419470915],
            [30.199996779101696, -7.079980970898163],
            [29.620032179490014, -6.520015150583426],
            [29.419992710088167, -5.939998874539434],
            [29.519986606572928, -5.419978936386315],
            [29.339997592900346, -4.499983412294092],
            [29.753512404099865, -4.452389418153302],
            [30.11632, -4.09012],
            [30.50554, -3.56858],
            [30.75224, -3.35931],
            [30.74301, -3.03431],
            [30.52766, -2.80762],
            [30.469673645761223, -2.41385475710134],
            [30.46967, -2.41383],
            [30.75830895358311, -2.287250257988369],
            [30.816134881317712, -1.698914076345389],
            [30.419104852019245, -1.134659112150416],
            [30.769860000000108, -1.01455],
            [31.86617, -1.02736],
            [33.90371119710453, -0.95]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SOL.geojson',
        iso_a2: '-99',
        label_y: 9.443889,
        label_x: 46.731595,
        name: 'Somaliland',
        formal_en: 'Republic of Somaliland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [48.94820475850985, 11.41061728169797],
            [48.94820475850974, 11.410617281697963],
            [48.94200524271835, 11.394266058798138],
            [48.938491245322496, 10.982327378783467],
            [48.93823286316103, 9.973500067581512],
            [48.93812951029645, 9.451748968946617],
            [48.48673587422695, 8.837626247589995],
            [47.78942, 8.003],
            [46.94834, 7.99688],
            [43.67875, 9.18358000000012],
            [43.29699000000011, 9.540480000000173],
            [42.92812, 10.021940000000143],
            [42.55876, 10.57258000000013],
            [42.77685184100096, 10.92687856693442],
            [43.14530480324214, 11.462039699748857],
            [43.470659620951665, 11.277709865763882],
            [43.66666832863484, 10.86416921634816],
            [44.11780358254282, 10.445538438351605],
            [44.614259067570856, 10.442205308468942],
            [45.55694054543915, 10.698029486529776],
            [46.645401238803004, 10.816549383991173],
            [47.525657586462785, 11.12722809492999],
            [48.02159630716778, 11.193063869669743],
            [48.37878380716927, 11.375481675660126],
            [48.94820641459347, 11.41062164961852],
            [48.94820475850985, 11.41061728169797]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MAR.geojson',
        iso_a2: 'MA',
        label_y: 31.650723,
        label_x: -7.187296,
        name: 'Morocco',
        formal_en: 'Kingdom of Morocco'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-2.169913702798624, 35.16839630791668],
            [-1.792985805661715, 34.527918606091305],
            [-1.733454555661467, 33.91971283623212],
            [-1.388049282222596, 32.86401500094138],
            [-1.124551153966308, 32.65152151135713],
            [-1.30789913573787, 32.2628889023061],
            [-2.616604783529567, 32.09434621838619],
            [-3.068980271812648, 31.724497992473218],
            [-3.647497931320146, 31.637294012980675],
            [-3.690441046554724, 30.896951605751156],
            [-4.859646165374471, 30.501187649043846],
            [-5.242129278982787, 30.00044302013559],
            [-6.060632290053774, 29.731699734001694],
            [-7.059227667661958, 29.5792284205246],
            [-8.674116176782974, 28.84128896739658],
            [-8.665589565454809, 27.656425889592356],
            [-8.817828334986672, 27.656425889592356],
            [-8.794883999049077, 27.120696316022507],
            [-9.41303748212448, 27.088476060488574],
            [-9.735343390328879, 26.860944729107405],
            [-10.189424200877582, 26.860944729107405],
            [-10.551262579785273, 26.990807603456886],
            [-11.392554897497007, 26.883423977154393],
            [-11.718219773800357, 26.104091701760623],
            [-12.03075883630163, 26.030866197203068],
            [-12.50096269372537, 24.7701162785782],
            [-13.891110398809047, 23.691009019459305],
            [-14.221167771857253, 22.31016307218816],
            [-14.630832688851072, 21.860939846274903],
            [-14.750954555713534, 21.500600083903663],
            [-17.00296179856109, 21.420734157796577],
            [-17.02042843267577, 21.422310288981578],
            [-16.973247849993243, 21.885744533774982],
            [-16.589136928767687, 22.158234361250095],
            [-16.261921759495635, 22.679339504481277],
            [-16.3264139469959, 23.017768459560898],
            [-15.982610642958036, 23.723358466074046],
            [-15.426003790742186, 24.35913361256104],
            [-15.089331834360735, 24.520260728447],
            [-14.824645148161665, 25.103532619725343],
            [-14.800925665739726, 25.63626496022232],
            [-14.439939947964831, 26.254418443297652],
            [-13.773804897506466, 26.618892320252314],
            [-13.13994177901435, 27.640147813420526],
            [-13.121613369914769, 27.65414767171984],
            [-12.618836635783111, 28.03818553314869],
            [-11.688919236690765, 28.148643907172527],
            [-10.900956997104402, 28.83214223888092],
            [-10.399592251008642, 29.09858592377782],
            [-9.564811163765683, 29.93357371674989],
            [-9.814718390329176, 31.17773550060906],
            [-9.434793260119363, 32.038096421836485],
            [-9.300692918321886, 32.564679266890664],
            [-8.657476365585012, 33.240245266242425],
            [-7.654178432638219, 33.69706492770251],
            [-6.912544114601417, 34.110476386037476],
            [-6.244342006851411, 35.145865383437524],
            [-5.92999426921989, 35.75998810479399],
            [-5.193863491222032, 35.75518219659085],
            [-4.591006232105144, 35.330711981745594],
            [-3.640056525070065, 35.399855048152006],
            [-2.604305792644084, 35.179093329401155],
            [-2.169913702798624, 35.16839630791668]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ESH.geojson',
        iso_a2: 'EH',
        label_y: 23.967592,
        label_x: -12.630304,
        name: 'W. Sahara',
        formal_en: 'Sahrawi Arab Democratic Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.665589565454809, 27.656425889592356],
            [-8.665124477564191, 27.589479071558227],
            [-8.684399786809053, 27.395744126896005],
            [-8.6872936670174, 25.881056219988906],
            [-11.96941891117116, 25.933352769468268],
            [-11.937224493853321, 23.374594224536168],
            [-12.874221564169575, 23.284832261645178],
            [-13.118754441774712, 22.771220201096256],
            [-12.929101935263532, 21.327070624267563],
            [-16.845193650773993, 21.33332347257488],
            [-17.06342322434257, 20.999752102130827],
            [-17.02042843267577, 21.422310288981578],
            [-17.00296179856109, 21.420734157796577],
            [-14.750954555713534, 21.500600083903663],
            [-14.630832688851072, 21.860939846274903],
            [-14.221167771857253, 22.31016307218816],
            [-13.891110398809047, 23.691009019459305],
            [-12.50096269372537, 24.7701162785782],
            [-12.03075883630163, 26.030866197203068],
            [-11.718219773800357, 26.104091701760623],
            [-11.392554897497007, 26.883423977154393],
            [-10.551262579785273, 26.990807603456886],
            [-10.189424200877582, 26.860944729107405],
            [-9.735343390328879, 26.860944729107405],
            [-9.41303748212448, 27.088476060488574],
            [-8.794883999049077, 27.120696316022507],
            [-8.817828334986672, 27.656425889592356],
            [-8.665589565454809, 27.656425889592356]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'COG.geojson',
        iso_a2: 'CG',
        label_y: 0.142331,
        label_x: 15.9005,
        name: 'Congo',
        formal_en: 'Republic of the Congo'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.45306521980993, 3.504385891123349],
            [18.393792351971143, 2.90044342692822],
            [18.094275750407434, 2.365721543788055],
            [17.898835483479587, 1.741831976728278],
            [17.774191928791566, 0.855658677571085],
            [17.826540154703252, 0.288923244626105],
            [17.66355268725468, -0.058083998213817],
            [17.638644646889986, -0.424831638189247],
            [17.523716261472856, -0.743830254726987],
            [16.865306837642123, -1.225816338713287],
            [16.407091912510054, -1.740927015798682],
            [15.972803175529151, -2.712392266453612],
            [16.0062895036543, -3.535132744972529],
            [15.753540073314753, -3.855164890156097],
            [15.170991652088444, -4.343507175314301],
            [14.582603794013181, -4.97023894615014],
            [14.209034864975223, -4.793092136253598],
            [14.144956088933299, -4.510008640158716],
            [13.600234816144678, -4.50013844159097],
            [13.258240187237048, -4.882957452009165],
            [12.995517205465177, -4.781103203961884],
            [12.620759718484493, -4.438023369976136],
            [12.318607618873926, -4.606230157086188],
            [11.91496300624209, -5.037986748884791],
            [11.093772820691925, -3.978826592630547],
            [11.855121697648116, -3.426870619321051],
            [11.478038771214303, -2.765618991714241],
            [11.820963575903193, -2.514161472181982],
            [12.495702752338161, -2.391688327650243],
            [12.575284458067642, -1.948511244315135],
            [13.109618767965628, -2.428740329603514],
            [13.99240726080771, -2.4708049454891],
            [14.299210239324566, -1.998275648612214],
            [14.425455763413595, -1.333406670744971],
            [14.316418491277744, -0.552627455247048],
            [13.843320753645656, 0.038757635901149],
            [14.276265903386957, 1.196929836426619],
            [14.026668735417218, 1.395677395021153],
            [13.282631463278818, 1.31418366129688],
            [13.003113641012078, 1.83089630778332],
            [13.075822381246752, 2.267097072759015],
            [14.33781253424658, 2.227874660649491],
            [15.146341993885244, 1.964014797367184],
            [15.940918816805066, 1.727672634280296],
            [16.012852410555354, 2.267639675298085],
            [16.537058139724138, 3.198254706226279],
            [17.133042433346304, 3.728196519379452],
            [17.809900343505262, 3.56019643799857],
            [18.45306521980993, 3.504385891123349]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'COD.geojson',
        iso_a2: 'CD',
        label_y: -1.858167,
        label_x: 23.458829,
        name: 'Dem. Rep. Congo',
        formal_en: 'Democratic Republic of the Congo'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.339997592900346, -4.499983412294092],
            [29.519986606572928, -5.419978936386315],
            [29.419992710088167, -5.939998874539434],
            [29.620032179490014, -6.520015150583426],
            [30.199996779101696, -7.079980970898163],
            [30.74001549655179, -8.340007419470915],
            [30.740009731422095, -8.34000593035372],
            [30.346086053190817, -8.238256524288218],
            [29.00291222506047, -8.407031752153472],
            [28.734866570762502, -8.526559340044578],
            [28.449871046672826, -9.164918308146085],
            [28.67368167492893, -9.605924981324932],
            [28.49606977714177, -10.789883721564046],
            [28.372253045370428, -11.793646742401393],
            [28.642417433392353, -11.971568698782315],
            [29.34154788586909, -12.360743910372413],
            [29.61600141777123, -12.178894545137311],
            [29.69961388521949, -13.257226657771831],
            [28.934285922976837, -13.248958428605135],
            [28.523561639121027, -12.698604424696683],
            [28.155108676879987, -12.272480564017897],
            [27.388798862423783, -12.132747491100666],
            [27.164419793412463, -11.608748467661075],
            [26.553087599399618, -11.924439792532127],
            [25.752309604604733, -11.784965101776358],
            [25.418118116973204, -11.330935967659961],
            [24.78316979340295, -11.238693536018964],
            [24.31451622894795, -11.26282642989927],
            [24.25715538910399, -10.951992689663657],
            [23.912215203555718, -10.926826267137514],
            [23.45679080576744, -10.867863457892483],
            [22.83734541188474, -11.01762175867433],
            [22.402798292742375, -10.993075453335692],
            [22.155268182064308, -11.084801120653772],
            [22.208753289486395, -9.894796237836509],
            [21.875181919042348, -9.523707777548566],
            [21.8018013851879, -8.90870655684298],
            [21.949130893652043, -8.305900974158277],
            [21.74645592620331, -7.920084730667149],
            [21.7281107927397, -7.290872491081302],
            [20.5147481625265, -7.299605808138629],
            [20.6018229509383, -6.939317722199682],
            [20.091621534920648, -6.943090101756994],
            [20.037723016040218, -7.116361179231646],
            [19.41750247567316, -7.155428562044299],
            [19.16661339689611, -7.738183688999754],
            [19.01675174324967, -7.988245944860132],
            [18.464175652752687, -7.847014255406443],
            [18.13422163256905, -7.987677504104923],
            [17.472970004962235, -8.0685511206417],
            [17.08999596524717, -7.545688978712526],
            [16.8601908708452, -7.222297865429987],
            [16.573179965896145, -6.622644545115087],
            [16.326528354567046, -5.877470391466268],
            [13.375597364971895, -5.864241224799549],
            [13.024869419006961, -5.984388929878158],
            [12.735171339578699, -5.965682061388499],
            [12.32243167486351, -6.10009246177966],
            [12.182336866920252, -5.789930515163839],
            [12.436688266660868, -5.684303887559246],
            [12.468004184629736, -5.248361504745005],
            [12.63161176926579, -4.991271254092936],
            [12.995517205465177, -4.781103203961884],
            [13.258240187237048, -4.882957452009165],
            [13.600234816144678, -4.50013844159097],
            [14.144956088933299, -4.510008640158716],
            [14.209034864975223, -4.793092136253598],
            [14.582603794013181, -4.97023894615014],
            [15.170991652088444, -4.343507175314301],
            [15.753540073314753, -3.855164890156097],
            [16.0062895036543, -3.535132744972529],
            [15.972803175529151, -2.712392266453612],
            [16.407091912510054, -1.740927015798682],
            [16.865306837642123, -1.225816338713287],
            [17.523716261472856, -0.743830254726987],
            [17.638644646889986, -0.424831638189247],
            [17.66355268725468, -0.058083998213817],
            [17.826540154703252, 0.288923244626105],
            [17.774191928791566, 0.855658677571085],
            [17.898835483479587, 1.741831976728278],
            [18.094275750407434, 2.365721543788055],
            [18.393792351971143, 2.90044342692822],
            [18.45306521980993, 3.504385891123349],
            [18.54298221199778, 4.201785183118318],
            [18.93231245288476, 4.709506130385975],
            [19.46778364429315, 5.03152781821278],
            [20.290679152108936, 4.691677761245288],
            [20.927591180106276, 4.322785549329737],
            [21.659122755630023, 4.22434194581372],
            [22.405123732195538, 4.029160061047321],
            [22.70412356943629, 4.633050848810157],
            [22.841479526468106, 4.710126247573484],
            [23.29721398285014, 4.609693101414223],
            [24.410531040146253, 5.10878408448913],
            [24.805028924262416, 4.89724660890235],
            [25.12883344900328, 4.92724477784779],
            [25.278798455514305, 5.170408229997192],
            [25.650455356557472, 5.256087754737123],
            [26.402760857862543, 5.150874538590871],
            [27.04406538260471, 5.127852688004836],
            [27.37422610851749, 5.233944403500061],
            [27.97997724784281, 4.408413397637375],
            [28.428993768026913, 4.287154649264494],
            [28.696677687298802, 4.455077215996937],
            [29.1590784034465, 4.389267279473231],
            [29.71599531425602, 4.600804755060153],
            [29.953500197069474, 4.173699042167684],
            [30.833852421715427, 3.509171604222463],
            [30.83385989759381, 3.509165961110341],
            [30.77334679538004, 2.339883327642127],
            [31.174149204235817, 2.204465236821264],
            [30.85267011894806, 1.849396470543809],
            [30.46850752129029, 1.583805446779706],
            [30.086153598762706, 1.062312730306289],
            [29.875778842902434, 0.597379868976361],
            [29.819503208136638, -0.205310153813372],
            [29.58783776217217, -0.587405694179381],
            [29.579466180140884, -1.341313164885626],
            [29.291886834436614, -1.620055840667987],
            [29.254834832483343, -2.215109958508911],
            [29.117478875451553, -2.292211195488385],
            [29.024926385216787, -2.839257907730158],
            [29.276383904749053, -3.293907159034063],
            [29.339997592900346, -4.499983412294092]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NAM.geojson',
        iso_a2: 'NA',
        label_y: -20.575298,
        label_x: 17.108166,
        name: 'Namibia',
        formal_en: 'Republic of Namibia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [19.895767856534434, -24.76779021576059],
            [19.894734327888614, -28.461104831660776],
            [19.002127312911085, -28.972443129188868],
            [18.464899122804752, -29.04546192801728],
            [17.83615197110953, -28.85637786226132],
            [17.387497185951503, -28.78351409272978],
            [17.218928663815404, -28.35594329194681],
            [16.824017368240902, -28.08216155366447],
            [16.344976840895242, -28.5767050106977],
            [15.601818068105816, -27.821247247022804],
            [15.21047244635946, -27.090955905874047],
            [14.989710727608553, -26.117371921495156],
            [14.743214145576331, -25.39292001719538],
            [14.408144158595833, -23.853014011329847],
            [14.385716586981149, -22.65665292734069],
            [14.257714064194175, -22.111208184499958],
            [13.86864220546866, -21.699036960539978],
            [13.35249799973744, -20.872834161057504],
            [12.826845330464494, -19.673165785401665],
            [12.608564080463621, -19.0453488094877],
            [11.794918654028066, -18.069129327061916],
            [11.734198846085121, -17.301889336824473],
            [12.215461460019355, -17.111668389558083],
            [12.814081251688407, -16.94134286872407],
            [13.462362094789967, -16.971211846588773],
            [14.05850141770901, -17.423380629142663],
            [14.209706658595024, -17.35310068122572],
            [18.263309360434164, -17.309950860262006],
            [18.956186964603603, -17.789094740472258],
            [21.377176141045567, -17.930636488519696],
            [23.215048455506064, -17.523116143465984],
            [24.033861525170778, -17.295843194246324],
            [24.682349074001507, -17.353410739819473],
            [25.07695031098226, -17.57882333747662],
            [25.08444339366457, -17.661815687737374],
            [24.520705193792537, -17.887124932529936],
            [24.217364536239213, -17.88934701911849],
            [23.579005568137717, -18.28126108162006],
            [23.1968583513393, -17.869038181227786],
            [21.655040317478978, -18.219146010005225],
            [20.910641310314535, -18.252218926672022],
            [20.88113406747587, -21.814327080983148],
            [19.89545779794068, -21.84915699634787],
            [19.895767856534434, -24.76779021576059]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ZAF.geojson',
        iso_a2: 'ZA',
        label_y: -29.708776,
        label_x: 23.665734,
        name: 'South Africa',
        formal_en: 'Republic of South Africa'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.344976840895242, -28.5767050106977],
            [16.824017368240902, -28.08216155366447],
            [17.218928663815404, -28.35594329194681],
            [17.387497185951503, -28.78351409272978],
            [17.83615197110953, -28.85637786226132],
            [18.464899122804752, -29.04546192801728],
            [19.002127312911085, -28.972443129188868],
            [19.894734327888614, -28.461104831660776],
            [19.895767856534434, -24.76779021576059],
            [20.16572553882719, -24.91796192800077],
            [20.75860924651184, -25.86813648855145],
            [20.66647016773544, -26.477453301704923],
            [20.88960900237174, -26.828542982695915],
            [21.605896030369394, -26.726533705351756],
            [22.105968865657868, -26.280256036079138],
            [22.57953169118059, -25.979447523708146],
            [22.8242712745149, -25.50045867279477],
            [23.312096795350186, -25.26868987396572],
            [23.73356977712271, -25.390129489851617],
            [24.211266717228796, -25.670215752873574],
            [25.025170525825786, -25.7196700985769],
            [25.66466637543772, -25.486816094669713],
            [25.76584882986521, -25.17484547292368],
            [25.94165205252216, -24.69637338633322],
            [26.4857532081233, -24.616326592713104],
            [26.786406691197413, -24.240690606383485],
            [27.119409620886245, -23.574323011979775],
            [28.01723595552525, -22.82775359465908],
            [29.43218834810904, -22.091312758067588],
            [29.839036899542972, -22.102216485281176],
            [30.322883335091774, -22.271611830333935],
            [30.65986535006709, -22.151567478119915],
            [31.19140913262129, -22.2515096981724],
            [31.670397983534656, -23.658969008073864],
            [31.930588820124253, -24.36941659922254],
            [31.75240848158188, -25.484283949487413],
            [31.83777794772806, -25.84333180105135],
            [31.333157586397903, -25.66019052500895],
            [31.04407962415715, -25.731452325139443],
            [30.949666782359913, -26.02264902110415],
            [30.67660851412964, -26.398078301704608],
            [30.68596194837448, -26.743845310169533],
            [31.28277306491333, -27.285879408478998],
            [31.86806033705108, -27.177927341421277],
            [32.07166548028107, -26.73382008230491],
            [32.830120477028885, -26.742191664336197],
            [32.580264926897684, -27.470157566031816],
            [32.46213260267845, -28.301011244420557],
            [32.20338870619304, -28.75240488049007],
            [31.521001417778876, -29.257386976846256],
            [31.325561150851, -29.401977634398914],
            [30.901762729625347, -29.909956963828037],
            [30.62281334811382, -30.42377573010613],
            [30.05571618014278, -31.140269463832958],
            [28.92555260591954, -32.1720411109725],
            [28.2197558936771, -32.771952813448856],
            [27.464608188595975, -33.2269637997788],
            [26.419452345492825, -33.61495045342619],
            [25.90966434093349, -33.6670402971764],
            [25.780628289500697, -33.94464609144834],
            [25.172861769315972, -33.796851495093584],
            [24.677853224392123, -33.98717579522455],
            [23.594043409934642, -33.794474379208154],
            [22.988188917744736, -33.91643075941698],
            [22.574157342222236, -33.86408253350531],
            [21.542799106541025, -34.258838799782936],
            [20.689052768647002, -34.417175388325234],
            [20.071261020597632, -34.79513681410799],
            [19.61640506356457, -34.81916635512371],
            [19.193278435958717, -34.46259897230979],
            [18.85531456876987, -34.444305515278465],
            [18.42464318204938, -33.99787281670897],
            [18.377410922934615, -34.13652068454807],
            [18.24449913907992, -33.86775156019803],
            [18.250080193767445, -33.28143075941444],
            [17.92519046394844, -32.61129078545343],
            [18.247909783611192, -32.42913136162457],
            [18.22176150887148, -31.66163298922567],
            [17.56691775886887, -30.725721123987547],
            [17.064416131262703, -29.878641045859162],
            [17.062917514726223, -29.875953871379984],
            [16.344976840895242, -28.5767050106977]
          ],
          [
            [28.978262566857243, -28.95559661226171],
            [28.541700066855498, -28.64750172293757],
            [28.074338413207784, -28.851468601193588],
            [27.532511020627478, -29.24271087007536],
            [26.999261915807637, -29.875953871379984],
            [27.749397006956485, -30.645105889612225],
            [28.107204624145425, -30.54573211031495],
            [28.29106937023991, -30.2262167294543],
            [28.84839969250774, -30.070050551068256],
            [29.018415154748027, -29.74376555757737],
            [29.32516645683259, -29.257386976846256],
            [28.978262566857243, -28.95559661226171]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LBY.geojson',
        iso_a2: 'LY',
        label_y: 26.638944,
        label_x: 18.011015,
        name: 'Libya',
        formal_en: 'Libya'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [25, 22],
            [25.000000000000114, 20.00304],
            [23.850000000000136, 20],
            [23.83766000000014, 19.580470000000105],
            [19.84926, 21.49509],
            [15.86085, 23.40972],
            [14.8513, 22.862950000000126],
            [14.143870883855243, 22.491288967371133],
            [13.581424594790462, 23.04050608976928],
            [11.999505649471613, 23.47166840259645],
            [11.560669386449005, 24.097909247325518],
            [10.771363559622927, 24.56253205006175],
            [10.303846876678362, 24.379313259370917],
            [9.94826134607797, 24.936953640232517],
            [9.910692579801776, 25.365454616796796],
            [9.319410841518163, 26.094324856057455],
            [9.716285841519664, 26.512206325785655],
            [9.629056023811074, 27.14095347748092],
            [9.756128370816782, 27.688258571884205],
            [9.683884718472768, 28.1441738957792],
            [9.859997999723447, 28.959989732371014],
            [9.805634392952356, 29.424638373323376],
            [9.482139926805274, 30.307556057246188],
            [9.970017124072854, 30.53932485607524],
            [10.056575148161699, 30.961831366493527],
            [9.950225050505082, 31.376069647745258],
            [10.636901482799487, 31.761420803345757],
            [10.944789666394456, 32.081814683555365],
            [11.432253452203696, 32.368903103152874],
            [11.488787469131012, 33.13699575452324],
            [12.66331, 32.79278],
            [13.08326, 32.87882],
            [13.91868, 32.71196],
            [15.24563, 32.26508],
            [15.71394, 31.37626],
            [16.61162, 31.18218],
            [18.02109, 30.76357],
            [19.08641, 30.26639],
            [19.57404, 30.52582],
            [20.05335, 30.98576],
            [19.82033, 31.751790000000142],
            [20.13397, 32.2382],
            [20.85452, 32.7068],
            [21.54298, 32.8432],
            [22.89576, 32.63858],
            [23.2368, 32.19149],
            [23.609130000000107, 32.18726],
            [23.9275, 32.01667],
            [24.92114, 31.89936],
            [25.16482, 31.56915],
            [24.80287, 31.08929],
            [24.95762, 30.6616],
            [24.70007, 30.04419],
            [25, 29.23865452953346],
            [25, 25.682499996361],
            [25, 22]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TUN.geojson',
        iso_a2: 'TN',
        label_y: 33.687263,
        label_x: 9.007881,
        name: 'Tunisia',
        formal_en: 'Republic of Tunisia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.482139926805274, 30.307556057246188],
            [9.05560265466815, 32.10269196220129],
            [8.439102817426118, 32.50628489840082],
            [8.430472853233368, 32.74833730725595],
            [7.612641635782182, 33.34411489514896],
            [7.524481642292244, 34.09737641045146],
            [8.140981479534304, 34.65514598239379],
            [8.376367628623768, 35.479876003555944],
            [8.217824334352315, 36.433176988260286],
            [8.420964389691676, 36.94642731378316],
            [9.509993523810607, 37.349994411766545],
            [10.210002475636317, 37.230001735984814],
            [10.180650262094531, 36.724037787415085],
            [11.02886722173335, 37.09210317641396],
            [11.100025668999251, 36.899996039368915],
            [10.600004510143094, 36.410000108377375],
            [10.593286573945138, 35.94744436293281],
            [10.939518670300687, 35.698984076473494],
            [10.807847120821009, 34.83350718844919],
            [10.149592726287125, 34.33077301689771],
            [10.339658644256616, 33.78574168551532],
            [10.856836378633687, 33.76874013929128],
            [11.108500603895122, 33.293342800422195],
            [11.488787469131012, 33.13699575452324],
            [11.432253452203696, 32.368903103152874],
            [10.944789666394456, 32.081814683555365],
            [10.636901482799487, 31.761420803345757],
            [9.950225050505082, 31.376069647745258],
            [10.056575148161699, 30.961831366493527],
            [9.970017124072854, 30.53932485607524],
            [9.482139926805274, 30.307556057246188]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ZMB.geojson',
        iso_a2: 'ZM',
        label_y: -14.660804,
        label_x: 26.395298,
        name: 'Zambia',
        formal_en: 'Republic of Zambia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.740009731422095, -8.34000593035372],
            [31.15775133695005, -8.594578747317366],
            [31.556348097466497, -8.762048841998642],
            [32.19186486179194, -8.930358981973257],
            [32.75937544122132, -9.23059905358906],
            [33.2313879737753, -9.6767216935648],
            [33.48568769708359, -10.525558770391115],
            [33.315310499817286, -10.796549981329697],
            [33.114289178201915, -11.607198174692314],
            [33.306422153463075, -12.435778090060218],
            [32.991764357237884, -12.783870537978274],
            [32.68816531752313, -13.712857761289277],
            [33.214024692525214, -13.971860039936153],
            [30.17948123548183, -14.796099134991529],
            [30.27425581230511, -15.507786960515213],
            [29.516834344203147, -15.644677829656388],
            [28.947463413211267, -16.04305144619444],
            [28.8258687680285, -16.389748630440614],
            [28.467906121542683, -16.468400160388846],
            [27.59824344250276, -17.290830580314008],
            [27.044427117630732, -17.938026218337434],
            [26.70677330903564, -17.961228936436484],
            [26.381935255648926, -17.8460421688579],
            [25.264225701608012, -17.736539808831417],
            [25.08444339366457, -17.661815687737374],
            [25.07695031098226, -17.57882333747662],
            [24.682349074001507, -17.353410739819473],
            [24.033861525170778, -17.295843194246324],
            [23.215048455506064, -17.523116143465984],
            [22.56247846852426, -16.898451429921813],
            [21.887842644953874, -16.08031015387688],
            [21.933886346125917, -12.898437188369359],
            [24.016136508894675, -12.911046237848574],
            [23.930922072045377, -12.565847670138856],
            [24.079905226342845, -12.191296888887365],
            [23.904153680118185, -11.722281589406322],
            [24.01789350759259, -11.23729827234709],
            [23.912215203555718, -10.926826267137514],
            [24.25715538910399, -10.951992689663657],
            [24.31451622894795, -11.26282642989927],
            [24.78316979340295, -11.238693536018964],
            [25.418118116973204, -11.330935967659961],
            [25.752309604604733, -11.784965101776358],
            [26.553087599399618, -11.924439792532127],
            [27.164419793412463, -11.608748467661075],
            [27.388798862423783, -12.132747491100666],
            [28.155108676879987, -12.272480564017897],
            [28.523561639121027, -12.698604424696683],
            [28.934285922976837, -13.248958428605135],
            [29.69961388521949, -13.257226657771831],
            [29.61600141777123, -12.178894545137311],
            [29.34154788586909, -12.360743910372413],
            [28.642417433392353, -11.971568698782315],
            [28.372253045370428, -11.793646742401393],
            [28.49606977714177, -10.789883721564046],
            [28.67368167492893, -9.605924981324932],
            [28.449871046672826, -9.164918308146085],
            [28.734866570762502, -8.526559340044578],
            [29.00291222506047, -8.407031752153472],
            [30.346086053190817, -8.238256524288218],
            [30.740009731422095, -8.34000593035372]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SLE.geojson',
        iso_a2: 'SL',
        label_y: 8.617449,
        label_x: -11.763677,
        name: 'Sierra Leone',
        formal_en: 'Republic of Sierra Leone'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-13.246550258832515, 8.903048610871508],
            [-12.71195756677308, 9.342711696810767],
            [-12.59671912276221, 9.62018830000197],
            [-12.425928514037565, 9.835834051955956],
            [-12.150338100625005, 9.858571682164381],
            [-11.917277390988659, 10.046983954300558],
            [-11.11748124840733, 10.045872911006285],
            [-10.839151984083301, 9.688246161330369],
            [-10.622395188835041, 9.267910061068278],
            [-10.654770473665891, 8.977178452994195],
            [-10.494315151399633, 8.715540676300435],
            [-10.505477260774668, 8.348896389189605],
            [-10.23009355309128, 8.406205552601293],
            [-10.69559485517648, 7.939464016141088],
            [-11.146704270868383, 7.396706447779536],
            [-11.19980180504828, 7.105845648624737],
            [-11.438779466182055, 6.785916856305747],
            [-11.70819454593574, 6.860098374860726],
            [-12.428098924193819, 7.26294200279203],
            [-12.949049038128194, 7.798645738145738],
            [-13.124025437868482, 8.163946438016978],
            [-13.246550258832515, 8.903048610871508]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GIN.geojson',
        iso_a2: 'GN',
        label_y: 10.618516,
        label_x: -10.016402,
        name: 'Guinea',
        formal_en: 'Republic of Guinea'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-13.700476040084325, 12.586182969610194],
            [-13.217818162478238, 12.575873521367967],
            [-12.499050665730564, 12.332089952031057],
            [-12.27859900557344, 12.354440008997287],
            [-12.203564825885634, 12.465647691289405],
            [-11.658300950557932, 12.386582749882836],
            [-11.51394283695059, 12.442987575729418],
            [-11.456168585648271, 12.076834214725338],
            [-11.297573614944511, 12.077971096235771],
            [-11.03655595543826, 12.211244615116515],
            [-10.870829637078215, 12.17788747807211],
            [-10.593223842806282, 11.92397532800598],
            [-10.165213792348837, 11.844083563682744],
            [-9.890992804392013, 12.060478623904972],
            [-9.567911749703214, 12.194243068892476],
            [-9.327616339546012, 12.334286200403454],
            [-9.127473517279583, 12.308060411015333],
            [-8.90526485842453, 12.088358059126437],
            [-8.786099005559464, 11.812560939984706],
            [-8.376304897484914, 11.393645941610629],
            [-8.581305304386774, 11.136245632364805],
            [-8.620321010767128, 10.810890814655183],
            [-8.407310756860028, 10.909256903522762],
            [-8.282357143578281, 10.792597357623846],
            [-8.33537716310974, 10.494811916541934],
            [-8.029943610048619, 10.206534939001713],
            [-8.229337124046822, 10.1290202905639],
            [-8.30961646161225, 9.789531968622441],
            [-8.07911373537435, 9.376223863152035],
            [-7.832100389019188, 8.575704250518626],
            [-8.20349890790088, 8.455453192575447],
            [-8.299048631208564, 8.316443589710303],
            [-8.221792364932199, 8.123328762235573],
            [-8.280703497744938, 7.687179673692157],
            [-8.439298468448698, 7.686042792181738],
            [-8.722123582382125, 7.71167430259851],
            [-8.926064622422004, 7.309037380396376],
            [-9.208786383490846, 7.313920803247953],
            [-9.40334815106975, 7.526905218938907],
            [-9.337279832384581, 7.928534450711354],
            [-9.755342169625834, 8.541055202666925],
            [-10.016566534861255, 8.428503933135232],
            [-10.23009355309128, 8.406205552601293],
            [-10.505477260774668, 8.348896389189605],
            [-10.494315151399633, 8.715540676300435],
            [-10.654770473665891, 8.977178452994195],
            [-10.622395188835041, 9.267910061068278],
            [-10.839151984083301, 9.688246161330369],
            [-11.11748124840733, 10.045872911006285],
            [-11.917277390988659, 10.046983954300558],
            [-12.150338100625005, 9.858571682164381],
            [-12.425928514037565, 9.835834051955956],
            [-12.59671912276221, 9.62018830000197],
            [-12.71195756677308, 9.342711696810767],
            [-13.246550258832515, 8.903048610871508],
            [-13.685153977909792, 9.49474376061346],
            [-14.074044969122282, 9.886166897008252],
            [-14.33007585291237, 10.015719712763968],
            [-14.579698859098258, 10.214467271358515],
            [-14.693231980843505, 10.656300767454042],
            [-14.839553798877944, 10.87657156009814],
            [-15.130311245168173, 11.040411688679526],
            [-14.685687221728898, 11.527823798056488],
            [-14.382191534878729, 11.509271958863692],
            [-14.121406419317779, 11.677117010947697],
            [-13.900799729863776, 11.678718980348748],
            [-13.743160773157413, 11.811269029177412],
            [-13.828271857142125, 12.142644151249044],
            [-13.718743658899513, 12.24718557377551],
            [-13.700476040084325, 12.586182969610194]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LBR.geojson',
        iso_a2: 'LR',
        label_y: 6.447177,
        label_x: -9.460379,
        name: 'Liberia',
        formal_en: 'Republic of Liberia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.439298468448698, 7.686042792181738],
            [-8.48544552248535, 7.39520783124307],
            [-8.385451626000574, 6.911800645368743],
            [-8.60288021486862, 6.46756419517166],
            [-8.311347622094019, 6.193033148621083],
            [-7.993692592795881, 6.126189683451543],
            [-7.570152553731688, 5.707352199725904],
            [-7.539715135111763, 5.313345241716519],
            [-7.635368211284031, 5.188159084489456],
            [-7.71215938966975, 4.364565944837722],
            [-7.974107224957251, 4.355755113131963],
            [-9.004793667018674, 4.8324185245922],
            [-9.913420376006684, 5.593560695819207],
            [-10.765383876986645, 6.140710760925558],
            [-11.438779466182055, 6.785916856305747],
            [-11.19980180504828, 7.105845648624737],
            [-11.146704270868383, 7.396706447779536],
            [-10.69559485517648, 7.939464016141088],
            [-10.23009355309128, 8.406205552601293],
            [-10.016566534861255, 8.428503933135232],
            [-9.755342169625834, 8.541055202666925],
            [-9.337279832384581, 7.928534450711354],
            [-9.40334815106975, 7.526905218938907],
            [-9.208786383490846, 7.313920803247953],
            [-8.926064622422004, 7.309037380396376],
            [-8.722123582382125, 7.71167430259851],
            [-8.439298468448698, 7.686042792181738]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CAF.geojson',
        iso_a2: 'CF',
        label_y: 6.989681,
        label_x: 20.906897,
        name: 'Central African Rep.',
        formal_en: 'Central African Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.37422610851749, 5.233944403500061],
            [27.04406538260471, 5.127852688004836],
            [26.402760857862543, 5.150874538590871],
            [25.650455356557472, 5.256087754737123],
            [25.278798455514305, 5.170408229997192],
            [25.12883344900328, 4.92724477784779],
            [24.805028924262416, 4.89724660890235],
            [24.410531040146253, 5.10878408448913],
            [23.29721398285014, 4.609693101414223],
            [22.841479526468106, 4.710126247573484],
            [22.70412356943629, 4.633050848810157],
            [22.405123732195538, 4.029160061047321],
            [21.659122755630023, 4.22434194581372],
            [20.927591180106276, 4.322785549329737],
            [20.290679152108936, 4.691677761245288],
            [19.46778364429315, 5.03152781821278],
            [18.93231245288476, 4.709506130385975],
            [18.54298221199778, 4.201785183118318],
            [18.45306521980993, 3.504385891123349],
            [17.809900343505262, 3.56019643799857],
            [17.133042433346304, 3.728196519379452],
            [16.537058139724138, 3.198254706226279],
            [16.012852410555354, 2.267639675298085],
            [15.907380812247652, 2.557389431158612],
            [15.862732374747482, 3.013537298998983],
            [15.405395948964383, 3.33530060466434],
            [15.036219516671252, 3.851367295747124],
            [14.950953403389661, 4.210389309094921],
            [14.47837243008047, 4.732605495620447],
            [14.558935988023507, 5.03059764243153],
            [14.459407179429348, 5.4517605656103],
            [14.536560092841114, 6.22695872642069],
            [14.776545444404576, 6.408498033062045],
            [15.279460483469109, 7.421924546737969],
            [16.106231723706742, 7.497087917506462],
            [16.290561557691888, 7.754307359239419],
            [16.456184523187346, 7.734773667832968],
            [16.705988396886255, 7.508327541529979],
            [17.964929640380888, 7.890914008002994],
            [18.38955488452322, 8.281303615751824],
            [18.911021762780507, 8.630894680206353],
            [18.812009718509273, 8.982914536978598],
            [19.09400800952602, 9.07484691002584],
            [20.05968549976427, 9.012706000194854],
            [21.000868361096167, 9.475985215691509],
            [21.723821648859456, 10.567055568885976],
            [22.23112918466876, 10.97188873946061],
            [22.864165480244225, 11.142395127807546],
            [22.97754357269261, 10.71446259199854],
            [23.554304233502194, 10.089255275915308],
            [23.55724979014283, 9.681218166538684],
            [23.394779087017184, 9.265067857292223],
            [23.459012892355986, 8.954285793488893],
            [23.805813429466752, 8.666318874542526],
            [24.567369012152085, 8.229187933785468],
            [25.11493248871679, 7.825104071479174],
            [25.124130893664727, 7.500085150579437],
            [25.79664798351118, 6.979315904158071],
            [26.213418409945117, 6.546603298362072],
            [26.465909458123235, 5.94671743410187],
            [27.21340905122517, 5.550953477394557],
            [27.37422610851749, 5.233944403500061]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SDN.geojson',
        iso_a2: 'SD',
        label_y: 16.330746,
        label_x: 29.260657,
        name: 'Sudan',
        formal_en: 'Republic of the Sudan'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [24.567369012152085, 8.229187933785468],
            [23.805813429466752, 8.666318874542526],
            [23.459012892355986, 8.954285793488893],
            [23.394779087017184, 9.265067857292223],
            [23.55724979014283, 9.681218166538684],
            [23.554304233502194, 10.089255275915308],
            [22.97754357269261, 10.71446259199854],
            [22.864165480244225, 11.142395127807546],
            [22.87622, 11.384610000000123],
            [22.50869, 11.67936],
            [22.49762, 12.26024],
            [22.28801, 12.64605],
            [21.93681, 12.588180000000136],
            [22.03759, 12.95546],
            [22.29658, 13.37232],
            [22.18329, 13.78648],
            [22.51202, 14.09318],
            [22.30351, 14.32682],
            [22.56795000000011, 14.944290000000137],
            [23.024590000000103, 15.68072],
            [23.886890000000108, 15.61084],
            [23.83766000000014, 19.580470000000105],
            [23.850000000000136, 20],
            [25.000000000000114, 20.00304],
            [25, 22],
            [29.02, 22],
            [32.9, 22],
            [36.86623, 22],
            [37.1887200000001, 21.01885],
            [36.96941, 20.83744000000013],
            [37.11470000000014, 19.80796],
            [37.4817900000001, 18.61409],
            [37.86276, 18.36786],
            [38.410089959473225, 17.998307399970315],
            [37.90400000000011, 17.42754],
            [37.16747, 17.263140000000135],
            [36.852530000000115, 16.95655],
            [36.75389, 16.29186],
            [36.32322, 14.82249],
            [36.42951, 14.42211],
            [36.27022, 13.563330000000121],
            [35.86363, 12.57828],
            [35.26049, 12.08286],
            [34.83163000000013, 11.318960000000118],
            [34.73115000000013, 10.910170000000107],
            [34.25745, 10.63009],
            [33.96162, 9.58358],
            [33.97498, 8.68456],
            [33.963392794971185, 9.464285229420625],
            [33.82496348090751, 9.484060845715362],
            [33.84213085302815, 9.981914637215993],
            [33.72195924818311, 10.325262079630193],
            [33.206938084561784, 10.720111638406593],
            [33.086766479716744, 11.441141267476496],
            [33.206938084561784, 12.179338268667095],
            [32.743419037302544, 12.248007757149992],
            [32.67474954881965, 12.02483191958072],
            [32.073891524594785, 11.973329803218519],
            [32.31423473428475, 11.68148447716652],
            [32.400071594888345, 11.080626452941488],
            [31.850715687025513, 10.531270545078826],
            [31.35286189552488, 9.810240916008695],
            [30.837840731903384, 9.70723668328452],
            [29.996639497988554, 10.290927335388687],
            [29.61895731133285, 10.084918869940225],
            [29.515953078608618, 9.793073543888056],
            [29.000931914987177, 9.60423245056029],
            [28.966597170745786, 9.398223985111656],
            [27.970889587744352, 9.398223985111656],
            [27.833550610778786, 9.60423245056029],
            [27.112520981708883, 9.638567194801624],
            [26.752006167173818, 9.466893473594496],
            [26.477328213242515, 9.552730334198088],
            [25.962307049621018, 10.136420986302426],
            [25.790633328413946, 10.411098940233728],
            [25.069603699343986, 10.273759963267992],
            [24.794925745412684, 9.810240916008695],
            [24.53741516360202, 8.91753756573172],
            [24.19406772118765, 8.728696472403897],
            [23.886979580860668, 8.619729712933065],
            [24.567369012152085, 8.229187933785468]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'DJI.geojson',
        iso_a2: 'DJ',
        label_y: 11.976343,
        label_x: 42.498825,
        name: 'Djibouti',
        formal_en: 'Republic of Djibouti'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [42.35156000000012, 12.542230000000131],
            [42.77964236834475, 12.455415757695675],
            [43.08122602720016, 12.699638576707116],
            [43.31785241066467, 12.390148423711025],
            [43.286381463398925, 11.974928290245884],
            [42.715873650896526, 11.735640570518342],
            [43.14530480324214, 11.462039699748857],
            [42.77685184100096, 10.92687856693442],
            [42.55493000000013, 11.105110000000195],
            [42.31414000000012, 11.0342],
            [41.755570000000205, 11.050910000000101],
            [41.73959000000019, 11.355110000000138],
            [41.66176000000013, 11.6312],
            [42.000000000000114, 12.100000000000136],
            [42.35156000000012, 12.542230000000131]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ERI.geojson',
        iso_a2: 'ER',
        label_y: 15.787401,
        label_x: 38.285566,
        name: 'Eritrea',
        formal_en: 'State of Eritrea'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [36.42951, 14.42211],
            [36.32322, 14.82249],
            [36.75389, 16.29186],
            [36.852530000000115, 16.95655],
            [37.16747, 17.263140000000135],
            [37.90400000000011, 17.42754],
            [38.410089959473225, 17.998307399970315],
            [38.990622999840014, 16.840626125551694],
            [39.26611006038803, 15.92272349696725],
            [39.814293654140215, 15.435647284400318],
            [41.17927493669765, 14.491079616753211],
            [41.73495161313235, 13.921036892141558],
            [42.27683068214486, 13.343992010954423],
            [42.58957645037526, 13.000421250861905],
            [43.08122602720016, 12.699638576707116],
            [42.77964236834475, 12.455415757695675],
            [42.35156000000012, 12.542230000000131],
            [42.00975, 12.86582],
            [41.59856, 13.452090000000112],
            [41.1552, 13.77333],
            [40.8966, 14.118640000000141],
            [40.02625000000012, 14.51959],
            [39.34061, 14.53155],
            [39.0994, 14.74064],
            [38.51295, 14.50547],
            [37.90607000000011, 14.959430000000168],
            [37.59377, 14.2131],
            [36.42951, 14.42211]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CIV.geojson',
        iso_a2: 'CI',
        label_y: 7.49139,
        label_x: -5.568618,
        name: "Côte d'Ivoire",
        formal_en: 'Republic of Ivory Coast'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.029943610048619, 10.206534939001713],
            [-7.899589809592372, 10.297382106970828],
            [-7.622759161804809, 10.147236232946796],
            [-6.850506557635057, 10.138993841996239],
            [-6.666460944027548, 10.430810655148449],
            [-6.493965013037268, 10.411302801958271],
            [-6.205222947606431, 10.524060777219134],
            [-6.050452032892267, 10.096360785355444],
            [-5.816926235365287, 10.222554633012194],
            [-5.404341599946974, 10.370736802609146],
            [-4.954653286143099, 10.152713934769736],
            [-4.779883592131966, 9.821984768101743],
            [-4.330246954760383, 9.610834865757141],
            [-3.980449184576685, 9.8623440617217],
            [-3.511898972986273, 9.90032623945622],
            [-2.827496303712707, 9.642460842319778],
            [-2.562189500326241, 8.219627793811483],
            [-2.983584967450327, 7.379704901555513],
            [-3.244370083011262, 6.250471503113502],
            [-2.81070146321784, 5.38905121502411],
            [-2.856125047202397, 4.994475816259509],
            [-3.311084357100071, 4.984295559098015],
            [-4.008819545904942, 5.179813340674315],
            [-4.649917364917911, 5.168263658057086],
            [-5.834496222344526, 4.993700669775137],
            [-6.528769090185847, 4.705087795425015],
            [-7.518941209330436, 4.338288479017308],
            [-7.71215938966975, 4.364565944837722],
            [-7.635368211284031, 5.188159084489456],
            [-7.539715135111763, 5.313345241716519],
            [-7.570152553731688, 5.707352199725904],
            [-7.993692592795881, 6.126189683451543],
            [-8.311347622094019, 6.193033148621083],
            [-8.60288021486862, 6.46756419517166],
            [-8.385451626000574, 6.911800645368743],
            [-8.48544552248535, 7.39520783124307],
            [-8.439298468448698, 7.686042792181738],
            [-8.280703497744938, 7.687179673692157],
            [-8.221792364932199, 8.123328762235573],
            [-8.299048631208564, 8.316443589710303],
            [-8.20349890790088, 8.455453192575447],
            [-7.832100389019188, 8.575704250518626],
            [-8.07911373537435, 9.376223863152035],
            [-8.30961646161225, 9.789531968622441],
            [-8.229337124046822, 10.1290202905639],
            [-8.029943610048619, 10.206534939001713]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MLI.geojson',
        iso_a2: 'ML',
        label_y: 18.692713,
        label_x: -2.038455,
        name: 'Mali',
        formal_en: 'Republic of Mali'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-11.51394283695059, 12.442987575729418],
            [-11.467899135778524, 12.754518947800975],
            [-11.55339779300543, 13.141213690641067],
            [-11.927716030311615, 13.422075100147396],
            [-12.12488745772126, 13.994727484589788],
            [-12.170750291380301, 14.616834214735505],
            [-11.834207526079467, 14.79909699142894],
            [-11.666078253617854, 15.388208319556298],
            [-11.349095017939504, 15.411256008358478],
            [-10.650791388379417, 15.132745876521426],
            [-10.086846482778213, 15.330485744686271],
            [-9.700255092802706, 15.264107367407362],
            [-9.55023840985939, 15.486496893775438],
            [-5.537744309908447, 15.501689764869257],
            [-5.315277268891933, 16.20185374599184],
            [-5.488522508150439, 16.325102037007966],
            [-5.971128709324248, 20.64083344164763],
            [-6.453786586930335, 24.956590684503425],
            [-4.923337368174231, 24.974574082941],
            [-1.550054897457613, 22.792665920497384],
            [1.823227573259032, 20.610809434486043],
            [2.06099083823392, 20.142233384679486],
            [2.683588494486429, 19.856230170160117],
            [3.1466610042539, 19.693578599521445],
            [3.158133172222705, 19.057364203360038],
            [4.267419467800039, 19.155265204337],
            [4.270209995143802, 16.852227484601215],
            [3.723421665063483, 16.184283759012615],
            [3.638258904646477, 15.568119818580456],
            [2.749992709981484, 15.409524847876696],
            [1.385528191746858, 15.323561102759172],
            [1.015783318698482, 14.96818227788799],
            [0.374892205414682, 14.92890818934613],
            [-0.26625729003058, 14.924308986872148],
            [-0.515854458000348, 15.116157741755728],
            [-1.066363491205664, 14.973815009007765],
            [-2.001035122068771, 14.559008287000893],
            [-2.191824510090385, 14.246417548067356],
            [-2.967694464520577, 13.79815033615151],
            [-3.10370683431276, 13.541266791228594],
            [-3.522802700199861, 13.337661647998615],
            [-4.006390753587226, 13.472485459848116],
            [-4.28040503581488, 13.228443508349741],
            [-4.427166103523803, 12.542645575404295],
            [-5.220941941743121, 11.713858954307227],
            [-5.197842576508648, 11.37514577885014],
            [-5.470564947929006, 10.951269842976048],
            [-5.404341599946974, 10.370736802609146],
            [-5.816926235365287, 10.222554633012194],
            [-6.050452032892267, 10.096360785355444],
            [-6.205222947606431, 10.524060777219134],
            [-6.493965013037268, 10.411302801958271],
            [-6.666460944027548, 10.430810655148449],
            [-6.850506557635057, 10.138993841996239],
            [-7.622759161804809, 10.147236232946796],
            [-7.899589809592372, 10.297382106970828],
            [-8.029943610048619, 10.206534939001713],
            [-8.33537716310974, 10.494811916541934],
            [-8.282357143578281, 10.792597357623846],
            [-8.407310756860028, 10.909256903522762],
            [-8.620321010767128, 10.810890814655183],
            [-8.581305304386774, 11.136245632364805],
            [-8.376304897484914, 11.393645941610629],
            [-8.786099005559464, 11.812560939984706],
            [-8.90526485842453, 12.088358059126437],
            [-9.127473517279583, 12.308060411015333],
            [-9.327616339546012, 12.334286200403454],
            [-9.567911749703214, 12.194243068892476],
            [-9.890992804392013, 12.060478623904972],
            [-10.165213792348837, 11.844083563682744],
            [-10.593223842806282, 11.92397532800598],
            [-10.870829637078215, 12.17788747807211],
            [-11.03655595543826, 12.211244615116515],
            [-11.297573614944511, 12.077971096235771],
            [-11.456168585648271, 12.076834214725338],
            [-11.51394283695059, 12.442987575729418]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SEN.geojson',
        iso_a2: 'SN',
        label_y: 15.138125,
        label_x: -14.778586,
        name: 'Senegal',
        formal_en: 'Republic of Senegal'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.71372880702347, 13.594958604379855],
            [-17.126106736712615, 14.373515733289224],
            [-17.62504269049066, 14.729540513564073],
            [-17.18517289882223, 14.919477240452862],
            [-16.700706346085923, 15.621527411354108],
            [-16.463098110407884, 16.13503611903846],
            [-16.12069007004193, 16.455662543193384],
            [-15.623666144258692, 16.369337063049812],
            [-15.135737270558819, 16.587282416240782],
            [-14.577347581428981, 16.59826365810281],
            [-14.099521450242179, 16.304302273010492],
            [-13.435737677453062, 16.03938304286619],
            [-12.830658331747516, 15.303691514542948],
            [-12.170750291380301, 14.616834214735505],
            [-12.12488745772126, 13.994727484589788],
            [-11.927716030311615, 13.422075100147396],
            [-11.55339779300543, 13.141213690641067],
            [-11.467899135778524, 12.754518947800975],
            [-11.51394283695059, 12.442987575729418],
            [-11.658300950557932, 12.386582749882836],
            [-12.203564825885634, 12.465647691289405],
            [-12.27859900557344, 12.354440008997287],
            [-12.499050665730564, 12.332089952031057],
            [-13.217818162478238, 12.575873521367967],
            [-13.700476040084325, 12.586182969610194],
            [-15.54847693527401, 12.628170070847347],
            [-15.816574266004254, 12.515567124883347],
            [-16.147716844130585, 12.547761542201187],
            [-16.677451951554573, 12.384851589401052],
            [-16.841524624081273, 13.15139394780256],
            [-15.931295945692211, 13.130284125211332],
            [-15.691000535534995, 13.270353094938457],
            [-15.511812506562933, 13.278569647672867],
            [-15.141163295949468, 13.509511623585238],
            [-14.712197231494628, 13.298206691943777],
            [-14.277701788784555, 13.280585028532244],
            [-13.844963344772408, 13.505041612192002],
            [-14.046992356817482, 13.79406789800045],
            [-14.376713833055788, 13.625680243377374],
            [-14.687030808968487, 13.630356960499784],
            [-15.08173539881382, 13.876491807505985],
            [-15.39877031092446, 13.86036876063092],
            [-15.624596320039942, 13.62358734786956],
            [-16.71372880702347, 13.594958604379855]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NGA.geojson',
        iso_a2: 'NG',
        label_y: 9.439799,
        label_x: 7.50322,
        name: 'Nigeria',
        formal_en: 'Federal Republic of Nigeria'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [2.691701694356254, 6.258817246928629],
            [2.74906253420022, 7.870734361192888],
            [2.723792758809509, 8.50684540448971],
            [2.912308383810256, 9.137607937044322],
            [3.220351596702101, 9.444152533399702],
            [3.705438266625919, 10.063210354040208],
            [3.600070021182802, 10.332186184119408],
            [3.797112257511714, 10.734745591673105],
            [3.572216424177469, 11.32793935795152],
            [3.611180454125559, 11.660167141155968],
            [3.680633579125811, 12.552903347214226],
            [3.967282749048849, 12.956108710171575],
            [4.107945997747322, 13.531215725147831],
            [4.368343540066007, 13.747481594289411],
            [5.443058302440136, 13.865923977102227],
            [6.445426059605637, 13.492768459522678],
            [6.820441928747754, 13.115091254117518],
            [7.330746697630019, 13.0980380314612],
            [7.804671258178786, 13.343526923063747],
            [9.014933302454438, 12.826659247280418],
            [9.524928012742947, 12.851102199754479],
            [10.114814487354693, 13.27725189864941],
            [10.701031935273706, 13.246917832894084],
            [10.989593133191534, 13.387322699431195],
            [11.527803175511394, 13.328980007373588],
            [12.302071160540523, 13.037189032437524],
            [13.083987257548813, 13.596147162322495],
            [13.318701613018561, 13.556356309457826],
            [13.995352817448293, 12.461565253138303],
            [14.181336297266794, 12.483656927943116],
            [14.577177768622533, 12.085360826053503],
            [14.468192172918975, 11.904751695193411],
            [14.415378859116684, 11.572368882692075],
            [13.572949659894562, 10.798565985553566],
            [13.308676385153918, 10.160362046748928],
            [13.167599724997103, 9.640626328973411],
            [12.955467970438974, 9.417771714714704],
            [12.753671502339216, 8.717762762888995],
            [12.218872104550599, 8.305824082874324],
            [12.063946160539558, 7.799808457872302],
            [11.839308709366803, 7.397042344589437],
            [11.74577436691851, 6.981382961449754],
            [11.058787876030351, 6.644426784690594],
            [10.497375115611419, 7.055357774275564],
            [10.118276808318257, 7.03876963950988],
            [9.522705926154401, 6.453482367372117],
            [9.233162876023044, 6.444490668153335],
            [8.757532993208628, 5.479665839047911],
            [8.500287713259695, 4.771982937026849],
            [7.462108188515941, 4.412108262546241],
            [7.082596469764439, 4.464689032403228],
            [6.6980721370806, 4.240594183769517],
            [5.898172641634687, 4.262453314628985],
            [5.362804803090881, 4.887970689305959],
            [5.033574252959369, 5.611802476418234],
            [4.325607130560684, 6.270651149923467],
            [3.574180128604553, 6.258300482605719],
            [2.691701694356254, 6.258817246928629]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BEN.geojson',
        iso_a2: 'BJ',
        label_y: 10.324775,
        label_x: 2.352018,
        name: 'Benin',
        formal_en: 'Republic of Benin'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [2.691701694356254, 6.258817246928629],
            [1.865240512712319, 6.142157701029731],
            [1.618950636409238, 6.832038072126238],
            [1.664477573258381, 9.12859039960938],
            [1.46304284018467, 9.334624335157088],
            [1.425060662450136, 9.825395412633],
            [1.077795037448738, 10.175606594275024],
            [0.772335646171484, 10.470808213742359],
            [0.899563022474069, 10.99733938236426],
            [1.243469679376489, 11.110510769083461],
            [1.447178175471066, 11.547719224488858],
            [1.935985548519881, 11.641150214072553],
            [2.154473504249921, 11.940150051313339],
            [2.49016360841793, 12.233052069543675],
            [2.848643019226586, 12.23563589115821],
            [3.611180454125559, 11.660167141155968],
            [3.572216424177469, 11.32793935795152],
            [3.797112257511714, 10.734745591673105],
            [3.600070021182802, 10.332186184119408],
            [3.705438266625919, 10.063210354040208],
            [3.220351596702101, 9.444152533399702],
            [2.912308383810256, 9.137607937044322],
            [2.723792758809509, 8.50684540448971],
            [2.74906253420022, 7.870734361192888],
            [2.691701694356254, 6.258817246928629]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'AGO.geojson',
        iso_a2: 'AO',
        label_y: -12.182762,
        label_x: 17.984249,
        name: 'Angola',
        formal_en: "People's Republic of Angola"
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [12.995517205465177, -4.781103203961884],
              [12.63161176926579, -4.991271254092936],
              [12.468004184629736, -5.248361504745005],
              [12.436688266660868, -5.684303887559246],
              [12.182336866920252, -5.789930515163839],
              [11.91496300624209, -5.037986748884791],
              [12.318607618873926, -4.606230157086188],
              [12.620759718484493, -4.438023369976136],
              [12.995517205465177, -4.781103203961884]
            ]
          ],
          [
            [
              [12.32243167486351, -6.10009246177966],
              [12.735171339578699, -5.965682061388499],
              [13.024869419006961, -5.984388929878158],
              [13.375597364971895, -5.864241224799549],
              [16.326528354567046, -5.877470391466268],
              [16.573179965896145, -6.622644545115087],
              [16.8601908708452, -7.222297865429987],
              [17.08999596524717, -7.545688978712526],
              [17.472970004962235, -8.0685511206417],
              [18.13422163256905, -7.987677504104923],
              [18.464175652752687, -7.847014255406443],
              [19.01675174324967, -7.988245944860132],
              [19.16661339689611, -7.738183688999754],
              [19.41750247567316, -7.155428562044299],
              [20.037723016040218, -7.116361179231646],
              [20.091621534920648, -6.943090101756994],
              [20.6018229509383, -6.939317722199682],
              [20.5147481625265, -7.299605808138629],
              [21.7281107927397, -7.290872491081302],
              [21.74645592620331, -7.920084730667149],
              [21.949130893652043, -8.305900974158277],
              [21.8018013851879, -8.90870655684298],
              [21.875181919042348, -9.523707777548566],
              [22.208753289486395, -9.894796237836509],
              [22.155268182064308, -11.084801120653772],
              [22.402798292742375, -10.993075453335692],
              [22.83734541188474, -11.01762175867433],
              [23.45679080576744, -10.867863457892483],
              [23.912215203555718, -10.926826267137514],
              [24.01789350759259, -11.23729827234709],
              [23.904153680118185, -11.722281589406322],
              [24.079905226342845, -12.191296888887365],
              [23.930922072045377, -12.565847670138856],
              [24.016136508894675, -12.911046237848574],
              [21.933886346125917, -12.898437188369359],
              [21.887842644953874, -16.08031015387688],
              [22.56247846852426, -16.898451429921813],
              [23.215048455506064, -17.523116143465984],
              [21.377176141045567, -17.930636488519696],
              [18.956186964603603, -17.789094740472258],
              [18.263309360434164, -17.309950860262006],
              [14.209706658595024, -17.35310068122572],
              [14.05850141770901, -17.423380629142663],
              [13.462362094789967, -16.971211846588773],
              [12.814081251688407, -16.94134286872407],
              [12.215461460019355, -17.111668389558083],
              [11.734198846085121, -17.301889336824473],
              [11.64009606288161, -16.67314218512925],
              [11.778537224991537, -15.793816013250735],
              [12.12358076340439, -14.878316338767904],
              [12.175618930722294, -14.449143568583892],
              [12.50009524908299, -13.54769988368445],
              [12.738478631245385, -13.137905775609902],
              [13.312913852601866, -12.483630466362492],
              [13.6337211442698, -12.03864470789717],
              [13.738727654686897, -11.297863050993165],
              [13.686379428775238, -10.73107594161589],
              [13.387327915102162, -10.373578383020714],
              [13.120987583069846, -9.766897067914122],
              [12.875369500386569, -9.166933689005468],
              [12.92906131353783, -8.959091078327553],
              [13.236432732809874, -8.562629489784307],
              [12.93304039882429, -7.596538588087733],
              [12.728298374083892, -6.927122084178805],
              [12.227347039446471, -6.294447523629394],
              [12.32243167486351, -6.10009246177966]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BWA.geojson',
        iso_a2: 'BW',
        label_y: -22.102634,
        label_x: 24.179216,
        name: 'Botswana',
        formal_en: 'Republic of Botswana'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [29.43218834810904, -22.091312758067588],
            [28.01723595552525, -22.82775359465908],
            [27.119409620886245, -23.574323011979775],
            [26.786406691197413, -24.240690606383485],
            [26.4857532081233, -24.616326592713104],
            [25.94165205252216, -24.69637338633322],
            [25.76584882986521, -25.17484547292368],
            [25.66466637543772, -25.486816094669713],
            [25.025170525825786, -25.7196700985769],
            [24.211266717228796, -25.670215752873574],
            [23.73356977712271, -25.390129489851617],
            [23.312096795350186, -25.26868987396572],
            [22.8242712745149, -25.50045867279477],
            [22.57953169118059, -25.979447523708146],
            [22.105968865657868, -26.280256036079138],
            [21.605896030369394, -26.726533705351756],
            [20.88960900237174, -26.828542982695915],
            [20.66647016773544, -26.477453301704923],
            [20.75860924651184, -25.86813648855145],
            [20.16572553882719, -24.91796192800077],
            [19.895767856534434, -24.76779021576059],
            [19.89545779794068, -21.84915699634787],
            [20.88113406747587, -21.814327080983148],
            [20.910641310314535, -18.252218926672022],
            [21.655040317478978, -18.219146010005225],
            [23.1968583513393, -17.869038181227786],
            [23.579005568137717, -18.28126108162006],
            [24.217364536239213, -17.88934701911849],
            [24.520705193792537, -17.887124932529936],
            [25.08444339366457, -17.661815687737374],
            [25.264225701608012, -17.736539808831417],
            [25.649163445750162, -18.53602589281899],
            [25.85039147309473, -18.714412937090536],
            [26.164790887158485, -19.29308562589494],
            [27.296504754350508, -20.391519870691],
            [27.724747348753255, -20.49905852629039],
            [27.72722781750326, -20.851801853114715],
            [28.021370070108617, -21.485975030200585],
            [28.794656202924216, -21.63945403410745],
            [29.43218834810904, -22.091312758067588]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ZWE.geojson',
        iso_a2: 'ZW',
        label_y: -18.91164,
        label_x: 29.925444,
        name: 'Zimbabwe',
        formal_en: 'Republic of Zimbabwe'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.19140913262129, -22.2515096981724],
            [30.65986535006709, -22.151567478119915],
            [30.322883335091774, -22.271611830333935],
            [29.839036899542972, -22.102216485281176],
            [29.43218834810904, -22.091312758067588],
            [28.794656202924216, -21.63945403410745],
            [28.021370070108617, -21.485975030200585],
            [27.72722781750326, -20.851801853114715],
            [27.724747348753255, -20.49905852629039],
            [27.296504754350508, -20.391519870691],
            [26.164790887158485, -19.29308562589494],
            [25.85039147309473, -18.714412937090536],
            [25.649163445750162, -18.53602589281899],
            [25.264225701608012, -17.736539808831417],
            [26.381935255648926, -17.8460421688579],
            [26.70677330903564, -17.961228936436484],
            [27.044427117630732, -17.938026218337434],
            [27.59824344250276, -17.290830580314008],
            [28.467906121542683, -16.468400160388846],
            [28.8258687680285, -16.389748630440614],
            [28.947463413211267, -16.04305144619444],
            [29.516834344203147, -15.644677829656388],
            [30.27425581230511, -15.507786960515213],
            [30.338954705534544, -15.880839125230246],
            [31.17306399915768, -15.860943698797874],
            [31.636498243951195, -16.071990248277885],
            [31.8520406430406, -16.319417006091378],
            [32.32823896661023, -16.392074069893752],
            [32.847638787575846, -16.713398125884616],
            [32.84986087416439, -17.97905730557718],
            [32.65488569512715, -18.672089939043495],
            [32.61199425632489, -19.419382826416275],
            [32.772707960752626, -19.715592136313298],
            [32.65974327976258, -20.304290052982317],
            [32.50869306817344, -20.395292250248307],
            [32.244988234188014, -21.116488539313693],
            [31.19140913262129, -22.2515096981724]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TCD.geojson',
        iso_a2: 'TD',
        label_y: 15.142959,
        label_x: 18.645041,
        name: 'Chad',
        formal_en: 'Republic of Chad'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.83766000000014, 19.580470000000105],
            [23.886890000000108, 15.61084],
            [23.024590000000103, 15.68072],
            [22.56795000000011, 14.944290000000137],
            [22.30351, 14.32682],
            [22.51202, 14.09318],
            [22.18329, 13.78648],
            [22.29658, 13.37232],
            [22.03759, 12.95546],
            [21.93681, 12.588180000000136],
            [22.28801, 12.64605],
            [22.49762, 12.26024],
            [22.50869, 11.67936],
            [22.87622, 11.384610000000123],
            [22.864165480244225, 11.142395127807546],
            [22.23112918466876, 10.97188873946061],
            [21.723821648859456, 10.567055568885976],
            [21.000868361096167, 9.475985215691509],
            [20.05968549976427, 9.012706000194854],
            [19.09400800952602, 9.07484691002584],
            [18.812009718509273, 8.982914536978598],
            [18.911021762780507, 8.630894680206353],
            [18.38955488452322, 8.281303615751824],
            [17.964929640380888, 7.890914008002994],
            [16.705988396886255, 7.508327541529979],
            [16.456184523187346, 7.734773667832968],
            [16.290561557691888, 7.754307359239419],
            [16.106231723706742, 7.497087917506462],
            [15.279460483469109, 7.421924546737969],
            [15.436091749745742, 7.692812404811889],
            [15.120865512765306, 8.382150173369439],
            [14.97999555833769, 8.796104234243472],
            [14.54446658698177, 8.965861314322268],
            [13.954218377344006, 9.549494940626687],
            [14.171466098699028, 10.021378282099931],
            [14.62720055508106, 9.920919297724538],
            [14.909353875394716, 9.992129421422732],
            [15.467872755605242, 9.982336737503545],
            [14.92356489427496, 10.891325181517473],
            [14.9601518083376, 11.555574042197224],
            [14.89336, 12.21905],
            [14.495787387762846, 12.85939626713733],
            [14.595781284247607, 13.33042694747786],
            [13.95447675950561, 13.353448798063766],
            [13.956698846094127, 13.996691189016929],
            [13.540393507550789, 14.367133693901224],
            [13.97217, 15.68437],
            [15.247731154041844, 16.627305813050782],
            [15.30044111497972, 17.927949937405003],
            [15.685740594147774, 19.957180080642388],
            [15.903246697664315, 20.387618923417506],
            [15.487148064850146, 20.730414537025638],
            [15.47106, 21.04845],
            [15.096887648181848, 21.30851878507491],
            [14.8513, 22.862950000000126],
            [15.86085, 23.40972],
            [19.84926, 21.49509],
            [23.83766000000014, 19.580470000000105]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'DZA.geojson',
        iso_a2: 'DZ',
        label_y: 27.397406,
        label_x: 2.808241,
        name: 'Algeria',
        formal_en: "People's Democratic Republic of Algeria"
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-8.684399786809053, 27.395744126896005],
            [-8.665124477564191, 27.589479071558227],
            [-8.665589565454809, 27.656425889592356],
            [-8.674116176782974, 28.84128896739658],
            [-7.059227667661958, 29.5792284205246],
            [-6.060632290053774, 29.731699734001694],
            [-5.242129278982787, 30.00044302013559],
            [-4.859646165374471, 30.501187649043846],
            [-3.690441046554724, 30.896951605751156],
            [-3.647497931320146, 31.637294012980675],
            [-3.068980271812648, 31.724497992473218],
            [-2.616604783529567, 32.09434621838619],
            [-1.30789913573787, 32.2628889023061],
            [-1.124551153966308, 32.65152151135713],
            [-1.388049282222596, 32.86401500094138],
            [-1.733454555661467, 33.91971283623212],
            [-1.792985805661715, 34.527918606091305],
            [-2.169913702798624, 35.16839630791668],
            [-1.208602871089056, 35.7148487411871],
            [-0.127454392894606, 35.888662421200806],
            [0.503876580415209, 36.30127289483528],
            [1.466918572606545, 36.605647081034405],
            [3.161698846050825, 36.78390493422522],
            [4.81575809084913, 36.86503693292346],
            [5.320120070017794, 36.71651886651662],
            [6.261819695672613, 37.11065501560674],
            [7.330384962603971, 37.11838064223437],
            [7.737078484741005, 36.885707505840216],
            [8.420964389691676, 36.94642731378316],
            [8.217824334352315, 36.433176988260286],
            [8.376367628623768, 35.479876003555944],
            [8.140981479534304, 34.65514598239379],
            [7.524481642292244, 34.09737641045146],
            [7.612641635782182, 33.34411489514896],
            [8.430472853233368, 32.74833730725595],
            [8.439102817426118, 32.50628489840082],
            [9.05560265466815, 32.10269196220129],
            [9.482139926805274, 30.307556057246188],
            [9.805634392952356, 29.424638373323376],
            [9.859997999723447, 28.959989732371014],
            [9.683884718472768, 28.1441738957792],
            [9.756128370816782, 27.688258571884205],
            [9.629056023811074, 27.14095347748092],
            [9.716285841519664, 26.512206325785655],
            [9.319410841518163, 26.094324856057455],
            [9.910692579801776, 25.365454616796796],
            [9.94826134607797, 24.936953640232517],
            [10.303846876678362, 24.379313259370917],
            [10.771363559622927, 24.56253205006175],
            [11.560669386449005, 24.097909247325518],
            [11.999505649471613, 23.47166840259645],
            [8.572893100629784, 21.565660712159143],
            [5.677565952180686, 19.601206976799716],
            [4.267419467800039, 19.155265204337],
            [3.158133172222705, 19.057364203360038],
            [3.1466610042539, 19.693578599521445],
            [2.683588494486429, 19.856230170160117],
            [2.06099083823392, 20.142233384679486],
            [1.823227573259032, 20.610809434486043],
            [-1.550054897457613, 22.792665920497384],
            [-4.923337368174231, 24.974574082941],
            [-8.684399786809053, 27.395744126896005]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MOZ.geojson',
        iso_a2: 'MZ',
        label_y: -13.94323,
        label_x: 37.83789,
        name: 'Mozambique',
        formal_en: 'Republic of Mozambique'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [34.55998904799935, -11.520020033415925],
            [35.31239790216904, -11.439146416879147],
            [36.51408165868426, -11.720938002166735],
            [36.775150994622805, -11.594537448780805],
            [37.47129, -11.56876],
            [37.82764, -11.26879],
            [38.42755659358775, -11.285202325081656],
            [39.521, -10.89688],
            [40.31659, -10.317099999999868],
            [40.316586229110854, -10.317097752817492],
            [40.31658857601719, -10.317096042525698],
            [40.47838748552303, -10.765440769089993],
            [40.437253045418686, -11.761710707245015],
            [40.56081139502857, -12.639176527561027],
            [40.59962039567975, -14.201975192931862],
            [40.775475294768995, -14.691764418194241],
            [40.4772506040126, -15.406294447493972],
            [40.08926395036522, -16.10077402106446],
            [39.45255862809705, -16.72089120856694],
            [38.53835086442152, -17.101023044505958],
            [37.41113284683888, -17.586368096591237],
            [36.28127933120936, -18.65968759529345],
            [35.89649661636406, -18.842260430580637],
            [35.198399692533144, -19.552811374593894],
            [34.78638349787005, -19.784011732667736],
            [34.70189253107284, -20.49704314543101],
            [35.176127150215365, -21.25436126066841],
            [35.37342776870574, -21.840837090748877],
            [35.385848253705404, -22.14],
            [35.562545536369086, -22.09],
            [35.533934767404304, -23.070787855727758],
            [35.37177412287238, -23.5353589820317],
            [35.60747033055563, -23.706563002214683],
            [35.45874555841962, -24.12260995859655],
            [35.04073489761066, -24.478350518493805],
            [34.21582400893547, -24.81631438568266],
            [33.01321007663901, -25.357573337507738],
            [32.574632195777866, -25.727318210556092],
            [32.66036339695009, -26.148584486599447],
            [32.91595503106569, -26.215867201443466],
            [32.830120477028885, -26.742191664336197],
            [32.07166548028107, -26.73382008230491],
            [31.98577924981197, -26.291779880480227],
            [31.83777794772806, -25.84333180105135],
            [31.75240848158188, -25.484283949487413],
            [31.930588820124253, -24.36941659922254],
            [31.670397983534656, -23.658969008073864],
            [31.19140913262129, -22.2515096981724],
            [32.244988234188014, -21.116488539313693],
            [32.50869306817344, -20.395292250248307],
            [32.65974327976258, -20.304290052982317],
            [32.772707960752626, -19.715592136313298],
            [32.61199425632489, -19.419382826416275],
            [32.65488569512715, -18.672089939043495],
            [32.84986087416439, -17.97905730557718],
            [32.847638787575846, -16.713398125884616],
            [32.32823896661023, -16.392074069893752],
            [31.8520406430406, -16.319417006091378],
            [31.636498243951195, -16.071990248277885],
            [31.17306399915768, -15.860943698797874],
            [30.338954705534544, -15.880839125230246],
            [30.27425581230511, -15.507786960515213],
            [30.17948123548183, -14.796099134991529],
            [33.214024692525214, -13.971860039936153],
            [33.789700148256685, -14.45183074306307],
            [34.064825473778626, -14.35995004644812],
            [34.45963341648854, -14.613009535381423],
            [34.51766604995231, -15.013708591372612],
            [34.307291294092096, -15.478641452702597],
            [34.38129194513405, -16.183559665596043],
            [35.033810255683534, -16.801299737213093],
            [35.339062941231646, -16.10744028083011],
            [35.77190473810836, -15.896858819240727],
            [35.68684533055594, -14.611045830954332],
            [35.26795617039801, -13.887834161029566],
            [34.907151320136165, -13.565424899960568],
            [34.55998904799935, -13.579997653866876],
            [34.28000613784198, -12.280025323132506],
            [34.55998904799935, -11.520020033415925]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SWZ.geojson',
        iso_a2: 'SZ',
        label_y: -26.533676,
        label_x: 31.467264,
        name: 'eSwatini',
        formal_en: 'Kingdom of eSwatini'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [32.07166548028107, -26.73382008230491],
            [31.86806033705108, -27.177927341421277],
            [31.28277306491333, -27.285879408478998],
            [30.68596194837448, -26.743845310169533],
            [30.67660851412964, -26.398078301704608],
            [30.949666782359913, -26.02264902110415],
            [31.04407962415715, -25.731452325139443],
            [31.333157586397903, -25.66019052500895],
            [31.83777794772806, -25.84333180105135],
            [31.98577924981197, -26.291779880480227],
            [32.07166548028107, -26.73382008230491]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BDI.geojson',
        iso_a2: 'BI',
        label_y: -3.332836,
        label_x: 29.917086,
        name: 'Burundi',
        formal_en: 'Republic of Burundi'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.469673645761223, -2.41385475710134],
            [30.52766, -2.80762],
            [30.74301, -3.03431],
            [30.75224, -3.35931],
            [30.50554, -3.56858],
            [30.11632, -4.09012],
            [29.753512404099865, -4.452389418153302],
            [29.339997592900346, -4.499983412294092],
            [29.276383904749053, -3.293907159034063],
            [29.024926385216787, -2.839257907730158],
            [29.632176141078588, -2.917857761246097],
            [29.93835900240794, -2.348486830254238],
            [30.469673645761223, -2.41385475710134]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'RWA.geojson',
        iso_a2: 'RW',
        label_y: -1.897196,
        label_x: 30.103894,
        name: 'Rwanda',
        formal_en: 'Republic of Rwanda'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [30.419104852019245, -1.134659112150416],
            [30.816134881317712, -1.698914076345389],
            [30.75830895358311, -2.287250257988369],
            [30.46967, -2.41383],
            [30.469673645761223, -2.41385475710134],
            [29.93835900240794, -2.348486830254238],
            [29.632176141078588, -2.917857761246097],
            [29.024926385216787, -2.839257907730158],
            [29.117478875451553, -2.292211195488385],
            [29.254834832483343, -2.215109958508911],
            [29.291886834436614, -1.620055840667987],
            [29.579466180140884, -1.341313164885626],
            [29.821518588996014, -1.443322442229785],
            [30.419104852019245, -1.134659112150416]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'UGA.geojson',
        iso_a2: 'UG',
        label_y: 1.972589,
        label_x: 32.948555,
        name: 'Uganda',
        formal_en: 'Republic of Uganda'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [33.90371119710453, -0.95],
            [31.86617, -1.02736],
            [30.769860000000108, -1.01455],
            [30.419104852019245, -1.134659112150416],
            [29.821518588996014, -1.443322442229785],
            [29.579466180140884, -1.341313164885626],
            [29.58783776217217, -0.587405694179381],
            [29.819503208136638, -0.205310153813372],
            [29.875778842902434, 0.597379868976361],
            [30.086153598762706, 1.062312730306289],
            [30.46850752129029, 1.583805446779706],
            [30.85267011894806, 1.849396470543809],
            [31.174149204235817, 2.204465236821264],
            [30.77334679538004, 2.339883327642127],
            [30.83385989759381, 3.509165961110341],
            [30.833852421715427, 3.509171604222463],
            [31.24556, 3.7819],
            [31.88145, 3.55827],
            [32.68642, 3.79232],
            [33.3900000000001, 3.79],
            [34.005, 4.249884947362048],
            [34.47913, 3.5556],
            [34.59607, 3.053740000000118],
            [35.03599, 1.90584],
            [34.6721, 1.17694],
            [34.18, 0.515],
            [33.893568969666944, 0.109813537861896],
            [33.90371119710453, -0.95]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LSO.geojson',
        iso_a2: 'LS',
        label_y: -29.480158,
        label_x: 28.246639,
        name: 'Lesotho',
        formal_en: 'Kingdom of Lesotho'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.978262566857243, -28.95559661226171],
            [29.32516645683259, -29.257386976846256],
            [29.018415154748027, -29.74376555757737],
            [28.84839969250774, -30.070050551068256],
            [28.29106937023991, -30.2262167294543],
            [28.107204624145425, -30.54573211031495],
            [27.749397006956485, -30.645105889612225],
            [26.999261915807637, -29.875953871379984],
            [27.532511020627478, -29.24271087007536],
            [28.074338413207784, -28.851468601193588],
            [28.541700066855498, -28.64750172293757],
            [28.978262566857243, -28.95559661226171]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CMR.geojson',
        iso_a2: 'CM',
        label_y: 4.585041,
        label_x: 12.473488,
        name: 'Cameroon',
        formal_en: 'Republic of Cameroon'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.495787387762846, 12.85939626713733],
            [14.89336, 12.21905],
            [14.9601518083376, 11.555574042197224],
            [14.92356489427496, 10.891325181517473],
            [15.467872755605242, 9.982336737503545],
            [14.909353875394716, 9.992129421422732],
            [14.62720055508106, 9.920919297724538],
            [14.171466098699028, 10.021378282099931],
            [13.954218377344006, 9.549494940626687],
            [14.54446658698177, 8.965861314322268],
            [14.97999555833769, 8.796104234243472],
            [15.120865512765306, 8.382150173369439],
            [15.436091749745742, 7.692812404811889],
            [15.279460483469109, 7.421924546737969],
            [14.776545444404576, 6.408498033062045],
            [14.536560092841114, 6.22695872642069],
            [14.459407179429348, 5.4517605656103],
            [14.558935988023507, 5.03059764243153],
            [14.47837243008047, 4.732605495620447],
            [14.950953403389661, 4.210389309094921],
            [15.036219516671252, 3.851367295747124],
            [15.405395948964383, 3.33530060466434],
            [15.862732374747482, 3.013537298998983],
            [15.907380812247652, 2.557389431158612],
            [16.012852410555354, 2.267639675298085],
            [15.940918816805066, 1.727672634280296],
            [15.146341993885244, 1.964014797367184],
            [14.33781253424658, 2.227874660649491],
            [13.075822381246752, 2.267097072759015],
            [12.951333855855609, 2.32161570882694],
            [12.359380323952221, 2.19281220133945],
            [11.75166548019979, 2.326757513839993],
            [11.276449008843713, 2.261050930180872],
            [9.649158155972628, 2.283866075037736],
            [9.795195753629457, 3.073404445809117],
            [9.404366896206, 3.734526882335203],
            [8.948115675501072, 3.904128933117136],
            [8.744923943729418, 4.35221527751996],
            [8.48881554529089, 4.495617377129918],
            [8.500287713259695, 4.771982937026849],
            [8.757532993208628, 5.479665839047911],
            [9.233162876023044, 6.444490668153335],
            [9.522705926154401, 6.453482367372117],
            [10.118276808318257, 7.03876963950988],
            [10.497375115611419, 7.055357774275564],
            [11.058787876030351, 6.644426784690594],
            [11.74577436691851, 6.981382961449754],
            [11.839308709366803, 7.397042344589437],
            [12.063946160539558, 7.799808457872302],
            [12.218872104550599, 8.305824082874324],
            [12.753671502339216, 8.717762762888995],
            [12.955467970438974, 9.417771714714704],
            [13.167599724997103, 9.640626328973411],
            [13.308676385153918, 10.160362046748928],
            [13.572949659894562, 10.798565985553566],
            [14.415378859116684, 11.572368882692075],
            [14.468192172918975, 11.904751695193411],
            [14.577177768622533, 12.085360826053503],
            [14.181336297266794, 12.483656927943116],
            [14.213530714584635, 12.802035427293347],
            [14.495787387762846, 12.85939626713733]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GAB.geojson',
        iso_a2: 'GA',
        label_y: -0.437739,
        label_x: 11.835939,
        name: 'Gabon',
        formal_en: 'Gabonese Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.276449008843713, 2.261050930180872],
            [11.75166548019979, 2.326757513839993],
            [12.359380323952221, 2.19281220133945],
            [12.951333855855609, 2.32161570882694],
            [13.075822381246752, 2.267097072759015],
            [13.003113641012078, 1.83089630778332],
            [13.282631463278818, 1.31418366129688],
            [14.026668735417218, 1.395677395021153],
            [14.276265903386957, 1.196929836426619],
            [13.843320753645656, 0.038757635901149],
            [14.316418491277744, -0.552627455247048],
            [14.425455763413595, -1.333406670744971],
            [14.299210239324566, -1.998275648612214],
            [13.99240726080771, -2.4708049454891],
            [13.109618767965628, -2.428740329603514],
            [12.575284458067642, -1.948511244315135],
            [12.495702752338161, -2.391688327650243],
            [11.820963575903193, -2.514161472181982],
            [11.478038771214303, -2.765618991714241],
            [11.855121697648116, -3.426870619321051],
            [11.093772820691925, -3.978826592630547],
            [10.06613528813574, -2.969482517105682],
            [9.40524539555497, -2.144313246269043],
            [8.79799563969317, -1.111301364754496],
            [8.830086704146424, -0.779073581550037],
            [9.048419630579588, -0.459351494960217],
            [9.29135053878369, 0.268666083167687],
            [9.492888624721985, 1.010119533691494],
            [9.830284051155644, 1.067893784993799],
            [11.285078973036462, 1.057661851400013],
            [11.276449008843713, 2.261050930180872]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NER.geojson',
        iso_a2: 'NE',
        label_y: 17.446195,
        label_x: 9.504356,
        name: 'Niger',
        formal_en: 'Republic of Niger'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.8513, 22.862950000000126],
            [15.096887648181848, 21.30851878507491],
            [15.47106, 21.04845],
            [15.487148064850146, 20.730414537025638],
            [15.903246697664315, 20.387618923417506],
            [15.685740594147774, 19.957180080642388],
            [15.30044111497972, 17.927949937405003],
            [15.247731154041844, 16.627305813050782],
            [13.97217, 15.68437],
            [13.540393507550789, 14.367133693901224],
            [13.956698846094127, 13.996691189016929],
            [13.95447675950561, 13.353448798063766],
            [14.595781284247607, 13.33042694747786],
            [14.495787387762846, 12.85939626713733],
            [14.213530714584635, 12.802035427293347],
            [14.181336297266794, 12.483656927943116],
            [13.995352817448293, 12.461565253138303],
            [13.318701613018561, 13.556356309457826],
            [13.083987257548813, 13.596147162322495],
            [12.302071160540523, 13.037189032437524],
            [11.527803175511394, 13.328980007373588],
            [10.989593133191534, 13.387322699431195],
            [10.701031935273706, 13.246917832894084],
            [10.114814487354693, 13.27725189864941],
            [9.524928012742947, 12.851102199754479],
            [9.014933302454438, 12.826659247280418],
            [7.804671258178786, 13.343526923063747],
            [7.330746697630019, 13.0980380314612],
            [6.820441928747754, 13.115091254117518],
            [6.445426059605637, 13.492768459522678],
            [5.443058302440136, 13.865923977102227],
            [4.368343540066007, 13.747481594289411],
            [4.107945997747322, 13.531215725147831],
            [3.967282749048849, 12.956108710171575],
            [3.680633579125811, 12.552903347214226],
            [3.611180454125559, 11.660167141155968],
            [2.848643019226586, 12.23563589115821],
            [2.49016360841793, 12.233052069543675],
            [2.154473504249921, 11.940150051313339],
            [2.177107781593776, 12.625017808477535],
            [1.024103224297477, 12.851825669806574],
            [0.993045688490071, 13.335749620003824],
            [0.429927605805517, 13.988733018443924],
            [0.295646396495101, 14.444234930880654],
            [0.374892205414682, 14.92890818934613],
            [1.015783318698482, 14.96818227788799],
            [1.385528191746858, 15.323561102759172],
            [2.749992709981484, 15.409524847876696],
            [3.638258904646477, 15.568119818580456],
            [3.723421665063483, 16.184283759012615],
            [4.270209995143802, 16.852227484601215],
            [4.267419467800039, 19.155265204337],
            [5.677565952180686, 19.601206976799716],
            [8.572893100629784, 21.565660712159143],
            [11.999505649471613, 23.47166840259645],
            [13.581424594790462, 23.04050608976928],
            [14.143870883855243, 22.491288967371133],
            [14.8513, 22.862950000000126]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BFA.geojson',
        iso_a2: 'BF',
        label_y: 12.673048,
        label_x: -1.36388,
        name: 'Burkina Faso',
        formal_en: 'Burkina Faso'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-5.404341599946974, 10.370736802609146],
            [-5.470564947929006, 10.951269842976048],
            [-5.197842576508648, 11.37514577885014],
            [-5.220941941743121, 11.713858954307227],
            [-4.427166103523803, 12.542645575404295],
            [-4.28040503581488, 13.228443508349741],
            [-4.006390753587226, 13.472485459848116],
            [-3.522802700199861, 13.337661647998615],
            [-3.10370683431276, 13.541266791228594],
            [-2.967694464520577, 13.79815033615151],
            [-2.191824510090385, 14.246417548067356],
            [-2.001035122068771, 14.559008287000893],
            [-1.066363491205664, 14.973815009007765],
            [-0.515854458000348, 15.116157741755728],
            [-0.26625729003058, 14.924308986872148],
            [0.374892205414682, 14.92890818934613],
            [0.295646396495101, 14.444234930880654],
            [0.429927605805517, 13.988733018443924],
            [0.993045688490071, 13.335749620003824],
            [1.024103224297477, 12.851825669806574],
            [2.177107781593776, 12.625017808477535],
            [2.154473504249921, 11.940150051313339],
            [1.935985548519881, 11.641150214072553],
            [1.447178175471066, 11.547719224488858],
            [1.243469679376489, 11.110510769083461],
            [0.899563022474069, 10.99733938236426],
            [0.023802524423701, 11.018681748900804],
            [-0.438701544588582, 11.098340969278722],
            [-0.761575893548183, 10.936929633015055],
            [-1.203357713211432, 11.009819240762738],
            [-2.940409308270461, 10.962690334512558],
            [-2.963896246747112, 10.395334784380083],
            [-2.827496303712707, 9.642460842319778],
            [-3.511898972986273, 9.90032623945622],
            [-3.980449184576685, 9.8623440617217],
            [-4.330246954760383, 9.610834865757141],
            [-4.779883592131966, 9.821984768101743],
            [-4.954653286143099, 10.152713934769736],
            [-5.404341599946974, 10.370736802609146]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'TGO.geojson',
        iso_a2: 'TG',
        label_y: 8.80722,
        label_x: 1.058113,
        name: 'Togo',
        formal_en: 'Togolese Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.899563022474069, 10.99733938236426],
            [0.772335646171484, 10.470808213742359],
            [1.077795037448738, 10.175606594275024],
            [1.425060662450136, 9.825395412633],
            [1.46304284018467, 9.334624335157088],
            [1.664477573258381, 9.12859039960938],
            [1.618950636409238, 6.832038072126238],
            [1.865240512712319, 6.142157701029731],
            [1.060121697604927, 5.928837388528876],
            [0.836931186536333, 6.279978745952149],
            [0.570384148774849, 6.914358628767189],
            [0.490957472342245, 7.411744289576475],
            [0.712029249686879, 8.31246450442383],
            [0.461191847342121, 8.677222601756014],
            [0.365900506195885, 9.465003973829482],
            [0.367579990245389, 10.19121287682718],
            [-0.049784715159944, 10.706917832883931],
            [0.023802524423701, 11.018681748900804],
            [0.899563022474069, 10.99733938236426]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GHA.geojson',
        iso_a2: 'GH',
        label_y: 7.717639,
        label_x: -1.036941,
        name: 'Ghana',
        formal_en: 'Republic of Ghana'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [0.023802524423701, 11.018681748900804],
            [-0.049784715159944, 10.706917832883931],
            [0.367579990245389, 10.19121287682718],
            [0.365900506195885, 9.465003973829482],
            [0.461191847342121, 8.677222601756014],
            [0.712029249686879, 8.31246450442383],
            [0.490957472342245, 7.411744289576475],
            [0.570384148774849, 6.914358628767189],
            [0.836931186536333, 6.279978745952149],
            [1.060121697604927, 5.928837388528876],
            [-0.507637905265938, 5.343472601742675],
            [-1.063624640294194, 5.000547797053812],
            [-1.964706590167594, 4.710462144383371],
            [-2.856125047202397, 4.994475816259509],
            [-2.81070146321784, 5.38905121502411],
            [-3.244370083011262, 6.250471503113502],
            [-2.983584967450327, 7.379704901555513],
            [-2.562189500326241, 8.219627793811483],
            [-2.827496303712707, 9.642460842319778],
            [-2.963896246747112, 10.395334784380083],
            [-2.940409308270461, 10.962690334512558],
            [-1.203357713211432, 11.009819240762738],
            [-0.761575893548183, 10.936929633015055],
            [-0.438701544588582, 11.098340969278722],
            [0.023802524423701, 11.018681748900804]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GNB.geojson',
        iso_a2: 'GW',
        label_y: 12.163712,
        label_x: -14.52413,
        name: 'Guinea-Bissau',
        formal_en: 'Republic of Guinea-Bissau'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.677451951554573, 12.384851589401052],
            [-16.147716844130585, 12.547761542201187],
            [-15.816574266004254, 12.515567124883347],
            [-15.54847693527401, 12.628170070847347],
            [-13.700476040084325, 12.586182969610194],
            [-13.718743658899513, 12.24718557377551],
            [-13.828271857142125, 12.142644151249044],
            [-13.743160773157413, 11.811269029177412],
            [-13.900799729863776, 11.678718980348748],
            [-14.121406419317779, 11.677117010947697],
            [-14.382191534878729, 11.509271958863692],
            [-14.685687221728898, 11.527823798056488],
            [-15.130311245168173, 11.040411688679526],
            [-15.664180467175527, 11.458474025920795],
            [-16.085214199273565, 11.52459402103824],
            [-16.314786749730203, 11.80651479740655],
            [-16.30894731288123, 11.95870189050612],
            [-16.61383826340328, 12.170911159712702],
            [-16.677451951554573, 12.384851589401052]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'EGY.geojson',
        iso_a2: 'EG',
        label_y: 26.186173,
        label_x: 29.445837,
        name: 'Egypt',
        formal_en: 'Arab Republic of Egypt'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [36.86623, 22],
            [32.9, 22],
            [29.02, 22],
            [25, 22],
            [25, 25.682499996361],
            [25, 29.23865452953346],
            [24.70007, 30.04419],
            [24.95762, 30.6616],
            [24.80287, 31.08929],
            [25.16482, 31.56915],
            [26.49533, 31.58568],
            [27.45762, 31.32126],
            [28.45048, 31.02577],
            [28.91353, 30.87005],
            [29.68342, 31.18686],
            [30.09503, 31.4734],
            [30.97693, 31.55586],
            [31.68796, 31.4296],
            [31.96041, 30.9336],
            [32.19247, 31.26034],
            [32.99392, 31.02407],
            [33.7734, 30.96746],
            [34.26543474464621, 31.21935730952032],
            [34.26544, 31.21936],
            [34.823243288783814, 29.76108076171822],
            [34.9226, 29.50133],
            [34.64174, 29.09942],
            [34.42655, 28.34399],
            [34.15451, 27.8233],
            [33.92136, 27.6487],
            [33.58811, 27.97136],
            [33.13676, 28.41765],
            [32.42323, 29.85108],
            [32.32046, 29.76043],
            [32.73482, 28.70523],
            [33.34876, 27.69989],
            [34.10455, 26.14227],
            [34.47387, 25.59856],
            [34.79507, 25.03375],
            [35.69241, 23.92671],
            [35.49372, 23.75237],
            [35.52598, 23.10244],
            [36.69069, 22.20485],
            [36.86623, 22]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MRT.geojson',
        iso_a2: 'MR',
        label_y: 19.587062,
        label_x: -9.740299,
        name: 'Mauritania',
        formal_en: 'Islamic Republic of Mauritania'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-17.06342322434257, 20.999752102130827],
            [-16.845193650773993, 21.33332347257488],
            [-12.929101935263532, 21.327070624267563],
            [-13.118754441774712, 22.771220201096256],
            [-12.874221564169575, 23.284832261645178],
            [-11.937224493853321, 23.374594224536168],
            [-11.96941891117116, 25.933352769468268],
            [-8.6872936670174, 25.881056219988906],
            [-8.684399786809053, 27.395744126896005],
            [-4.923337368174231, 24.974574082941],
            [-6.453786586930335, 24.956590684503425],
            [-5.971128709324248, 20.64083344164763],
            [-5.488522508150439, 16.325102037007966],
            [-5.315277268891933, 16.20185374599184],
            [-5.537744309908447, 15.501689764869257],
            [-9.55023840985939, 15.486496893775438],
            [-9.700255092802706, 15.264107367407362],
            [-10.086846482778213, 15.330485744686271],
            [-10.650791388379417, 15.132745876521426],
            [-11.349095017939504, 15.411256008358478],
            [-11.666078253617854, 15.388208319556298],
            [-11.834207526079467, 14.79909699142894],
            [-12.170750291380301, 14.616834214735505],
            [-12.830658331747516, 15.303691514542948],
            [-13.435737677453062, 16.03938304286619],
            [-14.099521450242179, 16.304302273010492],
            [-14.577347581428981, 16.59826365810281],
            [-15.135737270558819, 16.587282416240782],
            [-15.623666144258692, 16.369337063049812],
            [-16.12069007004193, 16.455662543193384],
            [-16.463098110407884, 16.13503611903846],
            [-16.549707810929064, 16.673892116761962],
            [-16.270551723688357, 17.166962795474873],
            [-16.14634741867485, 18.108481553616656],
            [-16.256883307347167, 19.096715806550307],
            [-16.37765112961327, 19.593817246981985],
            [-16.277838100641517, 20.0925206568147],
            [-16.536323614965468, 20.567866319251493],
            [-17.06342322434257, 20.999752102130827]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GNQ.geojson',
        iso_a2: 'GQ',
        label_y: 2.333,
        label_x: 8.9902,
        name: 'Eq. Guinea',
        formal_en: 'Republic of Equatorial Guinea'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.649158155972628, 2.283866075037736],
            [11.276449008843713, 2.261050930180872],
            [11.285078973036462, 1.057661851400013],
            [9.830284051155644, 1.067893784993799],
            [9.492888624721985, 1.010119533691494],
            [9.305613234096256, 1.160911363119183],
            [9.649158155972628, 2.283866075037736]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GMB.geojson',
        iso_a2: 'GM',
        label_y: 13.641721,
        label_x: -14.998318,
        name: 'Gambia',
        formal_en: 'Republic of the Gambia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-16.71372880702347, 13.594958604379855],
            [-15.624596320039942, 13.62358734786956],
            [-15.39877031092446, 13.86036876063092],
            [-15.08173539881382, 13.876491807505985],
            [-14.687030808968487, 13.630356960499784],
            [-14.376713833055788, 13.625680243377374],
            [-14.046992356817482, 13.79406789800045],
            [-13.844963344772408, 13.505041612192002],
            [-14.277701788784555, 13.280585028532244],
            [-14.712197231494628, 13.298206691943777],
            [-15.141163295949468, 13.509511623585238],
            [-15.511812506562933, 13.278569647672867],
            [-15.691000535534995, 13.270353094938457],
            [-15.931295945692211, 13.130284125211332],
            [-16.841524624081273, 13.15139394780256],
            [-16.71372880702347, 13.594958604379855]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MDG.geojson',
        iso_a2: 'MG',
        label_y: -18.628288,
        label_x: 46.704241,
        name: 'Madagascar',
        formal_en: 'Republic of Madagascar'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [49.54351891459575, -12.469832858940554],
            [49.808980747279094, -12.895284925999555],
            [50.056510857957164, -13.555761407121985],
            [50.21743126811407, -14.758788750876796],
            [50.47653689962553, -15.226512139550543],
            [50.377111443895956, -15.706069431219127],
            [50.20027469259318, -16.000263360256767],
            [49.86060550313868, -15.414252618066918],
            [49.67260664246086, -15.710203545802479],
            [49.863344354050156, -16.451036879138776],
            [49.77456424337271, -16.8750420060936],
            [49.49861209493412, -17.106035658438273],
            [49.435618523970305, -17.953064060134366],
            [49.041792433473944, -19.118781019774445],
            [48.54854088724801, -20.496888116134127],
            [47.93074913919867, -22.391501153251085],
            [47.54772342305131, -23.781958916928517],
            [47.095761346226595, -24.941629733990453],
            [46.282477654817086, -25.178462823184105],
            [45.40950768411045, -25.60143442149309],
            [44.833573846217554, -25.34610116953894],
            [44.03972049334976, -24.988345228782308],
            [43.76376834491117, -24.46067717864999],
            [43.697777540874455, -23.574116306250602],
            [43.345654331237625, -22.776903985283873],
            [43.254187046081, -22.057413018484123],
            [43.43329756040464, -21.33647511158019],
            [43.893682895692926, -21.16330738697013],
            [43.896370070172104, -20.830459486578174],
            [44.37432539243966, -20.07236622485639],
            [44.46439741392439, -19.435454196859048],
            [44.23242190936617, -18.961994724200906],
            [44.042976108584156, -18.33138722094317],
            [43.96308434426091, -17.409944756746782],
            [44.31246870298628, -16.850495700754955],
            [44.4465173683514, -16.216219170804507],
            [44.94493655780653, -16.1793738745804],
            [45.50273196796499, -15.97437346767854],
            [45.87299360533626, -15.793454278224687],
            [46.31224327981721, -15.780018405828798],
            [46.882182651564285, -15.210182386946315],
            [47.70512983581236, -14.594302666891764],
            [48.005214878131255, -14.091232598530375],
            [47.869047479042166, -13.663868503476586],
            [48.29382775248138, -13.784067884987486],
            [48.84506025573879, -13.089174899958664],
            [48.86350874206698, -12.48786793381042],
            [49.194651320193316, -12.04055673589197],
            [49.54351891459575, -12.469832858940554]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'FRA.geojson',
        iso_a2: '-99',
        label_y: 46.696113,
        label_x: 2.552275,
        name: 'France',
        formal_en: 'French Republic'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-51.65779741067889, 4.156232408053029],
              [-52.249337531123956, 3.241094468596245],
              [-52.55642473001842, 2.504705308437053],
              [-52.939657151894956, 2.124857692875636],
              [-53.41846513529531, 2.053389187015981],
              [-53.554839240113544, 2.334896551925951],
              [-53.77852067728892, 2.376702785650082],
              [-54.08806250671725, 2.105556545414629],
              [-54.524754197799716, 2.311848863123785],
              [-54.2697051662232, 2.732391669115046],
              [-54.181726040246275, 3.189779771330421],
              [-54.00693050801901, 3.620037746592558],
              [-54.399542202356514, 4.212611395683467],
              [-54.47863298197923, 4.896755682795586],
              [-53.9580446030709, 5.756548163267765],
              [-53.618452928264844, 5.646529038918374],
              [-52.88214128275409, 5.409850979021584],
              [-51.8233428615259, 4.565768133966131],
              [-51.65779741067889, 4.156232408053029]
            ]
          ],
          [
            [
              [6.186320428094177, 49.463802802114515],
              [6.658229607783568, 49.20195831969157],
              [8.099278598674744, 49.01778351500333],
              [7.593676385131062, 48.33301911070372],
              [7.466759067422231, 47.62058197691181],
              [7.192202182655507, 47.44976552997102],
              [6.736571079138059, 47.541801255882845],
              [6.768713820023606, 47.2877082383037],
              [6.037388950229001, 46.725778713561866],
              [6.022609490593538, 46.27298981382047],
              [6.500099724970426, 46.42967275652944],
              [6.843592970414505, 45.99114655210061],
              [6.802355177445605, 45.70857982032864],
              [7.096652459347837, 45.33309886329589],
              [6.749955275101655, 45.02851797136758],
              [7.007562290076635, 44.25476675066136],
              [7.549596388386107, 44.12790110938481],
              [7.435184767291872, 43.69384491634922],
              [6.52924523278304, 43.128892320318315],
              [4.556962517931424, 43.399650987311595],
              [3.100410597352663, 43.075200507167054],
              [2.985998976258458, 42.47301504166986],
              [1.826793247087153, 42.34338471126569],
              [0.701590610363894, 42.795734361332606],
              [0.338046909190581, 42.57954600683955],
              [-1.502770961910528, 43.03401439063043],
              [-1.901351284177764, 43.42280202897834],
              [-1.384225226232985, 44.022610378590116],
              [-1.193797573237418, 46.01491771095486],
              [-2.225724249673846, 47.06436269793822],
              [-2.963276129559603, 47.57032664650795],
              [-4.491554938159481, 47.954954332056374],
              [-4.592349819344776, 48.68416046812699],
              [-3.295813971357802, 48.90169240985963],
              [-1.616510789384961, 48.64442129169454],
              [-1.933494025063311, 49.776341864615745],
              [-0.98946895995536, 49.34737580016091],
              [1.338761020522696, 50.12717316344526],
              [1.6390010921385, 50.9466063502975],
              [2.513573032246143, 51.14850617126183],
              [2.658422071960274, 50.796848049515745],
              [3.123251580425688, 50.78036326761455],
              [3.588184441755658, 50.37899241800356],
              [4.286022983425084, 49.907496649772554],
              [4.799221632515724, 49.98537303323637],
              [5.674051954784829, 49.529483547557504],
              [5.897759230176348, 49.44266714130711],
              [6.186320428094177, 49.463802802114515]
            ]
          ],
          [
            [
              [8.746009148807559, 42.62812185319392],
              [9.390000848028876, 43.00998484961471],
              [9.560016310269134, 42.15249197037952],
              [9.229752231491773, 41.380006822264455],
              [8.775723097375362, 41.58361196549443],
              [8.544212680707773, 42.25651662858306],
              [8.746009148807559, 42.62812185319392]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'UKR.geojson',
        iso_a2: 'UA',
        label_y: 49.724739,
        label_x: 32.140865,
        name: 'Ukraine',
        formal_en: 'Ukraine'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.78599244755525, 52.1016775699397],
            [32.15944000000013, 52.06125000000014],
            [32.41205813978769, 52.28869497334978],
            [32.715760532367085, 52.23846548116211],
            [33.75269982273579, 52.3350745713318],
            [34.39173058445709, 51.768881740925906],
            [34.141978387190534, 51.566413479206346],
            [34.22481570815427, 51.25599315042888],
            [35.02218305841791, 51.2075723333715],
            [35.37791, 50.77394],
            [35.356116163887975, 50.57719737405904],
            [36.62616784032542, 50.225590928745135],
            [37.39345950699516, 50.38395335550365],
            [38.01063113785693, 49.9156615260747],
            [38.59498823421342, 49.9264619004237],
            [40.06904000000014, 49.60105],
            [40.080789015469406, 49.30742991799934],
            [39.67465, 48.78382000000016],
            [39.89562000000018, 48.23241],
            [39.738277622238854, 47.89893707945197],
            [38.77057, 47.825620000000185],
            [38.25511233902978, 47.54640045835691],
            [38.223538038899335, 47.10218984637595],
            [37.4251371599899, 47.02222056740419],
            [36.75985477066445, 46.69870026304102],
            [35.82368452326489, 46.64596446388714],
            [34.96234174982385, 46.27319651954974],
            [35.01265897004737, 45.73772519982549],
            [34.861792128174045, 45.76818243191957],
            [34.73201738827845, 45.96566573176062],
            [34.41040172853718, 46.005162391728845],
            [33.69946184910907, 46.219572831556434],
            [33.435988094713366, 45.971917370797485],
            [33.29856733575474, 46.08059845639781],
            [31.744140252415207, 46.33334788673733],
            [31.67530724460255, 46.70624502215554],
            [30.74874881360921, 46.583100084004116],
            [30.377608676888883, 46.03241018328572],
            [29.603289015427436, 45.293308010431126],
            [29.149724969201653, 45.464925442072456],
            [28.67977949393938, 45.304030870131704],
            [28.23355350109904, 45.48828318946829],
            [28.485269402792767, 45.5969070501459],
            [28.65998742037158, 45.93998688413164],
            [28.933717482221596, 46.25883047137256],
            [28.862972446414062, 46.43788930926383],
            [29.07210696789929, 46.517677720722496],
            [29.170653924279804, 46.37926239682872],
            [29.759971958136394, 46.34998769793536],
            [30.024658644335375, 46.42393667254504],
            [29.838210076626297, 46.52532583270169],
            [29.908851759569302, 46.67436066343146],
            [29.559674106573112, 46.928582872091326],
            [29.415135125452736, 47.34664520933258],
            [29.05086795422727, 47.5102269557525],
            [29.12269819511303, 47.849095160506465],
            [28.670891147585166, 48.1181485052341],
            [28.259546746541844, 48.15556224221342],
            [27.522537469195157, 48.467119452501116],
            [26.857823520624805, 48.368210761094495],
            [26.619336785597795, 48.22072622333347],
            [26.19745039236693, 48.22088125263035],
            [25.9459411964024, 47.987148749374214],
            [25.20774336111299, 47.89105642352747],
            [24.866317172960578, 47.737525743188314],
            [24.40205610525038, 47.98187775328043],
            [23.76095828623741, 47.985598456405455],
            [23.142236362406805, 48.09634105080695],
            [22.710531447040495, 47.88219391538941],
            [22.640819939878725, 48.150239569687415],
            [22.085608351334855, 48.42226430927179],
            [22.28084191253356, 48.82539215758067],
            [22.558137648211755, 49.085738023467144],
            [22.776418898212626, 49.02739533140962],
            [22.518450148211603, 49.47677358661974],
            [23.426508416444392, 50.30850576435745],
            [23.92275719574326, 50.42488108987874],
            [24.029985792748903, 50.70540660257518],
            [23.52707075368437, 51.57845408793031],
            [24.00507775238418, 51.617443956094405],
            [24.553106316839518, 51.888461005249184],
            [25.32778771332701, 51.91065603291855],
            [26.337958611768556, 51.83228872334797],
            [27.454066196408405, 51.592303371784396],
            [28.24161502453657, 51.57222707783907],
            [28.61761274589225, 51.42771393493484],
            [28.992835320763533, 51.602044379271476],
            [29.25493818534784, 51.36823436136689],
            [30.157363722460897, 51.41613841410147],
            [30.555117221811457, 51.31950348571566],
            [30.619454380014815, 51.822806098022454],
            [30.927549269338982, 52.04235342061439],
            [31.78599244755525, 52.1016775699397]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BLR.geojson',
        iso_a2: 'BY',
        label_y: 53.821888,
        label_x: 28.417701,
        name: 'Belarus',
        formal_en: 'Republic of Belarus'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.17670942557794, 56.16912995057879],
            [29.229513380660308, 55.91834422466636],
            [29.371571893030673, 55.670090643936184],
            [29.896294386522356, 55.78946320253041],
            [30.87390913262001, 55.55097646750341],
            [30.971835971813135, 55.08154775656404],
            [30.75753380709872, 54.81177094178432],
            [31.38447228366374, 54.15705638286238],
            [31.79142418796224, 53.974638576872124],
            [31.731272820774507, 53.79402944601202],
            [32.405598585751164, 53.61804535584204],
            [32.69364301934604, 53.35142080343218],
            [32.30451948418823, 53.13272614197291],
            [31.49764, 53.16743000000014],
            [31.305200636528014, 53.07399587667321],
            [31.54001834486226, 52.74205231384636],
            [31.78597, 52.1016800000001],
            [31.78599244755525, 52.1016775699397],
            [30.927549269338982, 52.04235342061439],
            [30.619454380014815, 51.822806098022454],
            [30.555117221811457, 51.31950348571566],
            [30.157363722460897, 51.41613841410147],
            [29.25493818534784, 51.36823436136689],
            [28.992835320763533, 51.602044379271476],
            [28.61761274589225, 51.42771393493484],
            [28.24161502453657, 51.57222707783907],
            [27.454066196408405, 51.592303371784396],
            [26.337958611768556, 51.83228872334797],
            [25.32778771332701, 51.91065603291855],
            [24.553106316839518, 51.888461005249184],
            [24.00507775238418, 51.617443956094405],
            [23.52707075368437, 51.57845408793031],
            [23.508002150168693, 52.02364655212473],
            [23.199493849386187, 52.48697744405367],
            [23.79919884613338, 52.69109935160657],
            [23.80493493011778, 53.089731350306074],
            [23.527535841575002, 53.470121568406555],
            [23.48412763844985, 53.91249766704114],
            [24.450683628037037, 53.905702216194754],
            [25.536353794056993, 54.28242340760253],
            [25.7684326514798, 54.84696259217509],
            [26.58827924979039, 55.16717560487167],
            [26.494331495883756, 55.615106919977634],
            [27.10245975109453, 55.783313707087686],
            [28.17670942557794, 56.16912995057879]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LTU.geojson',
        iso_a2: 'LT',
        label_y: 55.103703,
        label_x: 24.089932,
        name: 'Lithuania',
        formal_en: 'Republic of Lithuania'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.494331495883756, 55.615106919977634],
            [26.58827924979039, 55.16717560487167],
            [25.7684326514798, 54.84696259217509],
            [25.536353794056993, 54.28242340760253],
            [24.450683628037037, 53.905702216194754],
            [23.48412763844985, 53.91249766704114],
            [23.24398725758951, 54.22056671814914],
            [22.731098667092652, 54.327536932993326],
            [22.65105187347254, 54.582740993866736],
            [22.75776370615526, 54.85657440858138],
            [22.315723504330577, 55.015298570365864],
            [21.268448927503467, 55.190481675835315],
            [21.055800408622417, 56.031076361711065],
            [22.201156853939494, 56.33780182557949],
            [23.878263787539964, 56.27367137310527],
            [24.86068444184076, 56.37252838807963],
            [25.000934279080894, 56.16453074810484],
            [25.533046502390334, 56.100296942766036],
            [26.494331495883756, 55.615106919977634]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'RUS.geojson',
        iso_a2: 'RU',
        label_y: 58.249357,
        label_x: 44.686469,
        name: 'Russia',
        formal_en: 'Russian Federation'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [178.7253, 71.0988],
              [180, 71.51571433642829],
              [180, 70.83219920854673],
              [178.903425, 70.78114],
              [178.7253, 71.0988]
            ]
          ],
          [
            [
              [49.10116000000011, 46.399330000000134],
              [48.64541, 45.806290000000104],
              [47.67591, 45.64149000000015],
              [46.68201, 44.6092000000001],
              [47.59094, 43.66016],
              [47.49252, 42.98658000000012],
              [48.58437000000015, 41.80888],
              [48.58435339611342, 41.80886879162067],
              [47.98728315612598, 41.40581920019423],
              [47.81566572448463, 41.15141612402135],
              [47.37331546406623, 41.2197323675112],
              [46.68607059101661, 41.82713715266988],
              [46.404950799348825, 41.860675157227305],
              [45.7764, 42.09244000000018],
              [45.47027916848572, 42.50278066666998],
              [44.537622918481986, 42.71199270280363],
              [43.931210000000135, 42.55496000000011],
              [43.75599000000011, 42.74083],
              [42.39440000000013, 43.2203],
              [40.922190000000114, 43.38215000000014],
              [40.07696495947977, 43.553104153002316],
              [39.955008579270924, 43.43499766699922],
              [38.68, 44.28],
              [37.53912, 44.65721],
              [36.6754600000001, 45.244690000000105],
              [37.4031700000001, 45.40451000000013],
              [38.23295, 46.24087],
              [37.67372, 46.63657],
              [39.14767000000012, 47.044750000000136],
              [39.1212, 47.26336],
              [38.223538038899335, 47.10218984637595],
              [38.25511233902978, 47.54640045835691],
              [38.77057, 47.825620000000185],
              [39.738277622238854, 47.89893707945197],
              [39.89562000000018, 48.23241],
              [39.67465, 48.78382000000016],
              [40.080789015469406, 49.30742991799934],
              [40.06904000000014, 49.60105],
              [38.59498823421342, 49.9264619004237],
              [38.01063113785693, 49.9156615260747],
              [37.39345950699516, 50.38395335550365],
              [36.62616784032542, 50.225590928745135],
              [35.356116163887975, 50.57719737405904],
              [35.37791, 50.77394],
              [35.02218305841791, 51.2075723333715],
              [34.22481570815427, 51.25599315042888],
              [34.141978387190534, 51.566413479206346],
              [34.39173058445709, 51.768881740925906],
              [33.75269982273579, 52.3350745713318],
              [32.715760532367085, 52.23846548116211],
              [32.41205813978769, 52.28869497334978],
              [32.15944000000013, 52.06125000000014],
              [31.78599244755525, 52.1016775699397],
              [31.78597, 52.1016800000001],
              [31.54001834486226, 52.74205231384636],
              [31.305200636528014, 53.07399587667321],
              [31.49764, 53.16743000000014],
              [32.30451948418823, 53.13272614197291],
              [32.69364301934604, 53.35142080343218],
              [32.405598585751164, 53.61804535584204],
              [31.731272820774507, 53.79402944601202],
              [31.79142418796224, 53.974638576872124],
              [31.38447228366374, 54.15705638286238],
              [30.75753380709872, 54.81177094178432],
              [30.971835971813135, 55.08154775656404],
              [30.87390913262001, 55.55097646750341],
              [29.896294386522356, 55.78946320253041],
              [29.371571893030673, 55.670090643936184],
              [29.229513380660308, 55.91834422466636],
              [28.17670942557794, 56.16912995057879],
              [27.855282016722526, 56.75932648378429],
              [27.77001590344093, 57.24425812441123],
              [27.288184848751513, 57.47452830670383],
              [27.71668582531572, 57.79189911562436],
              [27.42015000000015, 58.72457000000014],
              [28.13169925305175, 59.300825100330925],
              [27.98112, 59.47537000000011],
              [27.981126857000987, 59.47537333432527],
              [29.1177, 60.02805000000012],
              [28.070001921525666, 60.50351912796823],
              [28.07, 60.50352],
              [30.21110721204445, 61.780027777749694],
              [31.139991082490894, 62.35769277612441],
              [31.516092156711125, 62.86768748641289],
              [30.035872430142717, 63.55281362573855],
              [30.44468468600371, 64.20445343693909],
              [29.54442955904699, 64.94867157659048],
              [30.21765, 65.80598],
              [29.054588657352326, 66.94428620062206],
              [29.97742638522061, 67.69829702419275],
              [28.445943637818658, 68.36461294216404],
              [28.591929559043194, 69.06477692328666],
              [29.39955, 69.15692000000013],
              [31.101042202597625, 69.55810108805622],
              [31.10108, 69.55811000000011],
              [32.13272000000018, 69.90595000000019],
              [33.77547, 69.30142000000012],
              [36.51396, 69.06342],
              [40.29234000000014, 67.9324],
              [41.05987, 67.45713000000012],
              [41.1259500000001, 66.79158],
              [40.01583, 66.26618000000013],
              [38.38295, 65.9995300000001],
              [33.91871000000015, 66.75961],
              [33.18444, 66.63253],
              [34.814770000000124, 65.90015000000017],
              [34.87857425307874, 65.4362128770482],
              [34.94391, 64.41437000000019],
              [36.23129, 64.10945],
              [37.01273, 63.84983000000011],
              [37.141970000000185, 64.33471],
              [36.53957903508979, 64.76446],
              [37.17604, 65.14322000000016],
              [39.59345, 64.5207900000002],
              [40.4356, 64.76446],
              [39.76260000000019, 65.49682],
              [42.09309000000013, 66.47623],
              [43.01604, 66.41858000000013],
              [43.94975000000011, 66.06908],
              [44.53226, 66.75634000000014],
              [43.69839, 67.35245],
              [44.18795000000017, 67.95051000000012],
              [43.45282, 68.57079],
              [46.250000000000114, 68.25],
              [46.82134, 67.68997],
              [45.55517, 67.56652],
              [45.56202000000013, 67.01005000000015],
              [46.34915000000012, 66.6676700000001],
              [47.89416000000017, 66.8845500000001],
              [48.13876, 67.52238],
              [50.22766000000013, 67.99867000000017],
              [53.71743000000015, 68.85738000000015],
              [54.47171, 68.80815],
              [53.48582000000016, 68.20131],
              [54.72628, 68.09702],
              [55.44268000000011, 68.43866],
              [57.31702, 68.46628],
              [58.802000000000135, 68.88082],
              [59.94142000000011, 68.27844],
              [61.07784000000021, 68.94069],
              [60.03, 69.52],
              [60.55, 69.85],
              [63.50400000000013, 69.54739],
              [64.888115, 69.234835],
              [68.51216000000016, 68.09233000000012],
              [69.18068000000011, 68.61563000000012],
              [68.16444, 69.14436],
              [68.13522, 69.35649000000012],
              [66.93008000000015, 69.45461000000012],
              [67.25976, 69.92873],
              [66.72492000000017, 70.70889000000017],
              [66.69466, 71.02897000000019],
              [68.54006, 71.93450000000018],
              [69.19636, 72.84336000000019],
              [69.94, 73.04],
              [72.58754, 72.77629000000013],
              [72.79603, 72.22006],
              [71.84811, 71.40898],
              [72.47011, 71.09019],
              [72.79188, 70.39114],
              [72.56470000000013, 69.02085],
              [73.66787, 68.4079],
              [73.2387, 67.7404],
              [71.28, 66.3200000000001],
              [72.42301, 66.17267000000021],
              [72.82077, 66.53267],
              [73.92099000000013, 66.78946000000013],
              [74.18651000000011, 67.28429],
              [75.052, 67.76047000000011],
              [74.46926000000013, 68.32899],
              [74.93584, 68.98918],
              [73.84236, 69.07146],
              [73.60187000000019, 69.62763],
              [74.3998, 70.63175],
              [73.1011, 71.4471700000002],
              [74.89082000000019, 72.12119000000013],
              [74.65926, 72.83227],
              [75.1580100000001, 72.85497000000015],
              [75.68351000000013, 72.30056000000013],
              [75.28898, 71.3355600000001],
              [76.35911, 71.15287000000018],
              [75.90313000000015, 71.87401],
              [77.57665, 72.26717],
              [79.6520200000001, 72.32011],
              [81.5, 71.75],
              [80.61071, 72.58285000000012],
              [80.51109, 73.6482],
              [82.25, 73.85000000000014],
              [84.65526, 73.80591000000021],
              [86.82230000000015, 73.93688],
              [86.00956, 74.45967000000019],
              [87.16682000000014, 75.11643],
              [88.31571, 75.14393],
              [90.26, 75.64],
              [92.90058, 75.7733300000001],
              [93.23421, 76.0472],
              [95.86000000000018, 76.1400000000001],
              [96.67821, 75.91548],
              [98.92254000000014, 76.44689],
              [100.75967000000014, 76.43028],
              [101.03532, 76.86189],
              [101.9908400000001, 77.28754000000015],
              [104.35160000000013, 77.69792],
              [106.06664000000018, 77.37389],
              [104.70500000000015, 77.1274],
              [106.97013, 76.97419],
              [107.24000000000012, 76.48],
              [108.1538000000001, 76.72335],
              [111.0772600000002, 76.71],
              [113.33151, 76.22224],
              [114.13417, 75.84764],
              [113.88539, 75.32779000000016],
              [112.77918, 75.03186000000011],
              [110.15125000000012, 74.47673],
              [109.4, 74.18],
              [110.64, 74.04],
              [112.11919, 73.78774000000016],
              [113.01954000000023, 73.97693000000015],
              [113.52958000000024, 73.33505000000014],
              [113.96881, 73.59488],
              [115.56782, 73.75285],
              [118.77633000000014, 73.58772],
              [119.02, 73.12],
              [123.20066, 72.97122],
              [123.25777000000016, 73.73503],
              [125.38000000000011, 73.56],
              [126.97644, 73.56549000000012],
              [128.59126, 73.03871],
              [129.05157, 72.39872],
              [128.46, 71.98],
              [129.71599000000015, 71.19304000000011],
              [131.28858000000025, 70.78699000000012],
              [132.25350000000014, 71.83630000000011],
              [133.85766000000024, 71.3864200000001],
              [135.56193, 71.65525000000014],
              [137.49755, 71.34763],
              [138.23409000000015, 71.62803],
              [139.86983, 71.48783000000014],
              [139.14791, 72.41619000000014],
              [140.46817, 72.84941000000015],
              [149.5, 72.2],
              [150.3511800000001, 71.60643],
              [152.9689000000002, 70.84222],
              [157.00688, 71.03141],
              [158.99779, 70.86672],
              [159.83031000000017, 70.45324],
              [159.70866, 69.72198],
              [160.9405300000003, 69.4372800000001],
              [162.27907, 69.64204],
              [164.05248, 69.66823],
              [165.94037000000014, 69.47199],
              [167.83567, 69.58269000000013],
              [169.5776300000001, 68.6938],
              [170.8168800000002, 69.01363],
              [170.0082000000001, 69.65276],
              [170.4534500000002, 70.09703],
              [173.64391000000012, 69.81743],
              [175.7240300000002, 69.87725000000017],
              [178.6, 69.4],
              [180, 68.96363636363651],
              [180, 64.97970870219848],
              [179.99281, 64.97433],
              [178.70720000000028, 64.53493],
              [177.41128, 64.60821],
              [178.3130000000001, 64.07593],
              [178.90825000000018, 63.25197],
              [179.37034, 62.98262000000011],
              [179.48636, 62.56894],
              [179.22825, 62.304100000000176],
              [177.3643, 62.5219],
              [174.56929000000025, 61.76915],
              [173.68013, 61.65261],
              [172.15, 60.95],
              [170.6985, 60.33618000000013],
              [170.33085000000028, 59.88177],
              [168.90046, 60.57355],
              [166.29498000000024, 59.78855000000016],
              [165.84000000000015, 60.16],
              [164.87674, 59.7316],
              [163.53929, 59.86871],
              [163.21711000000016, 59.21101],
              [162.01733, 58.24328],
              [162.05297, 57.83912],
              [163.19191, 57.615030000000104],
              [163.05794000000014, 56.159240000000125],
              [162.12958000000015, 56.12219],
              [161.70146, 55.285680000000184],
              [162.11749, 54.85514],
              [160.36877000000027, 54.34433],
              [160.02173000000016, 53.20257],
              [158.5309400000001, 52.958680000000186],
              [158.23118, 51.94269],
              [156.78979000000027, 51.01105],
              [156.42, 51.7],
              [155.99182, 53.15895],
              [155.43366, 55.38103000000012],
              [155.9144200000003, 56.767920000000174],
              [156.75815, 57.3647],
              [156.81035, 57.83204],
              [158.3643300000001, 58.05575],
              [160.15064, 59.31477],
              [161.87204, 60.343],
              [163.66969, 61.1409000000001],
              [164.47355, 62.55061],
              [163.25842000000011, 62.46627000000012],
              [162.65791, 61.6425],
              [160.12148, 60.54423],
              [159.30232, 61.7739600000001],
              [156.72068, 61.43442],
              [154.21806000000026, 59.75818000000015],
              [155.04375, 59.14495],
              [152.81185, 58.88385],
              [151.2657300000002, 58.78089],
              [151.33815, 59.50396],
              [149.78371, 59.655730000000176],
              [148.54481, 59.16448],
              [145.48722, 59.33637],
              [142.19782, 59.03998000000013],
              [138.95848000000024, 57.08805],
              [135.12619, 54.72959],
              [136.70171, 54.603550000000155],
              [137.19342, 53.97732],
              [138.1647, 53.7550100000002],
              [138.80463, 54.25455],
              [139.90151, 54.18968000000012],
              [141.34531, 53.08957000000015],
              [141.37923, 52.23877],
              [140.59742000000017, 51.2396700000001],
              [140.51308, 50.045530000000156],
              [140.0619300000002, 48.44671000000011],
              [138.5547200000002, 46.99965],
              [138.21971, 46.30795],
              [136.86232, 45.14350000000013],
              [135.51535000000013, 43.989],
              [134.86939000000018, 43.39821],
              [133.53687000000025, 42.81147],
              [132.90627, 42.79849000000013],
              [132.27807000000018, 43.28456000000011],
              [130.93587000000014, 42.55274],
              [130.78000485358513, 42.22001036108258],
              [130.78000366004676, 42.220007813203225],
              [130.78, 42.22000000000014],
              [130.77999231657833, 42.22000960427719],
              [130.6400000000001, 42.395],
              [130.63999970690955, 42.39502427522179],
              [130.63386640840974, 42.90301463477053],
              [131.1446879416149, 42.92998973242689],
              [131.28855512911548, 44.11151968034828],
              [131.02519000000018, 44.96796],
              [131.8834542176595, 45.32116160743652],
              [133.09712000000013, 45.14409],
              [133.76964399631288, 46.116926988299014],
              [134.1123500000001, 47.21248],
              [134.50081, 47.57845],
              [135.02631147678665, 48.478229885443874],
              [133.37359581922794, 48.18344167743487],
              [132.50669, 47.78896],
              [130.9872600000001, 47.79013],
              [130.58229332898242, 48.72968740497614],
              [129.39781782442043, 49.44060008401544],
              [127.65740000000028, 49.760270000000105],
              [127.28745568248485, 50.73979726826548],
              [126.93915652883766, 51.35389415140592],
              [126.56439904185697, 51.7842554795327],
              [125.94634891164617, 52.79279857035698],
              [125.06821129771038, 53.16104482686886],
              [123.57147, 53.4588],
              [122.24574791879283, 53.431725979213695],
              [121.00308475147017, 53.25140106873119],
              [120.17708865771681, 52.75388621684124],
              [120.72578901579197, 52.516226304730935],
              [120.7382, 51.96411],
              [120.18208000000016, 51.64355000000012],
              [119.27939, 50.58292],
              [119.2884607280258, 50.142882798862004],
              [117.8792444194264, 49.510983384796965],
              [116.67880089728612, 49.888531399121405],
              [115.48569542853136, 49.80517731383475],
              [114.96210981655018, 50.140247300815126],
              [114.36245649623527, 50.24830272073741],
              [112.89773969935436, 49.54356537535699],
              [111.58123091028662, 49.37796824807769],
              [110.66201053267878, 49.13012807880585],
              [109.40244917199664, 49.292960516957635],
              [108.47516727095129, 49.28254771585074],
              [107.86817589725095, 49.793705145865815],
              [106.8888041524553, 50.27429596618032],
              [105.88659142458675, 50.406019192092224],
              [104.62158, 50.27532000000019],
              [103.67654544476022, 50.089966132195116],
              [102.25589, 50.51056000000011],
              [102.06521, 51.25991],
              [100.88948042196262, 51.51685578063832],
              [99.98173221232354, 51.63400625264399],
              [98.86149051310034, 52.04736603454669],
              [97.82573978067431, 51.01099518493318],
              [98.23176150919156, 50.422400621128745],
              [97.25976000000014, 49.72605],
              [95.8140200000002, 49.97746000000012],
              [94.81594933469873, 50.01343333597085],
              [94.14756635943559, 50.48053660745717],
              [93.10421, 50.49529],
              [92.23471154171968, 50.80217072204172],
              [90.71366743364068, 50.33181183532109],
              [88.80556684769552, 49.47052073831242],
              [87.75126427607671, 49.297197984405486],
              [87.35997033076265, 49.21498078062912],
              [86.82935672398963, 49.82667470966817],
              [85.54126997268247, 49.69285858824816],
              [85.11555952346203, 50.11730296487764],
              [84.41637739455308, 50.311399644565824],
              [83.93511478061885, 50.88924551045358],
              [83.38300377801238, 51.069182847693924],
              [81.94598554883993, 50.81219594990637],
              [80.56844689323549, 51.38833649352847],
              [80.03555952344169, 50.86475088154725],
              [77.80091556184425, 53.404414984747575],
              [76.52517947785475, 54.17700348572714],
              [76.89110029491343, 54.49052440044193],
              [74.38482000000016, 53.54685000000012],
              [73.42567874542044, 53.489810289109755],
              [73.5085160663844, 54.0356167669766],
              [72.22415001820218, 54.376655381886735],
              [71.18013105660941, 54.13328522400826],
              [70.86526655465514, 55.169733588270105],
              [69.06816694527288, 55.38525014914353],
              [68.16910037625883, 54.97039175070432],
              [65.66687, 54.60125000000011],
              [65.17853356309593, 54.35422781027211],
              [61.43660000000017, 54.00625],
              [60.978066440683165, 53.66499339457914],
              [61.699986199800605, 52.97999644633427],
              [60.73999311711458, 52.71998647725775],
              [60.92726850774028, 52.44754832621504],
              [59.967533807215545, 51.9604204372157],
              [61.58800337102417, 51.272658799843214],
              [61.337424350840934, 50.79907013610426],
              [59.93280724471549, 50.842194118851864],
              [59.642282342370606, 50.545442206415714],
              [58.36332, 51.06364],
              [56.77798000000013, 51.04355],
              [55.71694, 50.62171000000018],
              [54.532878452376224, 51.02623973245932],
              [52.32872358583097, 51.718652248738124],
              [50.76664839051216, 51.6927623561599],
              [48.70238162618102, 50.60512848571284],
              [48.57784142435753, 49.87475962991567],
              [47.54948042174931, 50.454698391311126],
              [46.75159630716274, 49.35600576435377],
              [47.04367150247651, 49.152038886097614],
              [46.46644575377627, 48.39415233010493],
              [47.31524000000013, 47.7158500000001],
              [48.05725, 47.74377],
              [48.694733514201744, 47.07562816017793],
              [48.59325000000018, 46.56104],
              [49.10116000000011, 46.399330000000134]
            ]
          ],
          [
            [
              [93.77766, 81.0246],
              [95.940895, 81.2504],
              [97.88385, 80.746975],
              [100.186655, 79.780135],
              [99.93976, 78.88094],
              [97.75794, 78.7562],
              [94.97259, 79.044745],
              [93.31288, 79.4265],
              [92.5454, 80.14379],
              [91.18107, 80.34146],
              [93.77766, 81.0246]
            ]
          ],
          [
            [
              [102.837815, 79.28129],
              [105.37243, 78.71334],
              [105.07547, 78.30689],
              [99.43814, 77.921],
              [101.2649, 79.23399],
              [102.08635, 79.34641],
              [102.837815, 79.28129]
            ]
          ],
          [
            [
              [138.831075, 76.13676],
              [141.471615, 76.09289],
              [145.086285, 75.562625],
              [144.3, 74.82],
              [140.61381, 74.84768],
              [138.95544, 74.61148],
              [136.97439, 75.26167],
              [137.51176, 75.94917],
              [138.831075, 76.13676]
            ]
          ],
          [
            [
              [148.22223, 75.345845],
              [150.73167, 75.08406],
              [149.575925, 74.68892],
              [147.977465, 74.778355],
              [146.11919, 75.17298],
              [146.358485, 75.49682],
              [148.22223, 75.345845]
            ]
          ],
          [
            [
              [139.86312, 73.36983],
              [140.81171, 73.76506],
              [142.06207, 73.85758],
              [143.48283, 73.47525],
              [143.60385, 73.21244],
              [142.08763, 73.20544],
              [140.038155, 73.31692],
              [139.86312, 73.36983]
            ]
          ],
          [
            [
              [44.84695804218114, 80.58980988231714],
              [46.79913862487123, 80.77191762971368],
              [48.31847741068461, 80.78400991486998],
              [48.52280602396667, 80.51456899690017],
              [49.097189568890855, 80.7539859077084],
              [50.03976769389462, 80.91888540315178],
              [51.522932977103665, 80.69972565380193],
              [51.13618655783128, 80.54728017854093],
              [49.79368452332071, 80.4154277615482],
              [48.89441124857755, 80.33956675894375],
              [48.75493655782177, 80.17546824820089],
              [47.58611901224418, 80.01018117951533],
              [46.50282596210963, 80.2472468126543],
              [47.07245527526294, 80.55942414012951],
              [44.84695804218114, 80.58980988231714]
            ]
          ],
          [
            [
              [22.731098667092652, 54.327536932993326],
              [20.892244500418627, 54.31252492941253],
              [19.660640089606403, 54.42608388937393],
              [19.888481479581287, 54.86616038677151],
              [21.268448927503467, 55.190481675835315],
              [22.315723504330577, 55.015298570365864],
              [22.75776370615526, 54.85657440858138],
              [22.65105187347254, 54.582740993866736],
              [22.731098667092652, 54.327536932993326]
            ]
          ],
          [
            [
              [53.50828982932515, 73.7498139513002],
              [55.90245893740766, 74.62748647734536],
              [55.631932814359686, 75.08141225859718],
              [57.86864383324885, 75.60939036732326],
              [61.170044386647476, 76.25188345000812],
              [64.49836836127017, 76.43905548776927],
              [66.21097700385513, 76.80978221303117],
              [68.1570597675348, 76.93969676381293],
              [68.85221113472508, 76.5448113064546],
              [68.1805725442276, 76.23364166940907],
              [64.63732628770302, 75.73775462513625],
              [61.58350752141476, 75.26088450794684],
              [58.47708214705335, 74.30905630156285],
              [56.98678551618803, 73.33304352486623],
              [55.419335971910925, 72.37126760526603],
              [55.62283776227633, 71.54059479439032],
              [57.535692579992315, 70.72046397570212],
              [56.94497928246388, 70.63274323188666],
              [53.67737511578417, 70.76265778266846],
              [53.412016635965394, 71.20666168892022],
              [51.601894565645665, 71.47475901965045],
              [51.455753615124216, 72.01488108996513],
              [52.47827518088354, 72.22944163684097],
              [52.44416873557088, 72.77473135038481],
              [54.42761355979758, 73.6275475124976],
              [53.50828982932515, 73.7498139513002]
            ]
          ],
          [
            [
              [142.91461551327654, 53.704577541714784],
              [143.2608476096321, 52.74076040303906],
              [143.23526777564763, 51.75666026468876],
              [143.64800744036285, 50.747600409541505],
              [144.6541475770856, 48.97639069273754],
              [143.1739278505172, 49.306551418650315],
              [142.55866824765013, 47.86157501890495],
              [143.53349246640403, 46.83672801369252],
              [143.50527713437265, 46.137907619809525],
              [142.74770063697383, 46.74076487892651],
              [142.09203006405454, 45.966755276058834],
              [141.906925083585, 46.80592886004656],
              [142.01844282447087, 47.78013296161296],
              [141.90444461483503, 48.85918854429957],
              [142.1358000022057, 49.61516307229739],
              [142.17998335181528, 50.9523424342819],
              [141.59407596249002, 51.9354348822025],
              [141.6825460145737, 53.301966457728795],
              [142.60693403541075, 53.762145087287934],
              [142.20974897681543, 54.225475979216874],
              [142.65478641171302, 54.36588084575389],
              [142.91461551327654, 53.704577541714784]
            ]
          ],
          [
            [
              [-174.92825, 67.20589],
              [-175.01425, 66.58435],
              [-174.33983, 66.33556],
              [-174.57182, 67.06219],
              [-171.85731, 66.91308],
              [-169.89958, 65.97724],
              [-170.89107, 65.54139],
              [-172.53025, 65.43791],
              [-172.555, 64.46079],
              [-172.95533, 64.25269],
              [-173.89184, 64.2826],
              [-174.65392, 64.63125],
              [-175.98353, 64.92288],
              [-176.20716, 65.35667],
              [-177.22266, 65.52024],
              [-178.35993, 65.39052],
              [-178.90332, 65.74044],
              [-178.68611, 66.11211],
              [-179.88377, 65.87456],
              [-179.43268, 65.40411],
              [-180, 64.9797087021984],
              [-180, 68.96363636363635],
              [-177.55, 68.2],
              [-174.92825, 67.20589]
            ]
          ],
          [
            [
              [-178.69378, 70.89302],
              [-180, 70.83219920854673],
              [-180, 71.51571433642827],
              [-179.871875, 71.55762],
              [-179.02433, 71.55553],
              [-177.577945, 71.26948],
              [-177.663575, 71.13277],
              [-178.69378, 70.89302]
            ]
          ],
          [
            [
              [33.435988094713366, 45.971917370797485],
              [33.69946184910907, 46.219572831556434],
              [34.41040172853718, 46.005162391728845],
              [34.73201738827845, 45.96566573176062],
              [34.861792128174045, 45.76818243191957],
              [35.01265897004737, 45.73772519982549],
              [35.02078779474607, 45.65121898048466],
              [35.51000857925311, 45.40999339454612],
              [36.52999799983019, 45.46998973243717],
              [36.334712762199274, 45.11321564389402],
              [35.239999220528205, 44.93999624285175],
              [33.882511020652885, 44.361478583344194],
              [33.32642093276013, 44.564877020844904],
              [33.546924269349404, 45.03477081967486],
              [32.4541744321055, 45.327466132176085],
              [32.63080447767919, 45.51918569597899],
              [33.58816206231842, 45.85156850848023],
              [33.435988094713366, 45.971917370797485]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CZE.geojson',
        iso_a2: 'CZ',
        label_y: 49.882364,
        label_x: 15.377555,
        name: 'Czechia',
        formal_en: 'Czech Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [15.01699588385867, 51.10667409932158],
            [15.490972120839729, 50.78472992614321],
            [16.23862674323857, 50.69773265237984],
            [16.176253289462267, 50.42260732685791],
            [16.719475945714436, 50.21574656839354],
            [16.86876915860566, 50.47397370055603],
            [17.55456709155112, 50.36214590107642],
            [17.64944502123899, 50.049038397819956],
            [18.392913852622172, 49.98862864847075],
            [18.853144158613617, 49.49622976337764],
            [18.554971144289482, 49.495015367218784],
            [18.399993523846177, 49.31500051533004],
            [18.170498488037964, 49.271514797556435],
            [18.104972771891852, 49.04398346617531],
            [17.913511590250465, 48.996492824899086],
            [17.88648481616181, 48.90347524677371],
            [17.545006951577108, 48.80001902932537],
            [17.101984897538898, 48.816968899117114],
            [16.960288120194576, 48.5969823268506],
            [16.499282667718774, 48.78580801044511],
            [16.02964725105022, 48.73389903420793],
            [15.253415561593982, 49.03907420510758],
            [14.901447381254057, 48.964401760445824],
            [14.338897739324722, 48.55530528420721],
            [13.595945672264437, 48.87717194273715],
            [13.031328973043431, 49.30706818297324],
            [12.521024204161193, 49.547415269562734],
            [12.415190870827445, 49.96912079528057],
            [12.240111118222558, 50.266337795607285],
            [12.966836785543194, 50.484076443069085],
            [13.338131951560285, 50.73323436136435],
            [14.056227654688172, 50.9269176295943],
            [14.307013380600637, 51.117267767941414],
            [14.570718214586066, 51.002339382524276],
            [15.01699588385867, 51.10667409932158]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'DEU.geojson',
        iso_a2: 'DE',
        label_y: 50.961733,
        label_x: 9.678348,
        name: 'Germany',
        formal_en: 'Federal Republic of Germany'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [14.119686313542559, 53.75702912049104],
            [14.353315463934138, 53.24817129171297],
            [14.074521111719434, 52.98126251892535],
            [14.437599725002201, 52.62485016540839],
            [14.685026482815687, 52.0899474147552],
            [14.607098422919535, 51.74518809671997],
            [15.01699588385867, 51.10667409932158],
            [14.570718214586066, 51.002339382524276],
            [14.307013380600637, 51.117267767941414],
            [14.056227654688172, 50.9269176295943],
            [13.338131951560285, 50.73323436136435],
            [12.966836785543194, 50.484076443069085],
            [12.240111118222558, 50.266337795607285],
            [12.415190870827445, 49.96912079528057],
            [12.521024204161193, 49.547415269562734],
            [13.031328973043431, 49.30706818297324],
            [13.595945672264437, 48.87717194273715],
            [13.243357374737, 48.416114813829054],
            [12.884102817443875, 48.28914581968786],
            [13.02585127122049, 47.63758352313583],
            [12.932626987365948, 47.467645575544],
            [12.620759718484493, 47.67238760028441],
            [12.141357456112788, 47.703083401065776],
            [11.426414015354737, 47.523766181012974],
            [10.544504021861599, 47.5663992376538],
            [10.402083774465211, 47.30248769793916],
            [9.89606814946319, 47.580196845075704],
            [9.59422610844635, 47.52505809182027],
            [8.522611932009767, 47.83082754169129],
            [8.317301466514095, 47.61357982033627],
            [7.466759067422231, 47.62058197691181],
            [7.593676385131062, 48.33301911070372],
            [8.099278598674744, 49.01778351500333],
            [6.658229607783568, 49.20195831969157],
            [6.186320428094177, 49.463802802114515],
            [6.242751092156993, 49.90222565367873],
            [6.043073357781111, 50.128051662794235],
            [6.15665815595878, 50.80372101501058],
            [5.988658074577813, 51.851615709025054],
            [6.589396599970826, 51.852029120483394],
            [6.842869500362383, 52.22844025329755],
            [7.092053256873896, 53.144043280644894],
            [6.905139601274129, 53.48216217713065],
            [7.100424838905269, 53.69393219666267],
            [7.936239454793963, 53.74829580343379],
            [8.121706170289485, 53.52779246684429],
            [8.800734490604668, 54.020785630908904],
            [8.57211795414537, 54.39564647075406],
            [8.526229282270208, 54.96274363872516],
            [9.282048780971138, 54.830865383516176],
            [9.921906365609118, 54.98310415304803],
            [9.9395797054529, 54.596641954153256],
            [10.950112338920519, 54.363607082733154],
            [10.93946699386845, 54.00869334575259],
            [11.956252475643282, 54.19648550070116],
            [12.518440382546714, 54.470370591847995],
            [13.647467075259499, 54.0755109727059],
            [14.119686313542559, 53.75702912049104]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'EST.geojson',
        iso_a2: 'EE',
        label_y: 58.724865,
        label_x: 25.867126,
        name: 'Estonia',
        formal_en: 'Republic of Estonia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.981126857000987, 59.47537333432527],
            [27.98112, 59.47537000000011],
            [28.13169925305175, 59.300825100330925],
            [27.42015000000015, 58.72457000000014],
            [27.71668582531572, 57.79189911562436],
            [27.288184848751513, 57.47452830670383],
            [26.463532342237787, 57.47638865826633],
            [25.60280968598437, 57.84752879498657],
            [25.16459354014927, 57.97015696881519],
            [24.312862583114622, 57.79342357037697],
            [24.42892785004216, 58.38341339785329],
            [24.061198357853186, 58.25737457949341],
            [23.426560092876684, 58.612753404364625],
            [23.339795363058645, 59.187240302153384],
            [24.604214308376186, 59.46585378685502],
            [25.86418908051664, 59.61109039981133],
            [26.949135776484525, 59.445803331125774],
            [27.981114129353244, 59.475388088612874],
            [27.981126857000987, 59.47537333432527]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LVA.geojson',
        iso_a2: 'LV',
        label_y: 57.066872,
        label_x: 25.458723,
        name: 'Latvia',
        formal_en: 'Republic of Latvia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [27.288184848751513, 57.47452830670383],
            [27.77001590344093, 57.24425812441123],
            [27.855282016722526, 56.75932648378429],
            [28.17670942557794, 56.16912995057879],
            [27.10245975109453, 55.783313707087686],
            [26.494331495883756, 55.615106919977634],
            [25.533046502390334, 56.100296942766036],
            [25.000934279080894, 56.16453074810484],
            [24.86068444184076, 56.37252838807963],
            [23.878263787539964, 56.27367137310527],
            [22.201156853939494, 56.33780182557949],
            [21.055800408622417, 56.031076361711065],
            [21.090423618257972, 56.78387278912294],
            [21.581866489353672, 57.411870632549935],
            [22.52434126149288, 57.75337433535076],
            [23.318452996522097, 57.00623647727487],
            [24.12072960785343, 57.02569265403277],
            [24.312862583114622, 57.79342357037697],
            [25.16459354014927, 57.97015696881519],
            [25.60280968598437, 57.84752879498657],
            [26.463532342237787, 57.47638865826633],
            [27.288184848751513, 57.47452830670383]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NOR.geojson',
        iso_a2: '-99',
        label_y: 61.357092,
        label_x: 9.679975,
        name: 'Norway',
        formal_en: 'Kingdom of Norway'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [15.14282, 79.67431],
              [15.52255, 80.01608],
              [16.99085, 80.05086],
              [18.25183, 79.70175],
              [21.54383, 78.95611],
              [19.02737, 78.5626],
              [18.47172, 77.82669],
              [17.59441, 77.63796],
              [17.1182, 76.80941],
              [15.91315, 76.77045],
              [13.76259, 77.38035],
              [14.66956, 77.73565],
              [13.1706, 78.02493],
              [11.22231, 78.8693],
              [10.44453, 79.65239],
              [13.17077, 80.01046],
              [13.71852, 79.66039],
              [15.14282, 79.67431]
            ]
          ],
          [
            [
              [31.101042202597625, 69.55810108805622],
              [29.39955, 69.15692000000013],
              [28.591929559043194, 69.06477692328666],
              [29.015572950971972, 69.76649119737799],
              [27.732292107867863, 70.16419302029625],
              [26.179622023226244, 69.82529897732614],
              [25.689212680776365, 69.09211375596904],
              [24.735679152126725, 68.64955678982146],
              [23.66204959483076, 68.89124746365054],
              [22.356237827247412, 68.84174144151491],
              [21.244936150810673, 69.37044302029308],
              [20.645592889089528, 69.10624726020087],
              [20.025268995857886, 69.0651386583127],
              [19.878559604581255, 68.40719432237258],
              [17.993868442464333, 68.56739126247736],
              [17.729181756265348, 68.01055186631628],
              [16.768878614985482, 68.0139366726314],
              [16.108712192456778, 67.30245555283689],
              [15.108411492583002, 66.19386688909547],
              [13.55568973150909, 64.78702769638151],
              [13.919905226302204, 64.44542064071608],
              [13.571916131248713, 64.04911408146971],
              [12.579935336973934, 64.06621898055833],
              [11.930569288794231, 63.12831757267698],
              [11.992064243221563, 61.80036245385655],
              [12.631146681375185, 61.293571682370136],
              [12.3003658382749, 60.11793284773003],
              [11.468271925511146, 59.43239329694604],
              [11.027368605196868, 58.85614940045936],
              [10.356556837616067, 59.469807033925356],
              [8.382000359743586, 58.313288479233215],
              [7.048748406613271, 58.078884182357285],
              [5.665835402050419, 58.5881554225937],
              [5.308234490590678, 59.663231919993834],
              [4.992078077828978, 61.97099803328432],
              [5.912900424837886, 62.614472968182724],
              [8.553411085655739, 63.45400828719648],
              [10.527709181366758, 64.4860383164975],
              [12.358346795306373, 65.87972585719318],
              [14.761145867581604, 67.81064158799516],
              [16.435927361728943, 68.56320547146173],
              [19.18402835457846, 69.81744415961778],
              [21.378416375420585, 70.2551693793461],
              [23.023742303161526, 70.2020718451662],
              [24.546543409938465, 71.03049673123722],
              [26.37004967622181, 70.98626170519539],
              [28.165547316202943, 71.18547435168055],
              [31.293418409965454, 70.4537877468599],
              [30.005435011522792, 70.18625885688489],
              [31.101042202597625, 69.55810108805622]
            ]
          ],
          [
            [
              [27.407505730913446, 80.05640574820042],
              [25.92465050629815, 79.51783397085451],
              [23.024465773213617, 79.40001170522903],
              [20.075188429451828, 79.56682322866722],
              [19.897266473070914, 79.84236196564747],
              [18.462263624757867, 79.85988027619443],
              [17.368015170977458, 80.31889618602698],
              [20.45599205901064, 80.59815562613225],
              [21.907944777115404, 80.35767934846204],
              [22.91925255706738, 80.65714427359343],
              [25.447625359811866, 80.40734039989452],
              [27.407505730913446, 80.05640574820042]
            ]
          ],
          [
            [
              [24.72412, 77.85385],
              [22.49032, 77.44493],
              [20.72601, 77.67704],
              [21.41611, 77.93504],
              [20.8119, 78.25463],
              [22.88426, 78.45494],
              [23.28134, 78.07954],
              [24.72412, 77.85385]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SWE.geojson',
        iso_a2: 'SE',
        label_y: 65.85918,
        label_x: 19.01705,
        name: 'Sweden',
        formal_en: 'Kingdom of Sweden'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [11.027368605196868, 58.85614940045936],
            [11.468271925511146, 59.43239329694604],
            [12.3003658382749, 60.11793284773003],
            [12.631146681375185, 61.293571682370136],
            [11.992064243221563, 61.80036245385655],
            [11.930569288794231, 63.12831757267698],
            [12.579935336973934, 64.06621898055833],
            [13.571916131248713, 64.04911408146971],
            [13.919905226302204, 64.44542064071608],
            [13.55568973150909, 64.78702769638151],
            [15.108411492583002, 66.19386688909547],
            [16.108712192456778, 67.30245555283689],
            [16.768878614985482, 68.0139366726314],
            [17.729181756265348, 68.01055186631628],
            [17.993868442464333, 68.56739126247736],
            [19.878559604581255, 68.40719432237258],
            [20.025268995857886, 69.0651386583127],
            [20.645592889089528, 69.10624726020087],
            [21.978534783626117, 68.6168456081807],
            [23.53947309743444, 67.93600861273525],
            [23.565879754335583, 66.39605093043743],
            [23.903378533633802, 66.00692739527962],
            [22.18317345550193, 65.72374054632017],
            [21.21351687997722, 65.02600535751527],
            [21.369631381930958, 64.41358795842429],
            [19.77887576669022, 63.60955434839504],
            [17.84777916837521, 62.74940013289681],
            [17.119554884518124, 61.34116567651097],
            [17.83134606290639, 60.63658336042741],
            [18.78772179533209, 60.081914374422595],
            [17.86922488777634, 58.9537661810587],
            [16.829185011470088, 58.71982697207339],
            [16.447709588291474, 57.041118069071885],
            [15.879785597403783, 56.10430186626866],
            [14.666681349352075, 56.200885118222175],
            [14.100721062891465, 55.40778107362265],
            [12.942910597392057, 55.36173737245058],
            [12.625100538797028, 56.30708018658197],
            [11.787942335668674, 57.44181712506307],
            [11.027368605196868, 58.85614940045936]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'FIN.geojson',
        iso_a2: 'FI',
        label_y: 63.252361,
        label_x: 27.276449,
        name: 'Finland',
        formal_en: 'Republic of Finland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.591929559043194, 69.06477692328666],
            [28.445943637818658, 68.36461294216404],
            [29.97742638522061, 67.69829702419275],
            [29.054588657352326, 66.94428620062206],
            [30.21765, 65.80598],
            [29.54442955904699, 64.94867157659048],
            [30.44468468600371, 64.20445343693909],
            [30.035872430142717, 63.55281362573855],
            [31.516092156711125, 62.86768748641289],
            [31.139991082490894, 62.35769277612441],
            [30.21110721204445, 61.780027777749694],
            [28.07, 60.50352],
            [28.070001921525666, 60.50351912796823],
            [28.069997592895277, 60.50351654727584],
            [26.255172967236973, 60.4239606797625],
            [24.496623976344523, 60.05731639265166],
            [22.869694858499457, 59.846373196036225],
            [22.290763787533592, 60.39192129174154],
            [21.322244093519316, 60.720169989659524],
            [21.544866163832694, 61.70532949487179],
            [21.05921105315369, 62.60739329695874],
            [21.536029493910803, 63.18973501245587],
            [22.442744174903993, 63.81781037053129],
            [24.730511508897536, 64.90234365504084],
            [25.398067661243942, 65.11142650009374],
            [25.294043003040404, 65.53434642197045],
            [23.903378533633802, 66.00692739527962],
            [23.565879754335583, 66.39605093043743],
            [23.53947309743444, 67.93600861273525],
            [21.978534783626117, 68.6168456081807],
            [20.645592889089528, 69.10624726020087],
            [21.244936150810673, 69.37044302029308],
            [22.356237827247412, 68.84174144151491],
            [23.66204959483076, 68.89124746365054],
            [24.735679152126725, 68.64955678982146],
            [25.689212680776365, 69.09211375596904],
            [26.179622023226244, 69.82529897732614],
            [27.732292107867863, 70.16419302029625],
            [29.015572950971972, 69.76649119737799],
            [28.591929559043194, 69.06477692328666]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'LUX.geojson',
        iso_a2: 'LU',
        label_y: 49.733732,
        label_x: 6.07762,
        name: 'Luxembourg',
        formal_en: 'Grand Duchy of Luxembourg'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.043073357781111, 50.128051662794235],
            [6.242751092156993, 49.90222565367873],
            [6.186320428094177, 49.463802802114515],
            [5.897759230176348, 49.44266714130711],
            [5.674051954784829, 49.529483547557504],
            [5.782417433300907, 50.09032786722122],
            [6.043073357781111, 50.128051662794235]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BEL.geojson',
        iso_a2: 'BE',
        label_y: 50.785392,
        label_x: 4.800448,
        name: 'Belgium',
        formal_en: 'Kingdom of Belgium'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.15665815595878, 50.80372101501058],
            [6.043073357781111, 50.128051662794235],
            [5.782417433300907, 50.09032786722122],
            [5.674051954784829, 49.529483547557504],
            [4.799221632515724, 49.98537303323637],
            [4.286022983425084, 49.907496649772554],
            [3.588184441755658, 50.37899241800356],
            [3.123251580425688, 50.78036326761455],
            [2.658422071960274, 50.796848049515745],
            [2.513573032246143, 51.14850617126183],
            [3.314971144228537, 51.345780951536085],
            [3.31501148496416, 51.34577662473805],
            [3.314971144228537, 51.34575511331991],
            [4.047071160507528, 51.26725861266857],
            [4.973991326526914, 51.47502370869813],
            [5.606975945670001, 51.03729848896978],
            [6.15665815595878, 50.80372101501058]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MKD.geojson',
        iso_a2: 'MK',
        label_y: 41.558223,
        label_x: 21.555839,
        name: 'North Macedonia',
        formal_en: 'Republic of North Macedonia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.380525750424592, 42.32025950781509],
            [22.881373732197346, 41.999297186850356],
            [22.952377150166452, 41.33799388281115],
            [22.76177, 41.3048],
            [22.597308383889015, 41.130487168943205],
            [22.05537763844427, 41.14986583105269],
            [21.674160597426976, 40.931274522457954],
            [21.0200403174764, 40.84272695572588],
            [20.605181919037364, 41.086226304685226],
            [20.463175083099202, 41.51508901627534],
            [20.59024743010491, 41.855404161133606],
            [20.590246546680227, 41.855408919283626],
            [20.71731000000011, 41.84711],
            [20.76216, 42.05186],
            [21.35270000000014, 42.2068],
            [21.57663598940212, 42.24522439706186],
            [21.917080000000112, 42.30364],
            [22.380525750424592, 42.32025950781509]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ALB.geojson',
        iso_a2: 'AL',
        label_y: 40.654855,
        label_x: 20.11384,
        name: 'Albania',
        formal_en: 'Republic of Albania'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [21.0200403174764, 40.84272695572588],
            [20.999989861747224, 40.58000397395398],
            [20.674996779063633, 40.43499990494303],
            [20.615000441172754, 40.11000682225938],
            [20.15001590341052, 39.62499766698397],
            [19.980000441170148, 39.69499339452341],
            [19.960001661873207, 39.91500580500605],
            [19.406081984136733, 40.250773423822466],
            [19.319058872157143, 40.72723012955356],
            [19.40354983895429, 41.40956574153546],
            [19.540027296637106, 41.71998607031276],
            [19.37176883309496, 41.877547512370654],
            [19.37176816334725, 41.877550679783496],
            [19.304486118250793, 42.19574514420782],
            [19.73805138517963, 42.68824738216557],
            [19.801613396898688, 42.50009349219084],
            [20.0707, 42.58863],
            [20.283754510181893, 42.32025950781508],
            [20.52295, 42.21787],
            [20.590246546680227, 41.855408919283626],
            [20.59024743010491, 41.855404161133606],
            [20.463175083099202, 41.51508901627534],
            [20.605181919037364, 41.086226304685226],
            [21.0200403174764, 40.84272695572588]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'kosovo.geojson',
        iso_a2: '-99',
        label_y: 42.593587,
        label_x: 20.860719,
        name: 'Kosovo',
        formal_en: 'Republic of Kosovo'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.590246546680227, 41.855408919283626],
            [20.52295, 42.21787],
            [20.283754510181893, 42.32025950781508],
            [20.0707, 42.58863],
            [20.25758, 42.81275000000011],
            [20.49679, 42.88469],
            [20.63508, 43.21671],
            [20.81448, 43.27205],
            [20.95651, 43.13094],
            [21.143395, 43.06868500000013],
            [21.27421, 42.90959],
            [21.43866, 42.86255],
            [21.63302, 42.67717],
            [21.77505, 42.6827],
            [21.66292, 42.43922],
            [21.54332, 42.3202500000001],
            [21.57663598940212, 42.24522439706186],
            [21.35270000000014, 42.2068],
            [20.76216, 42.05186],
            [20.71731000000011, 41.84711],
            [20.590246546680227, 41.855408919283626]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ESP.geojson',
        iso_a2: 'ES',
        label_y: 40.090953,
        label_x: -3.464718,
        name: 'Spain',
        formal_en: 'Kingdom of Spain'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-7.453725551778092, 37.09778758396607],
            [-7.537105475281024, 37.42890432387624],
            [-7.166507941099865, 37.803894354802225],
            [-7.029281175148796, 38.07576406508977],
            [-7.374092169616318, 38.37305858006492],
            [-7.098036668313128, 39.03007274022379],
            [-7.498632371439726, 39.62957103124181],
            [-7.066591559263529, 39.711891587882775],
            [-7.026413133156595, 40.184524237624245],
            [-6.864019944679385, 40.33087189387483],
            [-6.851126674822552, 41.11108266861753],
            [-6.389087693700915, 41.381815497394655],
            [-6.668605515967656, 41.883386949219584],
            [-7.251308966490824, 41.91834605566505],
            [-7.422512986673795, 41.79207469335984],
            [-8.013174607769912, 41.790886135417125],
            [-8.263856980817792, 42.28046865495034],
            [-8.67194576662672, 42.13468943945496],
            [-9.034817674180246, 41.880570583659676],
            [-8.984433152695672, 42.59277517350627],
            [-9.392883673530648, 43.0266246608127],
            [-7.97818966310831, 43.74833771420099],
            [-6.754491746436756, 43.567909450853925],
            [-5.411886359061597, 43.57423981380968],
            [-4.347842779955783, 43.40344920508504],
            [-3.517531704106091, 43.4559007838613],
            [-1.901351284177764, 43.42280202897834],
            [-1.502770961910528, 43.03401439063043],
            [0.338046909190581, 42.57954600683955],
            [0.701590610363894, 42.795734361332606],
            [1.826793247087153, 42.34338471126569],
            [2.985998976258458, 42.47301504166986],
            [3.039484083680549, 41.892120266276905],
            [2.091841668312185, 41.226088568683096],
            [0.810524529635188, 41.01473196060934],
            [0.721331007499401, 40.678318386389236],
            [0.106691521819869, 40.12393362076202],
            [-0.278711310212941, 39.30997813573272],
            [0.111290724293838, 38.73851430923304],
            [-0.467123582349103, 38.29236583104115],
            [-0.683389451490598, 37.642353827457825],
            [-1.438382127274849, 37.44306366632422],
            [-2.146452602538119, 36.67414419203729],
            [-3.415780808923387, 36.65889964451118],
            [-4.368900926114719, 36.677839056946155],
            [-4.995219285492212, 36.32470815687964],
            [-5.377159796561457, 35.946850083961465],
            [-5.866432257500904, 36.02981659600606],
            [-6.236693894872175, 36.367677110330334],
            [-6.520190802425404, 36.94291331638732],
            [-7.453725551778092, 37.09778758396607]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'DNK.geojson',
        iso_a2: 'DK',
        label_y: 55.966965,
        label_x: 9.018163,
        name: 'Denmark',
        formal_en: 'Kingdom of Denmark'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.921906365609118, 54.98310415304803],
              [9.282048780971138, 54.830865383516176],
              [8.526229282270208, 54.96274363872516],
              [8.120310906617533, 55.5177226833236],
              [8.08997684086222, 56.54001170513759],
              [8.256581658571207, 56.80996938743033],
              [8.543437534223415, 57.11000275331695],
              [9.424469028367554, 57.1720661484995],
              [9.775558709358535, 57.44794078228966],
              [10.580005730846125, 57.73001658795485],
              [10.546105991262664, 57.21573273378612],
              [10.250000034230226, 56.89001618105044],
              [10.369992710011957, 56.60998159446079],
              [10.912181837618306, 56.458621324277885],
              [10.667803989310016, 56.08138336854721],
              [10.369992710011957, 56.19000722922473],
              [9.649984978889279, 55.469999498102055],
              [9.921906365609118, 54.98310415304803]
            ]
          ],
          [
            [
              [12.370904168353292, 56.111407375708794],
              [12.690006137755603, 55.60999095318074],
              [12.089991082414684, 54.80001455343792],
              [11.043543328504226, 55.36486379660424],
              [10.903913608451603, 55.77995473898872],
              [12.370904168353292, 56.111407375708794]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ROU.geojson',
        iso_a2: 'RO',
        label_y: 45.733237,
        label_x: 24.972624,
        name: 'Romania',
        formal_en: 'Romania'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [28.23355350109904, 45.48828318946829],
            [28.67977949393938, 45.304030870131704],
            [29.149724969201653, 45.464925442072456],
            [29.603289015427436, 45.293308010431126],
            [29.62654340995877, 45.0353909368624],
            [29.141611769331835, 44.820210272799045],
            [28.8378577003202, 44.913873806328056],
            [28.558081495891997, 43.70746165625813],
            [27.970107049275075, 43.81246816667522],
            [27.242399529740908, 44.175986029632405],
            [26.065158725699746, 43.94349376075127],
            [25.569271681426926, 43.68844472917472],
            [24.100679152124172, 43.74105133724785],
            [23.332302280376325, 43.897010809904714],
            [22.944832391051847, 43.82378530534713],
            [22.65714969248299, 44.23492300066128],
            [22.4740084164406, 44.40922760678177],
            [22.705725538837356, 44.57800283464702],
            [22.459022251075936, 44.7025171982543],
            [22.14508792490281, 44.47842234962059],
            [21.56202273935361, 44.7689472519655],
            [21.48352623870221, 45.18117015235788],
            [20.874312778413355, 45.416375433934235],
            [20.762174920339987, 45.734573065771485],
            [20.220192498462836, 46.127468980486555],
            [21.02195234547125, 46.3160879583519],
            [21.626514926853872, 46.99423777931816],
            [22.099767693782837, 47.6724392767167],
            [22.710531447040495, 47.88219391538941],
            [23.142236362406805, 48.09634105080695],
            [23.76095828623741, 47.985598456405455],
            [24.40205610525038, 47.98187775328043],
            [24.866317172960578, 47.737525743188314],
            [25.20774336111299, 47.89105642352747],
            [25.9459411964024, 47.987148749374214],
            [26.19745039236693, 48.22088125263035],
            [26.619336785597795, 48.22072622333347],
            [26.924176059687568, 48.123264472030996],
            [27.233872918412743, 47.82677094175638],
            [27.551166212684848, 47.40511709247083],
            [28.128030226359044, 46.810476386088254],
            [28.160017937947714, 46.37156260841722],
            [28.0544429867754, 45.944586086605625],
            [28.23355350109904, 45.48828318946829]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'HUN.geojson',
        iso_a2: 'HU',
        label_y: 47.086841,
        label_x: 19.447867,
        name: 'Hungary',
        formal_en: 'Republic of Hungary'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.085608351334855, 48.42226430927179],
            [22.640819939878725, 48.150239569687415],
            [22.710531447040495, 47.88219391538941],
            [22.099767693782837, 47.6724392767167],
            [21.626514926853872, 46.99423777931816],
            [21.02195234547125, 46.3160879583519],
            [20.220192498462836, 46.127468980486555],
            [19.596044549241583, 46.17172984474454],
            [18.82983808764996, 45.90887767189193],
            [18.829824792873946, 45.908872358025285],
            [18.45606245288286, 45.75948110613615],
            [17.630066359129557, 45.95176911069419],
            [16.8825150895953, 46.38063182228444],
            [16.564808383864857, 46.50375092221983],
            [16.370504998447416, 46.841327216166505],
            [16.202298211337364, 46.85238597267696],
            [16.534267612380376, 47.49617096616912],
            [16.340584344150415, 47.71290192320123],
            [16.90375410326726, 47.71486562762833],
            [16.979666782304037, 48.123497015976305],
            [17.48847293464982, 47.867466132186216],
            [17.857132602620027, 47.75842886005037],
            [18.696512892336926, 47.880953681014404],
            [18.77702477384767, 48.081768296900634],
            [19.17436486173989, 48.11137889260387],
            [19.661363559658497, 48.26661489520866],
            [19.769470656013112, 48.202691148463614],
            [20.239054396249347, 48.32756724709692],
            [20.473562045989866, 48.562850043321816],
            [20.801293979584926, 48.623854071642384],
            [21.872236362401736, 48.31997081155002],
            [22.085608351334855, 48.42226430927179]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SVK.geojson',
        iso_a2: 'SK',
        label_y: 48.734044,
        label_x: 19.049868,
        name: 'Slovakia',
        formal_en: 'Slovak Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.558137648211755, 49.085738023467144],
            [22.28084191253356, 48.82539215758067],
            [22.085608351334855, 48.42226430927179],
            [21.872236362401736, 48.31997081155002],
            [20.801293979584926, 48.623854071642384],
            [20.473562045989866, 48.562850043321816],
            [20.239054396249347, 48.32756724709692],
            [19.769470656013112, 48.202691148463614],
            [19.661363559658497, 48.26661489520866],
            [19.17436486173989, 48.11137889260387],
            [18.77702477384767, 48.081768296900634],
            [18.696512892336926, 47.880953681014404],
            [17.857132602620027, 47.75842886005037],
            [17.48847293464982, 47.867466132186216],
            [16.979666782304037, 48.123497015976305],
            [16.879982944413, 48.47001333270947],
            [16.960288120194576, 48.5969823268506],
            [17.101984897538898, 48.816968899117114],
            [17.545006951577108, 48.80001902932537],
            [17.88648481616181, 48.90347524677371],
            [17.913511590250465, 48.996492824899086],
            [18.104972771891852, 49.04398346617531],
            [18.170498488037964, 49.271514797556435],
            [18.399993523846177, 49.31500051533004],
            [18.554971144289482, 49.495015367218784],
            [18.853144158613617, 49.49622976337764],
            [18.90957482267632, 49.435845852244576],
            [19.320712517990472, 49.571574001659194],
            [19.825022820726872, 49.21712535256923],
            [20.415839471119853, 49.43145335549977],
            [20.887955356538413, 49.32877228453583],
            [21.607808058364213, 49.47010732685409],
            [22.558137648211755, 49.085738023467144]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'POL.geojson',
        iso_a2: 'PL',
        label_y: 51.990316,
        label_x: 19.490468,
        name: 'Poland',
        formal_en: 'Republic of Poland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [23.48412763844985, 53.91249766704114],
            [23.527535841575002, 53.470121568406555],
            [23.80493493011778, 53.089731350306074],
            [23.79919884613338, 52.69109935160657],
            [23.199493849386187, 52.48697744405367],
            [23.508002150168693, 52.02364655212473],
            [23.52707075368437, 51.57845408793031],
            [24.029985792748903, 50.70540660257518],
            [23.92275719574326, 50.42488108987874],
            [23.426508416444392, 50.30850576435745],
            [22.518450148211603, 49.47677358661974],
            [22.776418898212626, 49.02739533140962],
            [22.558137648211755, 49.085738023467144],
            [21.607808058364213, 49.47010732685409],
            [20.887955356538413, 49.32877228453583],
            [20.415839471119853, 49.43145335549977],
            [19.825022820726872, 49.21712535256923],
            [19.320712517990472, 49.571574001659194],
            [18.90957482267632, 49.435845852244576],
            [18.853144158613617, 49.49622976337764],
            [18.392913852622172, 49.98862864847075],
            [17.64944502123899, 50.049038397819956],
            [17.55456709155112, 50.36214590107642],
            [16.86876915860566, 50.47397370055603],
            [16.719475945714436, 50.21574656839354],
            [16.176253289462267, 50.42260732685791],
            [16.23862674323857, 50.69773265237984],
            [15.490972120839729, 50.78472992614321],
            [15.01699588385867, 51.10667409932158],
            [14.607098422919535, 51.74518809671997],
            [14.685026482815687, 52.0899474147552],
            [14.437599725002201, 52.62485016540839],
            [14.074521111719434, 52.98126251892535],
            [14.353315463934138, 53.24817129171297],
            [14.119686313542559, 53.75702912049104],
            [14.802900424873458, 54.05070628520575],
            [16.36347700365573, 54.513158677785725],
            [17.622831658608675, 54.85153595643291],
            [18.62085859546164, 54.68260569927078],
            [18.696254510175464, 54.43871877706929],
            [19.660640089606403, 54.42608388937393],
            [20.892244500418627, 54.31252492941253],
            [22.731098667092652, 54.327536932993326],
            [23.24398725758951, 54.22056671814914],
            [23.48412763844985, 53.91249766704114]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'IRL.geojson',
        iso_a2: 'IE',
        label_y: 53.078726,
        label_x: -7.798588,
        name: 'Ireland',
        formal_en: 'Ireland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-6.197884894220991, 53.867565009163364],
            [-6.032985398777611, 53.15316417094435],
            [-6.788856573910849, 52.260117906292336],
            [-8.56161658368356, 51.669301255899356],
            [-9.977085740590269, 51.82045482035308],
            [-9.166282517930782, 52.86462881124268],
            [-9.688524542672454, 53.8813626165853],
            [-8.327987433292009, 54.66451894796863],
            [-7.572167934591064, 55.13162221945487],
            [-7.366030646178785, 54.59584096945272],
            [-7.572167934591064, 54.059956366586],
            [-6.953730231138067, 54.073702297575636],
            [-6.197884894220991, 53.867565009163364]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GBR.geojson',
        iso_a2: 'GB',
        label_y: 54.402739,
        label_x: -2.116346,
        name: 'United Kingdom',
        formal_en: 'United Kingdom of Great Britain and Northern Ireland'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-6.197884894220991, 53.867565009163364],
              [-6.953730231138067, 54.073702297575636],
              [-7.572167934591064, 54.059956366586],
              [-7.366030646178785, 54.59584096945272],
              [-7.572167934591064, 55.13162221945487],
              [-6.733847011736145, 55.17286001242378],
              [-5.661948614921968, 54.55460317648381],
              [-6.197884894220991, 53.867565009163364]
            ]
          ],
          [
            [
              [-3.093830673788716, 53.404547400669685],
              [-3.092079637047107, 53.40444082296358],
              [-2.945008510744344, 53.98499970154671],
              [-3.614700825433033, 54.600936773292574],
              [-3.630005458989331, 54.615012925833014],
              [-4.844169073903061, 54.790971177786844],
              [-5.082526617849283, 55.06160065369937],
              [-4.7191121077567, 55.50847260194348],
              [-5.047980922862109, 55.78398550070756],
              [-5.586397670911197, 55.31114614523682],
              [-5.644998745130238, 56.27501496034483],
              [-6.149980841486411, 56.785009670633485],
              [-5.786824713555291, 57.81884837506465],
              [-5.009998745127632, 58.63001333275008],
              [-4.211494513353557, 58.55084503847911],
              [-3.005004848635281, 58.63500010846633],
              [-4.073828497728073, 57.55302480735526],
              [-3.055001796877718, 57.69001902936094],
              [-1.959280564776918, 57.68479970969952],
              [-2.219988165689358, 56.87001740175356],
              [-3.119003058271176, 55.973793036515474],
              [-2.085009324543023, 55.9099984808513],
              [-2.005675679673857, 55.804902850350175],
              [-1.11499101399221, 54.624986477265395],
              [-0.4304849918542, 54.46437612570219],
              [0.184981316742039, 53.32501414653103],
              [0.469976840831805, 52.929999498092],
              [1.681530795914682, 52.739520168664],
              [1.559987827164321, 52.09999848083601],
              [1.050561557630942, 51.806760565795685],
              [1.449865349950244, 51.28942780212191],
              [0.550333693045502, 50.765738837275876],
              [-0.787517462558696, 50.77498891865622],
              [-2.489997524414434, 50.50001862243124],
              [-2.956273972984093, 50.696879991247044],
              [-3.617448085942385, 50.22835561787275],
              [-4.542507900399244, 50.341837063185665],
              [-5.245023159191135, 49.95999990498109],
              [-5.776566941745273, 50.15967763935686],
              [-4.309989793301895, 51.21000112568919],
              [-3.414850633142123, 51.42600861266925],
              [-3.422719467108379, 51.42684816740609],
              [-4.98436723471093, 51.593466091511004],
              [-5.267295701508942, 51.991400458374585],
              [-4.222346564134909, 52.301355699261364],
              [-4.77001339356417, 52.840004991255626],
              [-4.579999152026971, 53.49500377055517],
              [-3.093830673788716, 53.404547400669685]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'GRC.geojson',
        iso_a2: 'GR',
        label_y: 39.492763,
        label_x: 21.72568,
        name: 'Greece',
        formal_en: 'Hellenic Republic'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [26.290002882601698, 35.29999034274793],
              [26.16499759288766, 35.00499542900977],
              [24.724982130642303, 34.91998769788964],
              [24.735007358506916, 35.08499054619759],
              [23.51497846852808, 35.27999156345098],
              [23.699980096133004, 35.70500438083549],
              [24.246665073348705, 35.368022365860185],
              [25.02501549652891, 35.42499563246197],
              [25.769207797964185, 35.35401805270908],
              [25.745023227651586, 35.1799976669662],
              [26.290002882601698, 35.29999034274793]
            ]
          ],
          [
            [
              [22.952377150166452, 41.33799388281115],
              [23.692073601992348, 41.309080918943856],
              [24.492644891057978, 41.58389618587205],
              [25.197201368925448, 41.23448598893053],
              [26.106138136507127, 41.32889883072784],
              [26.1170418637208, 41.82690460872456],
              [26.60419559093623, 41.562114569661105],
              [26.294602085075667, 40.93626129817413],
              [26.05694217296534, 40.82412344010076],
              [25.44767703624416, 40.85254547786144],
              [24.92584842296094, 40.947061672523205],
              [23.71481123220076, 40.687129218095095],
              [24.40799889496401, 40.12499298762407],
              [23.899967889102584, 39.96200552017552],
              [23.342999301860743, 39.96099782974579],
              [22.813987664488934, 40.476005153966526],
              [22.626298862404724, 40.25656118423916],
              [22.84974775563478, 39.659310818025745],
              [23.35002729665257, 39.190011298167235],
              [22.973099399515547, 38.97090322524963],
              [23.530016310324925, 38.51000112563844],
              [24.025024855248887, 38.21999298761642],
              [24.040011020613576, 37.655014553369426],
              [23.115002882589124, 37.920011298162194],
              [23.409971958111043, 37.40999074965737],
              [22.77497195810861, 37.30501007745653],
              [23.15422529469862, 36.422505804992056],
              [22.490028110451078, 36.4100001083774],
              [21.67002648284364, 36.8449864771942],
              [21.29501061370155, 37.64498932550464],
              [21.120034213961333, 38.31032339126273],
              [20.730032179454554, 38.769985256498785],
              [20.217712029712857, 39.34023468683961],
              [20.15001590341052, 39.62499766698397],
              [20.615000441172754, 40.11000682225938],
              [20.674996779063633, 40.43499990494303],
              [20.999989861747224, 40.58000397395398],
              [21.0200403174764, 40.84272695572588],
              [21.674160597426976, 40.931274522457954],
              [22.05537763844427, 41.14986583105269],
              [22.597308383889015, 41.130487168943205],
              [22.76177, 41.3048],
              [22.952377150166452, 41.33799388281115]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'AUT.geojson',
        iso_a2: 'AT',
        label_y: 47.518859,
        label_x: 14.130515,
        name: 'Austria',
        formal_en: 'Republic of Austria'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.979666782304037, 48.123497015976305],
            [16.90375410326726, 47.71486562762833],
            [16.340584344150415, 47.71290192320123],
            [16.534267612380376, 47.49617096616912],
            [16.202298211337364, 46.85238597267696],
            [16.011663852612656, 46.6836107448117],
            [15.137091912504985, 46.65870270444703],
            [14.63247155117483, 46.43181732846955],
            [13.806475457421527, 46.509306138691215],
            [12.376485223040817, 46.76755910906985],
            [12.153088006243054, 47.11539317482645],
            [11.16482791509327, 46.94157949481273],
            [11.048555942436536, 46.75135854754634],
            [10.44270145024663, 46.89354625099743],
            [9.932448357796659, 46.92072805438296],
            [9.479969516649021, 47.102809963563374],
            [9.632931756232978, 47.34760122332999],
            [9.59422610844635, 47.52505809182027],
            [9.89606814946319, 47.580196845075704],
            [10.402083774465211, 47.30248769793916],
            [10.544504021861599, 47.5663992376538],
            [11.426414015354737, 47.523766181012974],
            [12.141357456112788, 47.703083401065776],
            [12.620759718484493, 47.67238760028441],
            [12.932626987365948, 47.467645575544],
            [13.02585127122049, 47.63758352313583],
            [12.884102817443875, 48.28914581968786],
            [13.243357374737, 48.416114813829054],
            [13.595945672264437, 48.87717194273715],
            [14.338897739324722, 48.55530528420721],
            [14.901447381254057, 48.964401760445824],
            [15.253415561593982, 49.03907420510758],
            [16.02964725105022, 48.73389903420793],
            [16.499282667718774, 48.78580801044511],
            [16.960288120194576, 48.5969823268506],
            [16.879982944413, 48.47001333270947],
            [16.979666782304037, 48.123497015976305]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ITA.geojson',
        iso_a2: 'IT',
        label_y: 44.732482,
        label_x: 11.076907,
        name: 'Italy',
        formal_en: 'Italian Republic'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [10.44270145024663, 46.89354625099743],
              [11.048555942436536, 46.75135854754634],
              [11.16482791509327, 46.94157949481273],
              [12.153088006243054, 47.11539317482645],
              [12.376485223040817, 46.76755910906985],
              [13.806475457421527, 46.509306138691215],
              [13.698109978905478, 46.01677806251735],
              [13.937630242578308, 45.59101593686462],
              [13.141606479554296, 45.73669179949542],
              [12.32858117030625, 45.381778062514826],
              [12.383874952858548, 44.885374253919096],
              [12.261453484759102, 44.600482082693986],
              [12.589237094786455, 44.09136587175446],
              [13.526905958722494, 43.587727362637864],
              [14.029820997787029, 42.761007798832466],
              [15.142569614327925, 41.95513967545685],
              [15.926191033601896, 41.961315009115715],
              [16.169897088290384, 41.74029490820339],
              [15.889345737377797, 41.541082261718216],
              [16.785001661860548, 41.17960561783656],
              [17.51916873543118, 40.87714345963222],
              [18.37668745288252, 40.35562490494263],
              [18.48024702319543, 40.16886627863981],
              [18.293385044028042, 39.810774441073264],
              [17.738380161213314, 40.277671006830346],
              [16.869595981522338, 40.44223460546381],
              [16.448743116937322, 39.79540070246644],
              [17.17148969897147, 39.42469981542068],
              [17.052840610429314, 38.90287120213735],
              [16.635088331781816, 38.843572496082416],
              [16.100960727613, 37.9858987493342],
              [15.684086948314473, 37.908849188787016],
              [15.687962680736348, 38.21459280044188],
              [15.89198123542468, 38.75094249119924],
              [16.109332309644287, 38.9645470240777],
              [15.718813510814613, 39.54407237401492],
              [15.413612501698793, 40.048356838535156],
              [14.99849572109818, 40.17294871679087],
              [14.703268263414714, 40.604550279292596],
              [14.060671827865264, 40.7863479680954],
              [13.627985060285397, 41.1882872584616],
              [12.888081902730365, 41.253089504555604],
              [12.106682570044939, 41.70453481705741],
              [11.191906365614216, 42.3554253199897],
              [10.51194786951774, 42.93146251074721],
              [10.200028924203991, 43.9200068222746],
              [9.702488234097842, 44.03627879493132],
              [8.88894616052687, 44.36633616797951],
              [8.428560825238577, 44.23122813575242],
              [7.850766635783144, 43.76714793555524],
              [7.435184767291872, 43.69384491634922],
              [7.549596388386107, 44.12790110938481],
              [7.007562290076635, 44.25476675066136],
              [6.749955275101655, 45.02851797136758],
              [7.096652459347837, 45.33309886329589],
              [6.802355177445605, 45.70857982032864],
              [6.843592970414505, 45.99114655210061],
              [7.273850945676656, 45.776947740250776],
              [7.755992058959833, 45.82449005795931],
              [8.31662967289438, 46.16364248309086],
              [8.489952426801324, 46.005150865251686],
              [8.966305779667806, 46.03693187111119],
              [9.182881707403055, 46.44021474871698],
              [9.922836541390382, 46.31489940040919],
              [10.363378126678612, 46.48357127540986],
              [10.44270145024663, 46.89354625099743]
            ]
          ],
          [
            [
              [14.761249220446189, 38.14387360285046],
              [15.520376010813806, 38.23115509699143],
              [15.160242954171736, 37.44404551853776],
              [15.309897902089006, 37.13421946873183],
              [15.099988234119422, 36.6199872909954],
              [14.335228712631988, 36.996630967754726],
              [13.826732618879959, 37.10453135838016],
              [12.431003859108756, 37.61294993748375],
              [12.570943637755136, 38.12638113051966],
              [13.741156447004613, 38.03496552179533],
              [14.761249220446189, 38.14387360285046]
            ]
          ],
          [
            [
              [8.709990675500109, 40.899984442705225],
              [9.210011834356266, 41.209991360024176],
              [9.80997521326492, 40.50000885676613],
              [9.669518670295616, 39.17737641047178],
              [9.214817742559433, 39.24047333430015],
              [8.806935662479674, 38.906617743478506],
              [8.428302443077115, 39.17184703221655],
              [8.388253208050912, 40.37831085871876],
              [8.159998406617689, 40.95000722916376],
              [8.709990675500109, 40.899984442705225]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'CHE.geojson',
        iso_a2: 'CH',
        label_y: 46.719114,
        label_x: 7.463965,
        name: 'Switzerland',
        formal_en: 'Swiss Confederation'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [9.59422610844635, 47.52505809182027],
            [9.632931756232978, 47.34760122332999],
            [9.479969516649021, 47.102809963563374],
            [9.932448357796659, 46.92072805438296],
            [10.44270145024663, 46.89354625099743],
            [10.363378126678612, 46.48357127540986],
            [9.922836541390382, 46.31489940040919],
            [9.182881707403055, 46.44021474871698],
            [8.966305779667806, 46.03693187111119],
            [8.489952426801324, 46.005150865251686],
            [8.31662967289438, 46.16364248309086],
            [7.755992058959833, 45.82449005795931],
            [7.273850945676656, 45.776947740250776],
            [6.843592970414505, 45.99114655210061],
            [6.500099724970426, 46.42967275652944],
            [6.022609490593538, 46.27298981382047],
            [6.037388950229001, 46.725778713561866],
            [6.768713820023606, 47.2877082383037],
            [6.736571079138059, 47.541801255882845],
            [7.192202182655507, 47.44976552997102],
            [7.466759067422231, 47.62058197691181],
            [8.317301466514095, 47.61357982033627],
            [8.522611932009767, 47.83082754169129],
            [9.59422610844635, 47.52505809182027]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NLD.geojson',
        iso_a2: 'NL',
        label_y: 52.422211,
        label_x: 5.61144,
        name: 'Netherlands',
        formal_en: 'Kingdom of the Netherlands'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [6.905139601274129, 53.48216217713065],
            [7.092053256873896, 53.144043280644894],
            [6.842869500362383, 52.22844025329755],
            [6.589396599970826, 51.852029120483394],
            [5.988658074577813, 51.851615709025054],
            [6.15665815595878, 50.80372101501058],
            [5.606975945670001, 51.03729848896978],
            [4.973991326526914, 51.47502370869813],
            [4.047071160507528, 51.26725861266857],
            [3.314971144228537, 51.34575511331991],
            [3.31501148496416, 51.34577662473805],
            [3.830288527043137, 51.62054454203195],
            [4.705997348661185, 53.091798407597764],
            [6.074182570020923, 53.510403347378144],
            [6.905139601274129, 53.48216217713065]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SRB.geojson',
        iso_a2: 'RS',
        label_y: 44.189919,
        label_x: 20.787989,
        name: 'Serbia',
        formal_en: 'Republic of Serbia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.829824792873946, 45.908872358025285],
            [18.82983808764996, 45.90887767189193],
            [19.596044549241583, 46.17172984474454],
            [20.220192498462836, 46.127468980486555],
            [20.762174920339987, 45.734573065771485],
            [20.874312778413355, 45.416375433934235],
            [21.48352623870221, 45.18117015235788],
            [21.56202273935361, 44.7689472519655],
            [22.14508792490281, 44.47842234962059],
            [22.459022251075936, 44.7025171982543],
            [22.705725538837356, 44.57800283464702],
            [22.4740084164406, 44.40922760678177],
            [22.65714969248299, 44.23492300066128],
            [22.410446404721597, 44.008063462899955],
            [22.500156691180223, 43.642814439461006],
            [22.986018507588483, 43.2111612005271],
            [22.60480146657133, 42.898518785161144],
            [22.43659467946128, 42.580321153323936],
            [22.54501183440962, 42.46136200618804],
            [22.380525750424592, 42.32025950781509],
            [21.917080000000112, 42.30364],
            [21.57663598940212, 42.24522439706186],
            [21.54332, 42.3202500000001],
            [21.66292, 42.43922],
            [21.77505, 42.6827],
            [21.63302, 42.67717],
            [21.43866, 42.86255],
            [21.27421, 42.90959],
            [21.143395, 43.06868500000013],
            [20.95651, 43.13094],
            [20.81448, 43.27205],
            [20.63508, 43.21671],
            [20.49679, 42.88469],
            [20.25758, 42.81275000000011],
            [20.3398, 42.89852],
            [19.95857, 43.10604],
            [19.63, 43.21377997027054],
            [19.48389, 43.35229],
            [19.21852, 43.52384],
            [19.454, 43.56810000000013],
            [19.59976, 44.03847],
            [19.11761, 44.42307000000011],
            [19.36803, 44.863],
            [19.00548, 44.86023],
            [19.005484597557594, 44.86023449354299],
            [19.39047570158459, 45.236515611342384],
            [19.072768995854176, 45.52151113543209],
            [18.829824792873946, 45.908872358025285]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'HRV.geojson',
        iso_a2: 'HR',
        label_y: 45.805799,
        label_x: 16.37241,
        name: 'Croatia',
        formal_en: 'Republic of Croatia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [16.564808383864857, 46.50375092221983],
            [16.8825150895953, 46.38063182228444],
            [17.630066359129557, 45.95176911069419],
            [18.45606245288286, 45.75948110613615],
            [18.829824792873946, 45.908872358025285],
            [19.072768995854176, 45.52151113543209],
            [19.39047570158459, 45.236515611342384],
            [19.005484597557594, 44.86023449354299],
            [18.553214145591653, 45.08158966733146],
            [17.861783481526402, 45.067740383477144],
            [17.002146030351014, 45.23377676043094],
            [16.534939406000206, 45.21160757097772],
            [16.318156772535872, 45.00412669532591],
            [15.959367303133376, 45.23377676043094],
            [15.750026075918981, 44.818711656262565],
            [16.23966027188453, 44.35114329688571],
            [16.456442905348865, 44.04123973243128],
            [16.91615644701733, 43.66772247982567],
            [17.297373488034452, 43.44634064388737],
            [17.674921502358984, 43.02856252702361],
            [18.56, 42.65],
            [18.45001688302086, 42.47999224531218],
            [18.450016310304818, 42.47999136002932],
            [17.509970330483327, 42.849994615239154],
            [16.930005730871642, 43.20999848080038],
            [16.015384555737683, 43.50721548112722],
            [15.174453973052096, 44.243191229827914],
            [15.376250441151797, 44.31791535092208],
            [14.92030927904051, 44.73848399512946],
            [14.901602410550879, 45.07606028907611],
            [14.258747592839995, 45.23377676043094],
            [13.952254672917036, 44.80212352149687],
            [13.656975538801191, 45.13693512631596],
            [13.67940311041582, 45.48414907488501],
            [13.715059848697223, 45.500323798192376],
            [14.411968214585414, 45.46616567644746],
            [14.595109490627806, 45.634940904312714],
            [14.935243767972935, 45.471695054702685],
            [15.327674594797429, 45.45231639259333],
            [15.323953891672405, 45.73178253842768],
            [15.671529575267556, 45.83415355079788],
            [15.768732944408553, 46.23810822202345],
            [16.564808383864857, 46.50375092221983]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SVN.geojson',
        iso_a2: 'SI',
        label_y: 46.06076,
        label_x: 14.915312,
        name: 'Slovenia',
        formal_en: 'Republic of Slovenia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [13.806475457421527, 46.509306138691215],
            [14.63247155117483, 46.43181732846955],
            [15.137091912504985, 46.65870270444703],
            [16.011663852612656, 46.6836107448117],
            [16.202298211337364, 46.85238597267696],
            [16.370504998447416, 46.841327216166505],
            [16.564808383864857, 46.50375092221983],
            [15.768732944408553, 46.23810822202345],
            [15.671529575267556, 45.83415355079788],
            [15.323953891672405, 45.73178253842768],
            [15.327674594797429, 45.45231639259333],
            [14.935243767972935, 45.471695054702685],
            [14.595109490627806, 45.634940904312714],
            [14.411968214585414, 45.46616567644746],
            [13.715059848697223, 45.500323798192376],
            [13.937630242578308, 45.59101593686462],
            [13.698109978905478, 46.01677806251735],
            [13.806475457421527, 46.509306138691215]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BGR.geojson',
        iso_a2: 'BG',
        label_y: 42.508785,
        label_x: 25.15709,
        name: 'Bulgaria',
        formal_en: 'Republic of Bulgaria'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [22.65714969248299, 44.23492300066128],
            [22.944832391051847, 43.82378530534713],
            [23.332302280376325, 43.897010809904714],
            [24.100679152124172, 43.74105133724785],
            [25.569271681426926, 43.68844472917472],
            [26.065158725699746, 43.94349376075127],
            [27.242399529740908, 44.175986029632405],
            [27.970107049275075, 43.81246816667522],
            [28.558081495891997, 43.70746165625813],
            [28.03909508638472, 43.293171698574184],
            [27.67389773937805, 42.57789236100622],
            [27.99672041190539, 42.00735871028779],
            [27.13573937349048, 42.14148489030134],
            [26.1170418637208, 41.82690460872456],
            [26.106138136507127, 41.32889883072784],
            [25.197201368925448, 41.23448598893053],
            [24.492644891057978, 41.58389618587205],
            [23.692073601992348, 41.309080918943856],
            [22.952377150166452, 41.33799388281115],
            [22.881373732197346, 41.999297186850356],
            [22.380525750424592, 42.32025950781509],
            [22.54501183440962, 42.46136200618804],
            [22.43659467946128, 42.580321153323936],
            [22.60480146657133, 42.898518785161144],
            [22.986018507588483, 43.2111612005271],
            [22.500156691180223, 43.642814439461006],
            [22.410446404721597, 44.008063462899955],
            [22.65714969248299, 44.23492300066128]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MNE.geojson',
        iso_a2: 'ME',
        label_y: 42.803101,
        label_x: 19.143727,
        name: 'Montenegro',
        formal_en: 'Montenegro'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [20.0707, 42.58863],
            [19.801613396898688, 42.50009349219084],
            [19.73805138517963, 42.68824738216557],
            [19.304486118250793, 42.19574514420782],
            [19.37176816334725, 41.877550679783496],
            [19.16246, 41.95502],
            [18.88214, 42.28151],
            [18.45001688302086, 42.47999224531218],
            [18.56, 42.65],
            [18.70648, 43.20011],
            [19.03165, 43.43253],
            [19.21852, 43.52384],
            [19.48389, 43.35229],
            [19.63, 43.21377997027054],
            [19.95857, 43.10604],
            [20.3398, 42.89852],
            [20.25758, 42.81275000000011],
            [20.0707, 42.58863]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'BIH.geojson',
        iso_a2: 'BA',
        label_y: 44.091051,
        label_x: 18.06841,
        name: 'Bosnia and Herz.',
        formal_en: 'Bosnia and Herzegovina'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [18.56, 42.65],
            [17.674921502358984, 43.02856252702361],
            [17.297373488034452, 43.44634064388737],
            [16.91615644701733, 43.66772247982567],
            [16.456442905348865, 44.04123973243128],
            [16.23966027188453, 44.35114329688571],
            [15.750026075918981, 44.818711656262565],
            [15.959367303133376, 45.23377676043094],
            [16.318156772535872, 45.00412669532591],
            [16.534939406000206, 45.21160757097772],
            [17.002146030351014, 45.23377676043094],
            [17.861783481526402, 45.067740383477144],
            [18.553214145591653, 45.08158966733146],
            [19.005484597557594, 44.86023449354299],
            [19.00548, 44.86023],
            [19.36803, 44.863],
            [19.11761, 44.42307000000011],
            [19.59976, 44.03847],
            [19.454, 43.56810000000013],
            [19.21852, 43.52384],
            [19.03165, 43.43253],
            [18.70648, 43.20011],
            [18.56, 42.65]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PRT.geojson',
        iso_a2: 'PT',
        label_y: 39.606675,
        label_x: -8.271754,
        name: 'Portugal',
        formal_en: 'Portuguese Republic'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-9.034817674180246, 41.880570583659676],
            [-8.67194576662672, 42.13468943945496],
            [-8.263856980817792, 42.28046865495034],
            [-8.013174607769912, 41.790886135417125],
            [-7.422512986673795, 41.79207469335984],
            [-7.251308966490824, 41.91834605566505],
            [-6.668605515967656, 41.883386949219584],
            [-6.389087693700915, 41.381815497394655],
            [-6.851126674822552, 41.11108266861753],
            [-6.864019944679385, 40.33087189387483],
            [-7.026413133156595, 40.184524237624245],
            [-7.066591559263529, 39.711891587882775],
            [-7.498632371439726, 39.62957103124181],
            [-7.098036668313128, 39.03007274022379],
            [-7.374092169616318, 38.37305858006492],
            [-7.029281175148796, 38.07576406508977],
            [-7.166507941099865, 37.803894354802225],
            [-7.537105475281024, 37.42890432387624],
            [-7.453725551778092, 37.09778758396607],
            [-7.855613165711986, 36.83826854099627],
            [-8.382816127953689, 36.97888011326246],
            [-8.898856980820327, 36.86880931248078],
            [-8.746101446965554, 37.65134552667661],
            [-8.83999752443988, 38.266243394517616],
            [-9.287463751655224, 38.3584858261586],
            [-9.526570603869715, 38.73742910415491],
            [-9.446988898140233, 39.39206614842837],
            [-9.048305223008427, 39.75509308527877],
            [-8.977353481471681, 40.15930613866581],
            [-8.768684047877102, 40.76063894303019],
            [-8.79085323733031, 41.18433401139126],
            [-8.99078935386757, 41.54345937760364],
            [-9.034817674180246, 41.880570583659676]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'MDA.geojson',
        iso_a2: 'MD',
        label_y: 47.434999,
        label_x: 28.487904,
        name: 'Moldova',
        formal_en: 'Republic of Moldova'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [26.619336785597795, 48.22072622333347],
            [26.857823520624805, 48.368210761094495],
            [27.522537469195157, 48.467119452501116],
            [28.259546746541844, 48.15556224221342],
            [28.670891147585166, 48.1181485052341],
            [29.12269819511303, 47.849095160506465],
            [29.05086795422727, 47.5102269557525],
            [29.415135125452736, 47.34664520933258],
            [29.559674106573112, 46.928582872091326],
            [29.908851759569302, 46.67436066343146],
            [29.838210076626297, 46.52532583270169],
            [30.024658644335375, 46.42393667254504],
            [29.759971958136394, 46.34998769793536],
            [29.170653924279804, 46.37926239682872],
            [29.07210696789929, 46.517677720722496],
            [28.862972446414062, 46.43788930926383],
            [28.933717482221596, 46.25883047137256],
            [28.65998742037158, 45.93998688413164],
            [28.485269402792767, 45.5969070501459],
            [28.23355350109904, 45.48828318946829],
            [28.0544429867754, 45.944586086605625],
            [28.160017937947714, 46.37156260841722],
            [28.128030226359044, 46.810476386088254],
            [27.551166212684848, 47.40511709247083],
            [27.233872918412743, 47.82677094175638],
            [26.924176059687568, 48.123264472030996],
            [26.619336785597795, 48.22072622333347]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ISL.geojson',
        iso_a2: 'IS',
        label_y: 64.779286,
        label_x: -18.673711,
        name: 'Iceland',
        formal_en: 'Republic of Iceland'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-14.508695441129234, 66.45589223903143],
            [-14.739637417041607, 65.8087482774403],
            [-13.60973222497981, 65.12667104761987],
            [-14.909833746794902, 64.36408193628868],
            [-17.794438035543422, 63.678749091233854],
            [-18.656245896874992, 63.49638296167582],
            [-19.97275468594276, 63.64363495549153],
            [-22.762971971110158, 63.960178941495386],
            [-21.778484259517683, 64.40211579045551],
            [-23.95504391121911, 64.8911298692335],
            [-22.184402635170358, 65.0849681667603],
            [-22.227423265053332, 65.37859365504274],
            [-24.326184047939336, 65.61118927678847],
            [-23.65051469572309, 66.26251902939522],
            [-22.134922451250887, 66.41046865504687],
            [-20.57628373867955, 65.73211212835143],
            [-19.05684160000159, 66.27660085719477],
            [-17.79862382655905, 65.99385325790978],
            [-16.167818976292125, 66.52679230413587],
            [-14.508695441129234, 66.45589223903143]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'PNG.geojson',
        iso_a2: 'PG',
        label_y: -5.695285,
        label_x: 143.910216,
        name: 'Papua New Guinea',
        formal_en: 'Independent State of Papua New Guinea'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [141.00021040259185, -2.60015105551566],
              [142.73524661679147, -3.28915292726321],
              [144.58397098203324, -3.861417738463416],
              [145.27317955950997, -4.373737888205049],
              [145.8297864117257, -4.876497897972683],
              [145.981921828393, -5.465609226100043],
              [147.64807335834757, -6.083659356310847],
              [147.89110761941623, -6.614014580922343],
              [146.97090538959486, -6.721656589386313],
              [147.19187381407494, -7.388024183790023],
              [148.08463585834932, -8.044108168167647],
              [148.73410525939357, -9.104663588093764],
              [149.30683515848443, -9.07143564213009],
              [149.26663089416132, -9.514406019736029],
              [150.03872846903425, -9.684318129111709],
              [149.7387984560122, -9.872937106977048],
              [150.80162763895913, -10.293686618697478],
              [150.6905749859639, -10.582712904505925],
              [150.02839318257583, -10.652476088099952],
              [149.78231001200197, -10.393267103723923],
              [148.92313764871727, -10.280922539921384],
              [147.913018426708, -10.13044076908745],
              [147.13544315001218, -9.492443536011983],
              [146.56788089415056, -8.942554619994155],
              [146.04848107318492, -8.067414239131281],
              [144.74416792213805, -7.630128269077446],
              [143.89708784400966, -7.915330498896296],
              [143.28637576718432, -8.24549122480908],
              [143.41391320208066, -8.983068942910982],
              [142.62843143124417, -9.326820570516524],
              [142.06825890520025, -9.159595635620022],
              [141.03385176001382, -9.117892754760483],
              [141.01705691951895, -5.859021905138071],
              [141.00021040259185, -2.60015105551566]
            ]
          ],
          [
            [
              [152.64001671774253, -3.659983005389691],
              [153.0199935243847, -3.980015150573265],
              [153.14003787659874, -4.499983412294092],
              [152.82729210836828, -4.766427097190991],
              [152.63867313050298, -4.176127211120921],
              [152.40602583232493, -3.789742526874583],
              [151.95323693258354, -3.462062269711816],
              [151.38427941305002, -3.035421644710112],
              [150.66204959533883, -2.741486097833935],
              [150.93996544820448, -2.500002129734007],
              [151.47998416565457, -2.779985039891379],
              [151.8200150901351, -2.999971612157886],
              [152.23998945537113, -3.24000864015364],
              [152.64001671774253, -3.659983005389691]
            ]
          ],
          [
            [
              [151.30139041565388, -5.840728448106752],
              [150.75444705627666, -6.083762709175431],
              [150.2411967307538, -6.317753594593028],
              [149.70996300679332, -6.316513360218025],
              [148.89006473205046, -6.026040134305404],
              [148.31893680236067, -5.747142429226166],
              [148.40182579975686, -5.437755629094717],
              [149.29841190002082, -5.58374155031926],
              [149.84556196512722, -5.505503431829368],
              [149.99625044169028, -5.026101169457654],
              [150.13975589416486, -5.001348158389852],
              [150.23690758687354, -5.532220147324267],
              [150.80746707580812, -5.455842380396874],
              [151.08967207255404, -5.113692722192383],
              [151.6478808941709, -4.757073662946162],
              [151.53786176982146, -4.167807305521933],
              [152.1367916200843, -4.14879037843852],
              [152.33874311748093, -4.312966403829805],
              [152.3186926617517, -4.867661228050771],
              [151.98279585185452, -5.478063246282382],
              [151.45910688700866, -5.560280450058754],
              [151.30139041565388, -5.840728448106752]
            ]
          ],
          [
            [
              [154.75999067608439, -5.339983819198495],
              [155.06291792217934, -5.56679168052753],
              [155.5477462099417, -6.200654799019645],
              [156.0199654482248, -6.540013929880381],
              [155.8800256695784, -6.819996840037753],
              [155.59999108298877, -6.919990736522522],
              [155.16699425681514, -6.535931491729322],
              [154.72919152243838, -5.900828138862195],
              [154.51411421123964, -5.139117526879986],
              [154.65250369691728, -5.04243092206189],
              [154.75999067608439, -5.339983819198495]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'AUS.geojson',
        iso_a2: 'AU',
        label_y: -24.129522,
        label_x: 134.04972,
        name: 'Australia',
        formal_en: 'Commonwealth of Australia'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [147.68925947488418, -40.808258152022674],
              [148.289067824496, -40.87543751400211],
              [148.35986453673587, -42.06244516374644],
              [148.01730146707303, -42.40702361426865],
              [147.91405195535384, -43.211522312188535],
              [147.56456424376393, -42.937688897473905],
              [146.87034305235488, -43.6345972633621],
              [146.66332726459365, -43.58085377377856],
              [146.04837772032033, -43.549744561538844],
              [145.4319295595106, -42.693776137056254],
              [145.29509036680173, -42.033609714527564],
              [144.71807132383066, -41.16255177181576],
              [144.7437545106797, -40.70397511165767],
              [145.3979781434948, -40.79254851660594],
              [146.3641207216237, -41.13769540788336],
              [146.90858361225088, -41.00054615658073],
              [147.68925947488418, -40.808258152022674]
            ]
          ],
          [
            [
              [126.14871382050114, -32.21596607842059],
              [125.08862348846566, -32.72875131605285],
              [124.22164798390492, -32.95948658623607],
              [124.02894656788851, -33.4838473447017],
              [123.65966678273077, -33.89017913181271],
              [122.81103641163364, -33.914467054989885],
              [122.1830644064228, -34.0034021949642],
              [121.29919070850259, -33.821036065406176],
              [120.58026818245806, -33.93017669040661],
              [119.89369510302822, -33.9760653622818],
              [119.29889936734875, -34.50936614353394],
              [119.00734093635802, -34.46414926527854],
              [118.5057178081008, -34.74681934991509],
              [118.02497195848949, -35.0647327613747],
              [117.29550744025741, -35.02545867283287],
              [116.62510908413495, -35.02509693780683],
              [115.56434695847966, -34.38642791111157],
              [115.02680870977957, -34.19651702243893],
              [115.04861616420676, -33.623425388322055],
              [115.54512332566708, -33.48725798923297],
              [115.7146737000167, -33.25957162855497],
              [115.67937869676135, -32.900368747694166],
              [115.80164513556394, -32.205062351207005],
              [115.68961063035516, -31.612437025683807],
              [115.160909051577, -30.601594333622465],
              [114.99704308477948, -30.03072478609414],
              [115.04003787644629, -29.46109547294082],
              [114.64197431850201, -28.81023080822467],
              [114.6164978373821, -28.51639861421308],
              [114.17357913620847, -28.11807667410732],
              [114.04888390508816, -27.334765313427106],
              [113.47749759323692, -26.543134047147902],
              [113.33895307826242, -26.116545098578484],
              [113.77835778204022, -26.549025160429174],
              [113.44096235560656, -25.621278171493167],
              [113.93690107631167, -25.91123463308287],
              [114.23285200404723, -26.29844614024588],
              [114.21616051641698, -25.786281019801123],
              [113.7212553243577, -24.99893889740214],
              [113.62534386602397, -24.683971042583167],
              [113.39352339076264, -24.384764499613226],
              [113.5020438985756, -23.806350192970285],
              [113.70699262904515, -23.56021534596409],
              [113.84341841029567, -23.059987481378755],
              [113.73655154831609, -22.47547535572538],
              [114.1497563009219, -21.75588103606104],
              [114.22530724493262, -22.517488295178673],
              [114.6477620789187, -21.829519952076954],
              [115.46016727097924, -21.495173435148537],
              [115.94737267462702, -21.068687839443704],
              [116.71161543179153, -20.701681817306824],
              [117.16631635952771, -20.623598728113805],
              [117.44154503791424, -20.74689869556221],
              [118.229558953933, -20.37420826587322],
              [118.83608523974274, -20.263310642174858],
              [118.98780724495168, -20.044202569257315],
              [119.25249393115067, -19.952941989829867],
              [119.80522505094451, -19.976506442954964],
              [120.85622033089668, -19.683707777589206],
              [121.39985639860717, -19.239755547769725],
              [121.65513797412902, -18.70531788500717],
              [122.24166548064179, -18.197648614171804],
              [122.28662397673571, -17.798603204013958],
              [122.3127722514754, -17.25496713630345],
              [123.01257449757193, -16.405199883695886],
              [123.43378909718304, -17.268558037996215],
              [123.85934451710659, -17.069035332917288],
              [123.50324222218329, -16.596506036040402],
              [123.81707319549184, -16.111316013252],
              [124.25828657439985, -16.327943617419535],
              [124.37972619028575, -15.56705982835399],
              [124.92615278534004, -15.07510019293536],
              [125.16727501841387, -14.680395603090028],
              [125.67008670461381, -14.510070082256014],
              [125.68579634003055, -14.23065561285385],
              [126.12514936737608, -14.347340996968903],
              [126.14282270721986, -14.095986830301227],
              [126.58258914602374, -13.952791436420448],
              [127.06586714081732, -13.817967624570954],
              [127.80463341686196, -14.27690601975508],
              [128.35968997610894, -14.869169610252243],
              [128.98554324759584, -14.875990899314765],
              [129.62147342337965, -14.969783623924522],
              [129.40960005098293, -14.42066985439107],
              [129.8886405783286, -13.618703301653492],
              [130.33946577364293, -13.357375583553484],
              [130.18350630098604, -13.107520033422276],
              [130.61779503796697, -12.536392103732489],
              [131.22349450086, -12.183648776908166],
              [131.73509118054955, -12.302452894747184],
              [132.5752982931831, -12.114040622611007],
              [132.55721154188097, -11.603012383676678],
              [131.82469811414364, -11.27378183354515],
              [132.3572237489114, -11.128519382372696],
              [133.01956058159635, -11.376411228076812],
              [133.55084598198908, -11.786515394745116],
              [134.39306847548204, -12.042365411022182],
              [134.67863244032696, -11.941182956594693],
              [135.29849124566795, -12.248606052299046],
              [135.8826933127276, -11.962266940969776],
              [136.2583809754895, -12.049341729381588],
              [136.49247521377168, -11.857208754120398],
              [136.951620314685, -12.351958916882793],
              [136.6851249533558, -12.887223402562022],
              [136.3054065288751, -13.291229750219884],
              [135.96175825413417, -13.324509372615852],
              [136.07761681533253, -13.724278252825783],
              [135.78383629775323, -14.2239893530882],
              [135.4286641786112, -14.715432224183912],
              [135.50018436090318, -14.997740573794424],
              [136.2951745952813, -15.55026498785913],
              [137.06536014215942, -15.87076222093333],
              [137.5804708192448, -16.21508228929408],
              [138.30321740127897, -16.807604261952704],
              [138.58516401586343, -16.806622409739155],
              [139.10854292211548, -17.06267913174539],
              [139.2605749859182, -17.371600843986208],
              [140.21524539607827, -17.710804945550066],
              [140.87546349503924, -17.369068698803908],
              [141.07111046769626, -16.83204721442676],
              [141.27409549373874, -16.38887013109165],
              [141.39822228410384, -15.840531508042588],
              [141.70218305884464, -15.044921156476901],
              [141.56338016170866, -14.561333103089552],
              [141.6355204611881, -14.270394789286307],
              [141.5198686057189, -13.698078301653808],
              [141.65092003801107, -12.944687595270585],
              [141.8426912782462, -12.741547539931231],
              [141.68699018775084, -12.407614434461145],
              [141.9286291851476, -11.877465915578817],
              [142.11848839738798, -11.328042087451612],
              [142.1437064963464, -11.042736504768186],
              [142.51526004452495, -10.668185723516686],
              [142.797310011974, -11.157354831591562],
              [142.86676313697427, -11.784706719614903],
              [143.11594689348573, -11.905629571177885],
              [143.15863162655876, -12.325655612846232],
              [143.5221236512998, -12.834358412327433],
              [143.5971578309876, -13.400422051652612],
              [143.5618111513, -13.763655694232192],
              [143.9220992372389, -14.548310642151996],
              [144.56371382057483, -14.171176039285903],
              [144.89490807513346, -14.594457696188641],
              [145.3747237489635, -14.98497649501833],
              [145.27199100156724, -15.428205254785732],
              [145.4852596376358, -16.28567229580478],
              [145.637033319277, -16.78491830817657],
              [145.88890425026761, -16.906926364817686],
              [146.16030887266453, -17.761654554925272],
              [146.06367394427872, -18.28007252367734],
              [146.38747846901964, -18.958274021075887],
              [147.4710815777479, -19.48072275154673],
              [148.17760176004242, -19.9559392229028],
              [148.84841352762322, -20.391209812097244],
              [148.71746544819558, -20.63346892668155],
              [149.28942020080206, -21.260510756111135],
              [149.6783370302307, -22.342511895438385],
              [150.07738244038853, -22.122783705333337],
              [150.48293908101516, -22.556142266532994],
              [150.72726525289113, -22.402404880464665],
              [150.89955447815225, -23.462236830338696],
              [151.60917524638427, -24.07625619883074],
              [152.07353966695905, -24.457886651306225],
              [152.8551973818059, -25.267501316023],
              [153.1361621441768, -26.071173191026215],
              [153.16194868389044, -26.641319268502457],
              [153.0929089703485, -27.260299574494514],
              [153.56946902894418, -28.11006682710208],
              [153.51210818910022, -28.99507740653271],
              [153.339095493787, -29.45820159273248],
              [153.06924116435886, -30.350240166954794],
              [153.08960167868184, -30.923641859665423],
              [152.89157759013938, -31.640445651986],
              [152.45000247620533, -32.550002536755265],
              [151.70911746643674, -33.041342054986394],
              [151.3439717958624, -33.81602345147387],
              [151.0105554547152, -34.31036020277793],
              [150.71413943908902, -35.173459974916796],
              [150.3282198427333, -35.671879164371916],
              [150.0752120302323, -36.42020558039054],
              [149.9461243023672, -37.10905242284121],
              [149.99728397033613, -37.42526051203518],
              [149.42388227762552, -37.77268116633344],
              [148.30462243061584, -37.809061374666925],
              [147.38173302631526, -38.21921721776752],
              [146.92212283751132, -38.606532077795116],
              [146.31792199115478, -39.03575652441141],
              [145.4896521343806, -38.59376799901902],
              [144.87697635312816, -38.41744801203915],
              [145.03221235573295, -37.89618783951102],
              [144.48568240781407, -38.085323581699285],
              [143.60997358619602, -38.8094654274053],
              [142.74542687395297, -38.538267510737555],
              [142.17832970598192, -38.380034275059835],
              [141.60658165910468, -38.30851409276788],
              [140.63857872941327, -38.019332777662555],
              [139.99215823787426, -37.402936293285094],
              [139.8065881695141, -36.64360279718831],
              [139.57414757706528, -36.13836231867066],
              [139.08280805883413, -35.732754001611745],
              [138.12074791885635, -35.61229623793939],
              [138.44946170466494, -35.127261244447865],
              [138.20756432510672, -34.38472258884593],
              [137.71917036351618, -35.076825046531],
              [136.8294055523147, -35.26053476332861],
              [137.35237104710848, -34.7073385556441],
              [137.50388634658827, -34.13026783624075],
              [137.8901160015377, -33.64047861097838],
              [137.81032759007905, -32.90000701266812],
              [136.9968371929404, -33.752771498348615],
              [136.37206912653164, -34.094766127256236],
              [135.98904341038428, -34.89011809666046],
              [135.20821251845405, -34.478670342752565],
              [135.23921837782916, -33.94795338311502],
              [134.6134167827746, -33.222778008763164],
              [134.08590376193916, -32.84807219821479],
              [134.27390262261702, -32.61723357516699],
              [132.99077680880976, -32.01122405368019],
              [132.28808068250487, -31.982646986622782],
              [131.32633060112084, -31.49580331800104],
              [129.53579389863972, -31.590422865527465],
              [128.24093753470225, -31.948488864877852],
              [127.1028674663383, -32.28226694105106],
              [126.14871382050114, -32.21596607842059]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'FJI.geojson',
        iso_a2: 'FJ',
        label_y: -17.826099,
        label_x: 177.975427,
        name: 'Fiji',
        formal_en: 'Republic of Fiji'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [180, -16.067132663642447],
              [180, -16.555216566639196],
              [179.36414266196414, -16.801354076946883],
              [178.72505936299711, -17.01204167436804],
              [178.59683859511713, -16.63915],
              [179.0966093629971, -16.433984277547403],
              [179.4135093629971, -16.379054277547404],
              [180, -16.067132663642447]
            ]
          ],
          [
            [
              [178.12557, -17.50481],
              [178.3736, -17.33992],
              [178.71806, -17.62846],
              [178.55271, -18.15059],
              [177.93266, -18.28799],
              [177.38146, -18.16432],
              [177.28504, -17.72465],
              [177.67087, -17.38114],
              [178.12557, -17.50481]
            ]
          ],
          [
            [
              [-179.79332010904864, -16.020882256741224],
              [-179.9173693847653, -16.501783135649397],
              [-180, -16.555216566639196],
              [-180, -16.067132663642447],
              [-179.79332010904864, -16.020882256741224]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NZL.geojson',
        iso_a2: 'NZ',
        label_y: -39.759,
        label_x: 172.787,
        name: 'New Zealand',
        formal_en: 'New Zealand'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [176.8858236026052, -40.0659778785822],
              [176.50801720611926, -40.60480803808957],
              [176.01244022044023, -41.28962411882147],
              [175.23956749908297, -41.68830779395328],
              [175.06789839100935, -41.42589487077513],
              [174.65097293527847, -41.2818209775454],
              [175.22763024322356, -40.45923552832336],
              [174.9001566917899, -39.9089332008472],
              [173.82404666574394, -39.50885426204351],
              [173.85226199777532, -39.14660247167748],
              [174.57480187408035, -38.797683200842755],
              [174.74347374908098, -38.02780771255843],
              [174.6970166364506, -37.38112883885792],
              [174.2920284365792, -36.71109221776149],
              [174.31900353423552, -36.53482390721391],
              [173.8409965355357, -36.12198088963413],
              [173.0541711774596, -35.23712533950039],
              [172.63600548735377, -34.52910654066943],
              [173.00704227120946, -34.45066171645037],
              [173.55129845610747, -35.00618336358801],
              [174.3293904971262, -35.26549570082862],
              [174.61200890533044, -36.15639739354052],
              [175.33661583892712, -37.20909799575827],
              [175.3575964704376, -36.52619394302117],
              [175.80888675364253, -36.79894215265767],
              [175.9584900251275, -37.55538176854612],
              [176.76319542877656, -37.881253350578675],
              [177.4388131045605, -37.961248467766495],
              [178.01035444570866, -37.579824721020174],
              [178.51709354076274, -37.6953732236248],
              [178.27473107331383, -38.58281259537314],
              [177.97046023997927, -39.166342868812976],
              [177.20699262929918, -39.14577564876082],
              [176.93998050364706, -39.44973642350161],
              [177.0329464053401, -39.879942722331464],
              [176.8858236026052, -40.0659778785822]
            ]
          ],
          [
            [
              [169.66781456937315, -43.555325616226376],
              [170.52491987536615, -43.031688327812816],
              [171.125089960004, -42.51275359473782],
              [171.56971398344325, -41.767424411792135],
              [171.94870893787186, -41.514416599291124],
              [172.0972270042787, -40.956104424809716],
              [172.79857954334403, -40.4939620908235],
              [173.02037479074076, -40.919052422856446],
              [173.2472343285021, -41.33199879330081],
              [173.9584053897028, -40.926700534835646],
              [174.24758670480816, -41.34915536882171],
              [174.24851688058942, -41.77000823340673],
              [173.87644656808794, -42.23318409603879],
              [173.2227396995957, -42.970038344088614],
              [172.71124637277074, -43.372287693048555],
              [173.08011274647015, -43.853343601253606],
              [172.3085836123525, -43.86569426857136],
              [171.45292524646362, -44.2425188128437],
              [171.18513797432718, -44.89710418068486],
              [170.61669721911653, -45.908928724959736],
              [169.8314221540093, -46.35577483498757],
              [169.33233117093428, -46.641235446967876],
              [168.41135379462855, -46.61994475686363],
              [167.76374474514682, -46.29019744240919],
              [166.67688602118417, -46.219917494492236],
              [166.50914432196467, -45.85270476662619],
              [167.0464241885033, -45.110941257508635],
              [168.30376346259686, -44.12397307716614],
              [168.94940880765157, -43.935819187191434],
              [169.66781456937315, -43.555325616226376]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'NCL.geojson',
        iso_a2: 'NC',
        label_y: -21.064697,
        label_x: 165.084004,
        name: 'New Caledonia',
        formal_en: 'New Caledonia'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [165.77998986232637, -21.08000497811563],
            [166.59999148993384, -21.700018812753527],
            [167.1200114280869, -22.15999073658349],
            [166.7400346214448, -22.39997608814695],
            [166.18973229396866, -22.129708347260454],
            [165.47437544175222, -21.679606621998232],
            [164.82981530177568, -21.14981983814195],
            [164.16799523341365, -20.444746595951628],
            [164.029605747736, -20.105645847252354],
            [164.45996707586272, -20.1200118954295],
            [165.02003624904205, -20.45999114347773],
            [165.46000939357512, -20.80002206795826],
            [165.77998986232637, -21.08000497811563]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'SLB.geojson',
        iso_a2: 'SB',
        label_y: -8.029548,
        label_x: 159.170468,
        name: 'Solomon Is.',
        formal_en: null
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [162.1190246930409, -10.48271900802115],
              [162.3986458681722, -10.826367282762106],
              [161.70003218001835, -10.820011081590211],
              [161.31979699121476, -10.204751478723168],
              [161.91738325423802, -10.446700534713713],
              [162.1190246930409, -10.48271900802115]
            ]
          ],
          [
            [
              [161.67998172428912, -9.599982191611367],
              [161.5293966005906, -9.784312025596485],
              [160.78825320866054, -8.917543226764892],
              [160.57999718652434, -8.32000864017396],
              [160.92002811100485, -8.32000864017396],
              [161.28000613835, -9.120011488484451],
              [161.67998172428912, -9.599982191611367]
            ]
          ],
          [
            [
              [160.85222863183787, -9.872937106977048],
              [160.4625883323572, -9.895209649294841],
              [159.84944746321412, -9.794027194867354],
              [159.64000288313514, -9.639979750205278],
              [159.70294477766663, -9.242949720906815],
              [160.36295617089843, -9.40030445723557],
              [160.68851769433724, -9.610162448772869],
              [160.85222863183787, -9.872937106977048]
            ]
          ],
          [
            [
              [159.64000288313514, -8.020026950719632],
              [159.87502729719859, -8.337320244991737],
              [159.91740197167792, -8.53828989017483],
              [159.13367719953936, -8.114181410355428],
              [158.5861137229747, -7.754823500197737],
              [158.21114953026483, -7.421872246941199],
              [158.35997765526542, -7.320017998893917],
              [158.8200012555277, -7.560003350457379],
              [159.64000288313514, -8.020026950719632]
            ]
          ],
          [
            [
              [157.14000044171888, -7.021638278840641],
              [157.5384257346892, -7.347819919466943],
              [157.33941979393325, -7.404767347852592],
              [156.90203047101483, -7.176874281445428],
              [156.4913578635913, -6.765943291860452],
              [156.542827590154, -6.599338474151452],
              [157.14000044171888, -7.021638278840641]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'VUT.geojson',
        iso_a2: 'VU',
        label_y: -15.37153,
        label_x: 166.908762,
        name: 'Vanuatu',
        formal_en: 'Republic of Vanuatu'
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [167.2168013857696, -15.89184620530842],
              [167.84487674384502, -16.46633310309717],
              [167.51518110582288, -16.59784962327999],
              [167.1800077659778, -16.159995212470946],
              [167.2168013857696, -15.89184620530842]
            ]
          ],
          [
            [
              [166.79315799384085, -15.668810723536687],
              [166.6498592470955, -15.392703545801211],
              [166.6291369977464, -14.626497084209605],
              [167.10771243720149, -14.933920179913954],
              [167.27002811103023, -15.740020847234888],
              [167.00120731024793, -15.614602146062516],
              [166.79315799384085, -15.668810723536687]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ATA.geojson',
        iso_a2: 'AQ',
        label_y: -79.843222,
        label_x: 35.885455,
        name: 'Antarctica',
        formal_en: null
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-48.66061601418252, -78.04701873159873],
              [-48.1513964503784, -78.04706960058677],
              [-46.66285681821098, -77.83147552506506],
              [-45.15475765642109, -78.04706960058677],
              [-43.92082780615574, -78.47810272233328],
              [-43.48994971370611, -79.0855599913685],
              [-43.37243750667439, -79.51664478954731],
              [-43.33326677099714, -80.0261227355129],
              [-44.880536668464266, -80.33964365022769],
              [-46.50617387550203, -80.59435678499433],
              [-48.38642086444183, -80.82948455192233],
              [-50.48210689960646, -81.02544158317313],
              [-52.85198808451179, -80.96668547965729],
              [-54.16425940613162, -80.63352752067158],
              [-53.987991095584036, -80.22202809033138],
              [-51.85313432474216, -79.94772958772612],
              [-50.99132646341059, -79.6146233051727],
              [-50.364594692574755, -79.1834868305616],
              [-49.91413123228649, -78.81120900488669],
              [-49.30695899107312, -78.45856903092695],
              [-48.66061601418252, -78.04701792415447],
              [-48.66061601418252, -78.04701873159873]
            ]
          ],
          [
            [
              [-66.29003089055513, -80.25577280061798],
              [-64.03768775089767, -80.29494353629522],
              [-61.88324561221718, -80.39287037548831],
              [-61.13897579613348, -79.98137094514813],
              [-60.61011918805844, -79.6286792947561],
              [-59.572094692611586, -80.04017872509628],
              [-59.86584937197473, -80.54965667106185],
              [-60.15965572777019, -81.00032683707929],
              [-62.25539343936711, -80.86317758577665],
              [-64.48812537296979, -80.92193368929253],
              [-65.74166642928991, -80.5888274067391],
              [-65.74166642928991, -80.54965667106185],
              [-66.29003089055513, -80.25577280061798]
            ]
          ],
          [
            [
              [-73.9158186510023, -71.26934457792578],
              [-73.9158186510023, -71.26934377048153],
              [-73.23033077665059, -71.15177988701751],
              [-72.07471655952358, -71.19095062269476],
              [-71.78096188016042, -70.68147267672917],
              [-71.72217993842841, -70.30919565849851],
              [-71.74179114448319, -69.50578216565675],
              [-71.1738154771632, -69.03547495536841],
              [-70.25325151231577, -68.87874033622717],
              [-69.72444658067303, -69.25101735445783],
              [-69.48942216660959, -69.62334604912077],
              [-69.05851823594381, -70.0740162151382],
              [-68.72554114447112, -70.5051526897493],
              [-68.45134599473043, -70.95582285576673],
              [-68.33383378769872, -71.40649302178416],
              [-68.51012793646244, -71.79840708428573],
              [-68.78429724798698, -72.17073577894867],
              [-69.95947099473648, -72.3078850302513],
              [-71.07588863797011, -72.50384206150208],
              [-72.38813412137378, -72.48425669366347],
              [-71.89849992540829, -72.0923426311619],
              [-73.07362199572549, -72.22949188246453],
              [-74.19003963895912, -72.36669281019945],
              [-74.95389482288145, -72.07275726332328],
              [-75.01262508818117, -71.6612578329831],
              [-73.9158186510023, -71.26934457792578]
            ]
          ],
          [
            [
              [-102.33072506387639, -71.89416432076682],
              [-102.33072506387639, -71.89416351332257],
              [-101.70396745482441, -71.7177918499104],
              [-100.43091854531409, -71.85499277764532],
              [-98.98154964882391, -71.9333342489998],
              [-97.88474321164506, -72.07053517673472],
              [-96.78793677446619, -71.95297129327072],
              [-96.20034990109144, -72.52120534275215],
              [-96.98376461463623, -72.44286387139768],
              [-98.19808325884682, -72.48203460707492],
              [-99.43201310911218, -72.44286387139768],
              [-100.78345516640925, -72.50161997491354],
              [-101.80186845580137, -72.30566294366275],
              [-102.33072506387639, -71.89416432076682]
            ]
          ],
          [
            [
              [-122.62173458544193, -73.65777760202387],
              [-122.6217353928862, -73.65777679457962],
              [-122.40624467022907, -73.32461883559391],
              [-121.21151139385711, -73.50099049900608],
              [-119.91885127829204, -73.65772511814733],
              [-118.72414303269196, -73.48135345473517],
              [-119.29211870001194, -73.8340967815595],
              [-120.23221716370998, -74.08880991632614],
              [-121.62282995668428, -74.01046844497165],
              [-122.62173458544193, -73.65777760202387]
            ]
          ],
          [
            [
              [-127.28312964568192, -73.46176889434079],
              [-127.28313045312619, -73.46176808689654],
              [-126.55847184309725, -73.24622568780714],
              [-125.55956640689534, -73.48135345473517],
              [-124.03188187726683, -73.87326751723673],
              [-124.61946875064157, -73.8340967815595],
              [-125.91218054263894, -73.7361182659341],
              [-127.28312964568192, -73.46176889434079]
            ]
          ],
          [
            [
              [-163.7128956777287, -78.59566741324154],
              [-163.7128956777287, -78.59566660579729],
              [-163.1058009511638, -78.22333791113434],
              [-161.24511349184644, -78.38017588314018],
              [-160.24620805564453, -78.69364512142268],
              [-159.48240454815448, -79.04633757925897],
              [-159.20818356019765, -79.49705942170868],
              [-161.12760128481472, -79.63420867301133],
              [-162.43984676821842, -79.28146534618699],
              [-163.027407803377, -78.92877369579496],
              [-163.06660437727038, -78.8699659158468],
              [-163.7128956777287, -78.59566741324154]
            ]
          ],
          [
            [
              [180, -84.71338],
              [180, -90],
              [-180, -90],
              [-180, -84.71338],
              [-179.94249935617898, -84.72144337355252],
              [-179.05867733469117, -84.13941171664914],
              [-177.25677181710577, -84.45293263136392],
              [-177.1408066732658, -84.41794122714828],
              [-176.08467281807765, -84.09925912875838],
              [-175.94723461362773, -84.11044871021659],
              [-175.82988216866258, -84.11791432081571],
              [-174.38250281481567, -84.53432301222352],
              [-173.11655941474544, -84.11791432081571],
              [-172.88910559801278, -84.06101856886238],
              [-169.9512229075714, -83.88464690545015],
              [-168.9999889801587, -84.11791432081571],
              [-168.5301985341933, -84.23739023227444],
              [-167.02209937240337, -84.57049651482794],
              [-164.1821435211551, -84.82520964959463],
              [-161.9297745432814, -85.13873056430941],
              [-158.07137956442492, -85.37391000766968],
              [-155.19225297749927, -85.09955982863215],
              [-150.942098965438, -85.29551685988292],
              [-148.53307288307155, -85.60903777459771],
              [-145.88891822633295, -85.31510222772158],
              [-143.10771847860048, -85.04075204868394],
              [-142.89227943237557, -84.57049651482794],
              [-146.82906836646328, -84.53127410271838],
              [-150.06073157448392, -84.29614633579035],
              [-150.9029282297608, -83.90423227328881],
              [-153.58620113830017, -83.68868987419938],
              [-153.4099069895364, -83.23801970818204],
              [-153.03775916238646, -82.82652027784184],
              [-152.66563717345272, -82.45419158317884],
              [-152.86151669005503, -82.04269215283858],
              [-154.52629879455392, -81.76839365023335],
              [-155.29017981669236, -81.41565032340901],
              [-156.83744971415953, -81.10212940869428],
              [-154.4087865875222, -81.16093718864244],
              [-152.0976615061328, -81.00415089306881],
              [-150.6482926096426, -81.33730885205455],
              [-148.86599829811203, -81.0433733051783],
              [-147.22074988501947, -80.67104461051548],
              [-146.41774899619182, -80.33793832796204],
              [-146.77028642473124, -79.92643889762189],
              [-148.06294654029634, -79.65208871858425],
              [-149.53190080462508, -79.35820484814042],
              [-151.58841610411247, -79.29939706819226],
              [-153.39032162169778, -79.16224781688965],
              [-155.32937639058574, -79.06426930126423],
              [-155.97566769104412, -78.69193979915707],
              [-157.26830196839302, -78.37841888444223],
              [-158.05176835837008, -78.02567555761794],
              [-158.365134243788, -76.889207458655],
              [-157.8754742096064, -76.98723765071264],
              [-156.974573127246, -77.30075856542754],
              [-155.32937639058574, -77.20272837336978],
              [-153.74283240457677, -77.06557912206722],
              [-152.92024695535474, -77.49666392024602],
              [-151.33378048399427, -77.39873708105284],
              [-150.00194963275192, -77.18314300553124],
              [-148.7484860910803, -76.90884450292594],
              [-147.61248308000805, -76.57573822037256],
              [-146.10440894899006, -76.47775970474709],
              [-146.14352800823497, -76.1054310100842],
              [-146.4960912749905, -75.7331539918535],
              [-146.20230994996697, -75.38041066502922],
              [-144.9096239961858, -75.20403900161699],
              [-144.32203712281103, -75.53719696060273],
              [-142.79435259318257, -75.34123992935196],
              [-141.63876421427165, -75.08647511815298],
              [-140.2090065238362, -75.06688975031443],
              [-138.85759030475532, -74.96891123468896],
              [-137.5061999238905, -74.73378346776099],
              [-136.42890133990187, -74.51824106867161],
              [-135.21458269569132, -74.30269866958218],
              [-134.43119382036258, -74.36145477309799],
              [-133.7456542695786, -74.43984792088486],
              [-132.25716792873203, -74.30269866958218],
              [-130.92531123927358, -74.47901865656195],
              [-129.55428381413782, -74.45943328872346],
              [-128.2420383307342, -74.32228403742073],
              [-126.89062211165322, -74.4202625530462],
              [-125.40208247948584, -74.51824106867161],
              [-124.01149552472765, -74.47901865656195],
              [-122.56215246645365, -74.49860402440066],
              [-121.07361283428622, -74.51824106867161],
              [-119.70255957093428, -74.47901865656195],
              [-118.68414547409799, -74.18508310968588],
              [-117.46980099167126, -74.02834849054466],
              [-116.21631161178345, -74.24389088963397],
              [-115.02155249719539, -74.06751922622186],
              [-113.9443314278551, -73.71482757582987],
              [-113.29798845096451, -74.02834849054466],
              [-112.94545182986934, -74.38104014093665],
              [-112.29908301476263, -74.71419809992238],
              [-111.26105851931567, -74.4202625530462],
              [-110.06632524294378, -74.79253957127685],
              [-108.71490902386279, -74.91010345474086],
              [-107.55934648316816, -75.18445363377845],
              [-106.14914832235507, -75.12569753026253],
              [-104.87607357462872, -74.94932586685042],
              [-103.36794857462269, -74.98849660252768],
              [-102.01650651732564, -75.12569753026253],
              [-100.64553076862228, -75.30201751724246],
              [-100.1166999987633, -74.87093271906349],
              [-100.763042975654, -74.53782643651022],
              [-101.25270300983556, -74.18508310968588],
              [-102.54533728718457, -74.10674163833141],
              [-103.11331295450452, -73.73441294366842],
              [-103.32875200072931, -73.36208424900553],
              [-103.68128862182442, -72.61753021254418],
              [-102.9174851143344, -72.75467946384686],
              [-101.60523963093078, -72.81343556736266],
              [-100.31252783893342, -72.75467946384686],
              [-99.13737993040007, -72.91141408298807],
              [-98.11888912635953, -73.2053496298642],
              [-97.68803687212608, -73.5580412802563],
              [-96.33659481482897, -73.6168490602044],
              [-95.0439605374799, -73.47969980890184],
              [-93.67290727412814, -73.28374277765096],
              [-92.43900326207898, -73.16617889418706],
              [-91.42056413447074, -73.40130666111509],
              [-90.08873328322846, -73.32291351332816],
              [-89.22695126011297, -72.55872243259591],
              [-88.42395117872957, -73.00939259861343],
              [-87.26833696160259, -73.18576426202566],
              [-86.01482174349849, -73.08778574640019],
              [-85.19223629427654, -73.47969980890184],
              [-83.87999081087281, -73.51887054457893],
              [-82.66564632844609, -73.63643442804306],
              [-81.4709130520742, -73.85197682713243],
              [-80.68744666209705, -73.47969980890184],
              [-80.29579098175702, -73.12695648207739],
              [-79.29688554555503, -73.51887054457893],
              [-77.92585812041932, -73.42089202895363],
              [-76.90736731637878, -73.63643442804306],
              [-76.2218794420271, -73.96954071059645],
              [-74.89004859078483, -73.87161387140338],
              [-73.85202409533798, -73.65601979588166],
              [-72.83353329129744, -73.40130666111509],
              [-71.61921464708689, -73.26415740981241],
              [-70.20904232448999, -73.1465418499161],
              [-68.93591590033128, -73.00939259861343],
              [-67.95662167018412, -72.79385019952406],
              [-67.36906063502556, -72.48032928480927],
              [-67.13403622096206, -72.04924448663041],
              [-67.25154842799373, -71.63774505629027],
              [-67.56494015162792, -71.24583099378873],
              [-67.91747677272303, -70.85391693128707],
              [-68.23084265814094, -70.46205454521782],
              [-68.48545244004305, -70.10931121839354],
              [-68.54420854355897, -69.717397155892],
              [-68.44628170436579, -69.3255347698227],
              [-67.97623287623895, -68.95320607515976],
              [-67.58449968125035, -68.5417066448195],
              [-67.42784257675754, -68.14984425875025],
              [-67.62367041692767, -67.7187594605715],
              [-67.74118262395939, -67.3268453980699],
              [-67.25154842799373, -66.87617523205245],
              [-66.7031839667286, -66.5822396851762],
              [-66.05681515162189, -66.20996266694567],
              [-65.37132727727015, -65.89639007579859],
              [-64.56827551945446, -65.6025062053547],
              [-64.17654232446586, -65.1714230220644],
              [-63.62815202498456, -64.8970728430267],
              [-63.00139441593262, -64.6423080318279],
              [-62.041685553624006, -64.58355192831198],
              [-61.41492794457207, -64.27003101359719],
              [-60.70985470238173, -64.07407398234642],
              [-59.887269253159616, -63.956510098882404],
              [-59.16258480491456, -63.701745287683536],
              [-58.59455746116231, -63.38822437296864],
              [-57.811142747617566, -63.27066048950462],
              [-57.223581712458895, -63.52542530070366],
              [-57.5957295396089, -63.85853158325705],
              [-58.61414282900097, -64.15246713013318],
              [-59.04507259788289, -64.3680095292226],
              [-59.78934241396661, -64.2112232336491],
              [-60.61192786318867, -64.3092017492744],
              [-61.29741573754035, -64.54432951620254],
              [-62.02210018578546, -64.7990943274014],
              [-62.51176021996696, -65.09302987427748],
              [-62.6488577948374, -65.48494232189068],
              [-62.59012752953771, -65.85721934012133],
              [-62.12007870141076, -66.19032562267466],
              [-62.80556657576244, -66.42550506603499],
              [-63.7456900702324, -66.50384653738956],
              [-64.29410620792999, -66.83700449637524],
              [-64.88169308130466, -67.15047373465768],
              [-65.50842485214054, -67.58161020926889],
              [-65.66508195663334, -67.95388722749948],
              [-65.31254533553812, -68.36533498140739],
              [-64.7837145656793, -68.67890757255452],
              [-63.961103278241126, -68.9139836630502],
              [-63.1972997707511, -69.22755625419728],
              [-62.78595536970778, -69.61941864026653],
              [-62.570516323482934, -69.99174733492953],
              [-62.27673580590357, -70.38366139743107],
              [-61.80666113956062, -70.71676767998451],
              [-61.51290646019743, -71.0890446982151],
              [-61.37580888532716, -72.01007375095315],
              [-61.08197669131556, -72.38235076918386],
              [-61.003661058177215, -72.7742648316854],
              [-60.69026933454313, -73.16617889418706],
              [-60.827366909413456, -73.69524220799121],
              [-61.37580888532716, -74.10674163833141],
              [-61.96336992048572, -74.43984792088486],
              [-63.29520077172799, -74.57699717218742],
              [-63.7456900702324, -74.92974049901176],
              [-64.35283647322962, -75.2628467815652],
              [-65.86098731145182, -75.63512379979574],
              [-67.19281816269415, -75.79191009536942],
              [-68.44628170436579, -76.00745249445879],
              [-69.7977237616629, -76.22299489354822],
              [-70.6007238430463, -76.63449432388842],
              [-72.2067756822454, -76.67366505956568],
              [-73.9695363023697, -76.63449432388842],
              [-75.55597693551408, -76.71288747167517],
              [-77.24037024606764, -76.71288747167517],
              [-76.92697852243361, -77.10480153417677],
              [-75.39929399280499, -77.28106984472436],
              [-74.28287634957144, -77.55542002376177],
              [-73.6561187405195, -77.90811167415393],
              [-74.7725363837531, -78.22163258886872],
              [-76.49610042998398, -78.12365407324324],
              [-77.92585812041932, -78.37841888444223],
              [-77.98466590036753, -78.78991831478237],
              [-78.0237849596125, -79.18183318472825],
              [-76.8486370510791, -79.51493946728169],
              [-76.63322384307043, -79.88721648551223],
              [-75.36009741891172, -80.25954518017522],
              [-73.24485185412459, -80.41633147574879],
              [-71.44294633653925, -80.69062997835402],
              [-70.01316280788774, -81.00415089306881],
              [-68.19164608424757, -81.3176718077836],
              [-65.70427853052672, -81.47445810335722],
              [-63.25603003605073, -81.74875660596251],
              [-61.55202551944234, -82.04269215283858],
              [-59.69141557477349, -82.37585011182438],
              [-58.71212134462627, -82.84610564568038],
              [-58.22248714866089, -83.21843434034338],
              [-57.008116828017876, -82.86569101351904],
              [-55.362894253141576, -82.5717554666428],
              [-53.61977067728827, -82.25823455192807],
              [-51.54364417174605, -82.00352141716132],
              [-49.76134986021549, -81.7291712381238],
              [-47.27393063006224, -81.70958587028525],
              [-44.82570797380254, -81.84673512158781],
              [-42.80836340999241, -82.08191456494814],
              [-42.162020433101816, -81.65082976676933],
              [-40.771433478343624, -81.3568942198932],
              [-38.24481767429711, -81.33730885205455],
              [-36.26666968438025, -81.12171477653294],
              [-34.38639685722438, -80.90617237744351],
              [-32.31029618989834, -80.76902312614078],
              [-30.097097947702025, -80.59265146272867],
              [-28.549802212018733, -80.33793832796204],
              [-29.25490129242513, -79.9851950011377],
              [-29.685805223090995, -79.63250335074571],
              [-29.685805223090995, -79.260226332515],
              [-31.62480831554666, -79.29939706819226],
              [-33.68132361503399, -79.45613168733348],
              [-35.63991207532831, -79.45613168733348],
              [-35.91410722506902, -79.08385466910289],
              [-35.77700965019869, -78.33924814876502],
              [-35.32654618991046, -78.12365407324324],
              [-33.89676266125889, -77.88852630631527],
              [-32.21236935070527, -77.65345021581953],
              [-30.99805070649461, -77.35951466894335],
              [-29.783732062284116, -77.06557912206722],
              [-28.882779303491418, -76.67366505956568],
              [-27.511751878355653, -76.49734507258574],
              [-26.16033565927478, -76.36014414485078],
              [-25.474821946706925, -76.28180267349632],
              [-23.927552049239807, -76.24258026138676],
              [-22.458597784910978, -76.1054310100842],
              [-21.224693772861826, -75.90947397883343],
              [-20.010375128651106, -75.6743462119054],
              [-18.913542853256217, -75.43921844497731],
              [-17.5229817367142, -75.12569753026253],
              [-16.641588507544043, -74.79253957127685],
              [-15.70149085129026, -74.49860402440066],
              [-15.407710333710895, -74.10674163833141],
              [-16.4653201969964, -73.87161387140338],
              [-16.11278357590129, -73.46011444106318],
              [-15.44685523117198, -73.1465418499161],
              [-14.408804897509015, -72.95058481866533],
              [-13.311972622114013, -72.7154570517373],
              [-12.293507656289592, -72.40193613702252],
              [-11.510067104528616, -72.01007375095315],
              [-11.020432908563066, -71.53976654066486],
              [-10.295774298534184, -71.26541636162733],
              [-9.101015183946117, -71.32422414157548],
              [-8.611380987980624, -71.65733042412887],
              [-7.416621873392444, -71.69650115980608],
              [-7.377451137715298, -71.32422414157548],
              [-6.868231573911146, -70.93231007907394],
              [-5.790984666354802, -71.0302885946993],
              [-5.536374884452698, -71.4026172893623],
              [-4.341667446296924, -71.4613733928781],
              [-3.048981492515622, -71.28505340589817],
              [-1.795492112627812, -71.16743784600186],
              [-0.65948910155555, -71.22624562595007],
              [-0.228636847322093, -71.63774505629027],
              [0.868195428072909, -71.30463877373683],
              [1.886686232113505, -71.12826711032471],
              [3.022637566753417, -70.9911178590221],
              [4.139055209987021, -70.85391693128707],
              [5.157546014027616, -70.61878916435904],
              [6.273911980828871, -70.46205454521782],
              [7.135719842160597, -70.24651214612834],
              [7.742866245157813, -69.89376881930406],
              [8.487110223025297, -70.14853363050304],
              [9.525134718472202, -70.01133270276819],
              [10.249845004933434, -70.48163991305648],
              [10.817820672253333, -70.83433156344853],
              [11.953823683325595, -70.63837453219776],
              [12.404287143613942, -70.24651214612834],
              [13.422777947654367, -69.97216196709093],
              [14.734997592841978, -70.03091807060673],
              [15.126756626046586, -70.40324676526973],
              [15.94934207526859, -70.03091807060673],
              [17.02658898282516, -69.91335418714277],
              [18.201711053142276, -69.87418345146551],
              [19.259372592860018, -69.89376881930406],
              [20.375738559661443, -70.01133270276819],
              [21.452985467217786, -70.07014048271628],
              [21.923034295344735, -70.40324676526973],
              [22.56940311045139, -70.69718231214586],
              [23.666183709414213, -70.52081064873374],
              [24.841357456163564, -70.48163991305648],
              [25.977308790803647, -70.48163991305648],
              [27.09372643403725, -70.46205454521782],
              [28.09258019380684, -70.32485361748297],
              [29.15024173352458, -70.20728973401896],
              [30.031583286262503, -69.93293955498132],
              [30.97173261894858, -69.7566195680015],
              [31.990171746556825, -69.65864105237603],
              [32.75405276869532, -69.3842908733385],
              [33.30244306817667, -68.83564219169574],
              [33.87041873549657, -68.50258758557459],
              [34.908494907375825, -68.65927052828351],
              [35.300202264148254, -69.01201385510791],
              [36.16201012547975, -69.24714162203594],
              [37.2000346209266, -69.16874847424907],
              [37.905107863116825, -69.52144012464123],
              [38.649403517416886, -69.77620493584016],
              [39.66789432145737, -69.54107716891207],
              [40.02043094255248, -69.10994069430097],
              [40.92135786312906, -68.93362070732115],
              [41.959434035008144, -68.60051442476771],
              [42.938702426939074, -68.46331349703274],
              [44.11387617368865, -68.26740814221426],
              [44.897290887233396, -68.05186574312489],
              [45.719928012887806, -67.8167379761968],
              [46.50334272643261, -67.60119557710749],
              [47.443440382686276, -67.7187594605715],
              [48.34441897969509, -67.36606781017946],
              [48.99073611836957, -67.09171763114188],
              [49.930885451055644, -67.11130299898048],
              [50.753470900277705, -66.87617523205245],
              [50.94932457866389, -66.52348358166046],
              [51.79154707215696, -66.24913340262287],
              [52.61413252137896, -66.0531763713721],
              [53.61303795758079, -65.89639007579859],
              [54.533550245996025, -65.81804860444396],
              [55.41494347516618, -65.87680470795993],
              [56.35504113141985, -65.97478322358535],
              [57.15809288923566, -66.24913340262287],
              [57.25596805199649, -66.68021820080168],
              [58.13736128116665, -67.01332448335512],
              [58.744507684163864, -67.28767466239265],
              [59.93931847518422, -67.40523854585672],
              [60.60522098169736, -67.67958872489424],
              [61.42780643091936, -67.95388722749948],
              [62.38748945501169, -68.01269500744758],
              [63.19048953639515, -67.8167379761968],
              [64.052349074159, -67.40523854585672],
              [64.9924467304129, -67.62072926851374],
              [65.97171512234388, -67.73834482840999],
              [66.91186445502973, -67.85590871187412],
              [67.89113284696089, -67.93430185966076],
              [68.89003828316288, -67.93430185966076],
              [69.71262373238477, -68.97279144299841],
              [69.67345299670745, -69.22755625419728],
              [69.55594078967584, -69.67822642021474],
              [68.59625776558352, -69.93293955498132],
              [67.81273969917413, -70.30526824964431],
              [67.94988895047663, -70.69718231214586],
              [69.06630659371024, -70.67754526787496],
              [68.92915734240779, -71.06945933037656],
              [68.41998945503593, -71.4417880250395],
              [67.94988895047663, -71.85328745537964],
              [68.71376997261513, -72.16680837009437],
              [69.86930667509387, -72.26478688571984],
              [71.02489505400456, -72.08841522230773],
              [71.57328535348603, -71.69650115980608],
              [71.90628828317489, -71.32422414157548],
              [72.45462690622401, -71.01070322686058],
              [73.08141035349206, -70.71676767998451],
              [73.33602013539416, -70.36402435316018],
              [73.86487674346921, -69.87418345146551],
              [74.49155683787268, -69.77620493584016],
              [75.62755984894494, -69.73703420016284],
              [76.62646528514676, -69.61941864026653],
              [77.64490441275518, -69.46268402112526],
              [78.13453860872056, -69.07076995862371],
              [78.42837080273216, -68.69844126396072],
              [79.1138586770839, -68.32621592216248],
              [80.09312706901488, -68.07150278739579],
              [80.93534956250772, -67.87554575614502],
              [81.48379153842143, -67.54238779715922],
              [82.05176720574138, -67.36606781017946],
              [82.77642581577044, -67.20928151460589],
              [83.77533125197237, -67.30726003023125],
              [84.6762064961166, -67.20928151460589],
              [85.65552656447994, -67.09171763114188],
              [86.75235883987477, -67.15047373465768],
              [87.47701744990383, -66.87617523205245],
              [87.98628869014021, -66.20991099051338],
              [88.35841067907398, -66.4842611695509],
              [88.82840783076853, -66.95456837983926],
              [89.6706303242616, -67.15047373465768],
              [90.63036502478627, -67.22886688244449],
              [91.59009972531084, -67.11130299898048],
              [92.60853885291903, -67.18969614676723],
              [93.54863650917292, -67.20928151460589],
              [94.17541995644098, -67.11130299898048],
              [95.01759077350164, -67.17011077892869],
              [95.78147179564019, -67.385653178018],
              [96.68239871621677, -67.24850392671544],
              [97.75964562377311, -67.24850392671544],
              [98.68020958862053, -67.11130299898048],
              [99.71818240763503, -67.24850392671544],
              [100.38418826701275, -66.9153459677297],
              [100.8933561543846, -66.5822396851762],
              [101.57889570516852, -66.30788950613868],
              [102.83241092327262, -65.56328379324515],
              [103.47867638551475, -65.70048472098],
              [104.24255740765307, -65.97478322358535],
              [104.9084599141662, -66.32752655040969],
              [106.18156050010873, -66.93493133556836],
              [107.16088056847207, -66.95456837983926],
              [108.08139285688713, -66.95456837983926],
              [109.15863976444365, -66.83700449637524],
              [110.23583499556781, -66.69980356864033],
              [111.05847212122205, -66.42550506603499],
              [111.74395999557385, -66.13156951915892],
              [112.86037763880745, -66.09234710704936],
              [113.60467329310734, -65.87680470795993],
              [114.38808800665197, -66.0727617392107],
              [114.89730757045618, -66.38628265392543],
              [115.60238081264652, -66.69980356864033],
              [116.69916141160934, -66.66063283296302],
              [117.38470096239314, -66.9153459677297],
              [118.57946007698126, -67.17011077892869],
              [119.83292361865301, -67.26808929455399],
              [120.8709997905321, -67.18969614676723],
              [121.65441450407707, -66.87617523205245],
              [122.32036868702232, -66.56265431733766],
              [123.2212956075989, -66.4842611695509],
              [124.1222742046076, -66.62146209728587],
              [125.16024702362222, -66.71938893647894],
              [126.10039635630835, -66.56265431733766],
              [127.00142662974929, -66.56265431733766],
              [127.88276818248721, -66.66063283296302],
              [128.80328047090234, -66.75861134858849],
              [129.70425906791115, -66.5822396851762],
              [130.78145429903543, -66.42550506603499],
              [131.79994510307586, -66.38628265392543],
              [132.9358964377161, -66.38628265392543],
              [133.85646040256336, -66.28830413830013],
              [134.75738732313982, -66.20996266694567],
              [135.0315824728807, -65.72007008881866],
              [135.0707532085578, -65.30857065847846],
              [135.6974849793935, -65.58286916108369],
              [135.87380496637343, -66.03359100353345],
              [136.2067045431977, -66.4450904338737],
              [136.61804894424108, -66.77819671642698],
              [137.46027143773392, -66.95456837983926],
              [138.59622277237406, -66.8957605998911],
              [139.90844241756145, -66.87617523205245],
              [140.80942101457026, -66.81736745210435],
              [142.1216923361901, -66.81736745210435],
              [143.06184166887613, -66.79778208426569],
              [144.37406131406362, -66.83700449637524],
              [145.490427280865, -66.9153459677297],
              [146.1955521994878, -67.22886688244449],
              [145.99969852110144, -67.60119557710749],
              [146.64606733620815, -67.89513112398367],
              [147.7232625673323, -68.1302588909117],
              [148.83962853413368, -68.38502370211057],
              [150.13231448791487, -68.56129201265821],
              [151.48370486877957, -68.71812998466402],
              [152.50224734925246, -68.87481292737294],
              [153.63819868389248, -68.89450164807613],
              [154.2845674989992, -68.56129201265821],
              [155.16585737530477, -68.83564219169574],
              [155.92979007387544, -69.14921478284282],
              [156.81113162661336, -69.3842908733385],
              [158.02552778547238, -69.48226938896397],
              [159.1810128115187, -69.59983327242799],
              [159.6706986839165, -69.99174733492953],
              [160.8066500185564, -70.2268751018575],
              [161.57047936426272, -70.57961842868184],
              [162.68689700749633, -70.73635304782317],
              [163.8424337099749, -70.71676767998451],
              [164.91968061753118, -70.77552378350032],
              [166.11443973211942, -70.75593841566177],
              [167.30909549384296, -70.83433156344853],
              [168.42561648994115, -70.97148081475109],
              [169.46358930895587, -71.20666025811136],
              [170.50166548083496, -71.4026172893623],
              [171.20679039945753, -71.69650115980608],
              [171.0892265159937, -72.08841522230773],
              [170.5604215843507, -72.44115854913207],
              [170.10995812406236, -72.89182871514942],
              [169.75736982653507, -73.24452036554152],
              [169.2873209984083, -73.65601979588166],
              [167.97510135322068, -73.81280609145517],
              [167.38748864162972, -74.16549774184722],
              [166.09480268784841, -74.38104014093665],
              [165.64439090399242, -74.77295420343819],
              [164.9588513532085, -75.14528289810119],
              [164.23419274317962, -75.45880381281597],
              [163.8227966657039, -75.87030324315617],
              [163.56823856023425, -76.24258026138676],
              [163.47026004460895, -76.69330210383652],
              [163.48989708887973, -77.06557912206722],
              [164.05787275619969, -77.45744150813647],
              [164.27336347885694, -77.82977020279935],
              [164.74346398341606, -78.18251352962375],
              [166.60412560451732, -78.31961110449402],
              [166.99578128485734, -78.75074757910522],
              [165.19387576727195, -78.90748300569072],
              [163.66621707585955, -79.12302540477998],
              [161.7663847190811, -79.16224781688965],
              [160.92416222558825, -79.73048186637101],
              [160.74789391504066, -80.20073740022713],
              [160.31696414615863, -80.57306609489001],
              [159.78821089094833, -80.945394789553],
              [161.12001590397438, -81.27850107210645],
              [161.62928714421082, -81.6900005024466],
              [162.49099165267796, -82.0622775206773],
              [163.70533613510474, -82.39543547966286],
              [165.09594892807877, -82.70895639437782],
              [166.60412560451732, -83.02247730909255],
              [168.89566531806793, -83.3359982238074],
              [169.40478152900755, -83.82589080193435],
              [172.28393395414935, -84.04143320102366],
              [172.47704878162415, -84.11791432081571],
              [173.22408328683537, -84.41371021925437],
              [175.9856718285131, -84.15899708448768],
              [178.27721154206392, -84.47251799920247],
              [180, -84.71338]
            ]
          ]
        ]
      }
    },
    {
      type: 'Feature',
      properties: {
        filename: 'ATF.geojson',
        iso_a2: 'TF',
        label_y: -49.303721,
        label_x: 69.122136,
        name: 'Fr. S. Antarctic Lands',
        formal_en: 'Territory of the French Southern and Antarctic Lands'
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [68.935, -48.625],
            [69.58, -48.94],
            [70.525, -49.065],
            [70.56, -49.255],
            [70.28, -49.71],
            [68.745, -49.775],
            [68.72, -49.2425],
            [68.8675, -48.83],
            [68.935, -48.625]
          ]
        ]
      }
    }
  ]
};

export const countriesGeo =
  process.env.NODE_ENV === 'test' ? countriesForTest : countriesGeoJson;
