import { axios } from '../core';
import { mapToCamelCase } from '../helpers';

export const fetchSupportMaterials = () => {
  return axios
    .get(`/training-materials/`)
    .then((res) => {
      const { data } = res;
      return mapToCamelCase(data);
    })
    .catch(() => {
      return [];
    });
};
